import axios from "axios";
import { article } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddArticle(data, config) {
    const response = await axios.post(article.onPostArticle(), data, config);
    return response.data;
  },
  async onEditArticle(id, data, config) {
    const response = await axios.put(article.onEditArticle(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteArticle(id, config) {
    const response = await axios.delete(article.onDeleteArticle(id), config);
    // console.log(response)
    return response.data;
  },
  async onCommentArticle(data, config) {
    const response = await axios.post(article.onCommentArticle(), data, config);
    return response.data;
  },
};
