import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as IconlyPack from "react-iconly";
import UseAddPostForm from "../../FrontEnd/frontHome/requestPost/UseAddPostForm";
import Select from "react-select";
import { CKEditor } from "ckeditor4-react";
import { useTranslation } from "react-i18next";

function AjouterPostModal() {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
    setValue,
    isDone,
  } = UseAddPostForm();
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");
  const categories = useSelector((state) => state.categories);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if (!categories?.isLoading && categories?.data?.length) {
      setOptions(
        categories?.data.map((category) => ({
          label: category?.name,
          value: category?.id,
        }))
      );
    }
  }, [categories]);

  const uploadFile = (e) => {
    // console.log("target", e.target);
    setFileName(e.target.files[0].name);
    setValue("photo", e.target.files[0]);
  };

  const handleChange = (selected) => {
    setValue("category", selected?.value);
    setOption(selected);
  };

  useEffect(() => {
    if (formState.errors) {
      // console.log("error", formState.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (isDone) {
      setOption("");
      setFileName(null);
    }
  }, [isDone]);

  const handleReset = () => {
    setFileName(null);
    reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">{t("add_post")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="date">
                        {t("title")}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("title")}
                        name="titre"
                        {...register("titre")}
                        className="form-control  input-add-document"
                        id="Titre"
                      />
                      {formState.errors && formState.errors.titre && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.titre?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="text">
                        {t("text")}
                        <span className="required-label">*</span>
                      </label>
                      {/* <textarea
                        className="form-control input-add-document"
                        id="text"
                        rows="3"
                        name="text"
                        placeholder={t("text")}
                        {...register("text")}
                      ></textarea> */}
                      <CKEditor
                        className="form-control  input-add-document"
                        initData={""}
                        onChange={(evt) =>
                          setValue("text", evt.editor.getData())
                        }
                      />
                      {formState.errors && formState.errors.text && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.text?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="category">
                        {t("category")}
                        <span className="required-label">*</span>
                      </label>
                      <Select
                        className="basic-single input-add-document"
                        classNamePrefix="select"
                        value={option}
                        isLoading={categories?.isLoading}
                        name="category"
                        placeholder={t("select_category")}
                        {...register("category")}
                        options={options}
                        onChange={handleChange}
                      />
                      {formState.errors && formState.errors.category && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.category?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="filed">Image</label>
                      <div className="inputfile-box">
                        <input
                          type="file"
                          id="filed"
                          name="photo"
                          accept="image/*"
                          className="inputfile input-add-document"
                          onChange={(e) => uploadFile(e)}
                        />
                        <label htmlFor="filed">
                          <span
                            id="file-name"
                            className="file-box input-add-document"
                          >
                            <span className="text-input-file-costum">
                              {fileName || t("upload_image")}
                            </span>
                          </span>
                          <span className="file-button">
                            <IconlyPack.Upload
                              set="light"
                              primaryColor="#000"
                            />
                          </span>
                        </label>
                      </div>
                      {formState.errors && formState.errors.photo && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.photo?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("add")}
                    </button>
                  )}
                  {/* <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterPostModal;
