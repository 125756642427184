import React, { useMemo } from "react";
import * as IconlyPack from "react-iconly";
import AnnuairePP from "../../../images/NoImage.png";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import ImgFlag from "../../../images/pays.png";
import VoirCatalogueModal from "../../Modals/Frontend/VoirCatalogueModal";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

var CatalogueItem = ({ item }) => {
  const { t } = useTranslation();
  const country = useMemo(() => countryList().getData(), []);
  return (
    <div>
      <div className="details-annuaire-item">
        <div className="bloc-card pt-2">
          <div className="card">
            <div className="card-body p-0">
              <div className="p-2">
                <div className="d-flex">
                  <div className="pb-3">
                    <img
                      src={
                        item?.logo && !item?.logo?.includes("default.png")
                          ? baseUrl + item?.logo
                          : AnnuairePP
                      }
                      className="annuaire-pp"
                      alt="Annuaire Photo Profil"
                    />
                  </div>
                  <div className="pl-3">
                    <p className="name-annuaire">{item?.nom}</p>
                    <p className="fonction">
                      {item?.pays ? (
                        <img
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                            country?.filter(
                              (val) => val?.label === item?.pays
                            )[0]?.value
                          }.svg`}
                          alt=""
                          className="img-pays-fulbrighter"
                        />
                      ) : null}
                      {/* <img src={ImgFlag} alt="Image Drapeau" /> */}
                      <span className="pl-3">{item?.pays}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-top border-bottom bloc-catalogue">
                <div className="p-3">
                  <p className="total-nombre-facultes">
                    Total number of Faculty:
                  </p>
                  <p className="montant-catalogue">{item?.nb_facultes}</p>
                </div>
                <div className="border-milieu"></div>
                <div className="p-3">
                  <p className="total-nombre-facultes">
                    Total number of Students:{" "}
                  </p>
                  <p className="montant-catalogue">{item?.nb_students}</p>
                </div>
              </div>
              <div className="text-center my-2">
                <button
                  type="button"
                  className="btn btn-voir-catalogue"
                  data-toggle="modal"
                  data-target={`#VoirCatalogueModal${item?.id}`}
                >
                  <IconlyPack.Show set="light" primaryColor="#B6B6B6" />
                  <span className="pl-2">{t("view_catalog")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`VoirCatalogueModal${item?.id}`}
        tabIndex="-1"
        aria-labelledby="VoirCatalogueModalLabel"
        aria-hidden="true"
      >
        <VoirCatalogueModal item={item} />
      </div>
    </div>
  );
};

export default CatalogueItem;
