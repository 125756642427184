/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import FrontHeader from "../frontHeader/index";
import FrontFooter from "../frontFooter/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@nejcm/react-skeleton";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { fetchFaqs, searchFaq } from "../../../redux/faqs/faqAction";
import axios from "axios";
import api from "../../../Http/global-vars";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../../Modules/Pagination/Pagination";

var Guides = () => {
  const { t } = useTranslation();
  const faqs = useSelector((state) => state.faqs);
  const devise = useSelector((state) => state.devise);
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [per_page, setPer_page] = useState(6);

  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchFaqs());
  }, [dispatch]);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (faqs?.filteredData?.length) {
      formatData(faqs?.filteredData);
    }
  }, [faqs, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);

    setLastIndex(indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };

  const onEditFaqView = (id, index) => {
    console.log("dee");
    setSelectedIndex((prevState) => {
      console.log("prev", prevState, "index", index);
      if (prevState !== index) {
        axios
          .get(`${api}faq/${id}/`, config)
          .then((faq) => {
            console.log("faq", faq);
            if (faq?.data?.id) {
              axios
                .put(
                  `${api}faq/${id}/`,
                  {
                    nb_view: faq?.data?.nb_view + 1,
                  },
                  config
                )
                .then((response) => {
                  console.log("response update", response);
                  dispatch(fetchFaqs());
                })
                .catch((error) => {
                  console.log("error", error?.response);
                });
            }
          })
          .catch((error) => {
            console.log("error", error?.response);
          });
      }
      return index;
    });
  };

  const handleChange = (e) => {
    // console.log("search item", e.target.value.length);
    dispatch(searchFaq(faqs?.data, e.target.value.toLowerCase()));
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="bloc-banner2">
              <div className="container-warc">
                <div className="py-4 text-center">
                  <h1
                    className="titre-banner-annuaire"
                    style={{ color: "#000" }}
                  >
                    {t("guide")}
                  </h1>
                </div>
              </div>
            </div>

            <div className="container-warc py-5">
              <div className="row align-items-center pb-4">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control input-annuaire pl-3"
                      placeholder={t("search_guide")}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon-annuaire"
                      onChange={handleChange}
                    />
                    <span
                      className="input-group-text"
                      id="basic-addon-annuaire"
                    >
                      <IconlyPack.Search set="light" primaryColor="#000" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 mr-3">
                  <Accordion atomic={true}>
                    <div>
                      {faqs?.isLoading && <CardSkeleton />}
                      {!faqs?.isLoading &&
                        (currentList?.length ? (
                          currentList.map((faq, index) => (
                              <div
                                className="mb-4 guide-accordion"
                                key={faq?.id}
                                onClick={() => onEditFaqView(faq?.id, index)}
                              >
                                <AccordionItem title={faq?.titre}>
                                  <DummyContent content={faq?.contenu} />
                                </AccordionItem>
                              </div>
                            ))
                        ) : (
                          <div className="gfa-rowy mt-5">
                            <div className="alert alert-warning gfa-warning">
                              {t("noData")}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Accordion>
                </div>
                <div className="col-sm-12 text-center mb-5 py-5">
                  {currentList?.length ? (
                    <PaginationComponent
                      per_page={per_page}
                      current_page={current_page}
                      total_data={total_data}
                      handleClick={handleClick}
                      lastIndex={lastIndex}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="bloc-banner2">
          <div className="container-warc">
            <div className="py-5 text-center">
              <h1 className="titre-banner-annuaire" style={{ color: "#000" }}>
                {t("guide")}
              </h1>
            </div>
          </div>
        </div>
        <div className="container-warc pb-5 pt-3">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control input-annuaire pl-3"
                  placeholder={t("search_guide")}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon-annuaire"
                  onChange={handleChange}
                />
                <span className="input-group-text" id="basic-addon-annuaire">
                  <IconlyPack.Search set="light" primaryColor="#000" />
                </span>
              </div>
            </div>
          </div>
          <div className="my-4">
            <div className="row">
              <div className="col-sm-12">
                <Accordion atomic={true}>
                  <div className="">
                    {faqs?.isLoading && <CardSkeleton />}
                    {!faqs?.isLoading &&
                      (currentList?.length ? (
                        currentList.map((faq, index) => (
                          <div
                            className="mb-4 guide-accordion"
                            key={faq?.id}
                            onClick={() => onEditFaqView(faq?.id, index)}
                          >
                            <AccordionItem title={faq?.titre}>
                              <DummyContent content={faq?.contenu} />
                            </AccordionItem>
                          </div>
                        ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </Accordion>
              </div>
              <div className="col-sm-12 text-center mb-5">
                {currentList?.length ? (
                  <PaginationComponent
                    per_page={per_page}
                    current_page={current_page}
                    total_data={total_data}
                    handleClick={handleClick}
                    lastIndex={lastIndex}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Guides;
const DummyContent = ({ content }) => (
  <p style={{ padding: "18px" }}>
    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  </p>
);

const CardSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="col-xl-12 col-lg-12 col-md-12 pb-4">
        <Skeleton.Rectangle height={50} width={`100%`} header />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
