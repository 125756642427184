/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./Blog.css";
import Skeleton, { Card, Comment, Paragraph } from "@nejcm/react-skeleton";
import ImgBlog from "../../../images/NoImage.png";
import ImgBlog2 from "../../../images/NoImage.png";
import baseUrl from "../../../Http/backend-base-url";
import { NavLink } from "react-router-dom";
import FrontHeader from "../frontHeader/index";
import FrontFooter from "../frontFooter/index";
import ImgPp from "../../../images/others/default-profile-avatar.png";
import { isMobile } from "react-device-detect";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import api from "../../../Http/global-vars";
import {
  fetchArticles,
  searchArticle,
} from "../../../redux/articles/ArticlesAction";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/en-ca";

var Blog = () => {
  const { t } = useTranslation();
  let lng = window.localStorage.getItem("i18nextLng");
  const devise = useSelector((state) => state.devise);
  const articles = useSelector((state) => state.articles);
  const [itemToShow, setItemToShow] = useState(9);
  const [expand, setExpand] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchArticles());
  }, []);

  useEffect(() => {
    if (!articles?.isLoading && articles?.data?.length) {
      setSelectedArticle(articles?.data[0]);
    }
  }, [articles]);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onEditArticleView = (id) => {
    axios
      .get(`${api}article/${id}/`, config)
      .then((article) => {
        console.log("article", article);
        if (article?.data?.id) {
          axios
            .put(
              `${api}article/${id}/`,
              {
                nb_view: article?.data?.nb_view + 1,
              },
              config
            )
            .then((response) => {
              console.log("response update", response);
              dispatch(fetchArticles());
            })
            .catch((error) => {
              console.log("error", error?.response);
            });
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  useEffect(() => {
    if (itemToShow >= articles?.filteredData?.length) {
      // setItemToShow(3);
      setExpand(true);
    } else {
      // setItemToShow(itemToShow + 5);
      setExpand(false);
    }
  }, [itemToShow]);

  const showMore = () => {
    if (itemToShow >= articles?.filteredData?.length) {
      setItemToShow(9);
      setExpand(true);
      window.scroll(0, 0);
    } else {
      setItemToShow(itemToShow + 6);
      setExpand(false);
    }
  };

  const handleChange = (e) => {
    dispatch(searchArticle(articles?.data, e.target.value?.toLowerCase()));
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="container-warc py-5 mt-5">
              <div className="text-center mt-3 pt-3">
                <h1 className="titre-blog">{t("blog")}</h1>
                {/* <div className="pt-3 pb-5">
                  <p className="text-blog">
                    Netus enim duis nibh vitae phasellus mauris.
                  </p>
                </div> */}
                <div className="d-flex justify-content-center">
                  <form className="form ">
                    <div className="pseudo-search d-flex align-items-center">
                      <input
                        type="text"
                        className="input-search-blog pl-4"
                        placeholder={t("search_arcticle")}
                        onChange={handleChange}
                      />
                      <button
                        className="fa fa-search btn"
                        type="submit"
                      ></button>
                    </div>
                  </form>
                </div>
              </div>
              <div className=" py-5 mb-5">
                {!articles?.isLoading ? (
                  articles?.filteredData?.length &&
                  articles?.filteredData?.filter(
                    (article) => article?.id === selectedArticle?.id
                  )?.length ? (
                    <div
                      className="pb-3"
                      //   key={article?.id}
                    >
                      <div className="bg-white p-2">
                        <NavLink
                          to={{
                            pathname: `/app/detail-blog/${selectedArticle?.id}`,
                            state: { article: selectedArticle },
                          }}
                          className="no-link"
                        >
                          <div className="card">
                            <img
                              src={
                                selectedArticle?.image &&
                                !selectedArticle?.image?.includes("default.png")
                                  ? baseUrl + selectedArticle?.image
                                  : ImgBlog
                              }
                              className="card-img-top"
                              alt="ImgBlog"
                            />
                            <div className="card-body">
                              <p className="text-categorie-blog">
                                {selectedArticle?.category?.map(
                                  (item, index) =>
                                    item?.name +
                                    `${
                                      index <
                                      selectedArticle?.category?.length - 1
                                        ? " - "
                                        : " "
                                    }`
                                )}
                              </p>
                              <h5 className="card-title card-title-blog py-2">
                                {selectedArticle?.titre}
                              </h5>
                              <p className="date-publication-article pb-3">
                                {moment(
                                  selectedArticle?.date_publication
                                ).format("MMM DD, YYYY")}{" "}
                                .{" "}
                                {lng === "en"
                                  ? moment(selectedArticle?.date_publication)
                                      .locale("en-ca")
                                      .fromNow(true)
                                  : moment(selectedArticle?.date_publication)
                                      .locale("fr")
                                      .fromNow(true)}
                              </p>
                              {/* <p className="card-text card-text-blog pb-4">
                                  {article?.contenu?.slice(0, 200)}
                                </p> */}
                              <div
                                className="card-text card-text-blog pb-4"
                                // dangerouslySetInnerHTML={{
                                //   __html: article?.contenu?.slice(0, 200),
                                // }}
                              ></div>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    selectedArticle?.author?.avatar &&
                                    !selectedArticle?.author?.avatar?.includes(
                                      "default.png"
                                    )
                                      ? baseUrl +
                                        selectedArticle?.author?.avatar
                                      : ImgPp
                                  }
                                  alt="Imageblog"
                                  className="photo-pp-post"
                                />
                                <p className="name-blog pl-2">
                                  {selectedArticle?.author?.prenom +
                                    " " +
                                    selectedArticle?.author?.nom}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ) : null
                ) : null}

                {articles?.isLoading && <MobileSkeleton />}
                {!articles?.isLoading &&
                  (articles?.filteredData?.length ? (
                    articles?.filteredData
                      ?.filter((article) => article?.id !== selectedArticle?.id)
                      ?.slice(0, itemToShow)
                      .map((article) => (
                        <>
                          <div className="pb-3" key={article?.id}>
                            <div className="bg-white p-2">
                              <NavLink
                                // to="/app/detail-blog/:id"
                                to={{
                                  pathname: `/app/detail-blog/${article?.id}`,
                                  state: { article: article },
                                }}
                                className="no-link"
                              >
                                <div className="d-flex">
                                  <img
                                    // src={ImgBlog}
                                    src={
                                      article?.image &&
                                      !article?.image?.includes("default.png")
                                        ? baseUrl + article?.image
                                        : ImgBlog
                                    }
                                    className="card-img-top"
                                    alt="Imageblog"
                                    style={{ width: 130 }}
                                  />
                                  <div className="p-2">
                                    <div className="d-flex align-items-center">
                                      <p
                                        className="text-categorie-blog"
                                        style={{ fontSize: 11 }}
                                      >
                                        {article?.category?.map(
                                          (item, index) =>
                                            item?.name +
                                            `${
                                              index <
                                              article?.category?.length - 1
                                                ? " - "
                                                : " "
                                            }`
                                        )}
                                      </p>
                                      <p
                                        className="date-publication-article pl-2 mt-0"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(
                                          article?.date_publication
                                        ).format("MMM DD, YYYY")}{" "}
                                        .{" "}
                                        {lng === "en"
                                          ? moment(article?.date_publication)
                                              .locale("en-ca")
                                              .fromNow(true)
                                          : moment(article?.date_publication)
                                              .locale("fr")
                                              .fromNow(true)}
                                        {/* Jun 21, 2021 • 11 min read{" "} */}
                                      </p>
                                    </div>
                                    <h5
                                      className="card-title card-title-blog pt-2"
                                      style={{ fontSize: 12 }}
                                    >
                                      {article?.titre}
                                    </h5>
                                    {/* <p className="card-text card-text-blog pb-4">
                                {article?.contenu?.slice(0, 200)}
                              </p> */}
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          article?.author?.avatar &&
                                          !article?.author?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl + article?.author?.avatar
                                            : ImgPp
                                        }
                                        // src={ImgPp}
                                        alt="Imageprofil blog"
                                        className="photo-pp-post"
                                        style={{ width: 27, height: 27 }}
                                      />
                                      <p
                                        className="name-blog pl-2"
                                        style={{ fontSize: 11 }}
                                      >
                                        {article?.author?.prenom +
                                          " " +
                                          article?.author?.nom}{" "}
                                        {/* Aissatou DIOP */}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                        </>
                      ))
                  ) : !articles?.filteredData?.filter(
                      (article) => article?.id === selectedArticle?.id
                    )?.length ? (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ) : null)}
                {articles?.filteredData?.length > 9 ? (
                  <div className="col-12 text-center">
                    <a
                      className="btn btn-participate-event trans-0-2 ml-auto"
                      onClick={showMore}
                    >
                      {expand ? (
                        <span>{t("see_less")}</span>
                      ) : (
                        <span>{t("see_more")}</span>
                      )}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="container-warc py-5 mt-5">
          <div className="text-center">
            <h1 className="titre-blog pt-3 pb-5">{t("blog")}</h1>
            {/* <div className="pt-3 pb-5">
              <p className="text-blog">
                Netus enim duis nibh vitae phasellus mauris.
              </p>
            </div> */}
            <div className="d-flex justify-content-center">
              <form className="form ">
                <div className="pseudo-search d-flex align-items-center">
                  <input
                    type="text"
                    className="input-search-blog pl-4"
                    placeholder={t("search_arcticle")}
                    onChange={handleChange}
                  />
                  <button className="fa fa-search btn" type="submit"></button>
                </div>
              </form>
            </div>
          </div>
          <div className="row py-5 mb-5">
            {!articles?.isLoading ? (
              articles?.filteredData?.length &&
              articles?.filteredData?.filter(
                (article) => article?.id === selectedArticle?.id
              )?.length ? (
                <div className="col-md-12 py-3">
                  <div className="bg-white p-2">
                    <NavLink
                      to={{
                        pathname: `/app/detail-blog/${selectedArticle?.id}`,
                        state: { article: selectedArticle },
                      }}
                      className="no-link"
                    >
                      <div className="d-flex">
                        <div className="embed-responsive embed-responsive-16by9">
                          <img
                            alt="Cardimage cap"
                            className="card-img-top embed-responsive-item"
                            src={
                              selectedArticle?.image &&
                              !selectedArticle?.image?.includes("default.png")
                                ? baseUrl + selectedArticle?.image
                                : ImgBlog
                            }
                          />
                        </div>
                        {/* <div>
                        <img
                          src={
                            selectedArticle?.image &&
                            !selectedArticle?.image?.includes("default.png")
                              ? baseUrl + selectedArticle?.image
                              : ImgBlog
                          }
                          className="card-img-top"
                          alt="Image blog"
                        />
                      </div> */}
                        <div className="card-body position-relative">
                          <p className="text-categorie-blog">
                            {/* {selectedArticle?.category?.name} */}
                            {selectedArticle?.category?.map(
                              (item, index) =>
                                item?.name +
                                `${
                                  index < selectedArticle?.category?.length - 1
                                    ? " - "
                                    : " "
                                }`
                            )}
                          </p>
                          <h5 className="card-title card-title-blog py-2">
                            {selectedArticle?.titre}
                          </h5>
                          <p className="date-publication-article pb-3">
                            {moment(selectedArticle?.date_publication).format(
                              "MMM DD, YYYY"
                            )}{" "}
                            .{" "}
                            {lng === "en"
                              ? moment(selectedArticle?.date_publication)
                                  .locale("en-ca")
                                  .fromNow(true)
                              : moment(selectedArticle?.date_publication)
                                  .locale("fr")
                                  .fromNow(true)}
                          </p>

                          <div
                            className="card-text card-text-blog pb-4"
                            dangerouslySetInnerHTML={{
                              __html: selectedArticle?.contenu?.slice(0, 350),
                            }}
                          />
                          <div
                            className="d-flex align-items-center"
                            style={{ position: "absolute", bottom: 0 }}
                          >
                            <img
                              src={
                                selectedArticle?.author?.avatar &&
                                !selectedArticle?.author?.avatar?.includes(
                                  "default.png"
                                )
                                  ? baseUrl + selectedArticle?.author?.avatar
                                  : ImgPp
                              }
                              alt="Imageprofilblog"
                              className="photo-pp-post"
                            />
                            <p className="name-blog pl-2">
                              {selectedArticle?.author?.prenom +
                                " " +
                                selectedArticle?.author?.nom}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              ) : null
            ) : (
              <Skeleton className="col-md-12 py-3">
                <Skeleton className="d-flex">
                  <Skeleton.Rectangle
                    height={`20vw`}
                    width={`30%`}
                    className="mb-3"
                  />

                  <Skeleton className="card-body position-relative d-flex flex-column justify-content-between">
                    <Skeleton.Rectangle
                      height={20}
                      width={`25%`}
                      className="mb-1"
                    />
                    <Skeleton.Rectangle
                      height={20}
                      width={`50%`}
                      className="mb-3"
                    />
                    <Paragraph className="mb-3" />
                    <Skeleton className="d-flex">
                      <Skeleton.Circle
                        width={40}
                        height={40}
                        className="mb-3 mr-1"
                      />
                      <Skeleton.Rectangle
                        height={20}
                        width={`25%`}
                        className="mt-1"
                      />
                    </Skeleton>
                  </Skeleton>
                </Skeleton>
              </Skeleton>
            )}
            {articles?.isLoading && <CardSkeleton />}
            {!articles?.isLoading &&
              (articles?.filteredData?.filter(
                (article) => article?.id !== selectedArticle?.id
              )?.length ? (
                articles?.filteredData
                  ?.filter((article) => article?.id !== selectedArticle?.id)
                  ?.slice(0, itemToShow)
                  .map((article) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-4 pb-3 d-flex w-full"
                      key={article?.id}
                    >
                      <div
                        className="bg-white p-2"
                        onClick={() => onEditArticleView(article?.id)}
                      >
                        <NavLink
                          // to="/app/detail-blog/:id"
                          to={{
                            pathname: `/app/detail-blog/${article?.id}`,
                            state: { article: article },
                          }}
                          className="no-link"
                        >
                          <div className="card">
                            <img
                              // src={ImgBlog}
                              src={
                                article?.image &&
                                !article?.image?.includes("default.png")
                                  ? baseUrl + article?.image
                                  : ImgBlog
                              }
                              className="card-img-top article-home-img"
                              alt="Imageblog"
                            />
                            <div className="card-body">
                              <p className="text-categorie-blog">
                                {article?.category?.map(
                                  (item, index) =>
                                    item?.name +
                                    `${
                                      index < article?.category?.length - 1
                                        ? " - "
                                        : " "
                                    }`
                                )}
                              </p>
                              <h5 className="card-title card-title-blog py-2">
                                {article?.titre}
                              </h5>
                              <p className="date-publication-article pb-3">
                                {moment(article?.date_publication).format(
                                  "MMM DD, YYYY"
                                )}{" "}
                                .{" "}
                                {lng === "en"
                                  ? moment(article?.date_publication)
                                      .locale("en-ca")
                                      .fromNow(true)
                                  : moment(article?.date_publication)
                                      .locale("fr")
                                      .fromNow(true)}
                              </p>

                              <div
                                className="card-text card-text-blog pb-4"
                                dangerouslySetInnerHTML={{
                                  __html: article?.contenu?.slice(0, 150),
                                }}
                              />
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    article?.author?.avatar &&
                                    !article?.author?.avatar?.includes(
                                      "default.png"
                                    )
                                      ? baseUrl + article?.author?.avatar
                                      : ImgPp
                                  }
                                  // src={ImgPp}
                                  alt="Imageprofil blog"
                                  className="photo-pp-post"
                                />
                                <p className="name-blog pl-2">
                                  {article?.author?.prenom +
                                    " " +
                                    article?.author?.nom}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ))
              ) : !articles?.filteredData?.filter(
                  (article) => article?.id === selectedArticle?.id
                )?.length ? (
                <div className="gfa-rowy mt-5">
                  <div className="alert alert-warning gfa-warning">
                    {t("noData")}
                  </div>
                </div>
              ) : null)}

            {articles?.filteredData?.length > 9 ? (
              <div className="col-12 text-center ">
                <a
                  className="btn btn-participate-event trans-0-2 ml-auto"
                  onClick={showMore}
                >
                  {expand ? (
                    <span>{t("see_less")}</span>
                  ) : (
                    <span>{t("see_more")}</span>
                  )}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Blog;

const CardSkeleton = () => {
  const renderModules = Array(6)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="col-xl-4 col-lg-4 col-md-4 pb-3">
        <Skeleton.Rectangle height={300} width={`100%`} className="mb-3" />
        <Skeleton.Rectangle height={20} width={`25%`} className="mb-1" />
        <Skeleton.Rectangle height={20} width={`50%`} className="mb-3" />
        <Paragraph className="mb-3" />
        <Skeleton className="d-flex">
          <Skeleton.Circle width={40} height={40} className="mb-3 mr-1" />
          <Skeleton.Rectangle height={20} width={`25%`} className="mt-1" />
        </Skeleton>
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};

const MobileSkeleton = () => {
  const renderModules = Array(6)
    .fill()
    .map((item, index) => (
      <Skeleton className="col-md-12 py-3" key={index}>
        <Skeleton className="d-flex">
          <Skeleton.Rectangle height={`20vh`} width={`40%`} className="mb-3" />

          <Skeleton className="w-100 position-relative d-flex flex-column justify-content-between pl-1">
            <Skeleton.Rectangle height={20} width={`100%`} className="mb-1" />
            <Skeleton.Rectangle height={20} width={`100%`} className="mb-3" />
            {/* <Paragraph className="mb-3" /> */}
            <Skeleton className="d-flex">
              <Skeleton.Circle width={40} height={40} className="mb-3 mr-1" />
              <Skeleton.Rectangle height={20} width={`100%`} className="mt-2" />
            </Skeleton>
          </Skeleton>
        </Skeleton>
      </Skeleton>
    ));
  return (
    <React.Fragment>
      <Skeleton className="col-xl-4 col-lg-4 col-md-4 pb-3">
        <Skeleton.Rectangle height={300} width={`100%`} className="mb-3" />
        <Skeleton.Rectangle height={20} width={`25%`} className="mb-1" />
        <Skeleton.Rectangle height={20} width={`50%`} className="mb-3" />
        <Skeleton.Rectangle height={20} width={`50%`} className="mb-3" />
        <Skeleton className="d-flex">
          <Skeleton.Circle width={40} height={40} className="mb-3 mr-1" />
          <Skeleton.Rectangle height={20} width={`25%`} className="mt-1" />
        </Skeleton>
      </Skeleton>
      {renderModules}
    </React.Fragment>
  );
};
