import React from "react";
import { NavLink } from "react-router-dom";
import Logo from '../../images/logo.png'
var HeaderLandingPage = (props) => {
  return (
    <div className="container-fluid fixed-top padding-header-landingpage">
      <nav className="navbar navbar-expand-lg navbar-light justify-content-center">
        <NavLink className="navbar-brand" to="/">
          <div className="logo-item">
            <img src={Logo} alt="Logo" className="img-logo-landingpage" />
          </div>
        </NavLink>
      </nav>
    </div>
  );
};

export default HeaderLandingPage;