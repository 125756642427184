import api from "../../Http/global-vars";
import { CHEARCH_ARTICLE, FETCH_ARTICLES } from "../types";

export const fetchArticles = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "article/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_ARTICLES,
          payload: data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const searchArticle = (articles, searchItem) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line
  data = articles?.filter((doc) =>
    doc?.titre?.toLowerCase()?.includes(searchItem)
  );
  console.log("voir", searchItem, data);
  // return "";
  return (dispatch) => {
    dispatch({
      type: CHEARCH_ARTICLE,
      payload: {
        items: !searchItem?.length
          ? articles?.sort((a, b) => b?.id - a?.id)
          : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
