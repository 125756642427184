import React, { useEffect } from "react";
import Header from "../../../Navs/Header";
import Skeleton from "react-loading-skeleton";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import ChartApplication from "./chartApplication/chartApplication";
import "./DashboardAdmin.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from "../../../../redux/dashboard/dashboardAction";

var DashboardAdmin = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state?.dashboard);
  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
          key={index}
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };
  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header"
        >
          <div
            className="container-fluid  page-content-container"
            style={{
              background: "transparent",
              boxShadow: "inherit",
              borderRadius: "inherit",
            }}
          >
            <div className="row mb-3 px-2">
              {dashboard?.isLoading && <CardSkeleton />}
              {!dashboard?.isLoading && (
                <React.Fragment>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="child-label-card-statistics"></p>
                        <p className="parent-label-card-statistics">
                          {t("nbr_fulbrighter")}
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {dashboard?.data?.nbr_fulbrighter}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="child-label-card-statistics"></p>
                        <p className="parent-label-card-statistics">
                          {t("nbr_expert")}
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {dashboard?.data?.nbr_expert}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="child-label-card-statistics"></p>
                        <p className="parent-label-card-statistics">
                          {t("nbr_universities")}
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {dashboard?.data?.nbr_university}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="front-card p-sm-4 p-3 dashboard-card mb-3">
            <ChartApplication />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
