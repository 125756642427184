import React from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import Header from "../../../Navs/Header";
import PhotoProfil from "../../../../images/profil.png";

var ChatBoxAdmin = (props) => {
  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header-message"
        >
          <div className="page-body-container py-4">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">Messagerie</h1>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="content-view-start" id="messagerie-desktop">
                  <div className="mt-5 messagerie">
                    <div className="h-100">
                      <div className="row justify-content-center h-100 bg-white">
                        <div className="col-xl-3 col-lg-3 col-md-4 chat mt-0">
                          <div className="card mb-sm-3 mb-md-0 contacts_card w-full">
                            <div className="card-body contacts_body">
                              <ui className="contacts">
                                <li className="user_item active">
                                  <div className="d-flex bd-highlight">
                                    <div className="img_cont">
                                      <img
                                        src={PhotoProfil}
                                        alt="Profil"
                                        className="rounded-circle user_img"
                                      />
                                    </div>
                                    <div className="user_info">
                                      <span>Abdou Seck</span>
                                      <p>abdou2@gmail.com</p>
                                    </div>
                                  </div>
                                </li>
                                <li className="user_item">
                                  <div className="d-flex bd-highlight">
                                    <div className="img_cont">
                                      <img
                                        src={PhotoProfil}
                                        alt="Profil"
                                        className="rounded-circle user_img"
                                      />
                                    </div>
                                    <div className="user_info">
                                      <span>Abdou Seck</span>
                                      <p>abdou2@gmail.com</p>
                                    </div>
                                  </div>
                                </li>
                                <li className="user_item">
                                  <div className="d-flex bd-highlight">
                                    <div className="img_cont">
                                      <img
                                        src={PhotoProfil}
                                        alt="Profil"
                                        className="rounded-circle user_img"
                                      />
                                    </div>
                                    <div className="user_info">
                                      <span>Abdou Seck</span>
                                      <p>abdou2@gmail.com</p>
                                    </div>
                                  </div>
                                </li>
                                <li className="user_item">
                                  <div className="d-flex bd-highlight">
                                    <div className="img_cont">
                                      <img
                                        src={PhotoProfil}
                                        alt="Profil"
                                        className="rounded-circle user_img"
                                      />
                                    </div>
                                    <div className="user_info">
                                      <span>Abdou Seck</span>
                                      <p>abdou2@gmail.com</p>
                                    </div>
                                  </div>
                                </li>
                              </ui>
                            </div>
                            <div className="card-footer"></div>
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 chat">
                          <div className="card w-full">
                            <div className="card-header msg_head">
                              <div className="d-flex bd-highlight">
                                <div className="img_cont">
                                  <img
                                    alt=""
                                    src={PhotoProfil}
                                    className="rounded-circle user_img"
                                  />
                                </div>
                                <div className="user_info">
                                  <span> Abdou Seck</span>
                                  <p>3 Messages</p>
                                </div>
                              </div>

                              <span id="action_menu_btn">
                                <i className="fas fa-ellipsis-v"></i>
                              </span>
                              <div className="action_menu">
                                <ul>
                                  <li>
                                    <i className="fas fa-user-circle"></i> View
                                    profile
                                  </li>
                                  <li>
                                    <i className="fas fa-users"></i> Add to
                                    close friends
                                  </li>
                                  <li>
                                    <i className="fas fa-plus"></i> Add to group
                                  </li>
                                  <li>
                                    <i className="fas fa-ban"></i> Block
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="card-body msg_card_body">
                              <div className="d-flex justify-content-start mb-4">
                                <div className="img_cont_msg">
                                  <img
                                    alt=""
                                    src={PhotoProfil}
                                    className="rounded-circle user_img_msg"
                                  />
                                </div>
                                <div className="msg_cotainer">
                                  Abcdefghijklmno
                                  <span className="msg_time">
                                    8:40 AM, Today
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end mb-4">
                                <div className="msg_cotainer_send">
                                  Abcdefg
                                  <span className="msg_time_send">10.20</span>
                                </div>
                                <div className="img_cont_msg">
                                  <img
                                    alt=""
                                    src={PhotoProfil}
                                    className="rounded-circle user_img_msg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="card-footer">
                              <form className="input-group">
                                <div className="input-group-append">
                                  <span className="input-group-text attach_btn">
                                    <i className="fas fa-paperclip"></i>
                                  </span>
                                </div>
                                <textarea
                                  name="message"
                                  className="form-control type_msg"
                                  placeholder="Message"
                                ></textarea>
                                <div ty className="input-group-append">
                                  <button
                                    role="button"
                                    type="submit"
                                    className="input-group-text send_btn"
                                  >
                                    <i className="fas fa-location-arrow"></i>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxAdmin;
