import axios from "axios";
import { user } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddUser(data, config) {
    const response = await axios.post(user.onPostUser(), data, config);
    return response.data;
  },
  async onEditUser(id, data, config) {
    const response = await axios.put(user.onEditUser(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteUser(id, config) {
    const response = await axios.delete(user.onDeleteUser(id), config);
    // console.log(response)
    return response.data;
  },
  async onAddCoordo(data, config) {
    const response = await axios.post(user.onPostCoordo(), data, config);
    return response.data;
  },
  async onEditAdmin(id, data, config) {
    const response = await axios.put(user.onEditAdmin(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteAdmin(id, config) {
    const response = await axios.delete(user.onDeleteAdmin(id), config);
    // console.log(response)
    return response.data;
  },
  async onAddAdmin(data, config) {
    const response = await axios.post(user.onPostAdmin(), data, config);
    return response.data;
  },
};
