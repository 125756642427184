import api from "../../Http/global-vars";
import { FETCH_ML } from "../types";

export const fetchMl = () => {
  return async (dispatch) => {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    //   },
    // };
    const url = api + "legal_notice/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_ML,
          payload: data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
