import React, { useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./InstitutionsEtPartenairesCoordonnateur.css";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import GestionFulbrighterTable from "../../../Tables/Coordonnateur/GestionFulbrighterTable/GestionFulbrighterTable";
import * as IconlyPack from "react-iconly";
import AjouterInstitution from "../../../Modals/Coordonnateur/AjouterInstitution";
import Header from "../../../Navs/Header";
import InstitutionEtPartenairesTable from "../../../Tables/Coordonnateur/InstitutionEtPartenairesTable/InstitutionEtPartenairesTable";
import { useTranslation } from "react-i18next";

var InstitutionsEtPartenairesCoordonnateur = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("institution_partners")}</h1>
                </div>
                <div
                  className="adding-item-container linkable"
                  data-toggle="modal"
                  data-target="#AjouterInstitution"
                >
                  <span className="icon-container">
                    <IconlyPack.Plus set="light" primaryColor="#003DA5" />
                  </span>
                  <span className="adding-item-label">{t("add_partner")}</span>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <InstitutionEtPartenairesTable />
                </div>
              </div>
              <div
                className="modal fade"
                id="AjouterInstitution"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AjouterInstitutionLabel"
                aria-hidden="true"
              >
                <AjouterInstitution />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionsEtPartenairesCoordonnateur;
