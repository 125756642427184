import React, { useEffect, useState, useMemo } from "react";
import UseEditFulbrighter from "../../Dashboard/Coordonnateur/GestionDesFulbrighters/requestFulbrighters/UseEditFulbrighter";
import ReactFlagsSelect from "react-flags-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CKEditor } from "ckeditor4-react";

function ModifierFulbriterModal({ fulbrighter, from }) {
  const { t } = useTranslation();
  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseEditFulbrighter();

  const [phone, setPhone] = React.useState("");
  const [startDate, setStartDate] = useState(new Date());

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  const changeHandler = (value) => {
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  // const [input, setInput] = useState("");
  // const [tags, setTags] = useState([]);
  // const [isKeyRelease, setIsKeyReleased] = useState(false);

  // useEffect(() => {
  //   if (tags?.length) {
  //     register("domaine_expertise");
  //     setValue("domaine_expertise", JSON.stringify(tags));
  //     // console.log(tags);
  //   } else {
  //     register("domaine_expertise");
  //     setValue("domaine_expertise", "");
  //   }
  // }, [tags, input]);

  useEffect(() => {
    if (fulbrighter?.id) {
      setValue("id", fulbrighter?.id);
      setValue("prenom", fulbrighter?.prenom);
      setValue("nom", fulbrighter?.nom);
      setValue("email", fulbrighter?.email);
      setValue("phone", fulbrighter?.phone);
      if (fulbrighter?.phone) {
        setPhone(fulbrighter?.phone);
      }
      setValue("adresse", fulbrighter?.adresse || "");
      setValue("pays", fulbrighter?.pays);
      setValue("statut", fulbrighter?.statut);
      setValue("biographie", fulbrighter?.biographie);
      setValue("annee_cohorte", fulbrighter?.annee_cohorte);
      if (fulbrighter?.annee_cohorte) {
        setStartDate(new Date(fulbrighter?.annee_cohorte + "/01/01"));
      }
      setValue("numero_etudiant", fulbrighter?.numero_etudiant);
      setValue("from", from);
      console.log("from", from);
      if (
        fulbrighter?.pays &&
        options.filter((option) => option.label === fulbrighter?.pays)?.length
      ) {
        setVal(
          options.filter((option) => option.label === fulbrighter?.pays)[0]
            ?.value
        );
      }
      // setValue("domaine_expertise", fulbrighter?.domaine_expertise || "");
      // if (fulbrighter?.domaine_expertise) {
      //   if (IsJsonString(fulbrighter?.domaine_expertise)) {
      //     setTags(JSON.parse(fulbrighter?.domaine_expertise));
      //   }
      // }
    }
  }, [from, fulbrighter, options, setValue]);

  // const IsJsonString = (str) => {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // };

  useEffect(() => {
    if (formState.errors) {
      // console.log("error", formState.errors)
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  // const onKeyDown = (e) => {
  //   const { key } = e;
  //   const trimmedInput = input.trim();

  //   if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
  //     e.preventDefault();
  //     setTags((prevState) => [...prevState, trimmedInput]);
  //     setInput("");
  //   }

  //   if (key === "Backspace" && !input.length && tags.length && isKeyRelease) {
  //     e.preventDefault();
  //     const tagsCopy = [...tags];
  //     const poppedTag = tagsCopy.pop();

  //     setTags(tagsCopy);
  //     setInput(poppedTag);
  //   }

  //   setIsKeyReleased(false);
  // };

  // const onKeyUp = () => {
  //   setIsKeyReleased(true);
  // };

  // const deleteTag = (e, index) => {
  //   e.preventDefault();
  //   setTags((prevState) => prevState.filter((tag, i) => i !== index));
  // };

  // const onChange = (e) => {
  //   const { value } = e.target;
  //   setInput(value);
  // };

  const handleReset = () => {
    // reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("edit_fulbrighter")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="prenom">
                        {t("firstName")}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("firstName")}
                        {...register("prenom")}
                        name="prenom"
                        className="form-control  input-add-document"
                        id="prenom"
                      />
                      {formState.errors && formState.errors.prenom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.prenom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">
                        {t("lastName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("lastName")}
                        name="nom"
                        {...register("nom")}
                        className="form-control  input-add-document"
                        id="nom"
                      />
                      {formState.errors && formState.errors.nom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">
                        {t("email")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder={t("email")}
                        name="email"
                        {...register("email")}
                        className="form-control  input-add-document"
                        id="email"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="status">
                        {t("status")} <span className="required-label">*</span>
                      </label>
                      <select
                        className="form-control input-add-document"
                        id="status"
                        {...register("statut")}
                        name="statut"
                      >
                        <option value="" hidden>
                          {t("status")}
                        </option>
                        <option value="etudiant">{t("student")}</option>
                        <option value="faculty">{t("faculty")}</option>
                        <option value="chercheur">{t("searcher")}</option>
                        <option value="staff">{t("staff")}</option>
                        <option value="ata">{t("ata")}</option>
                      </select>
                      {formState.errors && formState.errors.statut && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.statut?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="country">
                        {t("country")}
                        <span className="required-label">*</span>
                      </label>

                      <ReactFlagsSelect
                        className="form-control input-add-document"
                        selected={val}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                        placeholder={t("select_country")}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <div className="form-group crud-form-group">
                      <label htmlFor="year">
                        {t("cohort_year")}
                        <span className="required-label">*</span>
                      </label>
                      <DatePicker
                        dateFormat="yyyy"
                        showYearPicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          console.log(date);
                          setValue(
                            "annee_cohorte",
                            moment(date).format("YYYY")
                          );
                        }}
                        className="form-control input-add-document"
                      />
                      {formState.errors && formState.errors.annee_cohorte && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.annee_cohorte?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="numero_etudiant">
                        {t("student_number")}
                      </label>
                      <input
                        type="number"
                        placeholder={t("student_number")}
                        {...register("numero_etudiant")}
                        name="numero_etudiant"
                        className="form-control  input-add-document"
                        id="numero_etudiant"
                      />
                      {formState.errors && formState.errors.numero_etudiant && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.numero_etudiant?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="phone">{t("phone")}</label>
                      <PhoneInput
                        masks={{ sn: ".. ... .. .." }}
                        inputClass="form-control input-add-document phone-edit"
                        country={"sn"}
                        // onlyCountries={['sn']}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "5px",
                          background: "#F2F3F3",
                          height: "49px",
                          border: "none",
                        }}
                      />
                      {/* <input
                        type="tel"
                        placeholder="Tel"
                        name="phone"
                        {...register("phone")}
                        className="form-control  input-add-document"
                        id="phone"
                      /> */}
                      {formState.errors && formState.errors.phone && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.phone?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">{t("address")}</label>
                      <input
                        type="text"
                        placeholder="Adress"
                        name="adresse"
                        {...register("adresse")}
                        className="form-control  input-add-document"
                        id="adresse"
                      />
                      {formState.errors && formState.errors.adresse && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.adresse?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="exampleFormControlSelect1">Country</label>

                      <ReactFlagsSelect
                        className="form-control input-add-document"
                        selected={val}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">{t("skills")}</label>
                      <div className="tags-input">
                        <input
                          type="text"
                          className="form-control  input-add-document"
                          value={input}
                          onKeyDown={onKeyDown}
                          onChange={onChange}
                          onKeyUp={onKeyUp}
                        />
                        {tags.map((tag, index) => (
                          <Tag className="mb-4 tag" key={index}>
                            {tag}
                            <Delete onClick={(e) => deleteTag(e, index)} />
                          </Tag>
                        ))}
                      </div>
                      <Help>{t("skills_guide")}</Help>
                      {formState.errors && formState.errors.adresse && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.adresse?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="bio">{t("bio")}</label>

                      {/* <textarea
                        className="form-control  input-add-document"
                        id="bio"
                        rows="3"
                        name="biographie"
                        {...register("biographie")}
                        placeholder={t("bio_guide")}
                      ></textarea> */}
                      <CKEditor
                        className="form-control  input-add-document"
                        initData={
                          fulbrighter?.biographie &&
                          fulbrighter?.biographie !== undefined
                            ? fulbrighter?.biographie
                            : ""
                        }
                        onChange={(evt) => {
                          if (evt.editor.getData() !== undefined) {
                            setValue("biographie", evt.editor.getData());
                          }
                        }}
                      />
                      {formState.errors && formState.errors.biographie && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.biographie?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("edit")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierFulbriterModal;

