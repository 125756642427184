import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useTranslation } from "react-i18next";

var AccountDetails = ({ state, onSubmit }) => {
  const { t } = useTranslation();

  const Schema = yup.object().shape({
    // email: yup.string().required(t("required")).email(t("invalid_mail")),
    prenom: yup.string().required(t("required")),
    nom: yup.string().required(t("required")),
    numero_etudiant: yup.string(),
    statut: yup.string().required(t("required")).nullable(),
    phone: yup.string(),
    numero_urgence: yup.string(),
  });

  const { formState, register, handleSubmit, setValue, clearErrors } = useForm({
    resolver: yupResolver(Schema),
  });
  const history = useHistory();
  let token = window.sessionStorage.getItem("userToken");

  const [phoneInput, setPhone] = React.useState("");

  useEffect(() => {
    // console.log("erreur", formState, state);
    window.scrollTo(0, 0);
  }, []);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        history.push("/app/home");
      }, 1000);
    }
  }, [history, token]);

  useEffect(() => {
    // console.log("erreur", formState.errors, state);
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  return (
    <div>
      <div className="login-page-container">
        <section className="auth-page-content">
          <form className="row auth-page-row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-4 offset-md-4 auth-page-col">
              <div className="row auth-form-row">
                {/* <div className="logo-ecompta mb-4">
                  <img src={Logo} alt="Logo E-compte" />
                </div> */}
                <h1 className="auth-form-title m-b-90">{t("welcome")}</h1>
                <div className="col-sm-12 d-flex justify-content-center mb-4">
                  <p className="text-comment">
                    {t("have_account")}&nbsp;
                    <NavLink
                      className="color-green no-link"
                      to="/app/connexion"
                    >
                      {t("login")}
                    </NavLink>
                  </p>
                </div>
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="numero_etudiant" className="label-ecompta">
                      {t("student_number")}
                      {/* <span className="required-label">*</span>{" "} */}
                    </label>
                    <input
                      name="numero_etudiant"
                      type="text"
                      {...register("numero_etudiant")}
                      className="form-control auth-form-control pl-3"
                      id="numero_etudiant"
                      placeholder={t("student_number")}
                    />
                    {formState.errors && formState.errors.numero_etudiant && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.numero_etudiant?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="prenom" className="label-ecompta">
                      {t("firstName")}
                      <span className="required-label">*</span>{" "}
                    </label>
                    <input
                      name="prenom"
                      type="text"
                      {...register("prenom")}
                      className="form-control auth-form-control pl-3"
                      id="prenom"
                      placeholder={t("firstName")}
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="nom" className="label-ecompta">
                      {t("lastName")}
                      <span className="required-label">*</span>{" "}
                    </label>
                    <input
                      name="nom"
                      type="text"
                      {...register("nom")}
                      className="form-control auth-form-control pl-3"
                      id="nom"
                      placeholder={t("lastName")}
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 crud-col-item m-b-5">
                  <div className="form-group" id="input-statut-tablette">
                    <label className="statut-label" htmlFor="fixe">
                      {t("status")}
                      <span className="required-label">*</span>
                    </label>
                    <div className="container-radio-input">
                      <div className="form-radio-input row-statut radio-input">
                        <div className="">
                          <label className="ml-0" htmlFor="bfem">
                            <input
                              type="radio"
                              name="statut"
                              {...register("statut")}
                              value="etudiant"
                              id="bfem"
                            />
                            <span>
                              {/* Étudiant */}
                              {t("student")}
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label htmlFor="bep">
                            <input
                              type="radio"
                              name="statut"
                              {...register("statut")}
                              value="chercheur"
                              id="bep"
                            />
                            <span>
                              {/* Chercheur */}
                              {t("searcher")}
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label htmlFor="cap">
                            <input
                              type="radio"
                              name="statut"
                              {...register("statut")}
                              value="faculty"
                              id="cap"
                            />
                            <span>
                              {/* Enseignant */}
                              {t("faculty")}
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label htmlFor="staff">
                            <input
                              type="radio"
                              name="statut"
                              {...register("statut")}
                              value="staff"
                              id="staff"
                            />
                            <span>
                              {/* Staff */}
                              {t("staff")}
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label htmlFor="ata">
                            <input
                              type="radio"
                              name="statut"
                              {...register("statut")}
                              value="ata"
                              id="ata"
                            />
                            <span>
                              {/* ata */}
                              {t("ata")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {formState.errors && formState.errors.statut && (
                  <div className="alert alert-danger gfa-alert-danger">
                    {formState.errors.statut?.message}
                  </div>
                )}
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="numero" className="label-ecompta">
                      {t("phone")}
                    </label>
                    <PhoneInput
                      masks={{ sn: ".. ... .. .." }}
                      inputclassName=""
                      country={"sn"}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phoneInput}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        padding: "30px 10px 30px 60px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col m-b-20">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="numero_urgence" className="label-ecompta">
                      {/* Contact en cas d’urgence */}
                      {t("emergency_contact")}
                    </label>
                    <input
                      name="numero_urgence"
                      type="tel"
                      {...register("numero_urgence")}
                      className="form-control auth-form-control pl-3"
                      id="numero"
                      placeholder={t("emergency_contact")}
                    />
                  </div>
                </div>

                <div className="col-sm-12 auth-input-col py-4">
                  {/* {isSuccessfullySubmitted === false ? ( */}
                  <button
                    className="btn auth-submit-btn"
                    type="submit"
                    // onClick={navigation.next}
                  >
                    {/* Inscription */}
                    {t("next")}
                    <span className="pl-2">
                      <IconlyPack.ArrowRight set="light" />
                    </span>
                  </button>
                  {/* ) : (
                    <button className="btn auth-submit-btn" disabled>
                    
                      {t("en_cours")}
                      <span className="pl-2">
                        <i className="fas fa-spin fa-spinner"></i>
                      </span>
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </form>
          <div className="row mx-0 pt-5 mt-5 py-md-4 py-3 justify-content-center">
            <p className="copyright-connexion">
              ©2021 All Rights Reserved. WARC.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AccountDetails;
