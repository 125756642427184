import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../Http/global-vars";
import { fetchUserMessages } from "../../../redux/messages/MessagesAction";

var ChatBoxModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const Schema = yup.object().shape({
    message: yup.string().required(t("required")),
  });
  const { reset, formState, register, handleSubmit, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(Schema),
    });
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  useEffect(() => {
    if (isDone) {
      setValue("message", "");
    }
  }, [isDone, setValue]);
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (user?.data?.id) {
      formValues["sender"] = user?.data?.id;
    }
    var request = axios.post(`${api}messageadmin/`, formValues, config);
    await request
      .then(async (resp) => {
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(fetchUserMessages(user?.data?.id));
        await setIsSuccessfullySubmitted(false);
        await window.$("#ChatBoxModal").modal("hide");
        await setIsDone(true);

        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return (
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title titre-nous-contacter" id="ChatBoxModa">
            Envoyer un message
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group my-4">
                    <label className="label-form">Message</label>
                    <textarea
                      class="form-control input-form-incident"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      placeholder="Message"
                      {...register("message")}
                    ></textarea>
                    {formState.errors && formState.errors.message && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.message?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-4 d-flex justify-content-end">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("send")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxModal;
