import React from "react";
import ReactPlayer from "react-player";
import baseUrl from "../../../Http/backend-base-url";
import { getVideoId } from "../../../utils";

function DetailsVideo({ video }) {
  // const { t } = useTranslation();
  const [videoId, setVideoId] = React.useState("");
  const [url, setUrl] = React.useState("");
  React.useEffect(() => {
    if (video?.lien) {
      console.log("lien", getVideoId(video?.lien));
      if (getVideoId !== false) {
        setVideoId(getVideoId(video?.lien));
      }
    }
  }, [video]);

  React.useEffect(() => {
    if (video?.lien) {
      if (videoId) {
        setUrl(`https://www.youtube.com/embed/${videoId}`);
      } else {
        setUrl(video?.lien);
      }
    } else {
      setUrl(baseUrl + video?.video);
    }
  }, [video, videoId]);
  return (
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5 className="modal-title text-titre" id="addCommentModal">
            Details
          </h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <div className="pb-5 pt-3 text-center">
              <h1 className="h3-theme">{video?.title} </h1>
            </div>
            <div className="pb-5 mb-5">
              <p className="text-paragraph" style={{ color: "#000000" }}>
                {video?.description}
              </p>
            </div>
            
            {!!url && (
              <iframe
                src={url}
                title={video?.title + "video"}
                width={"100%"}
                height={500}
                controls
                allowFullScreen
              ></iframe>
              
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsVideo;
