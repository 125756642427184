/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../Http/global-vars";
import { fetchAnnualReports } from "../../../redux/annual_reports/annual_reportsAction";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import DocumentItem from "./DocumentItem";
import { CardSkeleton } from "./Documents";
import "./Documents.css";

var AnnualReportsFront = (props) => {
  const abble = useReportAuthentification(true);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const devise = useSelector((state) => state.devise);
  const documents = useSelector((state) => (abble ? state.annual_reports : []));
  const [itemToShow, setItemToShow] = useState(6);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchAnnualReports());
    setTimeout(() => setIsLoading(false), 5000);
  }, []);

  useEffect(() => {
    if (itemToShow >= documents?.length) {
      // setItemToShow(3);
      setExpand(true);
    } else {
      // setItemToShow(itemToShow + 5);
      setExpand(false);
    }
  }, [itemToShow]);
  const showMore = () => {
    if (itemToShow >= documents?.length) {
      setItemToShow(6);
      setExpand(true);
      window.scroll(0, 0);
    } else {
      setItemToShow(itemToShow + 6);
      setExpand(false);
    }
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="banner">
              <div className="py-1 text-center">
                <h1 className="titre-banner">{t("annual_reports")}</h1>
              </div>
            </div>
            <div className="container-warc py-5 mt-5">
              <div className="row mb-5">
                {isLoading && <CardSkeleton />}
                {!isLoading &&
                  (documents?.length ? (
                    documents?.slice(0, itemToShow).map((doc) => (
                      <div
                        className="col-xl-4 col-lg-4 col-md-4 pb-4"
                        key={doc?.id}
                      >
                        <DocumentItem annual={true} doc={doc} devise={devise} />
                      </div>
                    ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
                {documents?.length > 6 ? (
                  <div className="col-12 text-center pb-4">
                    <a
                      className="btn btn-participate-event trans-0-2 ml-auto"
                      onClick={showMore}
                      href="#more"
                    >
                      {expand ? (
                        <span>{t("see_less")}</span>
                      ) : (
                        <span>{t("see_more")}</span>
                      )}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="banner">
          <div className="py-1 text-center">
            <h1 className="titre-banner">{t("annual_reports")}</h1>
          </div>
        </div>
        <div className="container-warc py-5 mt-5">
          <div className="row mb-5">
            {isLoading && <CardSkeleton />}
            {!isLoading &&
              (documents?.length ? (
                documents?.slice(0, itemToShow).map((doc) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-4 pb-4"
                    key={doc?.id}
                  >
                    <DocumentItem annual={true} doc={doc} devise={devise} />
                  </div>
                ))
              ) : (
                <div className="gfa-rowy mt-5">
                  <div className="alert alert-warning gfa-warning">
                    {t("noData")}
                  </div>
                </div>
              ))}
            {documents?.length > 6 ? (
              <div className="text-center pb-4 col-12">
                <a
                  className="btn btn-participate-event trans-0-2 ml-auto"
                  onClick={showMore}
                  href="#more"
                >
                  {expand ? (
                    <span>{t("see_less")}</span>
                  ) : (
                    <span>{t("see_more")}</span>
                  )}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default AnnualReportsFront;

export function useReportAuthentification(auto_trigger = false) {
  const [attempts, setAttempts] = useState(0);
  const [abble, setabble] = useState(false);
  const history = useHistory();

  const auth = async (email, code) => {
    console.log("auth", email, code);
    return axios
      .post(`${api}report_user_by_code_email/`, { code, email })
      .then((res) => {
        console.log("res", res);
        window.sessionStorage.setItem("ableToView", "ok");
        setabble(true);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Authentification failed please try again",
          showConfirmButton: false,
          timer: 2000,
        })
          .then(() => {
            setAttempts((old) => {
              if (old === 2) {
                history.replace("/");
                return old;
              } else {
                getUserInfos();
                return old + 1;
              }
            });
          })
          .catch(() => {
            getUserInfos();
          });
      });
  };
  const getUserInfos = async () => {
    const { value: email } = await Swal.fire({
      title: "Enter your email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
    });
    await Swal.fire({
      title: "Enter your code",
      input: "password",
      inputLabel: "Password",
      inputPlaceholder: "Enter your code",
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return auth(email, value);
      },
    }).then((res) => {
      if (!res.isConfirmed) {
        Swal.fire({
          icon: "error",
          title: "Authentification failed please try again",
          showConfirmButton: false,
          timer: 2000,
        });
        history.replace("/");
      }
    });
  };
  useEffect(() => {
    if (auto_trigger) {
      const ok = window.sessionStorage.getItem("ableToView");
      if (!!!ok) {
        getUserInfos();
      } else {
        setabble(true);
      }
    }
  }, []);
  return abble;
}
