import React, { useState, useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import * as IconlyPack from "react-iconly";
import CLose from "../../../../assets/images/icons/close.png";
import ImgBlog from "../../../../images/NoImage.png";
import { NavLink, useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import baseUrl from "../../../../Http/backend-base-url";
import axios from "axios";
import api from "../../../../Http/global-vars";
import Swal from "sweetalert2";
import Article from "../../../../services/Article";
import { useDispatch } from "react-redux";
import { fetchArticles } from "../../../../redux/articles/ArticlesAction";
import Header from "../../../Navs/Header";
import { useTranslation } from "react-i18next";

var DetailsBlogCoordonnateur = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [article, setArticle] = useState({});
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location?.state?.article) {
      setArticle(location?.state?.article);
    }
  }, [location]);

  useEffect(() => {
    if (params?.id) {
      getDetails(params?.id);
    }
  }, [params]);

  const getDetails = (id) => {
    axios
      .get(`${api}article/${id}/`, config)
      .then((response) => {
        // console.log("details", response?.data)
        setArticle(response?.data);
      })
      .catch((error) => {
        console.log("error", error?.response);
        if (error?.response?.status === 404) {
          history.goBack();
        }
      });
  };

  const onDelete = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_article"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Article.onDeleteArticle(item?.id, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchArticles());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push("/blog-coordonnateur");
        });
      }
    });
  };

  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container d-flex align-items-center">
                  <NavLink to="/blog-coordonnateur">
                    <img src={CLose} alt="Button Page précédente" />
                  </NavLink>
                  <h1 className="page-title pl-4">{t("details_article")}</h1>
                </div>
                <div
                  className="adding-item-container linkable"
                  style={{ justifyContent: "flex-start" }}
                >
                  <NavLink
                    to={{
                      pathname: `/modifier-article-coordonnateur`,
                      state: { article: article },
                    }}
                    className="btn"
                    title={t("edit")}
                  >
                    <IconlyPack.Edit set="light" primaryColor="#B7B1B1" />
                  </NavLink>
                  <button className="btn" title={t("delete")}>
                    <IconlyPack.Delete
                      set="light"
                      primaryColor="#B7B1B1"
                      onClick={() => onDelete(article)}
                    />
                  </button>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-12">
                      <h5 className="card-title card-title-blog pb-2">
                        {article?.titre}
                      </h5>
                      <p className="date-publication-article pb-3">
                        {/* Aug 1, 2021 • 7 min read{" "} */}
                        {moment(article?.date_publication).format(
                          "MMM DD, YYYY "
                        )}
                        &nbsp; {article?.nb_view} {t("vue")}
                      </p>

                      <img
                        // src={ImgBlog}
                        src={
                          article?.image &&
                          !article?.image?.includes("default.png")
                            ? baseUrl + article?.image
                            : ImgBlog
                        }
                        className="card-img-top w-full"
                        alt="ImgBlog"
                      />
                      <div className="py-4">
                        <div
                          className="text-blog-detail"
                          dangerouslySetInnerHTML={{
                            __html: article?.contenu,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="AjouterDocumentModal"
                tabIndex="-1"
                aria-labelledby="AjouterDocumentModalLabel"
                aria-hidden="true"
              >
                {/* <AjouterDocumentModal /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsBlogCoordonnateur;
