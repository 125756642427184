import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import "./RemonterIncidents.css";
import axios from "axios";
import api from "../../../Http/global-vars";

var RemonterIncidents = (props) => {
  const { t } = useTranslation();
  const Schema = yup.object().shape({
    message: yup.string().required(t("required")),
    email: yup.string().email(t("invalid_mail")).required(t("required")),
    fullname: yup.string().required(t("required")),
  });

  const { reset, formState, register, handleSubmit, clearErrors } = useForm({
    resolver: yupResolver(Schema),
  });
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
  //   },
  // };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);

    console.log("data", formValues);

    var request = axios.post(`${api}incidentbyvisitor/`, formValues);

    await request
      .then(async (resp) => {
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$("#SignalerIncidents").modal("hide");

        await e.target.reset();
        await reset();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };

  return (
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title titre-nous-contacter" id="SignalerIncidents">
            {t("report_problem")}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => reset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {/* <div className="py-4 col-md-12">
            <p className="text-nous-contacter-incident">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non ut
              habitant ullamcorper nunc turpis iaculis. Quam.
            </p>
          </div> */}
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-4">
                    <label className="label-form">
                      {t("fullname")} <span className="required-label">*</span>
                    </label>
                    <input
                      type="text"
                      name="fullname"
                      {...register("fullname")}
                      className="form-control input-form-incident"
                      placeholder={t("fullname")}
                    />
                    {formState.errors && formState.errors.fullname && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.fullname?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-4">
                    <label className="label-form">
                      {t("email")} <span className="required-label">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      {...register("email")}
                      className="form-control input-form-incident"
                      placeholder={t("email")}
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group my-4">
                    <label className="label-form">
                      Message <span className="required-label">*</span>
                    </label>
                    <textarea
                      class="form-control input-form-incident"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      {...register("message")}
                      placeholder={t("type_message")}
                    ></textarea>
                    {formState.errors && formState.errors.message && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.message?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn btn-theme w-25">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn btn-theme w-25">
                      {t("send")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemonterIncidents;
