import React, { useEffect, Fragment } from "react";
import FrontFooter from "../frontFooter/index";
import NavMenuMobile from "./NavMenuMobile";
import Skeleton from "@nejcm/react-skeleton";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/en-ca";
// import { filterByCategory } from "../../../redux/Posts/PostAction";
import VoirLive from "../../Modals/Frontend/VoirLive";
import Live from "../../../images/zoom.png";
import { Redirect } from "react-router-dom";
import { fetchLives } from "../../../redux/live/liveAction";

var DetailsLiveMobile = () => {
  const { t } = useTranslation();

  // const [checked, setChecked] = useState([]);
  const devise = useSelector((state) => state.devise);
  const lives = useSelector((state) => state.lives);
  const events = useSelector((state) => state.events);

  const userParticipations = useSelector((state) => state.userParticipations);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLives());
    window.scrollTo(0, 0);
  }, [dispatch]);
  //   const handleChangeFilter = (e) => {
  //     let checkedNew = [...checked, parseInt(e.target.value)];

  //     if (checked.includes(parseInt(e.target.value))) {
  //       checkedNew = checkedNew.filter((tes) => tes !== parseInt(e.target.value));
  //     }

  //     let x = (checkedNew) =>
  //       checkedNew.filter((v, i) => checkedNew.indexOf(v) === i);
  //     x(checkedNew);

  //     dispatch(filterByCategory(posts?.data, x(checkedNew)));
  //     setChecked(checkedNew);
  //   };
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <section className="container-warc mt-5">
              <div className="pt-5">
                <div className="bg-accueil mb-4">
                  <div className="border-bottom">
                    <p className="text-agenda pb-2">LIVE</p>
                  </div>
                  {events?.isLoading && userParticipations?.isLoading && (
                    <ProgramSkeleton />
                  )}
                  {!lives?.isLoading &&
                    !userParticipations?.isLoading &&
                    (lives?.filteredData?.length ? (
                      lives?.filteredData
                        ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
                        ?.map((live) => (
                          <Fragment key={live?.id}>
                            <div
                              className="d-flex mt-3 linkable"
                              data-toggle="modal"
                              data-target={`#VoirLive${live?.id}`}
                            >
                              <img src={Live} alt="Live" />
                              <p className="text-consultation-agenda pl-3">
                                {live?.libelle}
                              </p>
                            </div>
                            <div className="pl-5">
                              <p className="text-agenda-calendar pl-3">
                                <IconlyPack.Calendar set="bold" />
                                <span className="pl-2">
                                  {moment(live?.date).format("DD/MM/YYYY")} -{" "}
                                  {moment(live?.start_time, "HH:mm:ss").format(
                                    "HH:mm"
                                  )}
                                </span>
                              </p>
                            </div>
                            <div
                              className="modal fade"
                              id={`VoirLive${live?.id}`}
                              tabIndex="-1"
                              // data-backdrop="static"
                              aria-labelledby="VoirLiveLabel"
                              aria-hidden="true"
                            >
                              <VoirLive live={live} />
                            </div>
                          </Fragment>
                        ))
                    ) : (
                      <div className="gfa-rowy mt-5">
                        <div className="alert alert-warning gfa-warning">
                          {t("noData")}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }

  return <Redirect to="/app/home" />;
};
export default DetailsLiveMobile;
const ProgramSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index}>
        <Skeleton className="d-flex my-3">
          <Skeleton.Circle width={20} height={20} />
          <Skeleton.Rectangle height={20} width={`100%`} className="mr-1" />
        </Skeleton>
        <Skeleton className="d-flex">
          <Skeleton.Rectangle width={20} height={20} />
          <Skeleton.Rectangle height={20} width={`100%`} className="mr-1" />
        </Skeleton>
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
