// import api from "../../http/global-vars";
import api from "../../Http/global-vars";
import { FETCH_POSTES, FILTER_POSTES } from "../types";

export const fetchPostes = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "post/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_POSTES,
          payload: data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const filterByCategory = (posts, categoryID) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line
  data = posts?.filter((post) =>
    categoryID?.includes(parseInt(post?.category?.id))
  );
  console.log("voir", categoryID, data);
  // return "";
  return (dispatch) => {
    dispatch({
      type: FILTER_POSTES,
      payload: {
        items: !categoryID?.length
          ? posts?.sort((a, b) => b?.id - a?.id)
          : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
