import React, { useState } from "react";
import ContentDetails from "../../Dashboard/Coordonnateur/CatalogueCoordonnateur/ContentDetails";
import DetailsUniversite from "../../Dashboard/Coordonnateur/CatalogueCoordonnateur/DetailsUniversite";
import { useStep } from "react-hooks-helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const steps = [
  { id: "principal-information", Component: DetailsUniversite },
  { id: "other-information", Component: ContentDetails },
];

function AjouterCatalogueCoordonnateurModal() {
  const { t } = useTranslation();
  const [dataForm, setDataForm] = useState({});
  // const ref = useRef(null);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { navigation };
  const [fileName, setFileName] = useState(null);
  const [val, setVal] = useState("");
  const [phone, setPhone] = React.useState("");
  const [gouvernance, setGouvernance] = React.useState("");
  const [direction, setDirection] = React.useState("");
  const [note, setNote] = React.useState("");
  const [institut, setInstitut] = React.useState("");

  const Schema = yup.object().shape({
    nom: yup.string().required(t("required")),
    pays: yup.string().required(t("required")),
    nb_facultes: yup.string().nullable(),
    nb_students: yup.string().nullable(),
    head_text: yup.string().nullable(),
    adresse: yup.string().nullable(),
    email: yup.string().email(t("invalid_mail")).nullable(),
    phone: yup.string().nullable(),
    site_web: yup.string().required(t("required")),
    logo: yup.mixed().required(t("required")),
  });

  const { formState, register, handleSubmit, setValue, clearErrors, reset } =
    useForm({
      resolver: yupResolver(Schema),
    });

  // useEffect(() => {
  //   if (dataForm) {
  //     console.log("dataForm", dataForm);
  //     console.log("ref ", ref);
  //   }
  // }, [dataForm]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  const handleReset = () => {
    navigation.go("principal-information");
    setPhone("+221");
    setVal("");
    setDataForm({});
    setFileName("");
    setInstitut("");
    setNote("");
    setGouvernance("");
    setDirection("");
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("add_catalogue")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div>
                <Component
                  {...props}
                  setDataForm={setDataForm}
                  dataForm={dataForm}
                  reset={reset}
                  formState={formState}
                  register={register}
                  handleSubmit={handleSubmit}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setVal={setVal}
                  val={val}
                  phone={phone}
                  setPhone={setPhone}
                  fileName={fileName}
                  setFileName={setFileName}
                  gouvernance={gouvernance}
                  direction={direction}
                  institut={institut}
                  note={note}
                  setGouvernance={setGouvernance}
                  setDirection={setDirection}
                  setInstitut={setInstitut}
                  setNote={setNote}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterCatalogueCoordonnateurModal;
