import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import UseCreateAgenda from "../../Dashboard/Coordonnateur/AgendaCoordonnateur/requestAgenda/UseCreateAgenda";

function AjouterEvent(event) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
  } = UseCreateAgenda();

  useEffect(() => {
    if (event) {
      console.log("event",event)
    }
  }, [event])

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">{t("add_event")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-form-add-event" htmlFor="label">
                      {t("label")}
                      <span className="required-label">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("title")}
                      name="title"
                      className="form-control input-event-agenda"
                      id="exampleInputText"
                      placeholder={t("label")}
                    />
                    {formState.errors && formState.errors.title && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.title?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1 label-form-add-event">
                      Description
                    </label>
                    <textarea
                      className="form-control input-add-event textarrea-event"
                      {...register("description")}
                      id="exampleFormControlTextarea1"
                      rows="3"
                    >
                      Description
                    </textarea>
                    {formState.errors && formState.errors.description && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.description?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label
                      className="label-form-add-event"
                      htmlFor="inlineFormInputGroup"
                    >
                      {t("startDate")}
                      <span className="required-label">*</span>
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <IconlyPack.Calendar
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="datetime-local"
                        // defaultValue={new Date()}
                        name="startDate"
                        {...register("startDate")}
                        className="form-control input-add-event pl-5"
                        id="inlineFormInputGroup"
                        // placeholder="28 DEC 2021"
                      />
                      {formState.errors && formState.errors.startDate && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.startDate?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 py-4">
                  <div className="">
                    <label className="label-form-add-event" htmlFor="endDate">
                      {t("endDate")}
                      <span className="required-label">*</span>
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <IconlyPack.TimeCircle
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="datetime-local"
                        className="form-control input-add-event pl-5"
                        id="endDate"
                        name="endDate"
                        {...register("endDate")}
                        // placeholder="7 AM - 11 AM "
                      />
                      {formState.errors && formState.errors.endDate && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.endDate?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label className="label-form-add-event" htmlFor="location">
                      {t("address")}
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <IconlyPack.Location
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control input-add-event pl-5"
                        id="location"
                        {...register("location")}
                        placeholder={t("address")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                {isSuccessfullySubmitted && (
                  <button disabled className="btn crud-submit-btn">
                    {t("en_cours")} &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isSuccessfullySubmitted && (
                  <button type="submit" className="btn crud-submit-btn">
                    {t("add")}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterEvent;
