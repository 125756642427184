import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import ImgPP from "../../../images/NoImage.png";

function InstitutionsEtPartenairesModal({ item }) {
  const { t } = useTranslation();
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5
            className="modal-title text-titre"
            id="InstitutionsEtPartenairesModal"
          ></h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <div className="d-flex div-content-modal">
              <div>
                <img
                  src={
                    item?.logo && !item?.logo?.includes("default.png")
                      ? baseUrl + item?.logo
                      : ImgPP
                  }
                  alt="Image Fulbrighter"
                  className="PpFulbrighter-modal"
                />
              </div>
              <div className="px-4 bloc-titre-content-modal">
                <h1 className="titre-fulbrighter-modal">{item?.nom}</h1>
                <p className="sous-titre-fulbrighter-modal">{t("partner")}</p>
                <p
                  className="text-fulbrighter-modal"
                  style={{ textAlign: "justify" }}
                >
                  {item?.description}
                </p>
              </div>
            </div>
            <div className="my-4">
              <div className="border-top border-bottom bloc-annuaire-fulbrighter-item py-2">
                <div>
                  <p className="text-fulbrighter-modal">{t("email")}</p>
                  <p className="sous-titre-fulbrighter-modal">{item?.email}</p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("phone")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {" "}
                    {item?.contact}{" "}
                  </p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("website")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {item?.site_web}
                  </p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("country")}</p>
                  <p className="sous-titre-fulbrighter-modal">{item?.pays}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstitutionsEtPartenairesModal;
