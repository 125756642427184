import React, { useEffect } from "react";
// import AnnuairePP from "../../../assets/images/photo-profil.png";
import Swal from "sweetalert2";
import axios from "axios";
// import api from "../../../Http/global-vars";
import { useForm } from "react-hook-form";
import api from "../../../../Http/global-vars";
import { useTranslation } from "react-i18next";

var EditPasswordCoordonnateur = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm();

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    register("new_password", {
      required: {
        value: true,
        message: t("required"),
      },
    });
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors, register, t]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    delete data?.confirm_password;
    console.log("data", data);
    const request = axios.put(`${api}changepassword/`, data, config);
    await request
      .then((res) => {
        console.log("res", res);
        if (res.data.status === "success") {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: t("success"),
            showConfirmButton: false,
            timer: 1000,
          });
          window.$("#EditPasswordCoordonnateur").modal("hide");
          //   toast.success("Mot de passe modifié avec succés.");
          e.target.reset();
          reset();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error?.response?.data?.old_password &&
          error?.response?.data?.old_password[0] === "Wrong password."
        ) {
          setError(t("incorect_password"));
          //  setIsLoading(false)
          setValue("old_password", "");

          setTimeout(() => {
            setError(null);
          }, 5000);
        } else {
          //   toast.error("Oops! Une erreur est survenue lors de la modification.");
          Swal.fire({
            icon: "error",
            title: "Oops! Error.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="EditPasswordCoordonnateur">
            {t("edit_password")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => reset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label
                    className="label-form-profil"
                    htmlFor="exampleInputEmail1"
                  >
                    {t("current_password")}{" "}
                    <span className="required-label">*</span>
                  </label>
                  <input
                    type="password"
                    {...register("old_password", {
                      required: t("required"),
                    })}
                    name="old_password"
                    className="form-control form-input-profil"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  {formState?.errors && formState?.errors?.old_password && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.old_password?.message}
                    </div>
                  )}
                  {error && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="example1">
                    {t("new_password")}{" "}
                    <span className="required-label">*</span>
                  </label>
                  <input
                    type="password"
                    {...register("new_password", {
                      required: t("required"),
                    })}
                    name="new_password"
                    className="form-control form-input-profil"
                    id="example1"
                    aria-describedby="emailHelp"
                  />
                  {formState?.errors && formState?.errors?.new_password && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.new_password?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="example2">
                    {t("confirm_password")}{" "}
                    <span className="required-label">*</span>
                  </label>
                  <input
                    type="password"
                    {...register("confirm_password", {
                      required: t("required"),
                      validate: (value) => {
                        return (
                          value === watch("new_password") || t("match_password")
                        );
                      },
                    })}
                    name="confirm_password"
                    className="form-control form-input-profil"
                    id="example2"
                    aria-describedby="emailHelp"
                  />
                  {formState?.errors && formState?.errors?.confirm_password && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.confirm_password?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel-form"
                data-dismiss="modal"
                onClick={() => reset()}
              >
                {t("cancel")}
              </button>
              {isLoading && (
                <button className="btn-theme-modal" disabled>
                  {t("en_cours")} &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && (
                <button type="submit" className="btn btn-theme-modal">
                  {t("edit")}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPasswordCoordonnateur;
