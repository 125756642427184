import React, { useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import "./BlogCoordonnateur.css";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import Header from "../../../Navs/Header";
import { useTranslation } from "react-i18next";
import IncidentTable from "../../../Tables/Coordonnateur/IncidentTable/IncidentTable";

const GestionIncidents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("incidents")}</h1>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <IncidentTable />
                </div>
              </div>
              <div
                className="modal fade"
                id="AjouterDocumentModal"
                tabIndex="-1"
                aria-labelledby="AjouterDocumentModalLabel"
                aria-hidden="true"
              >
                {/* <AjouterDocumentModal /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionIncidents;
