import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAddComment from "../../Dashboard/Admin/ForumAdmin/requestComment/useAddComment";

function AjouterCommentaireModal({ post }) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
    setValue,
  } = useAddComment();

  useEffect(() => {
    if (formState.errors) {
      console.log(formState.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (post?.id) {
      setValue("post", post?.id);
    }
  }, [post, setValue]);

  const handleReset = () => {
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">{t("add_comment")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="text">{t("text")}</label>
                      <textarea
                        className="form-control input-add-document"
                        id="text"
                        rows="5"
                        name="text"
                        placeholder={t("add_comment")}
                        {...register("text")}
                      ></textarea>
                      {formState.errors && formState.errors.text && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.text?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <input hidden value={post?.id} {...register("post")} />
                </div>

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("add")}
                    </button>
                  )}
                  {/* <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterCommentaireModal;
