import { CHEARCH_FAQS, FETCH_FAQS } from "../types";

export const faqReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_FAQS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    case CHEARCH_FAQS:
      // console.log("action", action.payload)
      return {
        ...state,
        filteredData: action.payload.items,
      };
    default:
      return state;
  }
};
