import moment from "moment";
import { FETCH_EVENTS } from "../types";

export const eventReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload?.filter((event) =>
          moment(event?.startDate).isSameOrAfter(new Date())
        ),
      };
    default:
      return state;
  }
};
