/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import { useTranslation } from "react-i18next";
import baseUrl from "../../../Http/backend-base-url";
import ImgPP from "../../../images/others/default-profile-avatar.png";

function ExpertPersonnesRessourcesModal({ item }) {
  const { t } = useTranslation();
  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5
            className="modal-title text-titre"
            id="ExpertPersonnesRessourcesModal"
          ></h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <div className="d-flex div-content-modal">
              <div>
                <img
                  src={
                    item?.photo && !item?.photo?.includes("default.png")
                      ? baseUrl + item?.photo
                      : ImgPP
                  }
                  alt="Fulbrighter"
                  className="PpFulbrighter-modal"
                />
              </div>
              <div className="pl-4 bloc-titre-content-modal">
                <h1 className="titre-fulbrighter-modal">
                  {item?.prenom + " " + item?.nom}
                </h1>
                <p className="sous-titre-fulbrighter-modal">Expert</p>
                <p
                  className="text-fulbrighter-modal"
                  dangerouslySetInnerHTML={{ __html: item?.bio }}
                />
                <div className="bloc-annuaire-skills-item pt-2">
                  {item?.id && IsJsonString(item?.domaine_expertise)
                    ? JSON.parse(item?.domaine_expertise)?.length &&
                      JSON.parse(item?.domaine_expertise).map((tag, index) => (
                        <div className="annuaire-tag contain-item" key={index}>
                          <p className="text-fulbrighter-annuaire">{tag}</p>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="border-top border-bottom bloc-annuaire-fulbrighter-item py-2">
                <div>
                  <p className="text-fulbrighter-modal">{t("email")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {item?.email || t("not_specified")}
                  </p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("phone")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {item?.telephone || t("not_specified")}
                  </p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("country")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {item?.pays || t("not_specified")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpertPersonnesRessourcesModal;
