import React, { Fragment, useEffect, useState, useMemo } from "react";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import $ from "jquery";
import PhotoProfil from "../../../../images/others/default-profile-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserMessages } from "../../../../redux/messages/MessagesAction";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import api from "../../../../Http/global-vars";
import Skeleton, { Card, Comment, Paragraph } from "@nejcm/react-skeleton";
import { useTranslation } from "react-i18next";
import Header from "../../../Navs/Header";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";

var DetailsMessageCoordonnateur = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        message: yup.string().required("Message is required."),
        receiver: yup.string().required("Receiver is required."),
        sender: yup.string(),
      }),
    []
  );
  const { register, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const devise = useSelector((state) => state.devise);
  const userMessages = useSelector((state) => state.userMessages);
  const user = useSelector((state) => state.user);
  const fulbrighters = useSelector((state) => state.fulbrighters);
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [userSelected, setUserSelected] = useState({});

  useEffect(() => {
    if (!user?.isLoading && user?.data?.id) {
      dispatch(fetchUserMessages(user?.data?.id));
    }
  }, [user]);
  useEffect(() => {
    if (
      !location?.state?.userItem &&
      !fulbrighters?.isLoading &&
      fulbrighters?.data?.filter((u) => u?.id !== user?.data?.id)?.length
    ) {
      setUserSelected(
        fulbrighters?.data?.filter((u) => u?.id !== user?.data?.id)[0]
      );
    }

    if (location?.state?.userItem) {
      console.log("dd");
      setUserSelected(location?.state?.userItem);
    }
  }, [fulbrighters, user, location]);

  useEffect(() => {
    if (
      !userMessages?.isLoading &&
      userMessages?.data?.length &&
      userSelected?.id
    ) {
      let discutes = userMessages?.data?.filter(
        (u) =>
          u?.receiver?.id === userSelected?.id ||
          u?.sender?.id === userSelected?.id
      );
      setMessages(discutes?.sort((a, b) => a?.id - b?.id));
      console.log("message", discutes);
    }

    if (userSelected?.id) {
      setValue("receiver", userSelected?.id);
    }
  }, [userSelected, userMessages]);

  const onSubmit = async (data, e) => {
    data["sender"] = user?.data?.id;
    console.log("data", data);
    let request = axios.post(`${api}message/`, data, config);

    await request
      .then(async (response) => {
        console.log("response message", response);
        await dispatch(fetchUserMessages(user?.data?.id));
        await e.target.reset();
        await reset();
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $("#action_menu_btn").click(function () {
        $(".action_menu").toggle();
      });
    });
  }, []);
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <div className="no-view-mobile">
            <FrontHeader />
          </div>
          <div className="no-view-desktop">
            <Header />
          </div>
          <div className="content-position-after-header px-md-3 px-0">
            <div className="messagerie">
              <div className="row justify-content-center h-100 mt-5">
                <div className=" chat">
                  <div className="card">
                    <div className="card-header msg_head">
                      <div className="d-flex bd-highlight align-items-center">
                        <div>
                          <NavLink to="/app/messagerie" className="no-link">
                            <IconlyPack.CaretLeft
                              set="bold"
                              primaryColor="#000"
                            />
                          </NavLink>
                        </div>
                        {userSelected?.id ? (
                          <div className="d-flex pl-2">
                            <div className="img_cont">
                              <img
                                // src={PhotoProfil}
                                src={
                                  userSelected?.avatar &&
                                  !userSelected?.avatar?.includes("default.png")
                                    ? baseUrl + userSelected?.avatar
                                    : PhotoProfil
                                }
                                className="rounded-circle user_img"
                              />
                            </div>
                            <div className="user_info">
                              <span>
                                {t("chat_with")}{" "}
                                {userSelected?.prenom + " " + userSelected?.nom}
                              </span>
                              <p>{messages?.length} Messages</p>
                            </div>
                          </div>
                        ) : (
                          <Skeleton className="d-flex pl-2">
                            {/* <Skeleton className="img_cont mr-1"> */}
                            <Skeleton.Circle
                              width={70}
                              height={70}
                              className="rounded-circle user_img"
                            />
                            <Skeleton>
                              <Skeleton.Rectangle
                                className="user_info mb-1"
                                width={150}
                                height={30}
                              />
                              <Skeleton.Rectangle
                                className="user_info"
                                width={150}
                                height={30}
                              />
                            </Skeleton>
                          </Skeleton>
                        )}
                      </div>
                      <span id="action_menu_btn">
                        <i className="fas fa-ellipsis-v"></i>
                      </span>
                      <div className="action_menu">
                        <ul>
                          <li>
                            <i className="fas fa-user-circle"></i> View profile
                          </li>
                          <li>
                            <i className="fas fa-users"></i> Add to close
                            friends
                          </li>
                          <li>
                            <i className="fas fa-plus"></i> Add to group
                          </li>
                          <li>
                            <i className="fas fa-ban"></i> Block
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body msg_card_body">
                      {!userSelected?.id && <MessageSkeleton />}
                      {/* {userSelected?.id && (
                        <>
                          <div className="d-flex justify-content-start mb-4">
                            <div className="img_cont_msg">
                              <img
                                src={PhotoProfil}
                                className="rounded-circle user_img_msg"
                              />
                            </div>
                            <div className="msg_cotainer">
                              Hi, how are you samim?
                              <span className="msg_time">8:40 AM, Today</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end mb-4">
                            <div className="msg_cotainer_send">
                              Hi Khalid i am good tnx how about you?
                              <span className="msg_time_send">8:55 AM, Today</span>
                            </div>
                            <div className="img_cont_msg">
                              <img
                                src={PhotoProfil}
                                className="rounded-circle user_img_msg"
                              />
                            </div>
                          </div>
                        </>
                      )} */}
                      {userSelected?.id &&
                        (messages?.length
                          ? messages.map((msg) => (
                              <Fragment key={msg?.id}>
                                {msg?.sender?.id === userSelected?.id ? (
                                  <div className="d-flex justify-content-start mb-4">
                                    <div className="img_cont_msg">
                                      <img
                                        // src={PhotoProfil}
                                        src={
                                          userSelected?.avatar &&
                                          !userSelected?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl + userSelected?.avatar
                                            : PhotoProfil
                                        }
                                        className="rounded-circle user_img_msg"
                                      />
                                    </div>
                                    <div className="msg_cotainer">
                                      {msg?.message}
                                      <span className="msg_time">
                                        {/* 8:40 AM, Today */}
                                        {moment().calendar(msg?.created_at)}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-end mb-4">
                                    <div className="msg_cotainer_send">
                                      {msg?.message}
                                      <span className="msg_time_send">
                                        {moment().calendar(msg?.created_at)}
                                      </span>
                                    </div>
                                    <div className="img_cont_msg">
                                      <img
                                        src={
                                          user?.data?.avatar &&
                                          !user?.data?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl + user?.data?.avatar
                                            : PhotoProfil
                                        }
                                        className="rounded-circle user_img_msg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                            ))
                          : null)}
                    </div>
                    <div className="card-footer">
                      <form
                        className="input-group"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="input-group-append">
                          <span className="input-group-text attach_btn">
                            <i className="fas fa-paperclip"></i>
                          </span>
                        </div>
                        <textarea
                          name="message"
                          {...register("message")}
                          className="form-control type_msg"
                          placeholder={t("type_message")}
                        ></textarea>
                        <div className="input-group-append">
                          <button
                            type="submit"
                            className="input-group-text send_btn"
                          >
                            <i className="fas fa-location-arrow"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/messages-coordonnateur",
        state: { userItem: userSelected },
      }}
    />
  );
};

export default DetailsMessageCoordonnateur;

const MessageSkeleton = () => {
  return (
    <Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
    </Skeleton>
  );
};
