import React from "react";
import { CKEditor } from "ckeditor4-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { fetchCatalogue } from "../../../../redux/catalogues/cataloguesAction";
import University from "../../../../services/University";
import { useTranslation } from "react-i18next";

const ContentDetailsEdit = ({
  navigation,
  dataForm,
  direction,
  setDirection,
  institut,
  setInstitut,
  gouvernance,
  setGouvernance,
  note,
  setNote,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Schema = yup.object().shape({
    nom: yup.string(),
    pays: yup.string(),
    nb_facultes: yup.string(),
    nb_students: yup.string(),
    head_text: yup.string(),
    adresse: yup.string(),
    email: yup.string().email(),
    phone: yup.string(),
    site_web: yup.string(),
    logo: yup.mixed(),
    gouvernance: yup.string(),
    direction: yup.string(),
    institut: yup.string(),
    note: yup.string(),
  });

  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: dataForm,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  console.log("last step", dataForm);
  const onSubmitForm = async (formValues) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    let fd = new FormData();
    fd.append("nom", formValues?.nom);
    fd.append("adresse", formValues?.adresse);
    fd.append("pays", formValues?.pays);
    fd.append("email", formValues?.email);
    fd.append("gouvernance", formValues?.gouvernance);
    fd.append("direction", formValues?.direction);
    fd.append("institut", formValues?.institut);
    fd.append("note", formValues?.note);
    fd.append("site_web", formValues?.site_web);
    fd.append("phone", formValues?.phone);
    fd.append("nb_facultes", formValues?.nb_facultes);
    fd.append("nb_students", formValues?.nb_students);
    fd.append("head_text", formValues?.head_text);
    if (formValues?.logo) {
      fd.append("logo", formValues?.logo);
    }
    var request = University.onEditUniversity(formValues?.id, fd, config);

    await request
      .then(async () => {
        await dispatch(fetchCatalogue());
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window
          .$("#ModifierCatalogueCoordonnateurModal" + formValues?.id)
          .modal("hide");
        await navigation.go("principal-information");
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return (
    <div className="">
      <div className="text-center">
        <p className="titre-ajouter-catalogue-modal">{t("contenu")}</p>
      </div>
      <form className="row crud-row" onSubmit={handleSubmit(onSubmitForm)}>
        <div className="col-md-12 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="date">{t("governance")}</label>
              <CKEditor
                initData={gouvernance}
                name="gouvernance"
                onChange={(evt) => {
                  setGouvernance(evt.editor.getData());
                  setValue("gouvernance", evt.editor.getData());
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="country">{t("direction")}</label>
              <CKEditor
                initData={direction}
                onChange={(evt) => {
                  setDirection(evt.editor.getData());
                  setValue("direction", evt.editor.getData());
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="date">{t("institut")}</label>
              <CKEditor
                initData={institut}
                onChange={(evt) => {
                  setInstitut(evt.editor.getData());
                  setValue("institut", evt.editor.getData());
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="date"> {t("note")}</label>
              <CKEditor
                initData={note}
                onChange={(evt) => {
                  setNote(evt.editor.getData());
                  setValue("note", evt.editor.getData());
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          {isSuccessfullySubmitted && (
            <button className="btn crud-submit-btn">
              {t("en_cours")} &nbsp;
              <i className="fas fa-spin fa-spinner"></i>
            </button>
          )}
          {!isSuccessfullySubmitted && (
            <button type="submit" className="btn crud-submit-btn">
              {t("edit")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContentDetailsEdit;
