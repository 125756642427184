import React from "react";
import baseUrl from "../../../Http/backend-base-url";
import { Download } from "react-iconly";
import { useTranslation } from "react-i18next";

function DetailsDocument({ document }) {
  const { t } = useTranslation();
  return (
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5 className="modal-title text-titre" id="addCommentModal">
            Details
          </h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <div className="bloc-entete-document py-4">
              <div>
                {/* <NavLink to="/app/documents" className="no-link">
                  <CaretLeft set="bold" primaryColor="#000" />
                </NavLink> */}
              </div>
              <div>
                <a
                  className="btn btn-download-document"
                  // onClick={() =>
                  //   download(baseUrl + document?.fichier, document?.title)
                  // }
                  href={baseUrl + document?.fichier}
                  download
                  target="_blank"
                >
                  <Download set="bold" primaryColor="#003DA5" />
                  <span className="pl-2"> {t("download")} </span>
                </a>
              </div>
            </div>
            <div className="pb-5 pt-3 text-center">
              <h1 className="h3-theme">{document?.title} </h1>
            </div>
            <div className="pb-5 mb-5">
              <p className="text-paragraph" style={{ color: "#000000" }}>
                {document?.description}
               
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsDocument;
