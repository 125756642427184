import api from "../../Http/global-vars";
import { REPORT_USER } from "../types";

export const fetchReportUser = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "report_user/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: REPORT_USER,
          payload: data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
