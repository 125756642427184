import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import api from "./../../Http/global-vars";
// import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import EmptyHeader from "./EmptyHeader";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      InProgress: false,
      isSuccess: false,
      isError: false,
      is_empty_email: false,
      is_empty_new_password: false,
      is_empty_new_password_confirm: false,
      is_invalid_email: false,
      is_empty_code: false,
      is_not_same_password: false,
      haveMinUppercase: false,
      haveMinLowercase: false,
      haveMinNumber: false,
      haveMinCharacter: false,
      haveMinSpecialCharacter: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
  }

  componentDidMount() {
    let fields = this.state.fields;
    fields["email"] = window.localStorage.getItem("email");

    this.setState({
      fields: fields,
    });
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
    console.log(fields);
  }

  countUppercase = (str) => {
    if (str.replace(/[^A-Z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  countLowercase = (str) => {
    if (str.replace(/[^a-z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  countNumber = (str) => {
    if (str.replace(/[^0-9]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  countCharacters = (str) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  countSpecial = (str) => {
    const punct = "!,;.-?@";
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  onValidateResetForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["code"]) {
      isValidForm = false;
      this.setState({
        is_empty_code: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_code: false,
        });
      }, 5000);

      errors["code"] = this.props.t("required");
    }
    if (!fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_email: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        });
      }, 5000);

      errors["email"] = this.props.t("required");
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        isValidForm = false;
        this.setState({
          is_invalid_email: true,
        });
        setTimeout(() => {
          this.setState({
            is_invalid_email: false,
          });
        }, 5000);

        errors["invalid_email"] = this.props.t("invalid_mail");
      }
    }
    if (!fields["new_password"]) {
      isValidForm = false;
      this.setState({
        is_empty_new_password: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_new_password: false,
        });
      }, 5000);

      errors["new_password"] = this.props.t("required");
    }

    // if (this.countUppercase(password)) {
    //   this.setState({
    //     haveMinUppercase: true,
    //   });
    // } else {
    //   this.setState({
    //     haveMinUppercase: false,
    //   });

    //   isValidForm = false;
    //   this.setState({
    //     is_empty_new_password: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_new_password: false,
    //     });
    //   }, 5000);

    //   errors["new_password"] =
    //     " Votre mot de passe doit contenir \n :  Au minimum 8 caractères  \n  Au minimum 1 caractère en majuscule  \n  Au minimum 1 caractère en minuscule  \n  Au minimum 1 nombre Au minimum 1 caractère spéciale";
    // }
    // if (this.countLowercase(password)) {
    //   this.setState({
    //     haveMinLowercase: true,
    //   });
    // } else {
    //   this.setState({
    //     haveMinLowercase: false,
    //   });

    //   isValidForm = false;
    //   this.setState({
    //     is_empty_new_password: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_new_password: false,
    //     });
    //   }, 5000);

    //   errors["new_password"] =
    //     " Votre mot de passe doit contenir \n :  Au minimum 8 caractères  \n  Au minimum 1 caractère en majuscule  \n  Au minimum 1 caractère en minuscule  \n  Au minimum 1 nombre Au minimum 1 caractère spéciale";
    // }
    // if (this.countNumber(password)) {
    //   this.setState({
    //     haveMinNumber: true,
    //   });
    // } else {
    //   this.setState({
    //     haveMinNumber: false,
    //   });

    //   isValidForm = false;
    //   this.setState({
    //     is_empty_new_password: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_new_password: false,
    //     });
    //   }, 5000);

    //   errors["new_password"] =
    //     " Votre mot de passe doit contenir \n :  Au minimum 8 caractères  \n  Au minimum 1 caractère en majuscule  \n  Au minimum 1 caractère en minuscule  \n  Au minimum 1 nombre Au minimum 1 caractère spéciale";
    // }
    // if (this.countCharacters(password)) {
    //   this.setState({
    //     haveMinCharacter: true,
    //   });
    // } else {
    //   this.setState({
    //     haveMinCharacter: false,
    //   });

    //   isValidForm = false;
    //   this.setState({
    //     is_empty_new_password: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_new_password: false,
    //     });
    //   }, 5000);

    //   errors["new_password"] =
    //     " Votre mot de passe doit contenir \n :  Au minimum 8 caractères  \n  Au minimum 1 caractère en majuscule  \n  Au minimum 1 caractère en minuscule  \n  Au minimum 1 nombre Au minimum 1 caractère spéciale";
    // }
    // if (this.countSpecial(password)) {
    //   this.setState({
    //     haveMinSpecialCharacter: true,
    //   });
    // } else {
    //   this.setState({
    //     haveMinSpecialCharacter: false,
    //   });

    //   isValidForm = false;
    //   this.setState({
    //     is_empty_new_password: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_new_password: false,
    //     });
    //   }, 5000);

    //   errors["new_password"] =
    //     " Votre mot de passe doit contenir \n :  Au minimum 8 caractères  \n  Au minimum 1 caractère en majuscule  \n  Au minimum 1 caractère en minuscule  \n  Au minimum 1 nombre Au minimum 1 caractère spéciale";
    // }

    if (!fields["new_password_confirm"]) {
      isValidForm = false;
      this.setState({
        is_empty_new_password_confirm: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_new_password_confirm: false,
        });
      }, 5000);

      errors["new_password_confirm"] = this.props.t("required");
    }
    if (
      typeof fields["new_password"] !== "undefined" &&
      typeof fields["new_password_confirm"] !== "undefined"
    ) {
      if (fields["new_password"] !== fields["new_password_confirm"]) {
        isValidForm = false;
        this.setState({
          is_not_same_password: true,
        });
        setTimeout(() => {
          this.setState({
            is_not_same_password: false,
          });
        }, 5000);

        errors["not_same_password"] = this.props.t("match_password");
      }
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  onSubmitResetPassword(e) {
    e.preventDefault();

    if (this.onValidateResetForm()) {
      this.setState({
        InProgress: true,
      });

      var data = this.state.fields;

      axios
        .post(api + "reset-password/", data)
        .then((response) => {
          this.setState({
            InProgress: false,
          });
          if (response.status === 200 || response.status === 201) {
            // toast.success('')
            Swal.fire({
              icon: "success",
              title: this.props.t("success"),
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.localStorage.removeItem("email");
              this.setState({
                isSuccess: true,
              });

              window.location = "/app/connexion";
            });
          } else {
            console.log(response);
            Swal.fire({
              icon: "error",
              title:
                "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              isError: true,
            });

            setTimeout(() => {
              this.setState({
                isError: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
          // toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
          if (error?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            //   toast.error("Veuillez vérifier votre connexion internet.");
            Swal.fire({
              icon: "error",
              title:
                "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.setState({
            isError: true,
            InProgress: false,
          });

          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <EmptyHeader />
        <div className="reset-password-component">
          <div className="p-t-100">
            <div className="dis-flex">
              <div className="col-md-6 offset-md-3 ml-auto mr-auto reset-effective-password-container">
                <div className="card-reset-password">
                  <p className="titre-connexion">{t("edit_password")}</p>
                  <form id="reset-form">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text span-input-icon"
                          id="basic-addon1"
                        >
                          <FeatherIcon className="icon-input" icon="hash" />
                        </span>
                      </div>
                      <input
                        autoComplete="off"
                        name="code"
                        id="code"
                        onChange={(e) => this.handleChange(e)}
                        type="text"
                        className="form-control input-with-icon"
                        placeholder={t("enter_code")}
                      />
                      {this.state.is_empty_code && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {this.state.errors.code}{" "}
                        </div>
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text span-input-icon"
                          id="basic-addon1"
                        >
                          <FeatherIcon className="icon-input" icon="mail" />
                        </span>
                      </div>
                      <input
                        autoComplete="off"
                        name="email"
                        id="email"
                        readOnly
                        value={this.state.fields?.email}
                        // onChange={(e) => this.handleChange(e)}
                        type="email"
                        className="form-control input-with-icon"
                        placeholder={t("email")}
                      />
                      {this.state.is_empty_email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {this.state.errors.email}{" "}
                        </div>
                      )}
                      {this.state.is_invalid_email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {this.state.errors.invalid_email}{" "}
                        </div>
                      )}
                    </div>

                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text span-input-icon"
                          id="basic-addon1"
                        >
                          <FeatherIcon className="icon-input" icon="lock" />
                        </span>
                      </div>
                      <input
                        autoComplete="new-password"
                        name="new_password"
                        id="new_password"
                        onChange={(e) => this.handleChange(e)}
                        type="password"
                        className="form-control input-with-icon"
                        placeholder={t("new_password")}
                      />
                      {this.state.is_empty_new_password && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {this.state.errors.new_password}{" "}
                        </div>
                      )}
                    </div>

                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text span-input-icon"
                          id="basic-addon1"
                        >
                          <FeatherIcon className="icon-input" icon="lock" />
                        </span>
                      </div>
                      <input
                        autoComplete="new-password"
                        name="new_password_confirm"
                        id="new_password_confirm"
                        onChange={(e) => this.handleChange(e)}
                        type="password"
                        className="form-control input-with-icon"
                        placeholder={t("confirm_password")}
                      />
                      {this.state.is_empty_new_password_confirm && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {this.state.errors.new_password_confirm}{" "}
                        </div>
                      )}
                      {this.state.is_not_same_password && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {this.state.errors.not_same_password}{" "}
                        </div>
                      )}
                    </div>

                    {this.state.InProgress === false ? (
                      <button
                        type="submit"
                        onClick={(e) => this.onSubmitResetPassword(e)}
                        className="btn-connexion m-t-50"
                      >
                        {t("send")}
                      </button>
                    ) : (
                      <button className="btn-connexion in-progress-btn m-t-50">
                        {t("en_cours")} &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ResetPassword);
