import React, { useMemo } from "react";
import * as IconlyPack from "react-iconly";
import AnnuairePP from "../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../Http/backend-base-url";
import VoirFulbrighter from "../../Modals/Frontend/VoirFulbrighter";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import PublicIcon from "@mui/icons-material/Public";

var AnnuaireItem = ({ item }) => {
  const { t } = useTranslation();
  const country = useMemo(() => countryList().getData(), []);

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  return (
    <div>
      <div className="details-annuaire-item">
        <div className="bloc-card pt-2">
          <div className="card">
            <div className="card-body p-0 position-relative">
              <div className="p-4 container-bloc-card">
                <div className="d-flex align-items-center">
                  <div className="pb-3">
                    <img
                      src={
                        item?.avatar && !item?.avatar?.includes("default.png")
                          ? baseUrl + item?.avatar
                          : AnnuairePP
                      }
                      alt="Annuaire PhotoProfil"
                      className="annuaire-pp"
                    />
                  </div>
                  <div className="pl-3">
                    <p className="name-annuaire">
                      {item?.prenom + " " + item?.nom}
                    </p>
                    <p className="fonction">
                      {item?.statut === "etudiant"
                        ? t("student")
                        : item?.statut === "faculty"
                        ? t("faculty")
                        : item?.statut === "chercheur"
                        ? t("searcher")
                        : item?.statut === "staff"
                        ? t("staff")
                        : item?.statut === "ata"
                        ? t("ata")
                        : null}
                      -{item?.annee_cohorte}
                    </p>
                    <div className="d-flex align-items-center">
                      {item?.pays ? (
                        <img
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                            country?.filter(
                              (val) => val?.label === item?.pays
                            )[0]?.value
                          }.svg`}
                          alt=""
                          className="img-pays-fulbrighter"
                        />
                      ) : null}

                      <p className="pays-origine pl-2">{item?.pays}</p>
                    </div>
                  </div>
                </div>
                <div className="border-top border-bottom py-2">
                  <div className="row  pt-2">
                    <div
                      className="col-sm-12 d-flex align-items-center pb-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <IconlyPack.Message set="bold" primaryColor="#B6B6B6" />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.email}
                      </p>
                    </div>
                    {/* <div
                      className="col-sm-12 d-flex align-items-center pb-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <PublicIcon
                        style={{ color: "#B6B6B6", width: 24, height: 24 }}
                      />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.site_web}
                      </p>
                    </div> */}
                    <div
                      className="col-sm-12 d-flex align-items-center pb-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <IconlyPack.Call set="bold" primaryColor="#B6B6B6" />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.phone || t("not_specified")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="row bloc-annuaire-skills-item pt-2">
                  {item?.id && IsJsonString(item?.domaine_expertise)
                    ? JSON.parse(item?.domaine_expertise)?.length &&
                      JSON.parse(item?.domaine_expertise).map((tag, index) => (
                        // <Tag className="mb-4 tag" key={index}>
                        //   {tag}
                        // </Tag>
                        <div className="annuaire-tag contain-item" key={index}>
                          <p className="text-fulbrighter-annuaire">{tag}</p>
                        </div>
                      ))
                    : null}

               
                </div> */}

                <div className="text-center position-btn-card-annuaire">
                  <button
                    type="button"
                    className="btn btn-voir-catalogue"
                    data-toggle="modal"
                    data-target={`#VoirFulbrighter${item?.id}`}
                  >
                    <IconlyPack.Show set="light" primaryColor="#B6B6B6" />
                    <span className="pl-2">{t("view")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`VoirFulbrighter${item?.id}`}
        tabIndex="-1"
        aria-labelledby="VoirFulbrighterLabel"
        aria-hidden="true"
      >
        <VoirFulbrighter item={item} />
      </div>
    </div>
  );
};

export default AnnuaireItem;
