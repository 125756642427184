import React, { useEffect } from "react";
import { TimeCircle } from "react-iconly";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import Header from "../../../Navs/Header";

import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import CategoriesTable from "../../../Tables/Coordonnateur/categoriesTable/CategoriesTable";
import AjouterCategorieModal from "../../../Modals/Coordonnateur/AjouterCategorieModal";
import { useTranslation } from "react-i18next";

function CategorieAdmin() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header"
        >
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("list_category")}</h1>
                </div>
                <div
                  className="adding-item-container linkable no-link"
                  data-toggle="modal"
                  data-target="#AjouterCategorieModal"
                >
                  <span className="icon-container">
                    <IconlyPack.Plus set="light" primaryColor="#003DA5" />
                  </span>
                  <span className="adding-item-label">{t("add_category")}</span>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <CategoriesTable />
                </div>
              </div>
              <div
                className="modal fade"
                id="AjouterCategorieModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AjouterCategorieModalLabel"
                aria-hidden="true"
              >
                <AjouterCategorieModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorieAdmin;
