import api from "../../Http/global-vars";
import { FETCH_USER_MESSAGES } from "../types";

export const fetchUserMessages = (id) => {
  return async (dispatch) => {
    const userType = window.sessionStorage.getItem("userType");
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    let url = api + "messagebyadmin/";
    if (userType !== "admin") url = api + "messagebyuser/" + id + "/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data);
        dispatch({
          type: FETCH_USER_MESSAGES,
          payload: data.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
