/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton, { Card } from "@nejcm/react-skeleton";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlaylist } from "../../../redux/playlist/playlistAction";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import "./Documents.css";
import PlaylistItem, { VideoItem } from "./PlaylistItem";
var Media = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devise = useSelector((state) => state.devise);
  const [loading, setLoading] = useState(false);
  const { data, nextPageToken } = useSelector((s) => s.playlist);
  const [search, setSearch] = useState("");
  const getData = useCallback(async () => {
    if (data.length > 0 && !nextPageToken) return;
    setLoading(true);
    try {
      const res = await YoutubeApi.getPlaylists(nextPageToken);
      const { nextPageToken: np, items } = res;
      dispatch(fetchPlaylist([...data, ...items], np));
    } catch (ex) {
      alert(ex?.message);
    }
    setLoading(false);
  }, [data, nextPageToken]);
  useEffect(() => {
    getData();
  }, []);
  const filtred = searchPlaylist(data, search);
  const empty = filtred.length === 0 && !loading && <EmptyData />;
  const user = useSelector((state) => state.user);
  const { data: videos = [], isLoading } = useSelector((state) => state.videos);
  const visible = !!user;
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="banner">
              <div className="py-1 text-center">
                <h1 className="titre-banner">Media</h1>
              </div>
            </div>
            <div className="container-warc py-5 mt-2">
              {!!visible && (
                <div className="my-2">
                  <h4 className="mb-1">{t("privateVideos")}</h4>
                  <div className="row">
                    {isLoading && <PlaylistSkelaton />}
                    {!isLoading && (
                      <>
                        {videos?.length === 0 && <p>{t("no_videos")}</p>}
                        {videos?.map((item) => {
                          return (
                            <div
                              className="col-xl-4 col-lg-4 col-md-4 pb-4"
                              key={item?.id}
                            >
                              <VideoItem item={item} devise={devise} />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              )}
              <SearchInput
                value={search}
                onChange={(value) => {
                  setSearch(value);
                  if (nextPageToken && !loading) getData();
                }}
              />
              <div className="row my-5">
                {filtred.map((item) => {
                  return (
                    <div
                      className="col-xl-4 col-lg-4 col-md-4 pb-4"
                      key={item?.id}
                    >
                      <PlaylistItem item={item} devise={devise} />
                    </div>
                  );
                })}
                {loading && <PlaylistSkelaton />}
                {empty}
                {!loading && nextPageToken && (
                  <div className="col-12 text-center pb-4">
                    <span
                      className="cursor-pinter btn btn-participate-event trans-0-2 ml-auto"
                      onClick={() => getData()}
                    >
                      <span>{t("see_more")}</span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="banner">
          <div className="py-2 text-center">
            <h1 className="titre-banner">Media</h1>
          </div>
        </div>
        <div className="container-warc py-5 mt-2">
        {!!visible && (
                <div className="my-2">
                  <h4 className="mb-1">{t("privateVideos")}</h4>
                  <div className="row">
                    {isLoading && <PlaylistSkelaton />}
                    {!isLoading && (
                      <>
                        {videos?.length === 0 && <p>{t("no_videos")}</p>}
                        {videos?.map((item) => {
                          return (
                            <div
                              className="col-xl-4 col-lg-4 col-md-4 pb-4"
                              key={item?.id}
                            >
                              <VideoItem item={item} devise={devise} />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              )}
          <SearchInput
            value={search}
            onChange={(value) => {
              setSearch(value);
              if (nextPageToken && !loading) getData();
            }}
          />
          <div className="row my-5">
            {filtred.map((item) => {
              return (
                <div className="col-xl-4 col-lg-4 col-md-4 pb-4" key={item?.id}>
                  <PlaylistItem item={item} devise={devise} />
                </div>
              );
            })}
            {loading && <PlaylistSkelaton />}
            {empty}
            {!loading && nextPageToken && (
              <div className="col-12 text-center pb-4">
                <span
                  className="cursor-pinter btn btn-participate-event trans-0-2 ml-auto"
                  onClick={() => getData()}
                >
                  <span>{t("see_more")}</span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Media;

export const PlaylistSkelaton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="col-xl-4 col-lg-4 col-md-4 pb-4">
        <Card image width={`100%`} header />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};

const commonParams = {
  key: "AIzaSyCTXLkXzrRddHO0Fox6vB0__g__AkMQNb0",
  maxResults: 24,
  part: "snippet",
};

const defaultPlaylistParams = {
  ...commonParams,
  channelId: "UCcgES00GapHxx-bODWdFN5g",
};

export const YoutubeApi = {
  getPlaylists: (pageToken) => {
    const params = { ...defaultPlaylistParams, pageToken };
    const url = new URL(`https://youtube.googleapis.com/youtube/v3/playlists`);
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url.searchParams.append(key, params[key]);
      }
    });
    return fetch(url).then((res) => res.json());
  },
  getPlaylistsItems: (playlistId, pageToken) => {
    const params = { ...commonParams, playlistId, pageToken };
    const url = new URL(`https://www.googleapis.com/youtube/v3/playlistItems`);
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url.searchParams.append(key, params[key]);
      }
    });
    return fetch(url).then((res) => res.json());
  },
};

export function SearchInput({ value, onChange }) {
  return (
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-6 mt-2 mb-2">
        <div className="input-group">
          <input
            type="search"
            className="form-control padding-input"
            placeholder={"Search..."}
            aria-describedby="basic-addon2"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          <span className="input-group-text" id="basic-addon-annuaire">
            <IconlyPack.Search set="light" primaryColor="#000" />
          </span>
        </div>
      </div>
    </div>
  );
}

export function searchPlaylist(data, search) {
  const s = search.trim().toLowerCase();
  if (s.length > 0) {
    return data.filter((i) => i.snippet.title.toLowerCase().includes(s));
  }
  return data;
}

export function EmptyData() {
  const { t } = useTranslation();
  return (
    <div className="col-md-12">
      <div className="gfa-rowy mt-5">
        <div className="alert alert-warning gfa-warning">{t("noData")}</div>
      </div>
    </div>
  );
}
