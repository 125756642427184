import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionFulbrighterTableSkeleton from "./GestionFulbrighterTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ModifierFulbriterModal from "../../../Modals/Coordonnateur/ModifierFulbriterModal";
import { putData } from "../../../utils/fetchData";
import { fetchFulbrighters } from "../../../../redux/fulbrighters/FulbrighterAction";
// import {fetchUsers} from "../../../../redux/user/userAction"
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { fetchUsers } from "../../../../redux/Users/UsersAction";

var GestionFulbrighterTable = () => {
  const { t } = useTranslation();
  // const fulbrighters = useSelector((state) => state.fulbrighters);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFulbrighters());
  }, [dispatch]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const onDelete = async (item) => {
    let token = window.sessionStorage.getItem("userToken");
    let data =
      item?.is_archived === false
        ? { is_archived: true, is_active: false }
        : { is_archived: !item?.is_archived,  };
    await Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title:
        item?.is_archived === false
          ? t("warning_message_fulbrighter")
          : t("warning_unmessage_fulbrighter"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return putData(
          `user/${item?.id}/`,
          data,
          token
        )
          .then(async (response) => {
            // console.log("res", response);
            await dispatch(fetchUsers());
            await dispatch(fetchFulbrighters());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const statutFormatter = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/detail-fulbriters-coordonnateur/${row?.id}`,
          state: { userItem: row },
        }}
        className="no-link"
        style={{
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: 14,
          color: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <span className="pl-3">
          {" "}
          {cell === "etudiant"
            ? t("student")
            : cell === "faculty"
            ? t("faculty")
            : cell === "chercheur"
            ? t("searcher")
            : cell === "staff"
            ? t("staff")
            : cell === "ata"
            ? t("ata")
            : null}
        </span>
      </NavLink>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <NavLink
          to={{
            pathname: "/messages-coordonnateur",
            state: { userItem: row },
          }}
          className="action-btn show-btn"
          title={t("chat_with")}
        >
          <IconlyPack.Chat
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </NavLink>
        <NavLink
          to={{
            pathname: `/detail-fulbriters-coordonnateur/${row?.id}`,
            state: { userItem: row },
          }}
          className="action-btn show-btn"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-prestataire-modal"
          />
        </NavLink>
        <button className="action-btn edit-btn" title={t("edit")}>
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#ModifierFulbriterModal${row?.id}`}
          />
        </button>
        {!row?.is_archived ? (
          <button className="action-btn delete-btn" title={t("archive")}>
            <IconlyPack.Delete
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-delete-icon"
              onClick={() => onDelete(row)}
            />
          </button>
        ) : (
          <button className="action-btn delete-btn" title={t("unarchive")}>
            <IconlyPack.Delete
              set="light"
              primaryColor="red"
              className="action-btn-icon action-delete-icon"
              onClick={() => onDelete(row)}
            />
          </button>
        )}
        

        <div
          className="modal fade"
          id={`ModifierFulbriterModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="ModifierFulbriterModalLabel"
          aria-hidden="true"
        >
          <ModifierFulbriterModal fulbrighter={row} />
        </div>
      </div>
    );
  };

  const redirectionFormatter = (cell, row) => {
    return (
      <>
        <NavLink
          to={{
            pathname: `/detail-fulbriters-coordonnateur/${row?.id}`,
            state: { userItem: row },
          }}
          className="no-link"
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <span> {cell + " " + row?.nom}</span>
        </NavLink>
      </>
    );
  };

  const optionFilter = {
    etudiant: t("student"),
    chercheur: t("searcher"),
    faculty: t("faculty"),
    staff: t("staff"),
    ata: t("ata"),
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {users?.isLoading && <GestionFulbrighterTableSkeleton />}
            {!users?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  // data={fulbrighters?.data}
                  data={users?.data?.filter(
                    (user) => user?.user_type === "fulbrighter"
                  )}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="50"
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    filter={{ type: "TextFilter" }}
                    dataField="prenom"
                    dataFormat={(cell, row) => redirectionFormatter(cell, row)}
                  >
                    {t("firstName")}& {t("lastName")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{ type: "SelectFilter", options: optionFilter }}
                    width="150"
                    dataField="statut"
                    dataFormat={(cell, row) => statutFormatter(cell, row)}
                  >
                    {t("status")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="200"
                    dataField="email"
                  >
                    {t("email")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="annee_cohorte"
                  >
                    {t("cohort_year")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                    }}
                    width="150"
                    dataField="pays"
                    dataAlign="center"
                  >
                    {t("country")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // dataAlign="center"
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionFulbrighterTable;
