import React from "react";
import { Link, NavLink, use } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../languageSelect";

var EmptyHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid padding-hearder empty-fluidy d-flex justify-content-center">
      <div className="empty-header w-full">
        <div className="row justify-content-end">
          <div className="language-select">
            <div className="language-selector-mobile">
              <LanguageSelect />
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-3 pb-3">
            <div>
              <NavLink to="/app/connexion" className="btn btn-theme">
                {/* Connexion */}
                {t("connection")}
              </NavLink>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-3 pb-3">
            <div>
              <NavLink to="/app/inscription" className="btn btn-theme-light">
                {/* Inscription */}
                {t("register")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyHeader;
