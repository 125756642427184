import { ANNUAL_REPORT } from "../types";

export const annual_reportsReducer = (state = [], action) => {
  switch (action.type) {
    case ANNUAL_REPORT:
      return action.payload;
    default:
      return state;
  }
};
