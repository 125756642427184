import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import baseUrl from "../../../../Http/backend-base-url";
import banner from "../../../../images/warc-banniere.png";
import Slides from "../DocumentsAdmin/Slides";
import AddConfigModal from "./AddConfigModal";

function Personnalisation() {
  const { t } = useTranslation();
  const config = useSelector((state) => state?.config);

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="container-banner-admin">
          <div
            className="image banner-admin-dashboard"
            style={{
              background: `url(${
                config?.data?.id && config?.data?.image_landing_page
                  ? baseUrl + config?.data?.image_landing_page
                  : banner
              })`,
            }}
          >
            <div className="bloc-titre-admin pt-5 mt-5">
              <h1 className="titre-banner-admin">
                {config?.data?.id && config?.data?.title_landing_page
                  ? config?.data?.title_landing_page
                  : t("titre_landingpage")}
              </h1>
            </div>
          </div>
          <div className="middle mt-4">
            <button
              className="ajouter-btn-modal"
              type="button"
              data-toggle="modal"
              data-target="#AddConfig"
            >
              {/* <Edit set="bold" primaryColor="#003DA5" /> */}
              <span className="pl-2">{t("edit")}</span>
            </button>
          </div>
          <div
            className="modal fade"
            id="AddConfig"
            tabIndex="-1"
            aria-labelledby="AddConfigLabel"
            aria-hidden="true"
          >
            <AddConfigModal time={moment().format("HH:mm:ss")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default () => {
  return <Slides />;
};
