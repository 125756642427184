import moment from "moment";
import React from "react";
import {
  BootstrapTable,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchVideos } from "../../../../redux/video/VideoAction";
import DetailsVideo from "../../../Modals/Coordonnateur/DetailsVideo";
import UpdateVideoModal from "../../../Modals/Coordonnateur/UpdateVideoModal";
import { deleteData } from "../../../utils/fetchData";

var VideosAdminTable = () => {
  const { t } = useTranslation();
  const videos = useSelector((state) => state.videos);
  const dispatch = useDispatch();

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const photoFormatter = (cell, row) => {
    return (
      <div>
        <span className="pl-3"> {cell}</span>
      </div>
    );
  };

  const onDelete = (id) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: t("warning_message_video"),
      icon: "warning",
      //   customClass: {
      //     confirmButton: "btn-theme-plus",
      //     closeButton: "btn btn-danger",
      //   },
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`video/${id}/`, token)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchVideos());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const OpenModal = (id) => {
    window.$(`#DetailsDocumentModal${id}`).modal("show");
  };

  const OpenUpdateModal = (id) => {
    window.$(`#UpdateVideoModal${id}`).modal("show");
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container">
          <button className="action-btn show-btn" title="Details">
            <IconlyPack.Show
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              onClick={() => OpenModal(row?.id)}
            />
          </button>
          <button className="action-btn delete-btn" title={t("delete")}>
            <IconlyPack.Edit
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-delete-icon"
              onClick={() => OpenUpdateModal(row?.id)}
            />
          </button>
          <button className="action-btn delete-btn" title={t("delete")}>
            <IconlyPack.Delete
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-delete-icon"
              onClick={() => onDelete(row?.id)}
            />
          </button>

          <div
            className="modal fade"
            id={`UpdateVideoModal${row?.id}`}
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="DetailsDocumentModalLabel"
            aria-hidden="true"
          >
            <UpdateVideoModal item={row} />
          </div>
        </div>
        <div
          className="modal fade"
          id={`DetailsDocumentModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="DetailsDocumentModalLabel"
          aria-hidden="true"
        >
          <DetailsVideo video={row} />
        </div>
      </>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {!videos?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={videos?.data}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="50"
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    dataField="title"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    {t("title")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataFormat={(cell, row) =>
                      moment(cell).format("DD MMMM YYYY")
                    }
                    width="150"
                    dataField="created_at"
                    // dataAlign="center"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    {t("added_date")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosAdminTable;
