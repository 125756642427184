import React, { useEffect, useMemo } from "react";
import * as IconlyPack from "react-iconly";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

const DetailsUniversiteEdit = ({
  formData,
  setFormData,
  navigation,
  dataForm,
  setDataForm,
  formState,
  register,
  handleSubmit,
  setValue,
  clearErrors,
  phone,
  setPhone,
  fileName,
  setFileName,
  val,
  setVal,
  reset,
  university,
}) => {
  const options = useMemo(() => countryList().getData(), []);
  const { t } = useTranslation();
  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  const uploadFile = (e) => {
    console.log("target", e.target);
    setFileName(e.target.files[0].name);
    setValue("logo", e.target.files[0]);
  };

  const changeHandler = (value) => {
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  const onSubmit = async (data) => {
    await setDataForm({ ...dataForm, ...data });
    await navigation.next();

    await setFileName("");
    await setVal("");
    await setPhone("+221");
    await reset();
  };

  return (
    <div className="">
      <div className="text-center">
        <p className="titre-ajouter-catalogue-modal">
          {t("details_university")}
        </p>
      </div>
      <form className="row crud-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="nom">
                {t("name_university")} <span className="required-label">*</span>
              </label>
              <input
                type="text"
                placeholder={t("name_university")}
                name="nom"
                {...register("nom")}
                className="form-control input-add-document"
                id="nom"
              />
              {formState.errors && formState.errors.nom && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.nom?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="country">
                {t("country")}
                <span className="required-label">*</span>
              </label>

              <ReactFlagsSelect
                className="form-control input-add-document"
                selected={val}
                searchable
                showOptionLabel
                showSecondaryOptionLabel
                onSelect={changeHandler}
                placeholder={t("select_country")}
              />
              {formState.errors && formState.errors.pays && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.pays?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="facultes">
                {t("nbr_facultes")}
                {/* <span className="required-label">*</span> */}
              </label>
              <input
                type="number"
                placeholder={t("nbr_facultes")}
                name="nb_facultes"
                {...register("nb_facultes")}
                className="form-control  input-add-document"
                id="facultes"
              />
              {formState.errors && formState.errors.nb_facultes && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.nb_facultes?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="students">
                {t("nbr_students")}
                {/* <span className="required-label">*</span> */}
              </label>
              <input
                type="number"
                placeholder={t("nbr_students")}
                name="nb_students"
                {...register("nb_students")}
                className="form-control  input-add-document"
                id="students"
              />
              {formState.errors && formState.errors.nb_students && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.nb_students?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="date">
                {t("name_responsable")}{" "}
                {/* <span className="required-label">*</span> */}
              </label>
              <input
                type="text"
                placeholder={t("name_responsable")}
                name="titre"
                {...register("head_text")}
                className="form-control  input-add-document"
                id="Titre"
              />
              {formState.errors && formState.errors.head_text && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.head_text?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="email">
                {t("email")}
                {/* <span className="required-label">*</span> */}
              </label>
              <input
                type="email"
                placeholder={t("email")}
                name="email"
                {...register("email")}
                className="form-control  input-add-document"
                id="email"
              />
              {formState.errors && formState.errors.email && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.email?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="phone">
                {t("phone")}
                {/* <span className="required-label">*</span> */}
              </label>
              <PhoneInput
                masks={{ sn: ".. ... .. .." }}
                inputClass="form-control input-add-document phone-edit"
                country={"sn"}
                // onlyCountries={['sn']}
                inputProps={{
                  name: "telephone",
                  required: true,
                  autoFocus: true,
                }}
                // disableDropdown={true}
                enableAreaCodes={true}
                prefix="+"
                value={phone}
                countryCodeEditable={false}
                onChange={(phone) => handleSetPhone(phone)}
                inputStyle={{
                  width: "100%",
                  paddingBottom: "22px",
                  borderRadius: "5px",
                  background: "#F2F3F3",
                  height: "49px",
                  border: "none",
                }}
              />
              {formState.errors && formState.errors.phone && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.phone?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="adresse">
                {t("address")}
                {/* <span className="required-label">*</span> */}
              </label>
              <input
                type="text"
                placeholder={t("address")}
                name="adresse"
                className="form-control  input-add-document"
                id="adresse"
                {...register("adresse")}
              />
              {formState.errors && formState.errors.adresse && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.adresse?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="file">
                Logo<span className="required-label">*</span>
              </label>
              <div className="inputfile-box">
                <input
                  type="file"
                  id={`file${university?.id}`}
                  name="logo"
                  accept="image/*"
                  className="inputfile input-add-document"
                  onChange={(e) => uploadFile(e)}
                />
                <label htmlFor={`file${university?.id}`}>
                  <span
                    id={`file${university?.id}`}
                    className="file-box input-add-document"
                  >
                    <span className="text-input-file-costum">
                      {fileName || "upload_image"}
                    </span>
                  </span>
                  <span className="file-button">
                    <IconlyPack.Upload set="light" primaryColor="#000" />
                  </span>
                </label>
              </div>
              {formState.errors && formState.errors.logo && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.logo?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="">
            <div className="form-group crud-form-group">
              <label htmlFor="site_web">
                {t("website")}
                <span className="required-label">*</span>
              </label>
              <input
                type="url"
                placeholder={t("website")}
                name="site_web"
                className="form-control  input-add-document"
                id="site_web"
                {...register("site_web")}
              />
              {formState.errors && formState.errors.site_web && (
                <div className="alert alert-danger gfa-alert-danger">
                  {formState.errors.site_web?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <button
            type="submit"
            className="btn crud-submit-btn"
            // onClick={navigation.next}
          >
            {t("next")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DetailsUniversiteEdit;
