/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import Skeleton from "@nejcm/react-skeleton";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import PhotoProfil from "../../../images/others/default-profile-avatar.png";
import { fetchUserMessages } from "../../../redux/messages/MessagesAction";
import { fetchUsers } from "../../../redux/Users/UsersAction";
import { getAvatar } from "../../../utils";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import "./Messagerie.css";

var Messagerie = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { chatData, isLoading, user, userSelected, handleSelect } =
    useChatData();

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        message: yup.string().required("Message is required."),
      }),
    []
  );

  const { register, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const devise = useSelector((state) => state.devise);
  const scrollToEnd = () => {
    if (document.querySelector(`#lastMessage`)) {
      document.querySelector(`#lastMessage`).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  useEffect(() => {
    if (userSelected?.user?.id) {
      setValue("receiver", userSelected?.user?.id);
      setTimeout(() => scrollToEnd(), 2000);
    }
  }, [userSelected]);

  const onSubmit = async (data, e) => {
    data["sender"] = user?.data?.id;
    let request = axios.post(`${api}message/`, data, config);

    await request
      .then(async (response) => {
        console.log("response message", response);
        dispatch(fetchUserMessages(user?.data?.id));
        setValue("message", "");
        scrollToEnd();
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUsers());
    $(document).ready(function () {
      $("#action_menu_btn").click(function () {
        $(".action_menu").toggle();
      });
    });
  }, []);
  if (devise) {
    return (
      <div className="messagerie">
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div>
              <div className="row justify-content-center h-100 mt-5">
                <div className="chat">
                  <div className="card mb-sm-3 mb-md-0 contacts_card">
                    <div className="card-body contacts_body" id="contacts_body">
                      <ui className="contacts">
                        {isLoading && <ActivitiesSkeleton />}
                        {!isLoading &&
                          (chatData.length > 0 ? (
                            chatData.map((item) => (
                              <li
                                className={"user_item"}
                                key={item?.user?.id}
                                id={item?.user?.id}
                              >
                                <NavLink
                                  to={{
                                    pathname: `/app/messagerie/${item?.user?.id}`,
                                    state: { userItem: item.user },
                                  }}
                                  className="no-link"
                                >
                                  <div className="d-flex bd-highlight">
                                    <div className="img_cont">
                                      <img
                                        alt=""
                                        // src={PhotoProfil}
                                        src={getAvatar(item?.user?.avatar)}
                                        className="rounded-circle user_img"
                                      />
                                    </div>
                                    <div className="user_info">
                                      <span>
                                        {item?.user?.prenom +
                                          " " +
                                          item?.user?.nom}
                                      </span>
                                      <p>{item?.user?.email}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </li>
                            ))
                          ) : (
                            <div className="gfa-rowy mt-5">
                              <div className="alert alert-warning gfa-warning">
                                {t("noData")}
                              </div>
                            </div>
                          ))}
                      </ui>
                    </div>
                    <div className="card-footer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start" id="messagerie-desktop">
        <div className="container-warc py-5 mt-5 messagerie">
          <div className="h-100">
            <div className="row justify-content-center h-100 bg-white">
              <div className="col-xl-3 col-lg-3 col-md-4 chat mt-0">
                <div className="card mb-sm-3 mb-md-0 contacts_card w-full">
                  <div className="card-body contacts_body">
                    <ui className="contacts">
                      {isLoading && <ActivitiesSkeleton />}
                      {!isLoading &&
                        (chatData.length > 0 ? (
                          chatData.map((item) => (
                            <li
                              className={
                                userSelected?.user?.id === item?.user?.id
                                  ? "user_item active"
                                  : "user_item"
                              }
                              key={item?.user?.id}
                              id={item?.user?.id}
                              onClick={() => handleSelect(item?.user)}
                            >
                              <div className="d-flex bd-highlight">
                                <div className="img_cont">
                                  <img
                                    // src={PhotoProfil}
                                    alt=""
                                    src={getAvatar(item?.user?.avatar)}
                                    className="rounded-circle user_img"
                                  />
                                </div>
                                <div className="user_info">
                                  <span>
                                    {item?.user?.prenom + " " + item?.user?.nom}
                                  </span>
                                  <p>{item?.user?.email}</p>
                                  {item.count > 0 && (
                                    <div
                                      style={{
                                        width: 20,
                                        height: 20,
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        backgroundColor: "#F00",
                                        borderRadius: "50%",
                                        color: "#FFF",
                                      }}
                                    >
                                      {item.count}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))
                        ) : (
                          <div className="gfa-rowy mt-5">
                            <div className="alert alert-warning gfa-warning">
                              {t("noData")}
                            </div>
                          </div>
                        ))}
                    </ui>
                  </div>
                  <div className="card-footer"></div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-8 chat">
                <div className="card w-full">
                  <div className="card-header msg_head">
                    {userSelected?.user?.id ? (
                      <div className="d-flex bd-highlight">
                        <div className="img_cont">
                          <img
                            alt=""
                            //   src={PhotoProfil}
                            src={
                              userSelected?.user?.avatar &&
                              !userSelected?.user?.avatar?.includes(
                                "default.png"
                              )
                                ? baseUrl + userSelected?.user?.avatar
                                : PhotoProfil
                            }
                            className="rounded-circle user_img"
                          />
                        </div>
                        <div className="user_info">
                          <span>
                            {t("chat_with")}{" "}
                            {userSelected?.user?.prenom +
                              " " +
                              userSelected?.user?.nom}
                          </span>
                          <p>{userSelected?.messages?.length} Messages</p>
                        </div>
                      </div>
                    ) : (
                      <Skeleton className="d-flex bd-highlight">
                        <Skeleton className="img_cont mr-1">
                          <Skeleton.Circle
                            width={70}
                            height={70}
                            className="rounded-circle user_img"
                          />
                        </Skeleton>

                        <Skeleton.Rectangle width={`70%`} height={70} />
                      </Skeleton>
                    )}

                    <span id="action_menu_btn">
                      <i className="fas fa-ellipsis-v"></i>
                    </span>
                    <div className="action_menu">
                      <ul>
                        <li>
                          <i className="fas fa-user-circle"></i> View profile
                        </li>
                        <li>
                          <i className="fas fa-users"></i> Add to close friends
                        </li>
                        <li>
                          <i className="fas fa-plus"></i> Add to group
                        </li>
                        <li>
                          <i className="fas fa-ban"></i> Block
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body msg_card_body">
                    {!userSelected?.user?.id && <MessageSkeleton />}
                    {userSelected?.user?.id &&
                      (userSelected?.messages?.length
                        ? userSelected.messages.map((msg) => (
                            <Fragment key={msg?.id}>
                              {msg?.sender?.id === userSelected?.user?.id ||
                              msg?.sender?.user_type === "admin" ? (
                                <div className="d-flex justify-content-start mb-4 align-items-center">
                                  <div className="img_cont_msg">
                                    <img
                                      alt=""
                                      src={getAvatar(msg?.sender?.avatar)}
                                      className="rounded-circle user_img_msg"
                                    />
                                  </div>
                                  <div>
                                    <div className="msg_cotainer">
                                      <div className="name-admin-chat-send">
                                        {msg?.sender?.user_type === "admin" &&
                                          `${msg?.sender?.prenom} ${msg?.sender?.nom}`}
                                      </div>
                                      <p className="text-message-item">
                                        {msg?.message}
                                      </p>
                                      <div className="content-msg-time">
                                        <span className="msg_time">
                                          {moment(msg?.created_at).calendar()}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-end align-items-center mb-4">
                                  <div className="msg_cotainer_send">
                                    <p className="message-item-send">
                                      {msg?.message}
                                    </p>
                                    <div className="content-time-msg-send">
                                      <span className="msg_time_send">
                                        {moment(msg?.created_at).calendar()}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="img_cont_msg">
                                    <img
                                      alt=""
                                      // src={
                                      //   user?.data?.avatar &&
                                      //   !user?.data?.avatar?.includes(
                                      //     "default.png"
                                      //   )
                                      //     ? baseUrl + user?.data?.avatar
                                      //     : PhotoProfil
                                      // }
                                      src={getAvatar(user?.data?.avatar)}
                                      className="rounded-circle user_img_msg"
                                    />
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          ))
                        : null)}
                    <div id="lastMessage" />
                  </div>
                  <div className="card-footer">
                    <form
                      className="input-group"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="input-group-append">
                        <span className="input-group-text attach_btn">
                          <i className="fas fa-paperclip"></i>
                        </span>
                      </div>
                      <textarea
                        name="message"
                        {...register("message")}
                        className="form-control type_msg"
                        placeholder={t("type_message")}
                      ></textarea>
                      <div ty className="input-group-append">
                        <button
                          role="button"
                          type="submit"
                          className="input-group-text send_btn"
                        >
                          <i className="fas fa-location-arrow"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Messagerie;
export const MessageSkeleton = () => {
  return (
    <Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
    </Skeleton>
  );
};
export const ActivitiesSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="d-flex">
        {/* <Skeleton.Circle width={40} height={40} className="mb-3" /> */}
        <Skeleton.Circle
          height={70}
          width={70}
          className="rounded-circle user_img mr-1"
        />
        {/* <Paragraph className="mb-3" /> */}
        <Skeleton.Rectangle height={70} width={`100%`} className="mb-3" />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};

export function transformChatToChatData(data, userId, users) {
  const t = (chat) => moment(chat.created_at).toDate().getTime();
  if (!userId) return [];
  const finalData = [];
  const finalData1 = [];
  const sorted = [...data].sort((a, b) => t(a) - t(b));
  const usersId = Array.from(
    new Set(
      sorted.map((item) =>
        // @ts-ignore
        item.sender.id === userId ? item.receiver.id : item.sender.id
      )
    )
  );

  for (let id of usersId) {
    const messages = sorted.filter(
      (s) => s.sender.id === id || s.receiver.id === id
    );
    const count = messages.filter((f) => f.sender.id === id && !f.read).length;
    const user =
      messages[0].sender.id === id ? messages[0].sender : messages[0].receiver;
    finalData.push({
      lastMessage: messages[messages.length - 1],
      messages,
      user,
      count,
    });
  }
  const filtred = users.filter(
    (u) => finalData.findIndex((f) => f.user.id === u.id) === -1
  );
  for (let u of filtred) {
    finalData1.push({
      lastMessage: null,
      messages: [],
      user: u,
      count: 0,
    });
  }
  let m1 = null;
  const m2 = [];
  for (let u of finalData) {
    if (u.user.user_type === "admin") {
      if (m1 === null) {
        m1 = {
          lastMessage: u.lastMessage,
          messages: u.messages,
          user: { ...u.user, prenom: "CONTACT", nom: "WARC", email: "" },
          count: u.count,
        };
      } else {
        m1.messages = [...u.messages, ...m1.messages];
        m1.count = m1.count + u.count;
        m1.lastMessage = u.lastMessage;
      }
    } else {
      m2.push(u);
    }
  }
  if (m1) {
    m1.messages.sort((a, b) => t(a) - t(b));
    m2.push(m1);
  }
  return [...m2.reverse(), ...finalData1];
}

export function useChatData() {
  const history = useHistory();
  const location = useLocation();
  const [chatData, setChatData] = useState([]);
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const userMessages = useSelector((state) => state.userMessages);
  const user = useSelector((state) => state.user);
  const [isLoading, users] = useSelector((state) => [
    state.users.isLoading,
    state.users.data?.filter((u) => u.id !== user?.data?.id) ?? [],
  ]);
  const dispatch = useDispatch();

  const [userSelected, setUserSelected] = useState({});
  useEffect(() => {
    setChatData(
      transformChatToChatData(
        userMessages?.data ?? [],
        user?.data?.id,
        users ?? []
      )
    );
  }, [user.data.id, userMessages.data?.length, users?.length]);
  useEffect(() => {
    if (user?.data?.id) {
      dispatch(fetchUserMessages(user?.data?.id));
    }
  }, [user?.data?.id]);
  useEffect(() => {
    const uItem = location?.state?.userItem;
    if (!uItem && chatData.length > 0) {
      setUserSelected(chatData[0]);
    }
    if (uItem) {
      const chat = chatData.find((i) => i.user.id === uItem.id);
      setUserSelected(chat ?? chatData[0] ?? {});
      if (document.getElementById(`${chat?.user?.id}`)) {
        document.getElementById(`${chat?.user?.id}`).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [chatData, location]);

  useEffect(() => {
    if (userSelected?.user?.id) {
      const promises = [];
      for (let m of userSelected.messages) {
        if (
          (m.sender?.id === userSelected?.user?.id ||
            m.sender?.user_type === "admin") &&
          !m.read
        ) {
          let request = axios.put(
            `${api}message/${m.id}/`,
            { read: true },
            config
          );
          promises.push(request);
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          dispatch(fetchUserMessages(user?.data?.id));
        });
      }
    }
  }, [userSelected]);
  const handleSelect = (item) => {
    setUserSelected(item);
    history.push({
      pathname: "/app/messagerie",
      state: { userItem: item },
    });
  };

  return {
    isLoading,
    chatData,
    userSelected,
    user,
    handleSelect,
  };
}
