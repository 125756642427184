import React from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./CatalogueAdmin.css";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import CatalogueCoordonnateurTable from "../../../Tables/Coordonnateur/CatalogueCoordonnateurTable/CatalogueCoordonnateurTable";
import AjouterCatalogueCoordonnateurModal from "../../../Modals/Coordonnateur/AjouterCatalogueCoordonnateurModal";
import { useTranslation } from "react-i18next";

var CatalogueAdmin = () => {
  let { t } = useTranslation();
  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0">
          <div className="container-fluid">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">{t("catalogue")}</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjouterCatalogueCoordonnateurModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="#1A5C8C" />
                    </span>
                    <span className="adding-item-label">
                      {t("add_catalogue")}
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="py-5">
                    <CatalogueCoordonnateurTable />
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="AjouterCatalogueCoordonnateurModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterCatalogueCoordonnateurModalLabel"
                  aria-hidden="true"
                >
                  <AjouterCatalogueCoordonnateurModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogueAdmin;
