import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchReportUser } from "../../../redux/report_user/reports_userAction";
import { postData } from "../../utils/fetchData";
var AddShareWithModal = () => {
  const [emails, setEmails] = useState([""]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onSubmit = async () => {
    const sendValue = [...emails.filter((f) => !!f)];
    if (sendValue.length === 0) {
      setEmails([""]);
      setErrors(["Please enter an email"]);
    } else {
      setEmails(sendValue);
      let errors = sendValue.map((i) => "");
      sendValue.forEach((e, index) => {
        if (!validateEmail(e)) {
          errors[index] = "This is not a valid email";
        }
      });
      setErrors(errors);
      if (errors.filter((f) => !!f).length > 0) {
        return;
      }
      setLoading(true);
      try {
        await onSubmitForm(sendValue);
        Swal.fire({
          icon: "success",
          title: "Success!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(fetchReportUser());
        window.$("#AddShareWithModal").modal("hide");
        setErrors([]);
        setEmails([""]);
      } catch (error) {
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `Oups! une erreur de statut ${error?.response?.status} est survenue, veuillez réessayer plus tard.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
      setLoading(false);
    }
  };

  const isOk = true;

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="AddShareWithModal2"
          >
            {t("share_with")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setEmails([""]);
              setErrors([]);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form
              className="crud-form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="row crud-row" style={{ alignItems: "center" }}>
                {emails.map((email, index) => {
                  const error = errors[index];
                  return (
                    <div key={index} className="col-md-6">
                      <div className="">
                        <div className="form-group">
                          <label htmlFor="nom">
                            {t("email")} ({index + 1})
                            <span className="required-label">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder={t("email")}
                            name="email"
                            value={email}
                            onChange={(e) => {
                              setEmails((old) => {
                                const newEmails = [...old];
                                newEmails[index] = e.target.value;
                                return newEmails;
                              });
                            }}
                            className="form-control input-add-document"
                          />
                          {!!error && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn crud-submit-btn"
                    onClick={(e) => {
                      setEmails((old) => [...old, ""]);
                    }}
                  >
                    {t("addField")}
                  </button>
                </div>
                <div className="col-md-12 mt-4">
                  {loading && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!loading && (
                    <button
                      disabled={!isOk}
                      type="submit"
                      className="btn crud-submit-btn"
                    >
                      {t("share")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShareWithModal;

function validateEmail(email) {
  return (
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    )
  );
}

const onSubmitForm = async (emails) => {
  let token = window.sessionStorage.getItem("userToken");
  for (let e of emails) {
    let fd = new FormData();
    fd.append("email", e);
    await postData("report_user/", fd, token);
  }
};
