import React, { useEffect, useState } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImgProfilForum from "../../../../images/others/default-profile-avatar.png";
import * as IconlyPack from "react-iconly";
import MessageIcon from "@material-ui/icons/Message";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import Swal from "sweetalert2";
import { fetchPostes } from "../../../../redux/Posts/PostAction";
import Post from "../../../../services/Post";
import { useDispatch, useSelector } from "react-redux";
import AjouterCommentaireModal from "../../../Modals/Coordonnateur/AjouterCommentaireModal";
import axios from "axios";
import api from "../../../../Http/global-vars";
import Header from "../../../Navs/Header";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/en-ca";

var DetailsDiscussionForum = (props) => {
  const { t } = useTranslation();
  const posts = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [post, setPost] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.post) {
      setPost(location?.state?.post);
      getDetails(location?.state?.post?.id);
    }
  }, [location]);

  const getDetails = (id) => {
    axios
      .get(`${api}post/${id}/`, config)
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => {
        console.log("error", error?.response);
        if (error?.response?.status === 404) {
          history.goBack();
        }
      });
  };

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onDelete = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_post"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Post.onDeletePost(item?.id, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchPostes());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push("/forum-coordonnateur");
        });
      }
    });
  };

  const onPublish = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: !item?.publiee ? t("message_publish") : t("message_unpublish"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Post.onEditPost(item?.id, { publiee: !item?.publiee }, config)
          .then(async (response) => {
            console.log("res", response);
            await getDetails(location?.state?.post?.id);
            await dispatch(fetchPostes());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const onDeleteComment = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_comment"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Post.onDeleteComment(item?.id, config)
          .then(async (response) => {
            post["comments"] = await post?.comments?.filter(
              (c) => c?.id !== item?.id
            );
            await setPost(post);
            await console.log("res", post);
            await dispatch(fetchPostes());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // let value = posts?.data?.filter((u) => u?.id === post?.id)[0]
        history.push({
          pathname: "/detail-discussion-forum",
          state: { post: post },
        });
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container d-flex">
                    <div className="">
                      <img
                        // src={ImgProfilForum}
                        src={
                          post?.created_by?.avatar &&
                          !post?.created_by?.avatar?.includes("default.png")
                            ? baseUrl + post?.created_by?.avatar
                            : ImgProfilForum
                        }
                        alt="Image Profile Forum"
                        className="img-profil"
                      />
                    </div>
                    <div className=" bloc-liste pl-3">
                      <span className="titre-category-prestation">
                        {post?.titre}
                      </span>
                      <div className="date-faq mt-2">
                        <span className="consultation-name">
                          {t("par")}{" "}
                          <span>
                            {post?.created_by?.prenom +
                              " " +
                              post?.created_by?.nom}
                          </span>{" "}
                          {t("le")}{" "}
                          {moment(post?.created_at).format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="adding-item-container linkable btn-chat">
                    <div
                      data-toggle="modal"
                      data-target="#AjouterCommentModal"
                      className="no-link adding-item-label"
                    >
                      <span className="icon-container">
                        <MessageIcon className="icon-color" />
                      </span>
                      <span className="adding-item-label pl-3">
                        {t("add_comment")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="row mb-3 mt-5 border-forum align-items-center">
                    <div className="col-xl-2 col-lg-2 col-md-2 d-flex justify-content-center">
                      <div className="">
                        <h3 className="title-name-forum pb-3">
                          {post?.created_by?.prenom +
                            " " +
                            post?.created_by?.nom}
                        </h3>
                        <div className="">
                          <img
                            src={
                              post?.created_by?.avatar &&
                              !post?.created_by?.avatar?.includes("default.png")
                                ? baseUrl + post?.created_by?.avatar
                                : ImgProfilForum
                            }
                            alt="Image Profile Forum"
                            className="img-profil"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-10 col-lg-10 col-md-10">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div>
                            <span className="consultation-name">
                              {t("posted_on")}&nbsp;
                              {moment(post?.created_at).format(
                                "DD-MM-YYYY"
                              )}{" "}
                              {t("at")}&nbsp;
                              {moment(post?.created_at).format("hh:mm")}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end">
                          <div>
                            {!post?.publiee ? (
                              <span
                                className="bloc-icon m-2"
                                title={t("publish")}
                              >
                                <FeatherIcon
                                  className="linkable"
                                  icon="check-square"
                                  onClick={() => onPublish(post)}
                                />
                              </span>
                            ) : (
                              <span
                                className="bloc-icon m-2"
                                title={t("unpublish")}
                              >
                                <FeatherIcon
                                  className="linkable"
                                  icon="x-square"
                                  onClick={() => onPublish(post)}
                                />
                              </span>
                            )}
                          </div>
                          <div>
                            <span className="bloc-icon m-2" title={t("delete")}>
                              <FeatherIcon
                                className="linkable"
                                icon="trash-2"
                                onClick={() => onDelete(post)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="consultation-name pb-3">{post?.text}</p>
                      </div>
                    </div>
                  </div>
                  {post?.comments?.length
                    ? post?.comments
                        .sort((a, b) => b?.id - a?.id)
                        .map((comment) => (
                          <div
                            className="row mb-3 mt-5 shadow-category align-items-center"
                            key={comment?.id}
                          >
                            <div className="col-xl-2 col-lg-2 col-md-2 d-flex justify-content-center">
                              <div className="">
                                <h3 className="title-name-forum pb-3">
                                  {comment?.user?.prenom +
                                    " " +
                                    comment?.user?.nom}
                                </h3>
                                <div className="">
                                  <img
                                    src={
                                      comment?.user?.avatar &&
                                      !comment?.user?.avatar?.includes(
                                        "default.png"
                                      )
                                        ? baseUrl + comment?.user?.avatar
                                        : ImgProfilForum
                                    }
                                    alt="Image Profile Forum"
                                    className="img-profil"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-10 col-lg-10 col-md-10">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                  <div>
                                    <span className="consultation-name">
                                      {t("posted_on")} &nbsp;
                                      {moment(comment?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      {t("at")} &nbsp;
                                      {moment(comment?.created_at).format(
                                        "hh:mm"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end">
                                  <div>
                                    <span
                                      className="bloc-icon m-2"
                                      title={t("delete")}
                                    >
                                      <FeatherIcon
                                        className="linkable"
                                        icon="trash-2"
                                        onClick={() => onDeleteComment(comment)}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p className="consultation-name pb-3">
                                  {comment?.text}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                    : null}

                  {/* <div className="row mb-3 mt-5 shadow-category align-items-center">
                    <div className="col-xl-2 col-lg-2 col-md-2 d-flex justify-content-center">
                      <div className="">
                        <h3 className="title-name-forum pb-3">Abdou Seck</h3>
                        <div className="">
                          <img
                            src={ImgProfilForum}
                            alt="Image Profile Forum"
                            className="img-profil"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-10 col-lg-10 col-md-10">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div>
                            <span className="consultation-name">
                              Posté le 02/03/2020 à 10H
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end">
                          <div>
                            <span className="bloc-icon m-2">
                              <FeatherIcon className="" icon="trash-2" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="consultation-name pb-3">
                          Bonjour à tous,
                        </p>
                        <p className="consultation-name">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nibh facilisi vestibulum eget augue. Et,
                          faucibus tincidunt magna auctor. Morbi viverra risus
                          aliquam in id. Cursus pellentesque euismod urna mattis
                          tellus volutpat varius. Ultrices tristique habitant
                          volutpat dui eget cursus potenti eleifend. Dui, quam
                          condimentum nunc pellentesque molestie. Nulla viverra
                          egestas vitae commodo pharetra. Pellentesque orci
                          vulputate ut nulla. Quam risus sit morbi libero
                          adipiscing faucibus sit cras nec. Interdum tellus
                          posuere elementum maecenas massa consectetur. Pharetra
                          tellus consequat nascetur sagittis, ornare
                          suspendisse. Integer volutpat et molestie id amet,
                          facilisis. Erat lacus quisque faucibus sit dignissim
                          semper bibendum mauris eu. Nullam faucibus quam morbi
                          cras consectetur ipsum semper accumsan enim. Amet
                          fermentum dapibus tristique urna. Lectus vitae tortor
                          sit netus sit. Et pharetra rutrum nulla erat magna
                          odio amet dui. Ullamcorper libero, at euismod amet,
                          sit faucibus ante placerat faucibus. Habitasse
                          faucibus eu phasellus sed. Integer neque cursus eu
                          sapien. Eget ante sociis morbi pretium. Magna senectus
                          sed tempor ac cras a. At placerat suspendisse ut
                          curabitur neque, libero et enim. Neque in iaculis enim
                          elementum facilisi sed natoque.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* Modal */}
                <div
                  className="modal fade"
                  id="AjouterCommentModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterCommentModalLabel"
                  aria-hidden="true"
                >
                  <AjouterCommentaireModal post={location?.state?.post} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsDiscussionForum;
