import React, { Component } from "react";
import "./navbarMobileV2.css";
// import { NavLink } from 'react-router-dom';
// import * as IconlyPack from 'react-iconly';
import NavBarAdminMobile from "./NavBarAdminMobile";
import NavBarCoordonnateurMobile from "./NavBarCoordonnateurMobile";

export default class navbarMobileV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: window.sessionStorage.getItem("userType"),
    };
  }
  render() {
    const { userType } = this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <button
          className="navbar-toggler ml-sm-3"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse bg-theme"
          id="navbarNavDropdown"
        >
          <div className="py-4">
            <div className="mobile-menu-container" id="scrollbar-nav-mobile">
              <div className="mobile-menu-content content-nav-mobile">
                <ul className="mobile-menu-ul" id="menu-mobile-admin">
                  {userType === "coordo" && <NavBarCoordonnateurMobile />}

                  {userType === "admin" && <NavBarAdminMobile />}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
