import moment from "moment";
import { FETCH_LIVES } from "../types";

export const liveReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_LIVES:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload?.filter((event) =>
          moment(event?.date).isSameOrAfter(new Date())
        ),
      };
    default:
      return state;
  }
};
