import React from "react";
import * as IconlyPack from "react-iconly";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./AgendaAdmin.css";
import Header from "../../../Navs/Header";

import Paper from "@material-ui/core/Paper";
import { ViewState, EditingState, IntegratedEditing } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  WeekView,
  AllDayPanel,
  DateNavigator,
  Toolbar,
} from "@devexpress/dx-react-scheduler-material-ui";
import api from "../../../../Http/global-vars";
import axios from "axios";
import { connect } from "react-redux";
import { fetchEvents } from "../../../../redux/events/eventsAction";
import LiveEventTable from "../../../Tables/Coordonnateur/LiveEventTable/LiveEventTable";
import AjouterLive from "../../../Modals/Coordonnateur/AjouterLive";
import { withTranslation } from "react-i18next";
import AjouterEvent from "../../../Modals/Coordonnateur/AjouterEvent";

class AgendaAdmin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentDate: new Date(),
      userType: window.sessionStorage.getItem("userType"),
      addedAppointment: {},
      appointmentChanges: {},
      editingAppointment: undefined,
      config: {
        headers: {
          Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
        },
      },
      addField: {},
      editField: {},
      idField: null,
    };
  }

  componentDidMount() {
    // console.log("events", this.props.events);
    this.props.fetchEvents();
    if (!this.props.events?.isLoading) {
      this.setState({
        data: this.props.events?.data.map((item) =>
          item.id ? { ...item, ...{ notes: item?.description } } : item
        ),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.events !== this.props.events) {
      if (!this.props.events?.isLoading) {
        this.setState({
          data: this.props.events?.data.map((item) =>
            item.id ? { ...item, ...{ notes: item?.description } } : item
          ),
        });
      }
    }

    if (this.state.addField?.id) {
      console.log("cool");
      this.onAddEvent(this.state.addField);
    }

    if (this.state.editField?.id) {
      console.log("cool edit");
      // this.onEditEvent(this.state.editField);
    }
  }

  currentDateChange = (currentDate) => {
    this.setState({ currentDate });
  };

  changeAddedAppointment = (addedAppointment) => {
    console.log("add", addedAppointment);
    this.setState({ addedAppointment });
  };

  changeAppointmentChanges = (appointmentChanges) => {
    this.setState({ appointmentChanges });
  };

  changeEditingAppointment = (editingAppointment) => {
    console.log("add", editingAppointment);
    window.scrollTo(20,20)
    this.setState({ editingAppointment });
  };

  onAddEvent = (data) => {
    const { config } = this.state;
    data["created_by"] = this.props.user?.data?.id;
    if (data?.notes) {
      data["description"] = data?.notes;
    }
    axios
      .post(`${api}event/`, data, config)
      .then((response) => {
        // console.log("response", response);
        this.props.fetchEvents();
        this.setState({ addField: {} });
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  onEditEvent = (data) => {
    const { config } = this.state;
    data["created_by"] = data?.created_by?.id;
    if (data?.notes) {
      data["description"] = data?.notes;
    }
    console.log("data", data);
    axios
      .put(`${api}event/${data?.id}/`, data, config)
      .then(async (response) => {
        // console.log("response", response);
        await this.setState({ editField: {} });
        await this.props.fetchEvents();
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  onDeleteEvent = (id) => {
    const { config } = this.state;
    axios
      .delete(`${api}event/${id}/`, config)
      .then(async (response) => {
        return this.props.fetchEvents();
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  commitChanges = ({ added, changed, deleted }) => {
   
    this.setState(async (state) => {
      let { data } = state;
      if (added && added?.title) {
        const startingAddedId =
          data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
        // this.onAddEvent({ id: startingAddedId, ...added });
        this.setState({ addField: { id: startingAddedId, ...added } });
        // console.log("object", { id: startingAddedId, ...added });
      }
      if (changed) {
        let newChange = data.filter((item) => changed[item.id])[0];
        data = data.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        );

        try {
          await this.onEditEvent({ ...newChange, ...changed[newChange?.id] });
        } catch (error) {
          console.log("error", error);
        }
      }
      if (deleted !== undefined) {
        data = data.filter((appointment) => appointment.id !== deleted);
        console.log("delete", deleted);
        this.onDeleteEvent(deleted);
      }
      // console.log("custome", changed);
      return { data };
    });
  };

  render() {
    const {
      currentDate,
      data,
      addedAppointment,
      appointmentChanges,
      editingAppointment,
    } = this.state;
    let lng = window.localStorage.getItem("i18nextLng");
    const { t } = this.props;
    return (
      <div className="component-dashboard-coordonnateur">
        <Header />
        <div className="row">
          <div className="nav-container no-view-mobile">
            <NavBarVerticalV2 />
          </div>
          <div
            className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
            id="content-position-after-header"
          >
            <div className="page-body-container">
              <div className="page-content-container position-relative">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <ul
                      class="nav nav-tabs navtabs-agenda-coordonnateur"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link active agenda-tabs-nav"
                          id="agenda-tab"
                          data-toggle="tab"
                          href="#agenda"
                          role="tab"
                          aria-controls="agenda"
                          aria-selected="true"
                        >
                          Agenda
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link agenda-tabs-nav"
                          id="live-tab"
                          data-toggle="tab"
                          href="#live"
                          role="tab"
                          aria-controls="live"
                          aria-selected="false"
                        >
                          Live
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="parent-table-container table-responsive">
                  <div className="py-5">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="agenda"
                        role="tabpanel"
                        aria-labelledby="agenda-tab"
                      >
                        <div className="page-first-row-container">
                          <div className="page-title-container"></div>
                          <div className="position-btn-add-event-coordonnateur">
                            <div
                              className="adding-item-container linkable"
                              data-toggle="modal"
                              data-target="#AjouterEventModal"
                            >
                              <span className="icon-container">
                                <IconlyPack.Plus
                                  set="light"
                                  primaryColor="#1A5C8C"
                                />
                              </span>
                              <span className="adding-item-label">
                                {t("add_event")}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="py-5 row">
                          <div className="col-md-12">
                            <Paper>
                              <Scheduler
                                data={data}
                                locale={lng === "en" ? "en-US" : "fr-Fr"}
                              >
                                <ViewState
                                  currentDate={currentDate}
                                  onCurrentDateChange={this.currentDateChange}
                                />
                                <EditingState
                                  onCommitChanges={this.commitChanges}
                                  addedAppointment={addedAppointment}
                                  onAddedAppointmentChange={
                                    this.changeAddedAppointment
                                  }
                                  appointmentChanges={appointmentChanges}
                                  onAppointmentChangesChange={
                                    this.changeAppointmentChanges
                                  }
                                  editingAppointment={editingAppointment}
                                  onEditingAppointmentChange={
                                    this.changeEditingAppointment
                                  }
                                />
                                <IntegratedEditing />
                                <WeekView startDayHour={0} endDayHour={23} />
                                <Toolbar />
                                <DateNavigator />
                                {/* <TodayButton /> */}
                                <AllDayPanel />
                                {/* <EditRecurrenceMenu />
                                <ConfirmationDialog /> */}
                                <Appointments />
                                <AppointmentTooltip
                                  id="appointment"
                                  showOpenButton
                                  showDeleteButton
                                />
                                <AppointmentForm />
                              </Scheduler>
                            </Paper>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="live"
                        role="tabpanel"
                        aria-labelledby="live-tab"
                      >
                        <div className="page-first-row-container">
                          <div className="page-title-container"></div>
                          <div className="position-btn-add-event-coordonnateur">
                            <div
                              className="adding-item-container linkable"
                              data-toggle="modal"
                              data-target="#AjouterLive"
                            >
                              <span className="icon-container">
                                <IconlyPack.Plus
                                  set="light"
                                  primaryColor="#1A5C8C"
                                />
                              </span>
                              <span className="adding-item-label">
                                {t("add_live")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="py-5">
                          <LiveEventTable />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="AjouterEventModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterEventModalLabel"
                  aria-hidden="true"
                >
                  <AjouterEvent />
                </div>
                {/* <div
                  className="modal fade"
                  id="AjouterLiveAdmin"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterLiveAdminLabel"
                  aria-hidden="true"
                >
                  <AjouterLiveAdmin />
                </div> */}
                <div
                  className="modal fade"
                  id="AjouterLive"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterLiveLabel"
                  aria-hidden="true"
                >
                  <AjouterLive />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEvents: () => dispatch(fetchEvents()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AgendaAdmin));
