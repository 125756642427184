import React, { useEffect } from "react";
import "./frontLogin.css";
import { useHistory, NavLink, use } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Logo from "../../../images/logo.png";
import * as IconlyPack from "react-iconly";
import EmptyHeader from "../../Auth/EmptyHeader";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import DeviceDetect from "./DeviceDetect";
import UseLoginForm from "../../Auth/UseLoginForm";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../../languageSelect";

var FrontLogin = () => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
  } = UseLoginForm();

  const devise = useSelector((state) => state.devise);
  const history = useHistory();
  let token = window.sessionStorage.getItem("userToken");
  var userType = window.sessionStorage.getItem("userType");

  useEffect(() => {
    if (token && userType === "fulbrighter") {
      setTimeout(() => {
        history.push("/app/home");
      }, 100);
    }

    if (token && userType === "coordo") {
      setTimeout(() => {
        history.push("/dashboard-coordonnateur");
      }, 100);
    }

    if (token && userType === "admin") {
      setTimeout(() => {
        history.push("/dashboard-admin");
      }, 100);
    }
  }, [token]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  // useEffect(() => {
  //   console.log(isMobile, "isMobile");
  // });

  if (devise) {
    return (
      <div>
        <div className="connexion-page">
          <div className="p-4 bg-white">
            <div className="">
              <img src={Logo} alt="Logo E-compte" />
            </div>
          </div>
          <div className="login-page-container">
            <section className="auth-page-content">
              <form onSubmit={onSubmit}>
                <div className="row auth-page-row">
                  <div className="col-md-4 offset-md-4 auth-page-col">
                    <div className="row auth-form-row">
                      <h1 className="auth-form-title m-b-90">{t("welcome")}</h1>
                      <div className="col-sm-12 d-flex justify-content-center mb-4">
                        <p className="text-comment">
                          {t("no_account")}{" "}
                          <NavLink
                            className="color-green no-link"
                            to="/app/inscription"
                          >
                            {t("create_account")}
                          </NavLink>
                        </p>
                      </div>
                      <div className="col-sm-12 auth-input-col m-b-20">
                        <div className="form-group auth-form-group position-relative">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="label-ecompta"
                          >
                            {t("email")}
                          </label>
                          <input
                            name="email"
                            type="email"
                            {...register("email")}
                            className="form-control auth-form-control pl-3"
                            id="identifiant"
                            placeholder={t("email")}
                          />
                          {formState.errors && formState.errors.email && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.email?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 auth-input-col m-b-5">
                        <div className="form-group auth-form-group position-relative">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="label-ecompta"
                          >
                            {t("password")}
                          </label>
                          <input
                            name="password"
                            type="password"
                            {...register("password")}
                            className="form-control auth-form-control pl-3"
                            id="password"
                            placeholder={t("password")}
                          />
                          {formState.errors && formState.errors.password && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.password?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 d-flex justify-content-end w-full">
                        <NavLink
                          to="/request-password"
                          className="pas-de-compte py-4"
                        >
                          {t("forget_password")}
                        </NavLink>
                      </div>
                      <div className="col-sm-12 auth-input-col py-4">
                        {/* <NavLink to="/dashboard" className="btn auth-submit-btn">
                        Connexion
                      </NavLink> */}
                        {isSuccessfullySubmitted === false ? (
                          <button className="btn auth-submit-btn">
                            {t("connection")}
                          </button>
                        ) : (
                          <button className="btn auth-submit-btn" disabled>
                            {t("en_cours")} &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row mx-0 pt-5 mt-5 py-md-4 py-3">
                <div className="col-md-4 offset-md-5 mt-lg-0 mt-5 px-0 d-lg-flex">
                  <p className="copyright-connexion">
                    ©2021 All Rights Reserved. WARC.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="connexion-page">
        <EmptyHeader />
        <div className="login-page-container">
          <section className="auth-page-content">
            <form onSubmit={onSubmit}>
              <div className="row auth-page-row">
                <div className="col-md-4 offset-md-4 auth-page-col">
                  <div className="row auth-form-row">
                    <h1 className="auth-form-title m-b-90">{t("welcome")}</h1>
                    <div className="col-sm-12 d-flex justify-content-center mb-4">
                      <p className="text-comment">
                        {t("no_account")}{" "}
                        <NavLink
                          className="color-green no-link"
                          to="/app/inscription"
                        >
                          {t("create_account")}
                        </NavLink>
                      </p>
                    </div>
                    <div className="col-sm-12 auth-input-col m-b-20">
                      <div className="form-group auth-form-group position-relative">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-ecompta"
                        >
                          {t("email")}
                        </label>
                        <input
                          name="email"
                          type="email"
                          {...register("email")}
                          className="form-control auth-form-control pl-3"
                          id="identifiant"
                          placeholder={t("email")}
                        />
                        {formState.errors && formState.errors.email && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.email?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 auth-input-col m-b-5">
                      <div className="form-group auth-form-group position-relative">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-ecompta"
                        >
                          {t("password")}
                        </label>
                        <input
                          name="password"
                          type="password"
                          {...register("password")}
                          className="form-control auth-form-control pl-3"
                          id="password"
                          placeholder={t("password")}
                        />
                        {formState.errors && formState.errors.password && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.password?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-end w-full">
                      <NavLink
                        to="/request-password"
                        className="pas-de-compte py-4"
                      >
                        {t("forget_password")}
                      </NavLink>
                    </div>
                    <div className="col-sm-12 auth-input-col py-4">
                      {/* <NavLink to="/dashboard" className="btn auth-submit-btn">
                        Connexion
                      </NavLink> */}
                      {isSuccessfullySubmitted === false ? (
                        <button className="btn auth-submit-btn">
                          {t("connection")}
                        </button>
                      ) : (
                        <button className="btn auth-submit-btn" disabled>
                          {t("en_cours")} &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="row mx-0 pt-5 mt-5 py-md-4 py-3">
              <div className="col-md-4 offset-md-5 mt-lg-0 mt-5 px-0 d-lg-flex">
                <p className="copyright-connexion">
                  ©2021 All Rights Reserved. WARC.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FrontLogin;
