import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Logo from "../../../images/logo.png";
import LanguageSelect from "../../../languageSelect";

var NavMenuMobile = ({ laddingPage }) => {
  const { t } = useTranslation();

  var userType = window.sessionStorage.getItem("userType");
  const isLoggedIn = laddingPage
    ? false
    : !!window.sessionStorage.getItem("userToken");
  const onLogout = (e) => {
    e.preventDefault();
    window.sessionStorage.clear();
    window.location = "/app/connexion";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    background: #fff;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 576px) {
      width: 100%;
    }

    a {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #282829;
      transition: color 0.3s linear;

      &:hover {
        color: #003da5;
      }
    }
  `;

  const Menu = ({ open }) => {
    return (
      <StyledMenu open={open}>
        <div className="border-bottom pb-2" id="nav-mobile-warc">
          <p className="menu-title">MENU</p>
        </div>
        <ul className="navbar-nav py-lg-0 py-4">
          {!isLoggedIn && (
            <li className="nav-item nav-african-hot-jobs px-lg-2">
              <NavLink exact className="nav-link trans-0-2" to="/">
                {t("home")}
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink exact className="nav-link trans-0-2" to="/app/home">
                  {t("home")}
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/app/blog">
                  {t("blog")}
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/app/messagerie">
                  {t("messagerie")}
                </NavLink>
              </li>
              <div className="dropdown">
                <button
                  className="btn trans-0-2 nav-african-hot-jobs pl-0 dropdown-toggle btn-toggle-sous-menu"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("ressource")}
                </button>
                <div
                  className="dropdown-menu div-content-sous-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <NavLink
                    className="dropdown-item nav-african-hot-jobs"
                    to="/app/documents"
                  >
                    {t("document")}
                  </NavLink>
                  <NavLink
                    className="dropdown-item nav-african-hot-jobs"
                    to="/app/guides"
                  >
                    {t("guide")}
                  </NavLink>
                  <NavLink
                    className="dropdown-item nav-african-hot-jobs"
                    to="/app/catalogue-des-universites"
                  >
                    {t("catalogue")}
                  </NavLink>
                </div>
              </div>
              <div className="dropdown">
                <button
                  className="btn trans-0-2 nav-african-hot-jobs pl-0 dropdown-toggle btn-toggle-sous-menu"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("annuaire")}
                </button>
                <div
                  className="dropdown-menu div-content-sous-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <NavLink
                    className="dropdown-item nav-african-hot-jobs"
                    to="/app/annuaire/pills-fulbrighter"
                  >
                    {t("fulbrighter")}
                  </NavLink>
                  <NavLink
                    className="dropdown-item nav-african-hot-jobs"
                    to="/app/annuaire/pills-experts-personnes-ressources"
                  >
                    {t("experts_resource")}
                  </NavLink>
                  <NavLink
                    className="dropdown-item nav-african-hot-jobs"
                    to="/app/annuaire/pills-partenaires"
                  >
                    {t("institution_partners")}
                  </NavLink>
                </div>
              </div>
            </>
          )}
          <li className="nav-item nav-african-hot-jobs px-lg-2">
            <NavLink className="nav-link trans-0-2" to="/app/media">
              Media
            </NavLink>
          </li>
        </ul>
        {isLoggedIn && (
          <div className="border-top">
            <ul className="navbar-nav mx-auto py-lg-0 py-4">
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink
                  exact
                  className="nav-link trans-0-2"
                  to="/app/liste-des-live"
                >
                  LIVE
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink
                  exact
                  className="nav-link trans-0-2"
                  to="/app/liste-des-evenements"
                >
                  {t("agenda_label")}
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <div className="border-top">
          <ul className="navbar-nav mx-auto py-lg-0 py-4">
            <li className="nav-item nav-african-hot-jobs px-lg-2">
              <div className="language-select">
                <div className="language-selector-mobile">
                  <LanguageSelect />
                </div>
              </div>
            </li>
            {isLoggedIn && (
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink
                  exact
                  className="nav-link trans-0-2"
                  to={
                    userType === "coordo"
                      ? "/profil-coordonnateur"
                      : "/app/mon-profil"
                  }
                >
                  {t("account")}
                </NavLink>
              </li>
            )}

            {isLoggedIn && (
              <>
                {userType === "coordo" ? (
                  <li className="nav-item nav-african-hot-jobs px-lg-2">
                    <NavLink
                      className="nav-link trans-0-2"
                      to="/dashboard-coordonnateur"
                    >
                      {t("dashboard")}
                    </NavLink>
                  </li>
                ) : null}
                <li
                  className="nav-item nav-african-hot-jobs px-lg-2"
                  onClick={onLogout}
                >
                  <NavLink
                    className="nav-link trans-0-2"
                    to="#"
                    style={{ color: "#42922E" }}
                  >
                    {t("logout")}
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </StyledMenu>
    );
  };

  const StyledBurger = styled.button`
    position: absolute;
    top: 36%;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 2rem;
      height: 0.25rem;
      background: ${({ open }) => (open ? "#0D0C1D" : "#666666")};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) =>
          open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  `;

  const Burger = ({ open, setOpen }) => {
    return (
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    );
  };

  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  return (
    <>
      <div className="bg-white fixed-top">
        <div className="header-mobile">
          <div>
            <img src={Logo} alt="Logo E-compte" className="w-80" />
          </div>
          <div ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </div>
        </div>
      </div>
    </>
  );
};
export default NavMenuMobile;
