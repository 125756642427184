import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import FormulaireDeContact from '../FormulaireDeContact/FormulaireDeContact';
var FooterLandingPage = () => {
  const { t } = useTranslation();
  return (
    <footer className="component-footer-landingpage">
      <div className="container-warc">
        <div className="body-footer">
          <div className="row mx-0 d-flex  align-items-center  social-networking">
            <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
              <NavLink
                to="/app/page-content/pills-mention-legale"
                className="text-footer"
              >
                {t("legal_notice")}
              </NavLink>
              <NavLink to="/app/page-content/pills-cgu" className="text-footer">
                {t("cgu")}
              </NavLink>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
              <p className="copyright">©2021 All Rights Reserved. Warc, Inc.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLandingPage;
