import React, { useEffect } from "react";
import { CKEditor, useCKEditor } from "ckeditor4-react";
import useEditCondition from "../../Dashboard/Admin/ParametresAdmin/requestAdmin/useEditCondition";
import StepItem from "rsuite/lib/Steps/StepItem";
import { useTranslation } from "react-i18next";

function AddContentMentionLegale({ item, setItem }) {
  const { t } = useTranslation();

  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    isDone,
  } = useEditCondition();

  useEffect(() => {
    if (isDone) {
      setItem({});
      console.log("item", item);
    }
  }, [isDone]);

  useEffect(() => {
    if (item?.id) {
      register("id");
      setValue("id", item?.id);
      setValue("text", item?.text);
      setValue("type", item?.type);
    }
    // console.log("item", item);
  }, [item]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {" "}
            {t("edit_content")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="text"> {t("contenu")}</label>
                      {item?.id && (
                        <CKEditor
                          initData={item?.id ? item?.text : ""}
                          onChange={(evt) =>
                            setValue("text", evt.editor.getData())
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("edit")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddContentMentionLegale;
