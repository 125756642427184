import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import baseUrl from "../../Http/backend-base-url";
import ImgFoctionnalite from "../../images/fonctionnalite.png";
import banner from "../../images/warc-banniere.png";
import FrontHeader from "../FrontEnd/frontHeader/frontHeader";
import NavMenuMobile from "../FrontEnd/frontHome/NavMenuMobile";
import FooterLandingPage from "./FooterLandingPage";
import "./LandingPage.css";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

function SlideItem({ title, image }) {
  const { t } = useTranslation();
  return (
    <section
      className="section-banner"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="bloc-contain-section-banner">
        <div className="bloc-titre-landingpage pt-5">
          <h1 className="titre-banner-landingpage">{title}</h1>
        </div>
        <div className="row marge-bloc-btn-sm">
          <div className="col-xl-2 col-lg-2 col-md-3 pb-3 marge-btn-sm">
            <NavLink
              to="/app/connexion"
              className="no-link btn btn-login-landingpage trans-0-2"
            >
              {t("login")}
            </NavLink>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-3 pb-3 marge-btn-sm">
            <NavLink
              to="/app/inscription"
              className="no-link btn btn-register-landingpage trans-0-2"
            >
              {t("register")}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}
var LandingPage = () => {
  const { t } = useTranslation();
  const slides = useSelector((s) => s.slides);
  const devise = useSelector((state) => state.devise);
  const buttonStyle = {
    width: "45px",
    height: "45px",
    background: "#003da5",
    color: "#fff",
    border: "0px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
  };

  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <MdArrowBackIos style={{ fontSize: 22 }} />
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <MdArrowForwardIos style={{ fontSize: 22 }} />
      </button>
    ),
  };
  return (
    <div className="landing-page-component">
      {devise && <NavMenuMobile laddingPage />}
      {!devise && <FrontHeader laddingPage />}
      <div className="page-content">
        <div style={{ position: "relative" }}>
          {slides.length === 0 && (
            <SlideItem title={t("titre_landingpage")} image={banner} />
          )}
          {slides.length > 0 && (
            <Slide indicators {...properties}>
              {slides.map((s, index) => (
                <SlideItem
                  key={index}
                  title={s.title}
                  image={baseUrl + s.image}
                />
              ))}
            </Slide>
          )}
        </div>
        <div></div>
        <section className="section-a-propos-landingpage">
          <div className="container-warc">
            <div className="mb-5 pb-5">
              <h1 className="titre-a-propos">{t("about")}</h1>
            </div>
            <div className="row">
              <div className="col-md-6 pb-3">
                <div>
                  <p className="text-a-propos-landingpage pb-4">
                    {t("paragraph_one")}
                  </p>
                  <p className="text-a-propos-landingpage">
                    {t("paragraph_two")}
                  </p>
                </div>
              </div>
              <div className="col-md-6 pb-3 position-relative">
                <div className="">
                  <div className="d-flex div-bloc-apropos-mobile">
                    <div className="bloc-div-a-propos position1">
                      <div className="bloc-content-div">
                        <div>
                          {" "}
                          <p className="text-chiffres-landingpage">+52</p>
                        </div>
                        <div>
                          <p className="text-bloc-paragraphe-landingpage">
                            {t("fulbrighter")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bloc-div-a-propos position2">
                      <div className="bloc-content-div">
                        <div>
                          {" "}
                          <p className="text-chiffres-landingpage">+200</p>
                        </div>
                        <div>
                          <p className="text-bloc-paragraphe-landingpage">
                            {t("num_fulbrighter")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex div-bloc-apropos-mobile">
                    <div className="bloc-div-a-propos position3">
                      <div className="bloc-content-div">
                        <div>
                          {" "}
                          <p className="text-chiffres-landingpage">+44</p>
                        </div>
                        <div>
                          <p className="text-bloc-paragraphe-landingpage">
                            {t("num_university")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bloc-div-a-propos position4">
                      <div className="bloc-content-div">
                        <div>
                          {" "}
                          <p className="text-chiffres-landingpage">+12</p>
                        </div>
                        <div>
                          <p className="text-bloc-paragraphe-landingpage">
                            {t("num_country")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-fonctionnalite">
          <div className="container-warc">
            <div className="text-center mb-5 pb-5">
              <h1 className="titre-fonctionnalite">{t("features")}</h1>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 pb-3">
                <div>
                  <div className="no-view-desktop pb-5">
                    <img
                      src={ImgFoctionnalite}
                      alt="Imagefonctionnalite"
                      className="image-fonctionnalite"
                    />
                  </div>
                  <ul>
                    <li className="list-fonctionnalite">
                      {t("access_our_blog")}
                    </li>
                    <li className="list-fonctionnalite">{t("join_forum")}</li>
                    <li className="list-fonctionnalite">
                      {t("checkout_database")}
                    </li>
                    <li className="list-fonctionnalite">
                      {t("interact_with_fulbrighter")}
                    </li>
                    <li className="list-fonctionnalite">
                      {t("number_of-document")}
                    </li>
                    <li className="list-fonctionnalite">{t("learn_more")}</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 pb-3 no-view-mobile">
                <div>
                  <img
                    src={ImgFoctionnalite}
                    alt="Imagefonctionnalite"
                    className="image-fonctionnalite"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterLandingPage />
      </div>
    </div>
  );
};

export default LandingPage;
