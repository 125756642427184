import { FETCH_POSTES, FILTER_POSTES } from "../types";

export const postReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_POSTES:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload?.filter((post) => post?.publiee === true),
      };
    case FILTER_POSTES:
      // console.log("action", action.payload)
      return {
        ...state,
        filteredData: action.payload.items?.filter(
          (post) => post?.publiee === true
        ),
      };
    default:
      return state;
  }
};
