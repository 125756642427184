import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ImgBlog from "../../../../images/NoImage.png";
import profil from "../../../../images/others/default-profile-avatar.png";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { fetchReports } from "../../../../redux/report/reportAction";
import baseUrl from "../../../../Http/backend-base-url";
import { NavLink, useLocation } from "react-router-dom";
import { deleteData } from "../../../utils/fetchData";
import ReportSkeleton from "./ReportSkeleton";

function ReportsTable() {
  const location = useLocation();
  const { t } = useTranslation();
  const reports = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  let userType = window.sessionStorage.getItem("userType");

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  useEffect(() => {
    dispatch(fetchReports());
  }, []);



  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };



  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: t("warning_message_report"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`report/${item?.id}/`, token)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchReports());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const photoFormatter = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname:
            userType === "coordo"
              ? "detail-discussion-forum"
              : "admin-detail-discussion-forum",
          state: { post: cell },
        }}
        className="d-flex align-items-center"
      >
        <img
          style={{ width: "100px", height: "100px", borderRadius: "5px" }}
          src={
            cell?.photo && !cell?.photo?.includes("default.png")
              ? baseUrl + cell?.photo
              : ImgBlog
          }
          alt="Imaged"
        />
        <span
          className="pl-1"
          style={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          {" "}
          {cell?.titre}
        </span>
      </NavLink>
    );
  };

  const userFormatter = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname:
            userType === "coordo"
              ? `/detail-fulbriters-coordonnateur/${cell?.id}`
              : `/detail-fulbriters-admin/${cell?.id}`,
          state: { userItem: cell },
        }}
        className="d-flex align-items-center"
      >
        <img
          style={{ width: "50px", height: "50px", borderRadius: "5px" }}
          src={
            cell?.avatar && !cell?.avatar?.includes("default.png")
              ? baseUrl + cell?.avatar
              : profil
          }
          alt="Imaged"
        />
        <span
          className="pl-1"
          style={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          {" "}
          {cell?.prenom + " " + cell?.nom}
        </span>
      </NavLink>
    );
  };

  const filterUser = (cell, row) => {
    return cell?.prenom + " " + cell?.nom;
  };

  const filterTitle = (cell, row) => {
    return cell?.titre;
  };

  const reasonFormatter = (cell, row) => {
    return <span style={{ whiteSpace: "normal" }}> {cell} </span>;
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <button className="action-btn edit-btn" title={t("success")}>
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#ModifierLive${row?.id}`}
          />
        </button> */}
        <button className="action-btn delete-btn" title={t("delete")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row)}
          />
        </button>

        <div
          className="modal fade"
          id={`ModifierLive${row?.id}`}
          tabIndex="-1"
          aria-labelledby="ModifierLiveLabel"
          aria-hidden="true"
        >
          {/* <ModifierLive live={row} /> */}
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {reports?.isLoading && <ReportSkeleton />}
            {!reports?.isLoading && (
              <div className="table-container ">
                <BootstrapTable
                  data={reports?.data}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                      verticalAlign: "middle",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      defaultValue: location?.state?.report?.id
                        ? location?.state?.report?.id
                        : "",
                    }}
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                      verticalAlign: "middle",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    filter={{ type: "TextFilter" }}
                    filterValue={filterTitle}
                    dataField="post"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    Post
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                      verticalAlign: "middle",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                    }}
                    filterValue={filterUser}
                    width="100"
                    dataField="user"
                    dataFormat={userFormatter}
                  >
                    {t("user")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                      verticalAlign: "middle",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="created_at"
                    dataFormat={(cell, row) =>
                      moment(cell).format("DD/MM/YYYY")
                    }
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                      verticalAlign: "middle",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="200"
                    dataField="reason"
                    dataAlign="center"
                    dataFormat={(cell, row) => reasonFormatter(cell, row)}
                  >
                    {t("reason")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                      verticalAlign: "middle",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsTable;
