import api from "../../Http/global-vars";
import { FETCH_USERS, CHEARCH_USER } from "../types";

export const fetchUsers = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "user/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_USERS,
          payload: data.results
            .filter((user) => user?.user_type !== "admin")
            .sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const searchUser = (users, searchItem) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line

  data = users?.filter(
    (user) =>
      user?.prenom?.toLowerCase()?.includes(searchItem) ||
      user?.nom?.toLowerCase()?.includes(searchItem)
  );

  // console.log("voir", searchItem, pays, year);
  // return "";
  return (dispatch) => {
    dispatch({
      type: CHEARCH_USER,
      payload: {
        items: !searchItem?.length
          ? users?.sort((a, b) => b?.id - a?.id)
          : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
