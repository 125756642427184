/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from "react";
import * as IconlyPack from "react-iconly";
import ImgBlog from "../../../images/NoImage.png";
import ImgMemeTheme from "../../../images/NoImage.png";
import FrontHeader from "../frontHeader/index";
import FrontFooter from "../frontFooter/index";
import ImgPp from "../../../images/others/default-profile-avatar.png";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Skeleton from "@nejcm/react-skeleton";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import moment from "moment";
import baseUrl from "../../../Http/backend-base-url";
import axios from "axios";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import api from "../../../Http/global-vars";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Article from "../../../services/Article";
import { fetchArticles } from "../../../redux/articles/ArticlesAction";

var DetailsBlog = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required("Text is required."),
        article: yup.string().required("Article is required."),
        user: yup.string(),
        parent: yup.string(),
      }),
    []
  );

  const { handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const devise = useSelector((state) => state.devise);
  const [articles, setArticles] = useState([]);
  const [comments, setComments] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [itemToShow, setItemToShow] = useState(4);
  const [expand, setExpand] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(true);
  const [article, setArticle] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location?.state?.article) {
      window.scrollTo(0, 0);
      setArticle(location?.state?.article);
      getArticle(location?.state?.article?.id);
      getComment(location?.state?.article?.id);
    } else {
      setTimeout(() => {
        history.goBack();
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    if (itemToShow >= comments?.length) {
      // setItemToShow(3);
      setExpand(true);
    } else {
      // setItemToShow(itemToShow + 5);
      setExpand(false);
    }
  }, [itemToShow]);

  const getArticle = (id) => {
    axios
      .get(`${api}articlesbycatarticle/${id}/`, config)
      .then((response) => {
        console.log("response", response);
        setArticles(response?.data?.results?.sort((a, b) => b?.id - a?.id));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error?.response);
      });
  };

  const getComment = (id) => {
    axios
      .get(`${api}commentbyarticle/${id}/`, config)
      .then((response) => {
        console.log("response comment", response);
        setComments(response?.data?.results?.sort((a, b) => b?.id - a?.id));

        setCommentLoading(false);
      })
      .catch((error) => {
        setCommentLoading(false);
        console.log("error", error?.response);
      });
  };

  const onSubmit = async (data, e) => {
    console.log("data", data);
    data["user"] = user?.data?.id;

    let request = Article.onCommentArticle(data, config);

    await request
      .then(async (response) => {
        console.log("response comment", response);
        await getComment(article?.id);
        await dispatch(fetchArticles());
        await e.target.reset();
        await reset();
      })
      .catch((error) => {
        console.log("error response", error?.response);
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };

  const showMore = () => {
    if (itemToShow >= comments?.length) {
      setItemToShow(4);
      setExpand(true);
      // window.scroll(0, 0);
    } else {
      setItemToShow(itemToShow + 4);
      setExpand(false);
    }
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile mb-4">
            <div className="body-theme p-t-10 p-b-45 fixed-after-header mb-4">
              <div className="container">
                <div>
                  <div className="d-flex align-items-center py-3">
                    <div>
                      <NavLink to="/app/blog" className="no-link">
                        <IconlyPack.CaretLeft set="bold" primaryColor="#000" />
                      </NavLink>
                    </div>
                    <img
                      // src={ImgPp}
                      src={
                        article?.author?.avatar &&
                        !article?.author?.avatar?.includes("default.png")
                          ? baseUrl + article?.author?.avatar
                          : ImgPp
                      }
                      alt="Imageprofil blog"
                      className="photo-pp-post"
                    />
                    <p className="name-blog pl-2">
                      {article?.author?.prenom + " " + article?.author?.nom}
                    </p>
                  </div>
                  <p className="text-categorie-blog">
                    {article?.category?.map(
                      (item, index) =>
                        item?.name +
                        `${index < article?.category?.length - 1 ? " - " : " "}`
                    )}
                  </p>
                  <div className="bg-white p-3">
                    <h5 className="card-title card-title-blog py-2">
                      {article?.titre}
                    </h5>
                    <p className="date-publication-article pb-3">
                      {moment(article?.date_publication).format("MMM DD, YYYY")}{" "}
                    </p>

                    <img
                      src={
                        article?.image &&
                        !article?.image?.includes("default.png")
                          ? baseUrl + article?.image
                          : ImgBlog
                      }
                      className="card-img-top w-full"
                      alt="Imageblog"
                    />
                    <div className="py-4">
                      <div
                        className="text-blog-detail"
                        dangerouslySetInnerHTML={{
                          __html: article?.contenu,
                        }}
                      ></div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          user?.data?.avatar &&
                          !user?.data?.avatar?.includes("default.png")
                            ? baseUrl + user?.data?.avatar
                            : ImgPp
                        }
                        alt="Photprofil"
                        className="photo-pp-post"
                      />
                      <form
                        className="ml-3 input-file-modal position-relative d-flex w-full"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div>
                          <input
                            type="text"
                            className="form-control input-add-comment pl-5"
                            name="text"
                            placeholder={t("add_comment")}
                            onChange={(e) => {
                              setValue("article", article?.id);
                              setValue("text", e.target.value);
                            }}
                          />
                          <span className="span-icon-chat-blog">
                            <IconlyPack.Chat
                              set="bold"
                              primaryColor="#C4C4C4"
                            />
                          </span>
                        </div>
                        <button className="btn ml-auto" type="submit">
                          <IconlyPack.Send set="bold" primaryColor="#C4C4C4" />
                        </button>
                      </form>
                    </div>

                    {!isLoading ? (
                      comments?.length ? (
                        comments?.slice(0, itemToShow)?.map((comment) => (
                          <div className="d-flex my-4 mr-3" key={comment?.id}>
                            <img
                              src={
                                comment?.user?.avatar &&
                                !comment?.user?.avatar?.includes("default.png")
                                  ? baseUrl + comment?.user?.avatar
                                  : ImgPp
                              }
                              alt="profil"
                              className="photo-pp-post"
                            />
                            <div className="w-full">
                              <div className="container-comment-from ml-3 p-3 w-full">
                                <p className="comment-from-name">
                                  {comment?.user?.prenom +
                                    " " +
                                    comment?.user?.nom}
                                  <span className="comment-content"></span>
                                </p>
                                <p className="comment-content">
                                  {comment?.text}
                                </p>
                              </div>
                              <div className="icon-post-container-comment-blog">
                                <div className="d-flex align-items-center">
                                  <button
                                    className="btn btn-like"
                                    title={t("like")}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseReponseComment${comment?.id}`}
                                    aria-expanded="false"
                                    aria-controls={`collapseReponseComment${comment?.id}`}
                                  >
                                    <span className="text-comment-post">
                                      {t("reply")}
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div
                                class="collapse"
                                id={`collapseReponseComment${comment?.id}`}
                              >
                                {comment?.responses?.length
                                  ? comment.responses.map((response) => (
                                      <div
                                        className="d-flex"
                                        key={response?.id}
                                      >
                                        <img
                                          src={
                                            response?.user?.avatar &&
                                            !response?.user?.avatar?.includes(
                                              "default.png"
                                            )
                                              ? baseUrl + response?.user?.avatar
                                              : ImgPp
                                          }
                                          alt="avatar"
                                          className="photo-pp-post"
                                        />
                                        <div className="container-comment-from ml-3 p-3 w-full">
                                          <p className="comment-from-name">
                                            {response?.user?.prenom +
                                              " " +
                                              response?.user?.nom}
                                            <span className="comment-content"></span>
                                          </p>
                                          <p className="comment-content">
                                            {response?.text}
                                          </p>
                                        </div>
                                      </div>
                                    ))
                                  : null}

                                <div className="pt-3">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        user?.data?.avatar &&
                                        !user?.data?.avatar?.includes(
                                          "default.png"
                                        )
                                          ? baseUrl + user?.data?.avatar
                                          : ImgPp
                                      }
                                      alt="Avatr"
                                      className="photo-pp-post"
                                    />
                                    <form
                                      className="ml-3 input-file-modal position-relative d-flex w-full"
                                      onSubmit={handleSubmit(onSubmit)}
                                    >
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control input-add-comment pl-5"
                                          name="text"
                                          placeholder={t("add_response")}
                                          onChange={(e) => {
                                            setValue("article", article?.id);
                                            setValue("text", e.target.value);
                                            setValue("parent", comment?.id);
                                          }}
                                        />
                                        <span className="span-icon-chat-blog">
                                          <IconlyPack.Chat
                                            set="bold"
                                            primaryColor="#C4C4C4"
                                          />
                                        </span>
                                      </div>
                                      <button
                                        className="btn ml-auto"
                                        type="submit"
                                      >
                                        <IconlyPack.Send
                                          set="bold"
                                          primaryColor="#C4C4C4"
                                        />
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null
                    ) : (
                      <CommentSkeleton />
                    )}
                  </div>
                  <div className="bg-white p-3 my-4">
                    <div className="py-5">
                      <h1 className="card-title-blog">{t("other_article")}</h1>
                      {articles?.length ? (
                        articles?.map((item) => (
                          <NavLink
                            to={{
                              pathname: `/app/detail-blog/${item?.id}`,
                              state: { article: item },
                            }}
                            className="row pt-4 mb-3"
                            key={item?.id}
                          >
                            <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                              <div>
                                <img
                                  src={
                                    item?.image &&
                                    !item?.image?.includes("default.png")
                                      ? baseUrl + item?.image
                                      : ImgMemeTheme
                                  }
                                  alt="images"
                                  className="w-full"
                                />
                              </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 pb-3">
                              <div>
                                <h3 className="blog-article-titre">
                                  {item?.titre}
                                </h3>
                                <div className="pt-4 mb-3">
                                  {/* <p className="text-blog-detail">{}</p> */}
                                  <div
                                    className="text-blog-detail"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.contenu?.slice(0, 150),
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <FrontHeader />
      <div className="body-theme p-t-10 p-b-45 fixed-after-header">
        <div className="container-blog-article">
          <div className="py-5 mb-5">
            <div className="d-flex align-items-center py-3">
              <div>
                <NavLink to="/app/blog" className="no-link">
                  <IconlyPack.CaretLeft set="bold" primaryColor="#000" />
                </NavLink>
              </div>
              <img
                src={
                  article?.author?.avatar &&
                  !article?.author?.avatar?.includes("default.png")
                    ? baseUrl + article?.author?.avatar
                    : ImgPp
                }
                alt="blog"
                className="photo-pp-post"
              />
              <p className="name-blog pl-2">
                {article?.author?.prenom + " " + article?.author?.nom}
              </p>
            </div>
            <p className="text-categorie-blog">
              {" "}
              {article?.category?.map(
                (item, index) =>
                  item?.name +
                  `${index < article?.category?.length - 1 ? " - " : " "}`
              )}
            </p>
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8">
                <div className="bg-white p-3">
                  <h5 className="card-title card-title-blog py-2">
                    {article?.titre}
                  </h5>
                  <p className="date-publication-article pb-3">
                    {moment(article?.date_publication).format("MMM DD, YYYY")}{" "}
                  </p>

                  <img
                    src={
                      article?.image && !article?.image?.includes("default.png")
                        ? baseUrl + article?.image
                        : ImgBlog
                    }
                    className="card-img-top w-full"
                    alt="Imgblog"
                  />
                  <div className="py-4">
                    <div
                      className="text-blog-detail"
                      dangerouslySetInnerHTML={{
                        __html: article?.contenu,
                      }}
                    ></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      // src={ImgPpPost}
                      src={
                        user?.data?.avatar &&
                        !user?.data?.avatar?.includes("default.png")
                          ? baseUrl + user?.data?.avatar
                          : ImgPp
                      }
                      alt="Photoprofil"
                      className="photo-pp-post"
                    />
                    <form
                      className="ml-3 input-file-modal position-relative d-flex w-full"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="w-100">
                        <input
                          type="text"
                          className="form-control input-add-comment pl-5"
                          name="text"
                          placeholder={t("add_comment")}
                          onChange={(e) => {
                            setValue("article", article?.id);
                            setValue("text", e.target.value);
                          }}
                        />

                        <span className="span-icon-chat-blog">
                          <IconlyPack.Chat set="bold" primaryColor="#C4C4C4" />
                        </span>
                      </div>
                      <button className="btn ml-auto" type="submit">
                        <IconlyPack.Send set="bold" primaryColor="#C4C4C4" />
                      </button>
                    </form>
                  </div>
                  {!commentLoading ? (
                    comments?.length ? (
                      comments?.slice(0, itemToShow)?.map((comment) => (
                        <div className="d-flex my-4 mr-3" key={comment?.id}>
                          <img
                            // src={ImgComment1}
                            src={
                              comment?.user?.avatar &&
                              !comment?.user?.avatar?.includes("default.png")
                                ? baseUrl + comment?.user?.avatar
                                : ImgPp
                            }
                            alt="Pprofil"
                            className="photo-pp-post"
                          />
                          <div className="w-full">
                            <div className="container-comment-from ml-3 p-3 w-full">
                              <p className="comment-from-name">
                                {comment?.user?.prenom +
                                  " " +
                                  comment?.user?.nom}
                                <span className="comment-content">
                                  {" "}
                                  {/* {moment(comment?.created_at).format("HH:mm")} */}
                                </span>
                              </p>
                              <p className="comment-content">{comment?.text}</p>
                            </div>
                            <div className="icon-post-container-comment-blog">
                              <div className="d-flex align-items-center">
                                <button
                                  className="btn btn-like"
                                  title={t("like")}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={`#collapseReponseComment${comment?.id}`}
                                  aria-expanded="false"
                                  aria-controls={`collapseReponseComment${comment?.id}`}
                                >
                                  <span className="text-comment-post">
                                    {t("reply")}
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div
                              class="collapse"
                              id={`collapseReponseComment${comment?.id}`}
                            >
                              {comment?.responses?.length
                                ? comment?.responses?.map((response) => (
                                    <div
                                      className="d-flex mb-3"
                                      key={response?.id}
                                    >
                                      <img
                                        src={
                                          response?.user?.avatar &&
                                          !response?.user?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl + response?.user?.avatar
                                            : ImgPp
                                        }
                                        alt="Phprofil"
                                        className="photo-pp-post"
                                      />
                                      <div className="container-comment-from ml-3 p-3 w-full">
                                        <p className="comment-from-name">
                                          {response?.user?.prenom +
                                            " " +
                                            response?.user?.nom}
                                          <span className="comment-content">
                                            {/* {" "}
                                            {moment(
                                              response?.created_at
                                            ).format("HH:mm")} */}
                                          </span>
                                        </p>
                                        <p className="comment-content">
                                          {response?.text}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                : null}

                              <div className="pt-3">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      user?.data?.avatar &&
                                      !user?.data?.avatar?.includes(
                                        "default.png"
                                      )
                                        ? baseUrl + user?.data?.avatar
                                        : ImgPp
                                    }
                                    alt="Pp"
                                    className="photo-pp-post"
                                  />
                                  <form
                                    className="ml-3 input-file-modal position-relative d-flex w-full"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div className="w-100">
                                      <input
                                        type="text"
                                        className="form-control input-add-comment pl-5"
                                        name="text"
                                        // {...register("text")}
                                        onChange={(e) => {
                                          setValue("article", article?.id);
                                          setValue("text", e.target.value);
                                          setValue("parent", comment?.id);
                                        }}
                                        placeholder={t("add_response")}
                                      />
                                      <span className="span-icon-chat-blog">
                                        <IconlyPack.Chat
                                          set="bold"
                                          primaryColor="#C4C4C4"
                                        />
                                      </span>
                                    </div>
                                    <button
                                      className="btn ml-auto"
                                      type="submit"
                                    >
                                      <IconlyPack.Send
                                        set="bold"
                                        primaryColor="#C4C4C4"
                                      />
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : null
                  ) : (
                    <CommentSkeleton />
                  )}

                  {comments?.length > 4 ? (
                    <div className="col-12 text-center">
                      <a
                        className="btn btn-participate-event trans-0-2 ml-auto"
                        href="#"
                        onClick={showMore}
                      >
                        {expand ? (
                          <span>{t("see_less")}</span>
                        ) : (
                          <span>{t("see_more")}</span>
                        )}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="bg-white p-3">
                  <h1 className="card-title-blog">{t("other_article")}</h1>
                  {!isLoading ? (
                    articles?.length ? (
                      articles?.map((item) => (
                        <NavLink
                          to={{
                            pathname: `/app/detail-blog/${item?.id}`,
                            state: { article: item },
                          }}
                          className="row pt-4"
                          key={item?.id}
                        >
                          <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                            <div>
                              <img
                                src={
                                  item?.image &&
                                  !item?.image?.includes("default.png")
                                    ? baseUrl + item?.image
                                    : ImgMemeTheme
                                }
                                className="w-full"
                                alt="thème"
                              />
                            </div>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 pb-3">
                            <div>
                              <h3 className="blog-article-titre">
                                {item?.titre}
                              </h3>
                              <div className="">
                                <p className="text-blog-detail">
                                  {t("par")}&nbsp;
                                  {item?.author?.prenom +
                                    " " +
                                    item?.author?.nom}
                                </p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      ))
                    ) : (
                      <div className="gfa-rowy mt-5">
                        <div className="alert alert-warning gfa-warning">
                          {t("noData")}
                        </div>
                      </div>
                    )
                  ) : (
                    <CardSkeleton />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default DetailsBlog;

const CardSkeleton = () => {
  const renderModules = Array(6)
    .fill()
    .map((item, index) => (
      <Skeleton className="row pt-4" key={index}>
        <Skeleton className="col-xl-4 col-lg-4 col-md-4 pb-3">
          <Skeleton.Rectangle
            height={70}
            // width={`40%`}
            className="w-full"
          />
        </Skeleton>

        <Skeleton className="col-xl-8 col-lg-8 col-md-8 pb-3">
          <Skeleton.Rectangle
            height={30}
            width={`100%`}
            className="blog-article-titre mb-3"
          />
          <Skeleton.Rectangle height={30} width={`100%`} className="mb-1" />
        </Skeleton>
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};

const CommentSkeleton = () => {
  const renderModules = Array(4)
    .fill()
    .map((item, index) => (
      <Skeleton className="d-flex justify-content-start my-4" key={index}>
        <Skeleton.Circle width={40} height={40} className="photo-pp-post" />

        <Skeleton.Rectangle
          width={`100%`}
          height={60}
          className="container-comment-from ml-3 p-3 w-full"
          style={{ borderRadius: "0 20px 20px" }}
        />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
