import React, { useEffect, useState } from "react";
import { TimeCircle } from "react-iconly";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import * as IconlyPack from "react-iconly";
import { CKEditor } from "ckeditor4-react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import ImageLabel from "../../../../assets/images/image_label.png";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import UseAddBlogForm from "./requestBlog/UseAddBlogForm";
import Select from "react-select";
import { useSelector } from "react-redux";
import Header from "../../../Navs/Header";
import { useTranslation } from "react-i18next";

var AjouterArticleBlog = (props) => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseAddBlogForm();
  let userType = window.sessionStorage.getItem("userType");
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    if (!categories?.isLoading && categories?.data?.length) {
      setOptions(
        categories?.data.map((category) => ({
          label: category?.name,
          value: category?.id,
        }))
      );
    }
  }, [categories]);

  const handleChange = (selected) => {
    setValue(
      "category",
      selected?.map((item) => item?.value)
    );

    setOption(selected);
  };

  // Payload data and url to upload files
  const getUploadParams = ({ meta, file }) => {
    return { url: "https://httpbin.org/post" };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">{t("drop_img")}</span>
        <span className="second-label">{t("accept_format")}</span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {/* {text} */}
          <label htmlFor="dropzoneImage">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            accept={accept}
            // value={}
            // multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
            id="dropzoneImage"
          />
        </div>
      </div>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <div className="component-dashboard-coordonnateur">
      {/* {userType !== "admin" ? null : <Header />} */}
      <div className="no-view-mobile">
        {userType !== "admin" ? <FrontHeader /> : <Header />}
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className={
            userType !== "admin"
              ? "offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 px-md-3 px-0 content-position-after-header"
              : "offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          }
        >
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("add_article")}</h1>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <form onSubmit={onSubmit}>
                    <div className="row d-flex align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 py-5">
                        <div className="form-group">
                          <label className="label-input pb-2">
                            {t("title")}
                            <span className="required-label">*</span>
                          </label>
                          <input
                            type="text"
                            name="titre"
                            {...register("titre")}
                            className=" input-add-document form-control"
                            placeholder={t("title")}
                          />
                          {formState.errors && formState.errors.titre && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.titre?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-5">
                        <div className="form-group">
                          <label className="label-input pb-2">
                            {t("theme")}
                            <span className="required-label">*</span>
                          </label>
                          {/* <select
                            className=" input-add-document form-control"
                            aria-label="Default select example"
                          >
                            <option>Select a theme</option>
                          </select> */}
                          <Select
                            className="input-add-document form-control"
                            classNamePrefix="select"
                            value={option}
                            isLoading={categories?.isLoading}
                            name="category"
                            isMulti
                            placeholder={t("select_category")}
                            {...register("category")}
                            options={options}
                            onChange={handleChange}
                          />
                          {formState.errors && formState.errors.category && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.category?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 py-5 w-full">
                        <div className="input-file">
                          <div className="form-group">
                            <label className="label-input pb-2">Image</label>
                            <Dropzone
                              getUploadParams={getUploadParams}
                              accept="image/*"
                              maxFiles={1}
                              inputWithFilesContent={(files) => {
                                register("image");
                                setValue("image", files[0]?.file);
                              }}
                              InputComponent={InputChooseFile}
                              getFilesFromEvent={getFilesFromEvent}
                              classNames
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 py-5">
                        <div className="form-group">
                          <label className="label-input pb-2">
                            Description<span className="required-label">*</span>
                          </label>
                          <CKEditor
                            initData=""
                            onChange={(evt) =>
                              setValue("contenu", evt.editor.getData())
                            }
                          />
                          {formState.errors && formState.errors.contenu && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.contenu?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row my-5 d-flex justify-content-end">
                      <div className="col-xl-2 col-lg-2 col-md-4">
                        {!isSuccessfullySubmitted && (
                          <button className="btn crud-submit-btn">
                            {t("add")}
                          </button>
                        )}

                        {isSuccessfullySubmitted && (
                          <button className="btn crud-submit-btn">
                            {t("en_cours")} &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterArticleBlog;
