import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import UseEditLive from "../../Dashboard/Coordonnateur/AgendaCoordonnateur/requestAgenda/UseEditLive";

function ModifierLive({ live }) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseEditLive();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (live?.id) {
      setValue("libelle", live?.libelle);
      setValue("lien", live?.lien);
      setValue("date", live?.date);
      setValue("start_time", live?.start_time);
      setValue("end_time", live?.end_time);
      register("id");
      setValue("id", live?.id);
    }
  }, [live]);

  const handleReset = () => {
    //   reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">{t("edit_live")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-form-add-event" htmlFor="label">
                      {t("label")}
                      <span className="required-label">*</span>
                    </label>
                    <input
                      type="text"
                      name="libelle"
                      {...register("libelle")}
                      className="form-control input-event-agenda w-100"
                      id="exampleInputText"
                      placeholder={t("label")}
                    />
                    {formState.errors && formState.errors.libelle && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.libelle?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1 label-form-add-event">
                      {t("link")}
                      <span className="required-label">*</span>
                    </label>
                    <input
                      className="form-control input-add-event textarrea-event w-100"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="lien"
                      {...register("lien")}
                      type="url"
                      placeholder={t("link")}
                    />
                    {/* <textarea>Ajouter le lien de la réunion</textarea> */}
                    {formState.errors && formState.errors.lien && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.lien?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">
                    <label
                      className="label-form-add-event"
                      htmlFor="inlineFormInputGroup"
                    >
                      Date <span className="required-label">*</span>
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <IconlyPack.Calendar
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="date"
                        // defaultValue={new Date()}
                        name="date"
                        {...register("date")}
                        className="form-control input-add-event pl-5"
                        id="inlineFormInputGroup"
                        // placeholder="28 DEC 2021"
                      />
                    </div>
                    {formState.errors && formState.errors.date && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.date?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 py-4">
                  <div className="">
                    <label
                      className="label-form-add-event"
                      htmlFor="inlineFormInputGroup"
                    >
                      {t("startTime")} <span className="required-label">*</span>
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <IconlyPack.TimeCircle
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="time"
                        // defaultValue={new Date()}
                        name="start_time"
                        {...register("start_time")}
                        className="form-control input-add-event pl-5"
                        id="inlineFormInputGroup"
                        // placeholder="28 DEC 2021"
                      />
                    </div>
                    {formState.errors && formState.errors.start_time && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.start_time?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 py-4">
                  <div className="">
                    <label className="label-form-add-event" htmlFor="endDate">
                      {t("endTime")} <span className="required-label">*</span>
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <IconlyPack.TimeCircle
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="time"
                        className="form-control input-add-event pl-5"
                        id="endDate"
                        name="end_time"
                        {...register("end_time")}
                        // placeholder="7 AM - 11 AM "
                      />
                    </div>
                    {formState.errors && formState.errors.end_time && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.end_time?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                {isSuccessfullySubmitted && (
                  <button disabled className="btn crud-submit-btn">
                    {t("en_cours")} &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isSuccessfullySubmitted && (
                  <button type="submit" className="btn crud-submit-btn">
                    {t("edit")}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierLive;
