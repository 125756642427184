import { FETCH_ML } from "../types";

export const mlReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_ML:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};
