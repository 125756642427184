import React, { useEffect, useState, useMemo } from "react";
import * as IconlyPack from "react-iconly";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import api from "../../../Http/global-vars";
import Swal from "sweetalert2";
import { fetchMe } from "../../../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { t } from "i18next";
import { CKEditor } from "ckeditor4-react";

const Tag = (props) => <span className="tag" {...props}></span>;
const Delete = (props) => (
  <button className="delete btn mr-2" {...props}>
    <IconlyPack.CloseSquare set="light" primaryColor="#000" />
  </button>
);
const Help = (props) => <span className="help" {...props} />;

var EditProfil = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t("invalid_mail")).required(t("required")),
        prenom: yup.string().required(t("required")),
        nom: yup.string().required(t("required")),
        num_etudiant: yup.string(),
        statut: yup.string().required(t("required")).nullable(),
        phone: yup.string(),
        numero_urgence: yup.string(),
        biographie: yup.string().nullable(),
        pays: yup.string().required(t("required")),
        adresse: yup.string(),
        // domaine_expertise: yup.string().nullable(),
        annee_cohorte: yup.string().required(t("required")),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [phone, setPhone] = React.useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyRelease, setIsKeyReleased] = useState(false);

  useEffect(() => {
    if (tags?.length) {
      register("domaine_expertise");
      setValue("domaine_expertise", JSON.stringify(tags));
      // console.log(tags);
    } else {
      register("domaine_expertise");
      setValue("domaine_expertise", "");
    }
  }, [tags, input, register, setValue]);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  const changeHandler = (value) => {
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          console.log("error", formState.errors);
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (user?.data?.id) {
      setValue("prenom", user.data.prenom);
      setValue("nom", user.data.nom);
      setValue("phone", user.data.phone);
      setValue("email", user.data.email);
      setValue("statut", user.data.statut);
      setValue("adresse", user.data.adresse);
      setValue("pays", user.data.pays);
      setValue("annee_cohorte", user.data.annee_cohorte);
      // setValue("domaine_expertise", user.data.domaine_expertise);
      setValue("biographie", user.data.biographie);

      if (user.data.phone) {
        setPhone(user.data.phone);
      }
      // if (user.data?.domaine_expertise) {
      //   if (IsJsonString(user.data?.domaine_expertise)) {
      //     setTags(JSON.parse(user.data?.domaine_expertise));
      //   }
      // }
      if (user?.data?.annee_cohorte) {
        setStartDate(new Date(user?.data?.annee_cohorte + "/01/01"));
      }
      if (
        user.data?.pays &&
        options.filter((option) => option.label === user.data?.pays)?.length
      ) {
        setVal(
          options.filter((option) => option.label === user.data?.pays)[0]?.value
        );
      }
    }
  }, [options, setValue, user]);

  // const IsJsonString = (str) => {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // };

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmit = (data) => {
    console.log("avatar", data);
    // const fd = new FormData();
    // fd.append("avatar", data?.avatar);
    // setAvatar(file);
    setIsLoad(true);
    var request = axios.put(`${api}user/${user?.data?.id}/`, data, config);

    request
      .then(async (resp) => {
        console.log("res", resp);
        await dispatch(fetchMe());
        await Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
        await window.$("#editProfil").modal("hide");
        await setIsLoad(false);
      })
      .catch((error) => {
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: t("email_used"),
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsLoad(false);
      });
  };

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="editProfil">
            {t("edit")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="prenom">
                    {t("firstName")} <span className="required-label">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-input-profil"
                    placeholder={t("firstName")}
                    name="prenom"
                    {...register("prenom")}
                    id="prenom"
                    aria-describedby="emailHelp"
                  />
                  {formState.errors && formState.errors.prenom && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.prenom?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="nom">
                    {t("lastName")} <span className="required-label">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-input-profil"
                    placeholder={t("lastName")}
                    id="nom"
                    {...register("nom")}
                    aria-describedby="emailHelp"
                  />
                  {formState.errors && formState.errors.nom && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.nom?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label
                    className="label-form-profil"
                    htmlFor="exampleInputEmail1"
                  >
                    {t("email")} <span className="required-label">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control form-input-profil"
                    placeholder={t("email")}
                    id="exampleInputEmail1"
                    {...register("email")}
                    readOnly
                    aria-describedby="emailHelp"
                  />
                  {formState.errors && formState.errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.email?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="statut">
                    {t("status")} <span className="required-label">*</span>
                  </label>
                  <select
                    className="form-control form-input-profil py-0 px-2"
                    {...register("statut")}
                    id="statut"
                    // style={{ height: 60 }}
                  >
                    <option value="" hidden>
                      {t("status")}
                    </option>
                    <option value="etudiant">{t("student")}</option>
                    <option value="faculty">{t("faculty")}</option>
                    <option value="chercheur">{t("searcher")}</option>
                    <option value="staff">{t("staff")}</option>
                    <option value="ata">{t("ata")}</option>
                  </select>
                  {formState.errors && formState.errors.statut && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.statut?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="statut">
                    {t("country")} <span className="required-label">*</span>
                  </label>

                  <ReactFlagsSelect
                    className="form-control form-input-profil py-0 px-2"
                    selected={val}
                    searchable
                    showOptionLabel
                    showSecondaryOptionLabel
                    onSelect={changeHandler}
                    placeholder={t("select_country")}
                  />
                  {formState.errors && formState.errors.pays && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.pays?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="statut">
                    {t("cohort_year")} <span className="required-label">*</span>
                  </label>

                  <DatePicker
                    dateFormat="yyyy"
                    showYearPicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      console.log(date);
                      setValue("annee_cohorte", moment(date).format("YYYY"));
                    }}
                    className="form-control input-add-document"
                  />
                  {formState.errors && formState.errors.annee_cohorte && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.annee_cohorte?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="phone">
                    {t("phone")}
                  </label>
                  <PhoneInput
                    masks={{ sn: ".. ... .. .." }}
                    inputclassName="form-control form-input-profil"
                    country={"sn"}
                    // onlyCountries={['sn']}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    // disableDropdown={true}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(phone) => handleSetPhone(phone)}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                  {formState.errors && formState.errors.phone && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.phone?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="nom">
                    {t("address")}
                  </label>
                  <input
                    type="text"
                    className="form-control form-input-profil"
                    placeholder={t("address")}
                    id="adresse"
                    {...register("adresse")}
                    aria-describedby="emailHelp"
                  />
                  {formState.errors && formState.errors.adresse && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.adresse?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="bio">
                    {t("bio")}
                  </label>

                  {/* <textarea
                    className="form-control form-input-profil"
                    id="bio"
                    rows="3"
                    name="biographie"
                    {...register("biographie")}
                    placeholder={t("bio_guide")}
                  ></textarea> */}
                  {!user?.isLoading && (
                    <CKEditor
                      className="form-control  input-add-document"
                      initData={
                        user.data?.biographie &&
                        user.data?.biographie !== undefined
                          ? user.data?.biographie
                          : ""
                      }
                      onChange={(evt) => {
                        if (evt.editor.getData() !== undefined) {
                          setValue("biographie", evt.editor.getData());
                        }
                      }}
                    />
                  )}

                  {formState.errors && formState.errors.biographie && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.biographie?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel-form"
                data-dismiss="modal"
              >
                {t("cancel")}
              </button>

              {isLoad === false ? (
                <button type="submit" className="btn btn-theme-modal">
                  {t("edit")}
                </button>
              ) : (
                <button className="btn btn-theme-modal" disabled>
                  {t("en_cours")} &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfil;
