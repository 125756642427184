import React from "react";
import * as IconlyPack from "react-iconly";
import "./DocumentsAdmin.css";

import { useTranslation } from "react-i18next";

import AddAnnualReport from "../../../Modals/Coordonnateur/AddAnnualReport";
import AddShareWithModal from "../../../Modals/Coordonnateur/AddShareWithModal";
import Header from "../../../Navs/Header";
import AnnualReportsTable from "../../../Tables/Admin/DocumentsAdminTable/AnnualReportsTable";
import ShareWithTable from "../../../Tables/Admin/DocumentsAdminTable/ShareWithTable";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./DocumentsAdmin.css";

var AnnualReports = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="component-dashboard-coordonnateur">
        <Header />
        <div className="row">
          <div className="nav-container no-view-mobile">
            <NavBarVerticalV2 />
          </div>
          <div
            className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
            id="content-position-after-header"
          >
            <div className="page-body-container">
              <div className="page-content-container">
                <ul
                  className="nav nav-tabs navtabs-fulbrighter"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active navlink-fulbrighter"
                      id="annual-report"
                      data-toggle="tab"
                      href="#annual-report-tab"
                      role="tab"
                      aria-controls="annual-report-tab"
                      aria-selected="true"
                    >
                      {t("annual_reports")}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link navlink-fulbrighter"
                      id="share-with"
                      data-toggle="tab"
                      href="#share-with-tab"
                      role="tab"
                      aria-controls="share-with-tab"
                      aria-selected="false"
                    >
                      {t("share_with")}
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-5" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="annual-report-tab"
                    role="tabpanel"
                    aria-labelledby="annual-report"
                  >
                    <div className="page-first-row-container mt-3">
                      <div className="page-title-container">
                        <h1 className="page-title">{t("annual_reports")}</h1>
                      </div>
                      <div>
                        <div
                          className="adding-item-container linkable"
                          data-toggle="modal"
                          data-target="#AddAnnualReport"
                        >
                          <div>
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#003DA5"
                              />
                            </span>
                            <span className="adding-item-label">
                              {t("add_report")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AnnualReportsTable />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="share-with-tab"
                    role="tabpanel"
                    aria-labelledby="share-with"
                  >
                    <div className="page-first-row-container mt-3">
                      <div className="page-title-container">
                        <h1 className="page-title">{t("share_with")}</h1>
                      </div>
                      <div>
                        <div
                          className="adding-item-container linkable"
                          data-toggle="modal"
                          data-target="#AddShareWithModal"
                        >
                          <div>
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#003DA5"
                              />
                            </span>
                            <span className="adding-item-label">
                              {t("share")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ShareWithTable />
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="AddAnnualReport"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AjouterDocumentModalLabel"
                aria-hidden="true"
              >
                <AddAnnualReport />
              </div>
              <div
                className="modal fade"
                id="AddShareWithModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AjouterDocumentModalLabel"
                aria-hidden="true"
              >
                <AddShareWithModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnualReports;
