import axios from "axios";
import { video } from "./Endpoints";

export default {
  async onAddVideo(data, config) {
    const response = await axios.post(video.onPostVideo(), data, config);
    return response.data;
  },
  async onEditVideo(id, data, config) {
    const response = await axios.put(video.onEditVideo(id), data, config);
    return response.data;
  },

  async onDeleteVideo(id, config) {
    const response = await axios.delete(video.onDeleteVideo(id), config);
    return response.data;
  },
};
