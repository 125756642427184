import api from "../../Http/global-vars";
import { FETCH_USER_PARTICIPATION } from "../types";

export const fetchUserParticipations = (id) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "participationbyuser/" + id + "/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data);
        dispatch({
          type: FETCH_USER_PARTICIPATION,
          payload: data.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
