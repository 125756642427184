import axios from "axios";
import { expert } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddExpert(data, config) {
    const response = await axios.post(expert.onPostExpert(), data, config);
    return response.data;
  },
  async onEditExpert(id, data, config) {
    const response = await axios.put(expert.onEditExpert(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteExpert(id, config) {
    const response = await axios.delete(expert.onDeleteExpert(id), config);
    // console.log(response)
    return response.data;
  },
};
