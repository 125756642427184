import React, { useEffect, useMemo } from "react";
import * as IconlyPack from "react-iconly";
import AnnuairePP from "../../../images/others/default-profile-avatar.png";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import Allemagne from "../../../images/allemagne.png";
import ExpertPersonnesRessourcesModal from "../../Modals/Frontend/ExpertPersonnesRessourcesModal";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

var ExpertPersonnesRessources = ({ item }) => {
  const { t } = useTranslation();
  const country = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    if (item?.id) {
      // console.log(
      //   "dm",
      //   item?.domaine_expertise.map((item) => item)
      // );
    }
  }, [item]);

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  return (
    <div>
      <div className="details-annuaire-item">
        <div className="bloc-card pt-2">
          <div className="card">
            <div className="card-body p-0 position-relative">
              <div className="p-4 container-bloc-card">
                <div className="d-flex align-items-center">
                  <div className="pb-3">
                    <img
                      // src={AnnuairePP}
                      src={
                        item?.photo && !item?.photo?.includes("default.png")
                          ? baseUrl + item?.photo
                          : AnnuairePP
                      }
                      alt="AnnuairePhoto Profil"
                      className="annuaire-pp"
                    />
                  </div>
                  <div className="pl-3">
                    <p className="name-annuaire">
                      {item?.prenom + " " + item?.nom}
                    </p>
                    <p className="fonction">
                      {/* {item?.statut?.charAt(0).toUpperCase() +
                        item?.statut?.slice(1)} */}
                      {t("expert")}
                    </p>
                    <div className="d-flex align-items-center">
                      {item?.pays ? (
                        <img
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                            country?.filter(
                              (val) => val?.label === item?.pays
                            )[0]?.value
                          }.svg`}
                          alt=""
                          className="img-pays-fulbrighter"
                        />
                      ) : null}
                      <p className="pays-origine pl-2">{item?.pays}</p>
                    </div>
                  </div>
                </div>
                <div className="row bloc-annuaire-skills-item pt-2">
                  {item?.id && IsJsonString(item?.domaine_expertise)
                    ? JSON.parse(item?.domaine_expertise)?.length &&
                      JSON.parse(item?.domaine_expertise).map((tag, index) => (
                        // <Tag className="mb-4 tag" key={index}>
                        //   {tag}
                        // </Tag>
                        <div className="annuaire-tag contain-item" key={index}>
                          <p className="text-fulbrighter-annuaire">{tag}</p>
                        </div>
                      ))
                    : null}
                </div>

                <div className="text-center position-btn-card-annuaire">
                  <button
                    type="button"
                    className="btn btn-voir-catalogue"
                    data-toggle="modal"
                    data-target={`#ExpertPersonnesRessourcesModal${item?.id}`}
                  >
                    <IconlyPack.Show set="light" primaryColor="#B6B6B6" />
                    <span className="pl-2">{t("view")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`ExpertPersonnesRessourcesModal${item?.id}`}
        tabIndex="-1"
        aria-labelledby="ExpertPersonnesRessourcesModalLabel"
        aria-hidden="true"
      >
        <ExpertPersonnesRessourcesModal item={item} />
      </div>
    </div>
  );
};

export default ExpertPersonnesRessources;
