import { FETCH_CATEGORIES } from "../types";

export const categoriesReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
