/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDataByID, postData } from "../../utils/fetchData";
import { useForm } from "react-hook-form";
import { fetchContacts } from "../../../redux/contact/contactAction";
import PhotoProfil from "../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../Http/backend-base-url";

function RepondreMessageAdmin({ message }) {
  const { t } = useTranslation();
  let token = window.sessionStorage.getItem("userToken");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [responses, setResponses] = useState([]);
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm();
  useEffect(() => {
    if (message?.id && user?.data?.id) {
      register("message");
      setValue("message", message.id);
      register("admin");
      setValue("admin", user.data.id);
      getDataByID("responsebycontact", message.id, token).then((response) => {
        // console.log("response", response);
        setResponses(response?.results.sort((a, b) => b?.id - a?.id));
      });
    }
  }, [message?.id, user]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  const onSubmit = (data, e) => {
    setIsLoading(true);
    // console.log("data", data);
    data["admin"] = user?.data?.id;
    data["message"] = message?.id;
    postData("responsecontact/", data, token)
      .then(async (res) => {
        // console.log("res", res);
        await getDataByID("responsebycontact", message?.id, token).then(
          (response) => {
            // console.log("response", response);
            setResponses(response.results.sort((a, b) => b?.id - a?.id));
            setIsLoading(false);
            e.target.reset();
            reset();
          }
        );
        await dispatch(fetchContacts());
        // await Swal.fire({
        //   icon: "success",
        //   title: t("success"),
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        // await window.$(`#RepondreMessageAdmin${message?.id}`).modal("hide");
      })
      .catch((error) => {
        getDataByID("responsebycontact", message?.id, token).then(
          (response) => {
            // console.log("response", response);
            setResponses(response.results.sort((a, b) => b?.id - a?.id));
            setIsLoading(false);
            e.target.reset();
            reset();
          }
        );
        console.log("error", error?.response);
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("message_from")} (
            {message?.user?.id
              ? message?.user?.prenom + " " + message?.user?.nom
              : t("anonyme")}
            )
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item pb-4">
                  <div className="">
                    <p className="message-recu-admin">{message?.message}</p>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item border-top">
                  <div className="pt-4">
                    <div className="form-group crud-form-group">
                      <label htmlFor="text">{t("response")}</label>
                      <textarea
                        className="form-control input-add-document"
                        id="text"
                        rows="6"
                        name="response"
                        {...register("response", {
                          required: t("required"),
                        })}
                        placeholder={t("add_response")}
                      ></textarea>
                      {formState?.errors && formState?.errors?.response && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.response?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  {!isLoading && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("send")}
                    </button>
                  )}

                  {isLoading && (
                    <button className="btn crud-submit-btn" disabled>
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
            {responses?.length
              ? responses.map((resp) => (
                  <div
                    className="card-dashboard"
                    style={{ marginTop: "12px" }}
                    key={resp?.id}
                  >
                    <div className="col-12 itm-center">
                      <img
                        className="avatar-auteur-forum-card"
                        // src={PhotoProfil}
                        src={
                          resp?.admin?.avatar &&
                          !resp?.admin?.avatar?.includes("default.png")
                            ? baseUrl + resp?.admin?.avatar
                            : PhotoProfil
                        }
                        alt="Avatar"
                      />
                      <p className="text-forum-card fs-12 fw-300 sender-name">
                        {t("par")}&nbsp;
                        <strong>
                          {resp?.admin?.prenom + " " + resp?.admin?.nom}
                        </strong>
                        <br />
                        <span className="cm-sender-email">
                          {resp?.admin?.email}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 itm-center">
                      <p
                        className="text-forum-card fs-12 fw-300"
                        style={style.resp_style}
                      >
                        {resp?.response}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RepondreMessageAdmin;

const style = {
  resp_style: {
    margin: "1em 0px 0em 0em",
    fontSize: "20px",
    fontWeight: "500",
  },
  select_style: {
    display: "flex",
    alignItems: "self-start",
    flexDirection: "row-reverse",
  },
};
