const GestionExpertsPersonnesRessourcesTableData = [
    {
        id: 1,
        prenom: 'Abdou Ba',
        titre: 'Étudiant',
        phone: '770000000',
        card_number: '123333444'
    },
    {
        id: 2,
        prenom: 'Abdou Ba',
        titre: 'Étudiant',
        phone: '770000000',
        card_number: '123333444'
    },
    {
        id: 3,
        prenom: 'Abdou Ba',
        titre: 'Étudiant',
        phone: '770000000',
        card_number: '123333444'
    },
    {
        id: 4,
        prenom: 'Abdou Ba',
        titre: 'Étudiant',
        phone: '770000000',
        card_number: '123333444'
    },
];

export default GestionExpertsPersonnesRessourcesTableData;
