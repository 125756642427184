import React, { useEffect, useState } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./MessagesAdmin.css";
import $ from "jquery";
import PhotoProfil from "../../../../images/others/default-profile-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import Header from "../../../Navs/Header";
import RepondreMessageAdmin from "../../../Modals/Admin/RepondreMessageAdmin";
import { fetchContacts } from "../../../../redux/contact/contactAction";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import PaginationComponent from "../../../Modules/Pagination/Pagination";
import ChangeMessageStatus from "../../../Modals/Admin/ChangeMessageStatus";

var MessagesAdmin = (props) => {
  let { t } = useTranslation();

  const contacts = useSelector((state) => state.contacts);
  // const user = useSelector((state) => state.user);
  // const fulbrighters = useSelector((state) => state.fulbrighters);
  const dispatch = useDispatch();
  const [per_page] = useState(8);

  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);

  useEffect(() => {
    if (contacts?.data?.length) {
      formatData(contacts?.data);
    }
  }, [contacts, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
    console.log("indexs", indexOfFirstPage, indexOfLastPost, current_page);
    setLastIndex(indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $("#action_menu_btn").click(function () {
        $(".action_menu").toggle();
      });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header-message"
        >
          <div className="page-body-container py-4">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">Messages</h1>
                </div>
              </div>
              <div className="parent-table-container">
                {contacts?.isLoading && <PlainteSkeleton />}
                {!contacts?.isLoading &&
                  (currentList?.length ? (
                    currentList.map((contact) => (
                      <div
                        className="row mb-3 shadow-category align-items-center"
                        key={contact?.id}
                      >
                        <div className="col-xl-7 col-lg-7 col-md-5">
                          <div className="">
                            <div className="bloc-category-inline">
                              <div className=" bloc-liste">
                                <span
                                  className="titre-category-prestation linkable"
                                  data-toggle="modal"
                                  data-target={`#RepondreMessageAdmin${contact?.id}`}
                                >
                                  {`${contact?.message?.slice(0, 50)}...`}
                                </span>
                                <div className="date-faq mt-2">
                                  {moment(contact?.created_at).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  {t("at")}{" "}
                                  {moment(contact?.created_at).format("HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-3">
                          <div>
                            {contact?.status === "new" && (
                              <button
                                className="newmessage-admin new-status"
                                data-toggle="modal"
                                data-target={`#ChangeMessageStatus${contact?.id}`}
                                type="button"
                              >
                                {t("new")}
                              </button>
                            )}

                            {contact?.status === "en_cours" && (
                              <button
                                className="newmessage-admin warning-status"
                                data-toggle="modal"
                                data-target={`#ChangeMessageStatus${contact?.id}`}
                                type="button"
                              >
                                {t("doing")}
                              </button>
                            )}

                            {contact?.status === "traité" && (
                              <button
                                className="newmessage-admin success-status"
                                data-toggle="modal"
                                data-target={`#ChangeMessageStatus${contact?.id}`}
                                type="button"
                              >
                                {t("done")}
                              </button>
                            )}
                          </div>
                          <div
                            class="modal fade"
                            id={`RepondreMessageAdmin${contact?.id}`}
                            tabindex="-1"
                            aria-labelledby="RepondreMessageAdminLabel"
                            aria-hidden="true"
                          >
                            <RepondreMessageAdmin message={contact} />
                          </div>
                          <div
                            class="modal fade"
                            id={`ChangeMessageStatus${contact?.id}`}
                            tabindex="-1"
                            aria-labelledby="ChangeMessageStatusLabel"
                            aria-hidden="true"
                          >
                            <ChangeMessageStatus message={contact} />
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4">
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                contact?.user?.avatar &&
                                !contact?.user?.avatar?.includes("default.png")
                                  ? baseUrl + contact?.user?.avatar
                                  : PhotoProfil
                              }
                              alt="ImageProfile Forum"
                              className="img-profil"
                            />
                            <span className="consultation-name pl-3">
                              {t("par")}
                            </span>
                            <p className="name-auteur-forum pl-3">
                              {contact?.user?.id
                                ? contact?.user?.prenom +
                                  " " +
                                  contact?.user?.nom
                                : t("anonyme")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
              {currentList?.length ? (
                <PaginationComponent
                  per_page={per_page}
                  current_page={current_page}
                  total_data={total_data}
                  handleClick={handleClick}
                  lastIndex={lastIndex}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesAdmin;
const PlainteSkeleton = () => {
  return (
    <section>
      {Array(4)
        .fill()
        .map((item, index) => (
          <div className="card-dashboard m-b-30" key={index}>
            <div className="row">
              <div className=" col-xl-5 col-lg-5 col-md-6">
                <div className="titre-forum-card" to="/details-forum">
                  <Skeleton height={36} width={`90%`} />
                </div>
                <p className="publish-date-forum-card m-b-20">
                  <Skeleton width={`80%`} />
                </p>
              </div>
              <div className=" col-xl-4 col-lg-3 col-md-2 col-sm-6">
                <p className="nbre-message-forum-card text-center mt-3">
                  <Skeleton width={`40%`} />
                </p>
                {/* <p className="text-forum-card fs-12 fw-300 m-b-20">
                  <Skeleton width={`80%`} />
                </p> */}
              </div>
              <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 dis-flex m-b-10 align-items-center">
                <Skeleton circle={true} height={50} width={50} />
                &nbsp;&nbsp;
                <p className="text-forum-card fs-12 fw-300">
                  {/* <Skeleton width={`80%`} /> */}
                  <strong>
                    <Skeleton duration={1} height={30} width={150} />
                  </strong>
                </p>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
};
