const state = {
  step: "company-information",
  prenom: "",
  nom: "",
  statut: "",
  phone: "",
  numero_etudiant: "",
  numero_urgence: "",
  email: "",
  password: "",
  domaine_expertise: "",
  annee_cohorte: "",
  biographie: "",
  pays: "",
};

export default state;
