/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import "./Documents.css";
import {
  EmptyData,
  PlaylistSkelaton,
  SearchInput,
  searchPlaylist,
  YoutubeApi,
} from "./Media";
import { PlaylistVideo } from "./PlaylistItem";

var MediaDetail = (props) => {
  const item = useLocation().state;
  const history = useHistory();
  const { t } = useTranslation();
  const devise = useSelector((state) => state.devise);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPageToken, setNextPageToken] = useState(null);
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await YoutubeApi.getPlaylistsItems(item.id, nextPageToken);
      const { nextPageToken: np, items } = res;
      setData((old) => {
        return [...old, ...items];
      });
      setNextPageToken(np);
    } catch (ex) {
      alert(ex?.message);
    }
    setLoading(false);
  }, [nextPageToken]);
  useEffect(() => {
    if (item) {
      getData();
    } else {
      history.replace("/app/media");
    }
  }, []);
  if (!item) return null;
  const filtred = searchPlaylist(data, search);
  const empty = filtred.length === 0 && !loading && <EmptyData />;
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="banner">
              <div className="py-1 text-center">
                <h1 className="titre-banner">Media | {item.snippet.title}</h1>
              </div>
            </div>
            <div className="container-warc py-5 mt-2">
              <SearchInput
                value={search}
                onChange={(value) => {
                  setSearch(value);
                  if (nextPageToken && !loading) getData();
                }}
              />
              <div className="row my-5">
                {filtred.map((i) => {
                  return (
                    <div
                      className="col-xl-4 col-lg-4 col-md-4 pb-4"
                      key={i?.id}
                    >
                      <PlaylistVideo
                        videos={data}
                        playlist={item}
                        item={i}
                        devise={devise}
                      />
                    </div>
                  );
                })}
                {loading && <PlaylistSkelaton />}
                {empty}
                {!loading && nextPageToken && (
                  <div className="col-12 text-center pb-4">
                    <span
                      className="cursor-pinter btn btn-participate-event trans-0-2 ml-auto"
                      onClick={() => getData()}
                    >
                      <span>{t("see_more")}</span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="banner">
          <div className="py-2 text-center">
            <h1 className="titre-banner">Media | {item.snippet.title}</h1>
          </div>
        </div>
        <div className="container-warc py-5 mt-2">
          <SearchInput
            value={search}
            onChange={(value) => {
              setSearch(value);
              if (nextPageToken && !loading) getData();
            }}
          />
          <div className="row my-5">
            {filtred.map((i) => {
              return (
                <div className="col-xl-4 col-lg-4 col-md-4 pb-4" key={i?.id}>
                  <PlaylistVideo
                    videos={data}
                    playlist={item}
                    item={i}
                    devise={devise}
                  />
                </div>
              );
            })}
            {loading && <PlaylistSkelaton />}
            {empty}
            {!loading && nextPageToken && (
              <div className="col-12 text-center pb-4">
                <span
                  className="cursor-pinter btn btn-participate-event trans-0-2 ml-auto"
                  onClick={() => getData()}
                >
                  <span>{t("see_more")}</span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default MediaDetail;
