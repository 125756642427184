import { REPORT_USER } from "../types";

export const report_userReducer = (state = [], action) => {
  switch (action.type) {
    case REPORT_USER:
      return action.payload;
    default:
      return state;
  }
};
