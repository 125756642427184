import React, { useEffect } from "react";
import {
  BootstrapTable,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ImgDoc from "../../../../assets/images/icons/word-icon.png";
import ImgDocument from "../../../../assets/images/pdf.png";
import { fetchAnnualReports } from "../../../../redux/annual_reports/annual_reportsAction";
import AddAnnualReport from "../../../Modals/Coordonnateur/AddAnnualReport";
import DetailsDocument from "../../../Modals/Coordonnateur/DetailsDocument";
import { deleteData } from "../../../utils/fetchData";
import SlideTableSkelaton from "./SlideTableSkelaton";

var AnnualReportsTable = () => {
  const { t } = useTranslation();
  const documents = useSelector((state) => state.annual_reports);
  const dispatch = useDispatch();

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, [documents]);

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const photoFormatter = (cell, row) => {
    return (
      <div>
        {get_url_extension(row?.fichier) === "pdf" ? (
          <img
            style={{ width: "50px", height: "50px" }}
            src={ImgDocument}
            alt=""
          />
        ) : (
          <img style={{ width: "50px", height: "50px" }} src={ImgDoc} alt="" />
        )}

        <span className="pl-3"> {cell}</span>
      </div>
    );
  };

  const onDelete = (id) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: t("warning_message_report"),
      icon: "warning",

      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`annual_report/${id}/`, token)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchAnnualReports());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const OpenModal = (id) => {
    window.$(`#DetailsDocumentModal${id}`).modal("show");
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            onClick={() => OpenModal(row?.id)}
            // data-toggle="modal"
            // data-target={`#DetailsDocumentModal${row?.id}`}
          />
        </button>
        <button
          data-toggle="modal"
          data-target={`#AddAnnualReport${row.id}`}
          className="action-btn edit-btn"
          title={"edit"}
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button className="action-btn delete-btn" title={t("delete")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
        <div
          className="modal fade"
          id={`DetailsDocumentModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="DetailsDocumentModalLabel"
          aria-hidden="true"
        >
          <DetailsDocument document={row} />
        </div>
        <div
          className="modal fade"
          id={`AddAnnualReport${row.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AjouterDocumentModalLabel"
          aria-hidden="true"
        >
          <AddAnnualReport item={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <SlideTableSkelaton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={documents}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    dataField="title"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    {t("title")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataFormat={(cell, row) => {
                      if (row.description?.length > 100) {
                        return row.description.slice(0, 100) + "...";
                      }
                      return row.description;
                    }}
                    dataField="description"
                  >
                    Description
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualReportsTable;
