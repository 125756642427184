import React, { useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import UseAddPostForm from "./requestPost/UseAddPostForm";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";
// import { CKEditor } from "ckeditor4-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

var AddCommentModal = () => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");
  const [fileName, setFileName] = useState(t("upload_image"));
  const categories = useSelector((state) => state.categories);
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
    setValue,
    isDone,
  } = UseAddPostForm();

  useEffect(() => {
    if (!categories?.isLoading && categories?.data?.length) {
      setOptions(
        categories?.data.map((category) => ({
          label: category?.name,
          value: category?.id,
        }))
      );
    }
  }, [categories]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (isDone) {
      setOption("");
      setFileName(null);
      setText("")
    }
  }, [isDone]);

  const handleChange = (selected) => {
    setValue("category", selected?.value);
    setOption(selected);
  };

  const onChangeFile = (e) => {
    setFileName(e.target.files[0]?.name);
    setValue("photo", e.target.files[0]);
  };

  const handleReset = () => {
    setOption("");
    reset();
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5 className="modal-title text-titre" id="addCommentModal">
            {t("create_post")}
          </h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  {...register("titre")}
                  type="text"
                  name="titre"
                  className="form-control input-file-modal"
                  placeholder={t("title") + "*"}
                />
                {formState.errors && formState.errors.titre && (
                  <div className="alert alert-danger gfa-alert-danger">
                    {formState.errors.titre?.message}
                  </div>
                )}
              </div>
              <div className="mb-5">
                {/* <textarea
                  name="story"
                  rows="3"
                  cols="33"
                  {...register("text")}
               
                  className="form-control input-comment-modal"
                  placeholder={t("write_something") + "*"}
                ></textarea> */}
                <ReactQuill
                  theme="snow"
                  placeholder={t("write_something") + "*"}
                  value={text}
                  onChange={(val) => {
                    setValue("text",val)
                    setText(val)
                  }}
                />
                {formState.errors && formState.errors.text && (
                  <div className="alert alert-danger gfa-alert-danger">
                    {formState.errors.text?.message}
                  </div>
                )}
              </div>
              {/* <div className="position-relative mt-5 mb-3">
                <input
                  type="file"
                  name="photo"
                  {...register("photo")}
                  className="input-file-modal p-3"
                />
                <span className="span-icon-file">
                  <IconlyPack.Image2 set="bold" primaryColor="#666666" />
                </span>
              </div>
               */}

              <div className="form-group crud-form-group">
                <label htmlFor="file">{t("file")}</label>
                <div className="inputfile-box">
                  <input
                    type="file"
                    id="file"
                    name="fichier"
                    // {...register("photo")}
                    onChange={(e) => onChangeFile(e)}
                    accept="image/*"
                    className="inputfile  input-add-document"
                  />
                  <label htmlFor="file">
                    <span
                      id="file-name"
                      className="file-box input-add-document"
                    >
                      <span className="text-input-file-costum">{fileName}</span>
                    </span>
                    <span className="file-button">
                      <IconlyPack.Image set="light" primaryColor="#BDC0C4" />
                    </span>
                  </label>
                </div>
              </div>

              <div>
                <div className="form-group">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={option}
                    isLoading={categories?.isLoading}
                    name="category"
                    placeholder={t("select_category") + "*"}
                    {...register("category")}
                    options={options}
                    onChange={handleChange}
                  />
                  {/* <select
                    className=""
                    id="exampleFormControlSelect1"
                    name="category"
                  >
                    <option value="">Select a category</option>
                  </select> */}
                  {formState.errors && formState.errors.category && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.category?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer-no-border">
                {/* <button type="submit" className="btn btn-publier-comment">
                  Publier
                </button> */}
                {isSuccessfullySubmitted === false ? (
                  <button type="submit" className="btn btn-publier-comment">
                    {t("publish")}
                  </button>
                ) : (
                  <button className="btn btn-publier-comment" disabled>
                    {t("en_cours")} &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCommentModal;
