import React, { useState, useMemo, useEffect } from "react";
// import Select, { components } from "react-select";
import countryList from "react-select-country-list";
import { CKEditor } from "ckeditor4-react";
// import * as IconlyPack from "react-iconly";
import UseAddFulbrighters from "../../Dashboard/Admin/GestionDesFulbrightersAdmin/requestFulbrighters/UseAddFulbrighters";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useTranslation } from "react-i18next";

var AjouterFulbriterModal = (props) => {
  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [startDate, setStartDate] = useState(new Date());
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
    setValue,
    isDone,
  } = UseAddFulbrighters();

  const [phone, setPhone] = React.useState("");
  const [input, setInput] = useState("");
  // const [tags, setTags] = useState([]);
  // const [isKeyRelease, setIsKeyReleased] = useState(false);

  // useEffect(() => {
  //   if (tags?.length) {
  //     register("domaine_expertise");
  //     setValue("domaine_expertise", JSON.stringify(tags));
  //     console.log(JSON.stringify(tags));
  //   } else {
  //     register("domaine_expertise");
  //     setValue("domaine_expertise", "");
  //   }
  // }, [tags, input]);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  const changeHandler = (value) => {
    // console.log(
    //   "val",
    //   value,
    //   "options",
    //   options.filter((option) => option.value === value)[0]
    // );
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  // const onKeyDown = (e) => {
  //   const { key } = e;
  //   const trimmedInput = input.trim();

  //   if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
  //     e.preventDefault();
  //     setTags((prevState) => [...prevState, trimmedInput]);
  //     setInput("");
  //   }

  //   if (key === "Backspace" && !input.length && tags.length && isKeyRelease) {
  //     e.preventDefault();
  //     const tagsCopy = [...tags];
  //     const poppedTag = tagsCopy.pop();

  //     setTags(tagsCopy);
  //     setInput(poppedTag);
  //   }

  //   setIsKeyReleased(false);
  // };

  // const onKeyUp = () => {
  //   setIsKeyReleased(true);
  // };

  // const deleteTag = (e, index) => {
  //   e.preventDefault();
  //   setTags((prevState) => prevState.filter((tag, i) => i !== index));
  // };

  // const onChange = (e) => {
  //   const { value } = e.target;
  //   setInput(value);
  // };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (isDone) {
      setPhone("+221");
      setVal("");
      setInput("");
      // setTags([]);
    }
  }, [isDone]);

  const handleReset = () => {
    reset();
    setPhone("+221");
    setVal("");
    setInput("");
    // setTags([]);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("add_fulbrighter")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="prenom">
                        {t("firstName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("firstName")}
                        {...register("prenom")}
                        name="prenom"
                        className="form-control  input-add-document"
                        id="prenom"
                      />
                      {formState.errors && formState.errors.prenom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.prenom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">
                        {t("lastName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("lastName")}
                        name="nom"
                        {...register("nom")}
                        className="form-control  input-add-document"
                        id="nom"
                      />
                      {formState.errors && formState.errors.nom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">
                        {t("email")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder={t("email")}
                        name="email"
                        {...register("email")}
                        className="form-control  input-add-document"
                        id="email"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="status">
                        {t("status")} <span className="required-label">*</span>
                      </label>
                      <select
                        className="form-control input-add-document"
                        id="status"
                        {...register("statut")}
                        name="statut"
                      >
                        <option value="" hidden>
                          {t("status")}
                        </option>
                        <option value="etudiant">{t("student")}</option>
                        <option value="faculty">{t("faculty")}</option>
                        <option value="chercheur">{t("searcher")}</option>
                        <option value="staff">{t("staff")}</option>
                        <option value="ata">{t("ata")}</option>
                      </select>
                      {formState.errors && formState.errors.statut && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.statut?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="country">
                        {t("country")}
                        <span className="required-label">*</span>
                      </label>

                      <ReactFlagsSelect
                        className="form-control input-add-document"
                        selected={val}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                        placeholder={t("select_country")}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <div className="form-group crud-form-group">
                      <label htmlFor="year">
                        {t("cohort_year")}
                        <span className="required-label">*</span>
                      </label>
                      <DatePicker
                        dateFormat="yyyy"
                        showYearPicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          console.log(date);
                          setValue(
                            "annee_cohorte",
                            moment(date).format("YYYY")
                          );
                        }}
                        className="form-control input-add-document"
                      />
                      {formState.errors && formState.errors.annee_cohorte && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.annee_cohorte?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="numero_etudiant">
                        {t("student_number")}
                      </label>
                      <input
                        type="number"
                        placeholder={t("student_number")}
                        {...register("numero_etudiant")}
                        name="numero_etudiant"
                        className="form-control input-add-document"
                        id="numero_etudiant"
                      />
                      {formState.errors && formState.errors.numero_etudiant && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.numero_etudiant?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="phone">{t("phone")}</label>
                      <PhoneInput
                        masks={{ sn: ".. ... .. .." }}
                        inputclassName="form-control input-add-document"
                        country={"sn"}
                        // onlyCountries={['sn']}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />

                      {/* <input
                        type="tel"
                        placeholder="Tel"
                        name="phone"
                        {...register("phone")}
                        className="form-control  input-add-document"
                        id="phone"
                      /> */}
                      {formState.errors && formState.errors.phone && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.phone?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">{t("address")}</label>
                      <input
                        type="text"
                        placeholder={t("address")}
                        name="adresse"
                        {...register("adresse")}
                        className="form-control  input-add-document"
                        id="adresse"
                      />
                      {formState.errors && formState.errors.adresse && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.adresse?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">{t("skills")}</label>
                      <div className="tags-input">
                        <input
                          type="text"
                          className="form-control input-add-document"
                          value={input}
                          onKeyDown={onKeyDown}
                          onChange={onChange}
                          onKeyUp={onKeyUp}
                        />
                        {tags.map((tag, index) => (
                          <Tag className="mb-4 tag" key={index}>
                            {tag}
                            <Delete onClick={(e) => deleteTag(e, index)} />
                          </Tag>
                        ))}
                      </div>
                      <Help>{t("skills_guide")}</Help>
                      {formState.errors && formState.errors.adresse && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.adresse?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="bio">{t("bio")}</label>
                      {/* <textarea
                        className="form-control  input-add-document"
                        id="bio"
                        rows="5"
                        name="biographie"
                        {...register("biographie")}
                        placeholder={t("bio_guide")}
                      ></textarea> */}
                      <CKEditor
                        className="form-control  input-add-document"
                        initData={""}
                        onChange={(evt) => {
                          if (evt.editor.getData() !== undefined) {
                            setValue("biographie", evt.editor.getData());
                          }
                        }}
                      />
                      {formState.errors && formState.errors.bio && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.bio?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("add")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterFulbriterModal;

// const Tag = (props) => <span className="tag" {...props}></span>;
// const Delete = (props) => (
//   <button className="delete btn mr-2" {...props}>
//     <IconlyPack.CloseSquare set="light" primaryColor="#000" />
//   </button>
// );
// const Help = (props) => <span className="help" {...props} />;
