import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import useCreateAnnualReport from "../../Dashboard/Admin/DocumentsAdmin/requestDocument/useCreateAnnualReport";
var AddAnnualReport = ({ item }) => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
    setValue,
    isDone,
  } = useCreateAnnualReport(item);
  const [fileName, setFileName] = useState(null);
  const uploadFile = (e) => {
    console.log("target", e.target);
    setFileName(e.target.files[0].name);
    setValue("fichier", e.target.files[0]);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (isDone) {
      setFileName(null);
    }
  }, [isDone]);

  const handleReset = () => {
    setFileName(null);
    reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddAnnualReport2">
            {item ? t("edit_report") : t("add_report")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">
                        {t("title")}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("title")}
                        name="title"
                        {...register("title")}
                        className="form-control input-add-document"
                      />
                      {formState.errors && formState.errors.title && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.title?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="description">
                        Description<span className="required-label">*</span>
                      </label>
                      <textarea
                        className="form-control input-add-document"
                        id="description"
                        rows="3"
                        name="description"
                        placeholder="Description"
                        {...register("description")}
                      ></textarea>
                      {formState.errors && formState.errors.description && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.description?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <span>
                        {t("file")}
                        <span className="required-label">*</span>
                      </span>
                      <div className="inputfile-box">
                        <input
                          type="file"
                          id={item ? `file${item.id}` : "file"}
                          name="fichier"
                          className="inputfile  input-add-document"
                          onChange={(e) => uploadFile(e)}
                        />
                        <label htmlFor={item ? `file${item.id}` : "file"}>
                          <span
                            id="file-name"
                            className="file-box input-add-document"
                          >
                            <span className="text-input-file-costum">
                              {fileName || t("upload_file")}
                            </span>
                          </span>
                          <span className="file-button">
                            <IconlyPack.Upload
                              set="light"
                              primaryColor="#000"
                            />
                          </span>
                        </label>
                      </div>
                      {formState.errors && formState.errors.fichier && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.fichier?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t(item ? "edit" : "add")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAnnualReport;
