export const FETCH_POSTES = "FETCH_POSTES";
export const FILTER_POSTES = "FILTER_POSTES";

export const FETCH_SLIDES = "FETCH_SLIDES";
export const ANNUAL_REPORT = "ANNUAL_REPORT";
export const REPORT_USER = "REPORT_USER";
export const FETCH_PLAYLIST = "FETCH_PLAYLIST";

export const ME = "ME";

export const DEVISE = "DEVISE";

export const CONFIG = "CONFIG";

export const FETCH_USER_PARTICIPATION = "FETCH_USER_PARTICIPATION";

export const DASHBOARD = "DASHBOARD";

export const GRAPH = "GRAPH";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const FETCH_ARTICLES = "FETCH_ARTICLES";

export const CHEARCH_ARTICLE = "CHEARCH_ARTICLE";

export const FETCH_FULBRIGHTERS = "FETCH_FULBRIGHTERS";

export const FETCH_EXPERTS = "FETCH_EXPERTS";

export const FETCH_LIVES = "FETCH_LIVES";

export const FETCH_PARTNERS = "FETCH_PARTNERS";

export const FETCH_USERS = "FETCH_USERS";

export const CHEARCH_USERS = "CHEARCH_USERS";

export const CHEARCH_EXPERTS = "CHEARCH_EXPERTS";

export const CHEARCH_PARTNERS = "CHEARCH_PARTNERS";

export const FETCH_USER_MESSAGES = "FETCH_USER_MESSAGES";

export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_VIDEOS = "FETCH_VIDEOS";

export const CHEARCH_DOCUMENTS = "CHEARCH_DOCUMENTS";

export const FETCH_EVENTS = "FETCH_EVENTS";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";

export const FETCH_CONDITIONS = "FETCH_CONDITIONS";

export const FETCH_FAQS = "FETCH_FAQS";

export const CHEARCH_FAQS = "CHEARCH_FAQS";

export const FETCH_CATALOGUES = "FETCH_CATALOGUES";

export const CHEARCH_UNIVERSITY = "CHEARCH_UNIVERSITY";

export const CHEARCH_USER = "CHEARCH_USER";

export const FETCH_CONTACTS = "FETCH_CONTACTS";

export const FETCH_CGU = "FETCH_CGU";

export const FETCH_ML = "FETCH_ML";

export const FETCH_ADMINS = "FETCH_ADMINS";

export const FETCH_REPORTS = "FETCH_REPORTS";

export const FETCH_INCIDENTS = "FETCH_INCIDENTS";
