/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./FrontRegister.css";
import updateFormState from "../../../redux/register/actions";
import Logo from "../../../images/logo.png";
import * as IconlyPack from "react-iconly";
import EmptyHeader from "../../Auth/EmptyHeader";
import { isMobile } from "react-device-detect";
import UseRegisterForm from "../../Auth/UseRegisterForm";
import { useSelector } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import { useForm, useStep } from "react-hooks-helper";
import AccountDetails from "./AccountDetails";
import PersonalDetails from "./PersonalDetails";
import { useStateMachine } from "little-state-machine";
import Swal from "sweetalert2";
import AuthService from "../../../services/AuthServices";

const steps = [
  { id: "company-information", Component: AccountDetails },
  { id: "competition-information", Component: PersonalDetails },
];

var FrontRegister = () => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
  } = UseRegisterForm();
  const { state, actions } = useStateMachine({ updateFormState });
  // form on submit handles
  const firstFormHandle = ({
    prenom,
    nom,
    statut,
    phone,
    numero_etudiant,
    numero_urgence,
  }) => {
    actions.updateFormState({
      prenom: prenom,
      nom: nom,
      statut: statut,
      phone: phone,
      numero_etudiant: numero_etudiant,
      numero_urgence: numero_urgence,
      step: "competition-information",
    });
  };
  const lastFormHandle = async ({
    email,
    password,
    domaine_expertise,
    annee_cohorte,
    biographie,
    pays,
    passwordConfirmation,
  }) => {
    await actions.updateFormState({
      step: "competition-information",
      email: email,
      password: password,
      passwordConfirmation: passwordConfirmation,
      domaine_expertise: domaine_expertise,
      annee_cohorte: annee_cohorte,
      biographie: biographie,
      pays: pays,
    });
    await console.log("data", {
      ...state,
      ...{
        email,
        password,
        domaine_expertise,
        annee_cohorte,
        biographie,
        pays,
        passwordConfirmation,
      },
    });
    let formValues = {
      ...state,
      ...{
        email,
        password,
        domaine_expertise,
        annee_cohorte,
        biographie,
        pays,
        passwordConfirmation,
      },
    };
    // onSubmit(data, e);
    setIsSuccessfullySubmitted(true);
    formValues["user_type"] = "fulbrighter";
    console.log("data", formValues);
    var request = AuthService.onRegister(formValues);

    await request
      .then(async (resp) => {
        console.log("resp", resp);
        // window.sessionStorage.setItem("userToken", resp?.token);
        await Swal.fire({
          icon: "success",
          title: t("validate_message"),
          showConfirmButton: false,
          timer: 5000,
        }).then(() => {
          window.location = "/app/connexion";
        });
        // await dispatch(fetchMe());
      
      }).catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: t("email_used"),
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.non_field_errors) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: t("non_field_errors"),
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          //   toast.error("Veuillez vérifier votre connexion internet.");
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  const signOutHandle = () => {
    actions.updateFormState({
      step: "Email",
    });
  };

  const devise = useSelector((state) => state.devise);
  const history = useHistory();
  let token = window.sessionStorage.getItem("userToken");
  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [phone, setPhone] = React.useState("");

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { navigation };

  useEffect(() => {
    actions.updateFormState({
      step: "company-information",
      prenom: "",
      nom: "",
      statut: "",
      phone: "",
      numero_etudiant: "",
      numero_urgence: "",
      email: "",
      password: "",
      domaine_expertise: "",
      annee_cohorte: "",
      biographie: "",
      pays: "",
    });
    window.scrollTo(0, 0);
  }, []);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  const changeHandler = (value) => {
    console.log(
      "val",
      value,
      "options",
      options.filter((option) => option.value === value)[0]
    );
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        history.push("/app/home");
      }, 1000);
    }
  }, [token]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  if (devise) {
    return (
      <div>
        <div>
          <div className="connexion-page">
            <div className="p-4 bg-white">
              <div className="text-right">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="login-page-container">
              <section className="auth-page-content">
                <div>
                  {/* <Component {...props} />  */}
                  {state.step === "company-information" && (
                    <AccountDetails state={state} onSubmit={firstFormHandle} />
                  )}
                  {state.step === "competition-information" && (
                    <PersonalDetails
                      isSuccessfullySubmitted={isSuccessfullySubmitted}
                      onSubmit={lastFormHandle}
                    />
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="connexion-page">
      <EmptyHeader />
      <div className="login-page-container">
        <section className="auth-page-content">
          <div>
            {/* <Component {...props} /> */}
            {state.step === "company-information" && (
              <AccountDetails state={state} onSubmit={firstFormHandle} />
            )}
            {state.step === "competition-information" && (
              <PersonalDetails
                isSuccessfullySubmitted={isSuccessfullySubmitted}
                onSubmit={lastFormHandle}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default FrontRegister;
