import React, { useEffect, Fragment } from "react";
import FrontFooter from "../frontFooter/index";
import NavMenuMobile from "./NavMenuMobile";
import Skeleton from "@nejcm/react-skeleton";
import * as IconlyPack from "react-iconly";
import ImgEllipse from "../../../images/ellipse.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Events from "../../../services/Events";
import { fetchUserParticipations } from "../../../redux/participation/participationAction";
import "moment/locale/fr";
import "moment/locale/en-ca";
import { Redirect } from "react-router-dom";
import { fetchEvents } from "../../../redux/events/eventsAction";

var DetailsEventMobile = () => {
  const { t } = useTranslation();
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const devise = useSelector((state) => state.devise);
  const user = useSelector((state) => state.user);
  const events = useSelector((state) => state.events);
  const userParticipations = useSelector((state) => state.userParticipations);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEvents());
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (user?.data?.id) {
      // let lng = window.localStorage.getItem("i18nextLng");
      // console.log("langue", lng);
      dispatch(fetchUserParticipations(user?.data?.id));
    }
  }, [dispatch, user]);

  const onParticipEvent = async (id) => {
    console.log("id", id);
    let data = {};
    data["user"] = user?.data?.id;
    data["event"] = id;

    let request = Events.onUserParticipate(data, config);

    await request
      .then(async (response) => {
        console.log("response participation", response);
        await dispatch(fetchUserParticipations(user?.data?.id));
        await Swal.fire({
          icon: "success",
          title:
            "Votre Participation a été bien prise en compte. Un mail vous sera envoyé 24h avant l'évènement.",
          showConfirmButton: false,
          timer: 5000,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title:
            "Votre Participation n'est pas prise en compte, veuillez réessayer plut tard.",
          showConfirmButton: false,
          timer: 1500,
        });
        // Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };

  const onDeleteParticipate = (id) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: `Êtes vous sûr de vouloir annuler votre participation à cet évènement ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Events.onDeleteParticipate(id, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchUserParticipations(user?.data?.id));
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //  console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Votre participation a été annulée avec succés.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <section className="container-warc mt-5">
              <div className="pt-5">
                <div className="bg-accueil mb-4">
                  <div className="border-bottom">
                    <p className="text-agenda pb-2">Agenda of the program</p>
                  </div>
                  {events?.isLoading && userParticipations?.isLoading && (
                    <ProgramSkeleton />
                  )}
                  {!events?.isLoading &&
                    !userParticipations?.isLoading &&
                    (events?.filteredData?.length ? (
                      events?.filteredData
                        ?.sort(
                          (a, b) =>
                            new Date(a?.startDate) - new Date(b?.startDate)
                        )
                        ?.map((event) => (
                          <Fragment key={event?.id}>
                            <div className="d-flex my-3">
                              <img
                                src={ImgEllipse}
                                alt="elImage"
                                className="img-event-participate"
                              />
                              <p className="text-consultation-agenda pl-3">
                                {event?.title}
                              </p>
                            </div>
                            <div>
                              <p className="text-agenda-calendar">
                                <IconlyPack.Calendar set="bold" />
                                <span className="pl-2">
                                  {moment(event?.startDate).format(
                                    "DD/MM/YYYY - HH:MM"
                                  )}
                                </span>
                              </p>
                              {/* <button className="btn btn-participate-event">
                                  PARTICIPATE
                                </button> */}
                              {userParticipations?.data?.filter(
                                (item) => item?.event?.id === event?.id
                              )?.length ? (
                                <button
                                  className="btn btn-participate-event active"
                                  type="button"
                                  onClick={() =>
                                    onDeleteParticipate(
                                      userParticipations?.data?.filter(
                                        (item) => item?.event?.id === event?.id
                                      )[0]?.id
                                    )
                                  }
                                >
                                  {t("is_participated")}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-participate-event"
                                  type="button"
                                  onClick={() => onParticipEvent(event?.id)}
                                >
                                  {t("participate")}
                                </button>
                              )}
                            </div>
                          </Fragment>
                        ))
                    ) : (
                      <div className="gfa-rowy mt-5">
                        <div className="alert alert-warning gfa-warning">
                          {t("noData")}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }

  return <Redirect to="/app/home" />;
};
export default DetailsEventMobile;
const ProgramSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index}>
        <Skeleton className="d-flex my-3">
          <Skeleton.Circle width={20} height={20} />
          <Skeleton.Rectangle height={20} width={`100%`} className="mr-1" />
        </Skeleton>
        <Skeleton className="d-flex">
          <Skeleton.Rectangle width={20} height={20} />
          <Skeleton.Rectangle height={20} width={`100%`} className="mr-1" />
        </Skeleton>
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
