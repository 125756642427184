import React, { useEffect } from "react";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./DocumentsAdmin.css";

import AjouterVideoModal from "../../../Modals/Coordonnateur/AjouterVideoModal";
import VideosAdminTable from "../../../Tables/Admin/DocumentsAdminTable/VideosAdminTable";

var VideosAdmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header"
        >
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">Videos</h1>
                </div>
                <div
                  className="adding-item-container linkable"
                  data-toggle="modal"
                  data-target="#AjouterVideoModal"
                >
                  <span className="icon-container">
                    <IconlyPack.Plus set="light" primaryColor="#003DA5" />
                  </span>
                  <span className="adding-item-label">Add video</span>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <VideosAdminTable />
                </div>
              </div>
              <div
                className="modal fade"
                id="AjouterVideoModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AjouterVideoModalLabel"
                aria-hidden="true"
              >
                <AjouterVideoModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosAdmin;
