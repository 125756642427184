import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

function IncidentSkeleton() {
  const { t } = useTranslation();
  return (
    <div className="col-md-12 as-col-table-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col" style={{ color: "#1A5C8C" }}>
              {t("fullname")}
            </th>
            <th scope="col" style={{ color: "#1A5C8C" }}>
              {t("email")}
            </th>
            {/* <th scope="col" style={{ color:'#42922E' }}>ID de la réunion</th> */}
            <th scope="col" style={{ color: "#1A5C8C" }}>
              Date{" "}
            </th>
            <th scope="col" style={{ color: "#1A5C8C" }}>
              Message
            </th>
            <th scope="col" style={{ color: "#1A5C8C" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default IncidentSkeleton;
