import React, { useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./ProfilAdmin.css";
import Header from "../../../Navs/Header";

import AvatarPp from "../../../../images/others/default-profile-avatar.png";
import * as IconlyPack from "react-iconly";
import EditAvatarCoordonnateur from "./EditAvatarCoordonnateur";
import EditProfilCoordonnateur from "./EditProfilCoordonnateur";
import EditPasswordCoordonnateur from "./EdittPasswordCoordonnateur";
import { useSelector } from "react-redux";
import baseUrl from "../../../../Http/backend-base-url";
import { useTranslation } from "react-i18next";

var ProfilAdmin = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header"
        >
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("my_profil")}</h1>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="p-5">
                  <div className="avatar-container">
                    <div className="text-center">
                      <img
                        // src={AvatarPp}
                        src={
                          !user?.isLoading
                            ? user?.data?.avatar &&
                              !user?.data?.avatar?.includes("default.png")
                              ? baseUrl + user?.data?.avatar
                              : AvatarPp
                            : AvatarPp
                        }
                        alt="Avatar"
                        className="Avatar-profil"
                      />
                    </div>
                    <div className="icon-edit-avatar">
                      <button
                        className="btn btn-edit-avatar-desktop"
                        data-toggle="modal"
                        data-target="#EditAvatarCoordonnateur"
                        type="button"
                      >
                        <IconlyPack.Edit set="light" />
                        <span className="pl-2">{t("edit")}</span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-content-container my-4">
              <div className="">
                <div className="row border-bottom align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("firstName")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">{user?.data?.prenom}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
                <div className="row border-bottom align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("lastName")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">{user?.data?.nom}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
                <div className="row border-bottom align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("email")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">{user?.data?.email}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
                <div className="row border-bottom align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("address")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">
                      {user?.data?.adresse || "not informed"}
                    </p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
                <div className="row border-bottom align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("phone")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">
                      {user?.data?.phone || "not informed"}
                    </p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
                <div className="row border-bottom align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("country")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">
                      {user?.data?.pays || "not informed"}
                    </p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("status")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">{t("admin")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#EditProfilCoordonnateur"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 bg-white p-3">
              <div className="pb-3">
                <p className="profil-user-name"> {t("password")}</p>
              </div>
              <div className="">
                <button
                  className="btn btn-change-password"
                  data-toggle="modal"
                  data-target="#EditPasswordCoordonnateur"
                >
                  {t("edit_password")}
                </button>
              </div>
            </div>
            <div
              className="modal fade"
              id="EditAvatarCoordonnateur"
              tabIndex="-1"
              data-backdrop="static"
              aria-labelledby="EditAvatarCoordonnateurLabel"
              aria-hidden="true"
            >
              <EditAvatarCoordonnateur />
            </div>
            <div
              className="modal fade"
              id="EditProfilCoordonnateur"
              data-backdrop="static"
              tabIndex="-1"
              aria-labelledby="EditProfilCoordonnateurLabel"
              aria-hidden="true"
            >
              <EditProfilCoordonnateur />
            </div>
            <div
              className="modal fade"
              id="EditPasswordCoordonnateur"
              data-backdrop="static"
              tabIndex="-1"
              aria-labelledby="EditPasswordCoordonnateurLabel"
              aria-hidden="true"
            >
              <EditPasswordCoordonnateur />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilAdmin;
