import React, { Fragment, useEffect, useState, useMemo } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./Messages.css";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import $ from "jquery";
import PhotoProfil from "../../../../images/others/default-profile-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserMessages } from "../../../../redux/messages/MessagesAction";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import api from "../../../../Http/global-vars";
import Skeleton from "@nejcm/react-skeleton";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Header from "../../../Navs/Header";
import { useTranslation } from "react-i18next";
import { searchUser } from "../../../../redux/Users/UsersAction";

var Messages = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        message: yup.string().required("Message is required."),
        receiver: yup.string().required("Receiver is required."),
        sender: yup.string(),
      }),
    []
  );
  const { register, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const devise = useSelector((state) => state.devise);
  const userMessages = useSelector((state) => state.userMessages);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [userSelected, setUserSelected] = useState({});

  useEffect(() => {
    if (!user?.isLoading && user?.data?.id) {
      dispatch(fetchUserMessages(user?.data?.id));
    }
  }, [user]);
  useEffect(() => {
    if (
      !location?.state?.userItem &&
      !users?.isLoading &&
      users?.data?.filter((u) => u?.id !== user?.data?.id)?.length
    ) {
      setUserSelected(users?.data?.filter((u) => u?.id !== user?.data?.id)[0]);
    }

    if (location?.state?.userItem) {
      setUserSelected(location?.state?.userItem);
      if (document.getElementById(`${location?.state?.userItem?.id}`)) {
        document
          .getElementById(`${location?.state?.userItem?.id}`)
          .scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
      }
    }
  }, [users, user, location]);

  useEffect(() => {
    if (
      !userMessages?.isLoading &&
      userMessages?.data?.length &&
      userSelected?.id
    ) {
      let discutes = userMessages?.data?.filter(
        (u) =>
          u?.receiver?.id === userSelected?.id ||
          u?.sender?.id === userSelected?.id
      );
      setMessages(discutes?.sort((a, b) => a?.id - b?.id));
      //   console.log("message", test);
    }

    if (userSelected?.id) {
      setValue("receiver", userSelected?.id);
    }
  }, [userSelected, userMessages]);

  const handleSelect = (item) => {
    setUserSelected(item);
    history.push({
      pathname: "/messages-coordonnateur",
      state: { userItem: item },
    });
  };

  const onSubmit = async (data, e) => {
    data["sender"] = user?.data?.id;
    console.log("data", data);
    let request = axios.post(`${api}message/`, data, config);

    await request
      .then(async (response) => {
        console.log("response message", response);
        await dispatch(fetchUserMessages(user?.data?.id));
        await e.target.reset();
        await reset();
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $("#action_menu_btn").click(function () {
        $(".action_menu").toggle();
      });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeUser = (e) => {
    dispatch(searchUser(users?.data, e.target.value?.toLowerCase()));
  };

  if (devise) {
    return (
      <div className="messagerie">
        <div className="component-front-home">
          <Header />
          <div
            className="content-position-after-header px-md-3 px-0"
            style={{ left: "9%" }}
          >
            <div>
              <div className="row justify-content-center h-100 mt-5">
                <div className="chat">
                  <div className="card mb-sm-3 mb-md-0 contacts_card">
                    <div className="card-header">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder={t("search")}
                          name=""
                          className="form-control search-user"
                          onChange={handleChangeUser}
                        />
                        <div className="input-group-prepend">
                          <span className="input-group-text search_btn">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body contacts_body" id="contacts_body">
                      <ui className="contacts">
                        {users?.isLoading && <ActivitiesSkeleton />}
                        {!users?.isLoading &&
                          (users?.filteredData?.filter(
                            (u) => u?.id !== user?.data?.id
                          )?.length ? (
                            users?.filteredData
                              ?.filter((u) => u?.id !== user?.data?.id)
                              ?.map((item) => (
                                <li
                                  className={
                                    userSelected?.id === item?.id
                                      ? "user_item active"
                                      : "user_item"
                                  }
                                  key={item?.id}
                                  id={item?.id}
                                  // className="user_item active"
                                >
                                  <NavLink
                                    to={{
                                      pathname: `/details-messages-coordonnateur/${item?.id}`,
                                      state: { userItem: item },
                                    }}
                                    className="no-link"
                                  >
                                    <div className="d-flex bd-highlight">
                                      <div className="img_cont">
                                        <img
                                          src={
                                            item?.avatar &&
                                            !item?.avatar?.includes(
                                              "default.png"
                                            )
                                              ? baseUrl + item?.avatar
                                              : PhotoProfil
                                          }
                                          className="rounded-circle user_img"
                                          alt="Default"
                                        />
                                      </div>
                                      <div className="user_info">
                                        <span>
                                          {item?.prenom + " " + item?.nom}
                                        </span>
                                        <p>{item?.email}</p>
                                      </div>
                                    </div>
                                  </NavLink>
                                </li>
                              ))
                          ) : (
                            <div className="gfa-rowy mt-5">
                              <div className="alert alert-warning gfa-warning">
                                {t("noData")}
                              </div>
                            </div>
                          ))}
                      </ui>
                    </div>
                    <div className="card-footer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">Messages</h1>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <div className="messagerie">
                    <div className="h-100">
                      <div className="row justify-content-center h-100 bg-white">
                        <div className="col-xl-3 col-lg-3 col-md-4 chat mt-0">
                          <div className="card mb-sm-3 mb-md-0 contacts_card w-full">
                            <div className="card-body contacts_body">
                              <ui className="contacts">
                                {users?.isLoading && <ActivitiesSkeleton />}
                                {!users?.isLoading &&
                                  (users?.data?.filter(
                                    (u) => u?.id !== user?.data?.id
                                  )?.length ? (
                                    users?.data
                                      ?.filter((u) => u?.id !== user?.data?.id)
                                      ?.map((item) => (
                                        <li
                                          className={
                                            userSelected?.id === item?.id
                                              ? "user_item active"
                                              : "user_item"
                                          }
                                          key={item?.id}
                                          id={item?.id}
                                          onClick={() => handleSelect(item)}
                                        >
                                          <div className="d-flex bd-highlight">
                                            <div className="img_cont">
                                              <img
                                                // src={PhotoProfil}
                                                src={
                                                  item?.avatar &&
                                                  !item?.avatar?.includes(
                                                    "default.png"
                                                  )
                                                    ? baseUrl + item?.avatar
                                                    : PhotoProfil
                                                }
                                                className="rounded-circle user_img"
                                                alt="Default"
                                              />
                                            </div>
                                            <div className="user_info">
                                              <span>
                                                {item?.prenom + " " + item?.nom}
                                              </span>
                                              <p>{item?.email}</p>
                                            </div>
                                          </div>
                                        </li>
                                      ))
                                  ) : (
                                    <div className="gfa-rowy mt-5">
                                      <div className="alert alert-warning gfa-warning">
                                        {t("noData")}
                                      </div>
                                    </div>
                                  ))}
                              </ui>
                            </div>
                            <div className="card-footer"></div>
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 chat">
                          <div className="card w-full">
                            <div className="card-header msg_head">
                              {userSelected?.id ? (
                                <div className="d-flex bd-highlight">
                                  <div className="img_cont">
                                    <img
                                      //   src={PhotoProfil}
                                      src={
                                        userSelected?.avatar &&
                                        !userSelected?.avatar?.includes(
                                          "default.png"
                                        )
                                          ? baseUrl + userSelected?.avatar
                                          : PhotoProfil
                                      }
                                      className="rounded-circle user_img"
                                      alt="Default"
                                    />
                                  </div>
                                  <div className="user_info">
                                    <span>
                                      {t("chat_with")}{" "}
                                      {userSelected?.prenom +
                                        " " +
                                        userSelected?.nom}
                                    </span>
                                    <p>{messages?.length} Messages</p>
                                  </div>
                                </div>
                              ) : (
                                <Skeleton className="d-flex bd-highlight">
                                  <Skeleton className="img_cont mr-1">
                                    <Skeleton.Circle
                                      width={70}
                                      height={70}
                                      className="rounded-circle user_img"
                                    />
                                  </Skeleton>

                                  <Skeleton.Rectangle
                                    width={`70%`}
                                    height={70}
                                  />
                                </Skeleton>
                              )}

                              <span id="action_menu_btn">
                                <i className="fas fa-ellipsis-v"></i>
                              </span>
                              <div className="action_menu">
                                <ul>
                                  <li>
                                    <i className="fas fa-user-circle"></i> View
                                    profile
                                  </li>
                                  <li>
                                    <i className="fas fa-users"></i> Add to
                                    close friends
                                  </li>
                                  <li>
                                    <i className="fas fa-plus"></i> Add to group
                                  </li>
                                  <li>
                                    <i className="fas fa-ban"></i> Block
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="card-body msg_card_body">
                              {!userSelected?.id && <MessageSkeleton />}
                              {userSelected?.id &&
                                (messages?.length
                                  ? messages.map((msg) => (
                                      <Fragment key={msg?.id}>
                                        {msg?.sender?.id ===
                                        userSelected?.id ? (
                                          <div className="d-flex justify-content-start mb-4">
                                            <div className="img_cont_msg">
                                              <img
                                                // src={PhotoProfil}
                                                src={
                                                  userSelected?.avatar &&
                                                  !userSelected?.avatar?.includes(
                                                    "default.png"
                                                  )
                                                    ? baseUrl +
                                                      userSelected?.avatar
                                                    : PhotoProfil
                                                }
                                                className="rounded-circle user_img_msg"
                                                alt="Default"
                                              />
                                            </div>
                                            <div className="msg_cotainer">
                                              {msg?.message}
                                              <span className="msg_time">
                                                {/* 8:40 AM, Today */}
                                                {moment().calendar(
                                                  msg?.created_at
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="d-flex justify-content-end mb-4">
                                            <div className="msg_cotainer_send">
                                              {msg?.message}
                                              <span className="msg_time_send">
                                                {moment().calendar(
                                                  msg?.created_at
                                                )}
                                              </span>
                                            </div>
                                            <div className="img_cont_msg">
                                              <img
                                                src={
                                                  user?.data?.avatar &&
                                                  !user?.data?.avatar?.includes(
                                                    "default.png"
                                                  )
                                                    ? baseUrl +
                                                      user?.data?.avatar
                                                    : PhotoProfil
                                                }
                                                className="rounded-circle user_img_msg"
                                                alt="Default"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Fragment>
                                    ))
                                  : null)}
                            </div>
                            <div className="card-footer">
                              <form
                                className="input-group"
                                onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="input-group-append">
                                  <span className="input-group-text attach_btn">
                                    <i className="fas fa-paperclip"></i>
                                  </span>
                                </div>
                                <textarea
                                  name="message"
                                  {...register("message")}
                                  className="form-control type_msg"
                                  placeholder={t("type_message")}
                                ></textarea>
                                <div ty className="input-group-append">
                                  <button
                                    type="submit"
                                    className="input-group-text send_btn"
                                  >
                                    <i className="fas fa-location-arrow"></i>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
const MessageSkeleton = () => {
  return (
    <Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
    </Skeleton>
  );
};
const ActivitiesSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="d-flex">
        {/* <Skeleton.Circle width={40} height={40} className="mb-3" /> */}
        <Skeleton.Circle
          height={70}
          width={70}
          className="rounded-circle user_img mr-1"
        />
        {/* <Paragraph className="mb-3" /> */}
        <Skeleton.Rectangle height={70} width={`100%`} className="mb-3" />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
