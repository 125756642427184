/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import moment from "moment";
import React from "react";
import * as IconlyPack from "react-iconly";
import ImgPP from "../../../images/zoom.png";

function VoirLive({ live }) {
  return (
    <div className="modal-dialog modal-dialog-centered modal-md">
      <div className="modal-content">
        {/* <div className="modal-header py-3">
          <h5 className="modal-title text-titre" id="VoirFulbrighter"></h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}
        <div>
          <div className="modal-body py-4">
            <div class="d-flex mb-4 align-items-center">
              <img
                className="mr-3"
                src={ImgPP}
                alt="logo"
                // style={{ width: "50px", height: "50px", borderRadius: "100%" }}
              />
              <div>
                <h4>{live?.libelle}</h4>
                <span>{moment(live?.date).format("DD/MM/YYYY")}</span>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <IconlyPack.TimeCircle
                className="mr-3"
                set="bold"
                primaryColor="#DADADA"
              />
              <span>
                {moment(live?.start_time, "HH:mm:ss").format("hh:mm")} -{" "}
                {moment(live?.end_time, "HH:mm:ss").format("hh:mm")}
              </span>
            </div>
            <div class="d-flex align-items-center ">
              <IconlyPack.Video
                className="mr-3"
                set="bold"
                primaryColor="#DADADA"
              />
              <a
                href={live?.lien}
                target="_blank"
                onClick={() => window.$(`#VoirLive${live?.id}`).modal("hide")}
                className="text-consultation-agenda"
              >
                {live?.lien}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoirLive;
