/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchContacts } from "../../../redux/contact/contactAction";
import { putData } from "../../utils/fetchData";

function ChangeMessageStatus({ message }) {
  const { t } = useTranslation();
  let token = window.sessionStorage.getItem("userToken");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (message?.id) {
      register("status", {
        required: t("required"),
      });
      setValue("status", message?.status);
    }
  }, [message?.id]);

  const onSubmit = (data) => {
    setIsLoading(true);
    putData(`contact/${message?.id}/`, data, token)
      .then(async (response) => {
        console.log("response statut", response);

        await dispatch(fetchContacts());
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await window.$(`#ChangeMessageStatus${message?.id}`).modal("hide");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("status")}&nbsp; message
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {message?.status !== undefined && (
              <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="statut">{t("status")}</label>
                      <div className="filter-item-container">
                        <select
                          defaultValue={message?.status}
                          name="statut"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          onChange={(e) => setValue("status", e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" hidden>
                            {t("status")}
                          </option>
                          <option value="new">{t("new")}</option>
                          <option value="en_cours">{t("doing")}</option>
                          <option value="traité">{t("done")}</option>
                          {/* <option value="inactif">Inactif</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    {!isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        {t("edit")}
                      </button>
                    )}

                    {isLoading && (
                      <button
                        disabled
                        className="btn btn-primary crud-submit-btn"
                      >
                        {t("en_cours")} &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeMessageStatus;
