import axios from "axios";
import { post } from "./Endpoints";
// import axios from "axios";

export default {
  async onComment(data, config) {
    const response = await axios.post(post.onComment(), data, config);
    return response.data;
  },
  async onEditComment(id, data, config) {
    const response = await axios.put(post.onEditComment(id), data, config);
    // console.log(response)
    return response.data;
  },
  async onDeleteComment(id, config) {
    const response = await axios.delete(post.onDeleteComment(id), config);
    // console.log(response)
    return response.data;
  },
  async onLike(data, config) {
    const response = await axios.post(post.onLike(), data, config);
    return response.data;
  },
  async onDisLike(data, config) {
    const response = await axios.post(post.onDisLike(), data, config);
    return response.data;
  },
  async onAddPost(data, config) {
    const response = await axios.post(post.onAddPost(), data, config);
    return response.data;
  },
  async onEditPost(id, data, config) {
    const response = await axios.put(post.onEditPost(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeletePost(id, config) {
    const response = await axios.delete(post.onDeletePost(id), config);
    // console.log(response)
    return response.data;
  },
  async onDeleteLike(id, config) {
    const response = await axios.delete(post.onDeleteLike(id), config);
    // console.log(response)
    return response.data;
  },
  async onDeleteDisLike(id, config) {
    const response = await axios.delete(post.onDeleteDisLike(id), config);
    // console.log(response)
    return response.data;
  },
};
