import React, { useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { NavLink, useHistory, useParams } from "react-router-dom";
import FrontHeader from "../frontHeader/index";
import FrontFooter from "./index";
import Skeleton, { Paragraph } from "@nejcm/react-skeleton";
import { useDispatch, useSelector } from "react-redux";
// import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { fetchCgu } from "../../../redux/cgu/cguAction";
import { fetchMl } from "../../../redux/legalNotice/LegalNoticeAction";
import { useTranslation } from "react-i18next";
import HeaderLandingPage from "../../LandingPage/HeaderLandingPage";
import FooterLandingPage from "../../LandingPage/FooterLandingPage";

var MentionLegaleCgu = () => {
  const { t } = useTranslation();
  let token = window.sessionStorage.getItem("userToken");
  const cgus = useSelector((state) => state.cgus);
  const legal_notices = useSelector((state) => state.legal_notices);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { idTabs } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCgu());
    dispatch(fetchMl());
  }, []);

  return (
    <div className="component-front-home">
      {token ? <FrontHeader /> : <HeaderLandingPage />}
      {/* <EmptyHeader /> */}

      <div className="content-view-start content-start-page-cgu">
        <div className="container-warc" id="nav-annuaire-mentionlegale">
          <ul
            className="nav nav-pills mb-3 align-items-center"
            id="pills-tab"
            role="tablist"
          >
            {!token && (
              <li>
                <div>
                  <NavLink to="/" className="no-link">
                    <IconlyPack.CaretLeft set="bold" primaryColor="#000" />
                  </NavLink>
                </div>
              </li>
            )}

            <li className="nav-item" role="presentation">
              <a
                className={`nav-link nav-tabs-annuaire nav-tabs-page-content ml-0 ${
                  idTabs === "pills-cgu" ? "active" : ""
                }`}
                id="pills-cgu-tab"
                data-toggle="pill"
                href="#pills-cgu"
                onClick={() => {
                  history.push("/app/page-content/pills-cgu");
                }}
                role="tab"
                aria-controls="pills-cgu"
                aria-selected={idTabs === "pills-cgu" ? true : false}
              >
                {t("cgu")}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                // className="nav-link nav-tabs-annuaire"
                className={`nav-link nav-tabs-annuaire  nav-tabs-page-content ${
                  idTabs === "pills-mention-legale" ? " active" : ""
                }`}
                id="pills-mention-legale-tab"
                data-toggle="pill"
                href="#pills-mention-legale"
                onClick={() => {
                  // setPays("");
                  // setStartDate("");
                  // setSearchTerm("");
                  // setVal("");
                  // setItemToShow(12);
                  // setExpand(true);
                  history.push("/app/page-content/pills-mention-legale");
                }}
                role="tab"
                aria-controls="pills-mention-legale"
                aria-selected="false"
              >
                {t("legal_notice")}
              </a>
            </li>
          </ul>
        </div>

        <div className="container-warc py-5 mt-5">
          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade ${
                idTabs === "pills-cgu" ? " show active" : ""
              }`}
              id="pills-cgu"
              role="tabpanel"
              aria-labelledby="pills-cgu-tab"
            >
              <div className="bg-white p-4 mb-5">
                <h5 className="sous-titre-page-content-front pb-3">
                  {t("cgu")}
                </h5>
                {/* <p className="text-version-page-content-front">
                  Dernière version: 13/01/2022{" "}
                  <span className="text-color-page-content-front">
                    (Cliquez ici pour voir la version précédente)
                  </span>
                </p> */}
                {cgus?.isLoading && <ParagraphSkeleton />}
                {!cgus?.isLoading &&
                  (cgus?.data?.length ? (
                    <div
                      className="text-page-content-front pt-2"
                      dangerouslySetInnerHTML={{
                        __html: cgus?.data[0]?.text,
                      }}
                    />
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                idTabs === "pills-mention-legale" ? " show active" : ""
              }`}
              id="pills-mention-legale"
              role="tabpanel"
              aria-labelledby="pills-mention-legale-tab"
            >
              <div className="bg-white p-4 mb-5">
                <h5 className="sous-titre-page-content-front pb-3">
                  {t("legal_notice")}
                </h5>
                {legal_notices?.isLoading && <ParagraphSkeleton />}
                {!legal_notices?.isLoading &&
                  (legal_notices?.data?.length ? (
                    <div
                      className="text-page-content-front pt-2"
                      dangerouslySetInnerHTML={{
                        __html: legal_notices?.data[0]?.text,
                      }}
                    />
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {token ? <FrontFooter /> : <FooterLandingPage />}
    </div>
  );
};

export default MentionLegaleCgu;

const ParagraphSkeleton = () => {
  const renderModules = Array(5)
    .fill()
    .map((item, index) => (
      <Skeleton key={index}>
        <Paragraph className="mb-3" />
        <Paragraph className="mb-3" />
        <Paragraph className="mb-3" />
        <Paragraph className="mb-3" />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};