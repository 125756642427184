import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Article from "../../../../../services/Article";
import { fetchArticles } from "../../../../../redux/articles/ArticlesAction";
import moment from "moment";
import { useTranslation } from "react-i18next";

function UseAddBlogForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        titre: yup.string().required(t("required")),
        category: yup.array().required(t("required")),
        image: yup.mixed(),
        contenu: yup.string().required(t("required")),
        author: yup.string(),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    let fd = new FormData();
    fd.append("titre", formValues?.titre);
    fd.append("contenu", formValues?.contenu);
    if (formValues?.image) {
      fd.append("image", formValues?.image);
    }
    for (var i = 0; i < formValues?.category.length; i++) {
      fd.append("category", formValues?.category[i]);
    }
    fd.append("author", user?.data?.id);
    fd.append("publiee", true);
    fd.append("date_publication", moment(new Date()).format("YYYY-MM-DD"));
    console.log("data", formValues, user);
    // formValues["password"] = "passerty";
    var request = Article.onAddArticle(fd, config);

    await request
      .then(async (resp) => {
        await dispatch(fetchArticles());
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        if (user?.data?.user_type === "coordo") {
          await history.push("/blog-coordonnateur");
        }

        if (user?.data?.user_type === "admin") {
          await history.push("/blog-admin");
        }

        await setIsDone(true);
        await e.target.reset();
        await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default UseAddBlogForm;
