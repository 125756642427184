import React, { useState, useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import Header from "../../../Navs/Header";
import "./ParametresAdmin.css";
import * as IconlyPack from "react-iconly";
import AvatarCoordonnateur from "../../../../assets/images/Avatars.png";
import { NavLink } from "react-router-dom";
import ListeAdminTable from "../../../Tables/Admin/ListeAdminTable/ListeAdminTable";
import AddContentPageModal from "../../../Modals/Admin/AddContentPageModal";
import AddContentMentionLegale from "../../../Modals/Admin/AddContentMentionLegaleModal";
import AddCoordoModal from "../../../Modals/Admin/AddCoordoModal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ListSubAdminsTable from "../../../Tables/Admin/ListSubAdminsTable/ListSubAdminsTable";
import { fetchAdmins } from "../../../../redux/admins/adminsAction";
import { fetchUsers } from "../../../../redux/Users/UsersAction";
import AddSubAdminModal from "../../../Modals/Admin/AddSubAdminModal";
import Personnalisation from "./Personnalisation";

var ParametresAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const conditions = useSelector((state) => state.conditions);
  const [type, setType] = useState("");
  const [item, setItem] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchAdmins());
    dispatch(fetchUsers());
  }, []);

  // var [isPageLoading, setIsPageLoading] = React.useState(true);

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header"
        >
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="position-relative">
                <div className="page-title-container">
                  <h1 className="page-title">{t("setting")}</h1>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <ul
                    className="nav nav-tabs navtabs-fulbrighter"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active navlink-fulbrighter"
                        id="gestion-coord-tab"
                        data-toggle="tab"
                        href="#gestion-coord"
                        role="tab"
                        aria-controls="gestion-coord"
                        aria-selected="true"
                      >
                        {t("manag_coord")}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link navlink-fulbrighter"
                        id="gestion-admin-tab"
                        data-toggle="tab"
                        href="#gestion-admin"
                        role="tab"
                        aria-controls="gestion-admin"
                        aria-selected="false"
                      >
                        {t("manag_admin")}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link navlink-fulbrighter"
                        id="cgu-tab"
                        data-toggle="tab"
                        href="#cgu"
                        role="tab"
                        aria-controls="cgu"
                        aria-selected="false"
                      >
                        {t("cgu")}{" "}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link navlink-fulbrighter"
                        id="mention-legale-tab"
                        data-toggle="tab"
                        href="#mention-legale"
                        role="tab"
                        aria-controls="mention-legale"
                        aria-selected="false"
                      >
                        {t("legal_notice")}{" "}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link navlink-fulbrighter"
                        id="personalization-tab"
                        data-toggle="tab"
                        href="#personalization"
                        role="tab"
                        aria-controls="personalization"
                        aria-selected="false"
                      >
                        {t("personalization")}{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-5" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="gestion-coord"
                      role="tabpanel"
                      aria-labelledby="gestion-coord-tab"
                    >
                      <ListeAdminTable />
                      <div>
                        <div className="d-flex justify-content-end btn-add-content-position">
                          <button
                            className="btn btn-send-message"
                            data-toggle="modal"
                            data-target="#AjouterAdminModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#003DA5"
                              />
                            </span>
                            <span className="adding-item-label pl-2">
                              {t("add_coord")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade "
                      id="gestion-admin"
                      role="tabpanel"
                      aria-labelledby="gestion-admin-tab"
                    >
                      <ListSubAdminsTable />
                      <div>
                        <div className="d-flex justify-content-end btn-add-content-position">
                          <button
                            className="btn btn-send-message"
                            data-toggle="modal"
                            data-target="#AjouterSubAdminModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#003DA5"
                              />
                            </span>
                            <span className="adding-item-label pl-2">
                              {t("add_admin")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="cgu"
                      role="tabpanel"
                      aria-labelledby="cgu-tab"
                    >
                      <div>
                        <div className="d-flex justify-content-end btn-add-content-position">
                          {conditions?.data?.length &&
                          conditions?.data?.filter((c) => c?.type === "cgu")
                            ?.length ? null : (
                            <button
                              className="btn btn-send-message"
                              data-toggle="modal"
                              data-target={`#AddContentPage${type}`}
                              onClick={() => setType("cgu")}
                            >
                              <span className="icon-container">
                                <IconlyPack.Plus
                                  set="light"
                                  primaryColor="#003DA5"
                                />
                              </span>
                              <span className="adding-item-label pl-2">
                                {t("add_content")}
                              </span>
                            </button>
                          )}
                        </div>
                        <div className="pr-5">
                          <div className="d-flex justify-content-end py-4">
                            {conditions?.data?.length &&
                            conditions?.data?.filter((c) => c?.type === "cgu")
                              ?.length ? (
                              <button
                                className="btn-edit-page-setting btn"
                                type="button"
                                data-toggle="modal"
                                data-target={`#EditContentPage${item?.id}`}
                                onClick={() =>
                                  setItem(
                                    conditions?.data?.filter(
                                      (c) => c?.type === "cgu"
                                    )[
                                      conditions?.data?.filter(
                                        (c) => c?.type === "cgu"
                                      )?.length - 1
                                    ]
                                  )
                                }
                              >
                                <IconlyPack.Edit
                                  set="bold"
                                  primaryColor="#003DA5"
                                />
                                <span className="pl-2">{t("edit")}</span>
                              </button>
                            ) : null}
                          </div>
                          {!conditions?.isLoading &&
                            (conditions?.data?.length &&
                            conditions?.data?.filter((c) => c?.type === "cgu")
                              ?.length ? (
                              <div
                                className="texte-page-cgu"
                                dangerouslySetInnerHTML={{
                                  __html: conditions?.data?.filter(
                                    (c) => c?.type === "cgu"
                                  )[
                                    conditions?.data?.filter(
                                      (c) => c?.type === "cgu"
                                    )?.length - 1
                                  ]?.text,
                                }}
                              />
                            ) : (
                              <div className="gfa-rowy mt-5">
                                <div className="alert alert-warning gfa-warning">
                                  {t("noData")}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="mention-legale"
                      role="tabpanel"
                      aria-labelledby="mention-legale-tab"
                    >
                      <div>
                        <div className="d-flex justify-content-end btn-add-content-position">
                          {conditions?.data?.length &&
                          conditions?.data?.filter((c) => c?.type === "ml")
                            ?.length ? null : (
                            <button
                              className="btn btn-send-message"
                              data-toggle="modal"
                              data-target={`#AddContentPage${type}`}
                              onClick={() => setType("ml")}
                            >
                              <span className="icon-container">
                                <IconlyPack.Plus
                                  set="light"
                                  primaryColor="#003DA5"
                                />
                              </span>
                              <span className="adding-item-label pl-2">
                                {t("add_content")}
                              </span>
                            </button>
                          )}
                        </div>
                        <div className="pr-5">
                          <div className="d-flex justify-content-end py-4">
                            {conditions?.data?.length &&
                            conditions?.data?.filter((c) => c?.type === "ml")
                              ?.length ? (
                              <button
                                className="btn-edit-page-setting btn"
                                type="button"
                                data-toggle="modal"
                                data-target={`#EditContentPage${item?.id}`}
                                onClick={() =>
                                  setItem(
                                    conditions?.data?.filter(
                                      (c) => c?.type === "ml"
                                    )[
                                      conditions?.data?.filter(
                                        (c) => c?.type === "ml"
                                      )?.length - 1
                                    ]
                                  )
                                }
                              >
                                <IconlyPack.Edit
                                  set="bold"
                                  primaryColor="#003DA5"
                                />
                                <span className="pl-2">{t("edit")}</span>
                              </button>
                            ) : null}
                            {/* <button
                              className="btn-edit-page-setting btn"
                              type="button"
                              data-toggle="modal"
                              data-target="#EditMentionLegale"
                            >
                              <IconlyPack.Edit
                                set="bold"
                                primaryColor="#003DA5"
                              />
                              <span className="pl-2">EDIT</span>
                            </button> */}
                          </div>
                          {!conditions?.isLoading &&
                            (conditions?.data?.length &&
                            conditions?.data?.filter((c) => c?.type === "ml")
                              ?.length ? (
                              <div
                                className="texte-page-cgu"
                                dangerouslySetInnerHTML={{
                                  __html: conditions?.data?.filter(
                                    (c) => c?.type === "ml"
                                  )[
                                    conditions?.data?.filter(
                                      (c) => c?.type === "ml"
                                    )?.length - 1
                                  ]?.text,
                                }}
                              />
                            ) : (
                              <div className="gfa-rowy mt-5">
                                <div className="alert alert-warning gfa-warning">
                                  {t("noData")}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="personalization"
                      role="tabpanel"
                      aria-labelledby="personalization-tab"
                    >
                      <Personnalisation />
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={`AddContentPage${type}`}
                    tabIndex="-1"
                    aria-labelledby="AddContentPageLabel"
                    aria-hidden="true"
                  >
                    <AddContentPageModal type={type} />
                  </div>
                  <div
                    className="modal fade"
                    id={`EditContentPage${item?.id}`}
                    tabIndex="-1"
                    aria-labelledby="EditContentPageLabel"
                    aria-hidden="true"
                  >
                    <AddContentMentionLegale setItem={setItem} item={item} />
                  </div>
                  <div
                    className="modal fade"
                    id="AjouterAdminModal"
                    tabIndex="-1"
                    aria-labelledby="AjouterAdminModalLabel"
                    aria-hidden="true"
                  >
                    <AddCoordoModal />
                  </div>
                  <div
                    className="modal fade"
                    id="AjouterSubAdminModal"
                    tabIndex="-1"
                    aria-labelledby="AjouterSubAdminModalLabel"
                    aria-hidden="true"
                  >
                    <AddSubAdminModal />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametresAdmin;
