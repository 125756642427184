import React, { useEffect } from "react";
import "./MonProfil.css";
import * as IconlyPack from "react-iconly";

import FrontHeader from "../frontHeader/index";
import FrontFooter from "../frontFooter/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import Avatar from "../../../images/others/default-profile-avatar.png";
import EditAvatar from "./EditAvatar";
import EditPassword from "./EditPassword";
import EditProfil from "./EditProfil";
import { useSelector } from "react-redux";
import baseUrl from "../../../Http/backend-base-url";
import { useTranslation } from "react-i18next";

var MonProfil = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const devise = useSelector((state) => state.devise);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const IsJsonString = (str) => {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="bloc-banner2 position-relative">
              <div className="py-3 text-center">
                <img
                  src={
                    !user?.isLoading
                      ? user?.data?.avatar &&
                        !user?.data?.avatar?.includes("default.png")
                        ? baseUrl + user?.data?.avatar
                        : Avatar
                      : Avatar
                  }
                  alt="Avatar"
                  className="Avatar-profil"
                />
              </div>
              <div className="icon-edit-avatar">
                <button
                  className="btn"
                  data-toggle="modal"
                  data-target="#editAvatar"
                  type="button"
                >
                  <IconlyPack.Edit set="light" primaryColor="#000" />
                </button>
              </div>
            </div>
            <div className="container-warc">
              <div className="bg-white p-3">
                <div className="text-right">
                  <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#editProfil"
                  >
                    <IconlyPack.Edit set="light" primaryColor="#000" />
                  </button>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("firstName")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">{user?.data?.prenom}</p>
                  </div>
                </div>

                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("lastName")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">{user?.data?.nom}</p>
                  </div>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("phone")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">
                      {user?.data?.phone || t("not_specified")}
                    </p>
                  </div>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("email")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">{user?.data?.email}</p>
                  </div>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("address")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">
                      {user?.data?.adresse || t("not_specified")}
                    </p>
                  </div>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("country")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">
                      {user?.data?.pays || t("not_specified")}
                    </p>
                  </div>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("cohort_year")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">
                      {user?.data?.annee_cohorte || t("not_specified")}
                    </p>
                  </div>
                </div>

                <div className="identifiant-user">
                  <div className="mr-2">
                    <p className="profil-user-name">{t("bio")}</p>
                  </div>
                  <div>
                    <p
                      className="profil-user-value"
                      dangerouslySetInnerHTML={{
                        __html: user?.data?.biographie,
                      }}
                    />
                  </div>
                </div>
                <div className="identifiant-user">
                  <div>
                    <p className="profil-user-name">{t("status")}</p>
                  </div>
                  <div>
                    <p className="profil-user-value">{user?.data?.statut}</p>
                  </div>
                </div>
              </div>
              <div className="identifiant-user my-4 bg-white p-3">
                <div>
                  <p className="profil-user-name">{t("password")}</p>
                </div>
                <div>
                  <button
                    className="btn btn-change-password"
                    data-toggle="modal"
                    data-target="#editPassword"
                  >
                    {t("edit_password")}
                  </button>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="editAvatar"
              tabIndex="-1"
              aria-labelledby="editAvatarLabel"
              aria-hidden="true"
            >
              <EditAvatar />
            </div>
            <div
              className="modal fade"
              id="editProfil"
              tabIndex="-1"
              aria-labelledby="editProfilLabel"
              aria-hidden="true"
            >
              <EditProfil />
            </div>
            <div
              className="modal fade"
              id="editPassword"
              tabIndex="-1"
              aria-labelledby="editPasswordLabel"
              aria-hidden="true"
            >
              <EditPassword />
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="bloc-banner2 position-relative">
          <div className="py-4 text-center">
            <p className="titre-profil">{t("my_profil")}</p>
          </div>
        </div>
        <div className="container-warc py-5 mt-5">
          <div className="bg-white my-4 p-4 avatar-container">
            <div className="text-center">
              <img
                src={
                  !user?.isLoading
                    ? user?.data?.avatar &&
                      !user?.data?.avatar?.includes("default.png")
                      ? baseUrl + user?.data?.avatar
                      : Avatar
                    : Avatar
                }
                alt="Avatar"
                className="Avatar-profil"
              />
            </div>
            <div className="icon-edit-avatar">
              <button
                className="btn btn-edit-avatar-desktop"
                data-toggle="modal"
                data-target="#editAvatar"
                type="button"
              >
                <IconlyPack.Edit set="light" />
                <span className="pl-2">{t("edit")}</span>{" "}
              </button>
            </div>
          </div>
          <div className="bg-white p-3">
            <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("firstName")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">{user?.data?.prenom}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
            {/* <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">Fist name</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">ABDOU</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >{t("edit")}
                </button>
              </div>
            </div> */}
            <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("lastName")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">{user?.data?.nom}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
            <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("phone")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">
                  {user?.data?.phone || t("not_specified")}
                </p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
            <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("address")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">
                  {user?.data?.adresse || t("not_specified")}
                </p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
            <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("email")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">{user?.data?.email}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
            <div className="row border-bottom align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("country")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">
                  {user?.data?.pays || t("not_specified")}
                </p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
            {user?.data?.user_type === "fulbrighter" ? (
              <>
                <div className="row border-bottom  align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-name">{t("cohort_year")}</p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <p className="profil-user-value">
                      {user?.data?.annee_cohorte}
                    </p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#editProfil"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>

                <div className="row border-bottom align-items-center">
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    <p className="profil-user-name">{t("bio")}</p>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 d-flex justify-content-center">
                    <p
                      className="profil-user-value"
                      dangerouslySetInnerHTML={{
                        __html: user?.data?.biographie,
                      }}
                    />
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 d-flex justify-content-end">
                    <button
                      className="btn btn-edit-profil-identifiant"
                      data-toggle="modal"
                      data-target="#editProfil"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-name">{t("status")}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <p className="profil-user-value">{user?.data?.statut}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                <button
                  className="btn btn-edit-profil-identifiant"
                  data-toggle="modal"
                  data-target="#editProfil"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4 bg-white p-3 div-change-password-profil">
            <div className="pb-3">
              <p className="profil-user-name">{t("password")}</p>
            </div>
            <div className="">
              <button
                className="btn btn-change-password"
                data-toggle="modal"
                data-target="#editPassword"
              >
                {t("edit_password")}
              </button>
            </div>
            <div
              className="modal fade"
              id="editAvatar"
              tabIndex="-1"
              data-backdrop="static"
              aria-labelledby="editAvatarLabel"
              aria-hidden="true"
            >
              <EditAvatar />
            </div>
            <div
              className="modal fade"
              id="editProfil"
              data-backdrop="static"
              tabIndex="-1"
              aria-labelledby="editProfilLabel"
              aria-hidden="true"
            >
              <EditProfil />
            </div>
            <div
              className="modal fade"
              id="editPassword"
              data-backdrop="static"
              tabIndex="-1"
              aria-labelledby="editPasswordLabel"
              aria-hidden="true"
            >
              <EditPassword />
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default MonProfil;

const Tag = (props) => <span className="tag" {...props}></span>;
