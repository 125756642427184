import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import userAvatar from "../../images/others/default-profile-avatar.png";
// import NavBarAdminMobile from "../Backend/NavBarAdminMobile/NavBarAdminMobile";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../images/logo.png";
import NavbarMobileV2 from "../Dashboard/navbarMobileV2/navbarMobileV2";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifUser } from "../../redux/notifications/notificationAction";
import moment from "moment";
import { useTranslation } from "react-i18next";
import axios from "axios";
import api from "../../Http/global-vars";
import LanguageSelect from "../../languageSelect";

var Header = () => {
  const { t } = useTranslation();
  var [showNotifications, setShowNotifications] = React.useState(false);
  let userType = window.sessionStorage.getItem("userType");
  const user = useSelector((state) => state.user);
  const userNotifications = useSelector((state) => state.userNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.isLoading) {
      dispatch(fetchNotifUser(user.data?.id));
    }
  }, [dispatch, user]);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onLogout = (e) => {
    e.preventDefault();
    window.sessionStorage.clear();
    window.location = "/app/connexion";
  };

  const handleRead = (id) => {
    axios
      .put(`${api}notification/${id}/`, { read: true }, config)
      .then((response) => {
        dispatch(fetchNotifUser(user.data?.id));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering">
          <div className="logo-item">
            <div className="logo-container dashboard-header-logo">
              <img src={Logo} className="logo-img" alt="WARC" />
            </div>
          </div>

          <div className="header-others-items">
            <div className="language-select">
              <div className="language-selector-mobile">
                <LanguageSelect />
              </div>
            </div>
            <div
              className="notification-item-container linkable"
              onClick={() => onShowAndHideNotification()}
            >
              <div className="notification-item-content">
                <IconlyPack.Notification set="light" primaryColor="black" />
                {userNotifications?.data?.length ? (
                  <span className="notification-counter"></span>
                ) : (
                  <span className=""></span>
                )}{" "}
              </div>
            </div>
            {userType === "admin" ? (
              <div className="notification-item-container linkable">
                <Link
                  className="notification-item-content"
                  to="/parametres-admin"
                >
                  <IconlyPack.Setting set="light" primaryColor="black" />
                </Link>
              </div>
            ) : null}

            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display">
                      <img
                        src={userAvatar}
                        alt="user-avatar"
                        height="50"
                        width="50"
                      />
                      <FeatherIcon
                        className="icon-user-acount-dashboard"
                        icon="chevron-down"
                      />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item item-dropdown-custom"
                      onClick={onLogout}
                    >
                      {" "}
                      {t("logout")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenable-nav-mobile-container no-view-desktop">
          {/* Navbar Mobile */}
          <div className="hiddenable-nav-mobile-content" id="menu-mobile-admin">
            <NavbarMobileV2 />
          </div>
        </div>

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <div className="header-hiddenable-notification-title">
              Notifications
            </div>
            <ul className="header-hiddenable-notification-ul">
              {!userNotifications?.isLoading &&
                (userNotifications?.data?.length ? (
                  userNotifications?.data?.map((notif) => (
                    <li
                      className="header-hiddenable-notification-li"
                      key={notif?.id}
                    >
                      <NavLink
                        to={
                          notif?.notif_type === "inform_admin"
                            ? {
                                pathname: `/detail-fulbriters-admin/${notif?.data?.id}`,
                                state: { userItem: notif?.sender },
                              }
                            : notif?.notif_type === "inform"
                            ? {
                                pathname: "/reports-admin",
                                state: { report: notif?.data },
                              }
                            : notif?.notif_type === "inform_incident"
                            ? {
                                pathname: "/incidents&feedbacks-admin",
                                state: { incident: notif?.data },
                              }
                            : "#"
                        }
                        onClick={() => handleRead(notif?.id)}
                        className="notification-item-link"
                        key={notif?.id}
                      >
                        <p>{notif?.message}</p>
                        <span>
                          {moment(notif?.created_at).format("DD/MM/YYYY")}
                        </span>
                      </NavLink>
                    </li>
                  ))
                ) : (
                  <li className="header-hiddenable-notification-li">
                    <div className="notification-item-link">
                      <p>{t("noData")}</p>
                    </div>
                  </li>
                ))}
              {/* <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>hjjnn</p>
                  <span>12/02/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>jknkjkj</p>
                  <span>12/02/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>jkjjnlza</p>
                  <span>28/12/2021</span>
                </NavLink>
              </li> */}
            </ul>
            {/* <p
              onClick={() => showProjetSoumis()}
              className="text-md text-center font-weight-bold"
              style={{ cursor: "pointer" }}
            >
              Voir tout
            </p> */}
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
