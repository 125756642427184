import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Post from "../../../../../services/Post";
import { fetchPostes } from "../../../../../redux/Posts/PostAction";
import { useTranslation } from "react-i18next";

function useAddComment() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const posts = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required(t("required")),
        post: yup.string(),
        user: yup.number(),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmitForm = async (formValues, e) => {
    let userType = window.sessionStorage.getItem("userType");
    setIsSuccessfullySubmitted(true);
    formValues["user"] = parseInt(user?.data?.id);
    console.log("data", formValues);
    let postId = parseInt(formValues?.post);
    var request = Post.onComment(formValues, config);

    await request
      .then(async (resp) => {
        await dispatch(fetchPostes());
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$("#addCommentModal").modal("hide");
        await window.$("#AjouterCommentModal").modal("hide");
        if (userType === "coordo") {
          history.push({
            pathname: "/detail-discussion-forum",
            state: {
              post: posts?.data?.filter((p) => parseInt(p?.id) === postId)[0],
            },
          });
        } else {
          history.push({
            pathname: "/admin-detail-discussion-forum",
            state: {
              post: posts?.data?.filter((p) => parseInt(p?.id) === postId)[0],
            },
          });
        }

        await setIsDone(true);
        await e.target.reset();
        await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default useAddComment;
