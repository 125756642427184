import React from 'react';
import './frontFooter.css';
import { NavLink } from 'react-router-dom';
import NousContacter from '../../Modals/Frontend/NousContacter';
import { useTranslation } from "react-i18next";

var FrontFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="component-front-footer">
      <div className="container-warc">
        <div className="body-footer">
          <div className="div-footer-warc mx-0  social-networking">
            <div>
              <NavLink
                to="/app/page-content/pills-mention-legale"
                className="text-footer"
              >
                {t("legal_notice")}
              </NavLink>
            </div>
            <div>
              <NavLink to="/app/page-content/pills-cgu" className="text-footer">
                {t("cgu")}
              </NavLink>
            </div>
            <div>
              <p className="copyright">©2021 All Rights Reserved. Warc, Inc.</p>
            </div>
          </div>
         
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;