import axios from "axios";
import { faq } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddFaq(data, config) {
    const response = await axios.post(faq.onPostFaq(), data, config);
    return response.data;
  },
  async onEditFaq(id, data, config) {
    const response = await axios.put(faq.onEditFaq(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteFaq(id, config) {
    const response = await axios.delete(faq.onDeleteFaq(id), config);
    // console.log(response)
    return response.data;
  },
};
