import axios from "axios";
import { document } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddDocument(data, config) {
    const response = await axios.post(document.onPostDocument(), data, config);
    return response.data;
  },
  async onEditDocument(id, data, config) {
    const response = await axios.put(document.onEditDocument(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteDocument(id, config) {
    const response = await axios.delete(document.onDeleteDocument(id), config);
    // console.log(response)
    return response.data;
  },
};
