import { DEVISE } from "../types";

export const deviseReducer = (state = false, action = false) => {
  switch (action.type) {
    case DEVISE:
      return action.payload;
    default:
      return state;
  }
};
