import React, { useState, useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./Forum.css";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImgProfilForum from "../../../../images/others/default-profile-avatar.png";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import baseUrl from "../../../../Http/backend-base-url";
import Skeleton from "react-loading-skeleton";
import Post from "../../../../services/Post";
import { fetchPostes } from "../../../../redux/Posts/PostAction";
import Swal from "sweetalert2";
import AjouterPostModal from "../../../Modals/Coordonnateur/AjouterPostModal";
import Header from "../../../Navs/Header";
import PaginationComponent from "../../../Modules/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import ModifierPostModal from "../../../Modals/Coordonnateur/ModifierPostModal";

var Forum = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const posts = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const [per_page] = useState(8);

  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [selectIndex, setSelectIndex] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (posts?.data?.length) {
      formatData(posts?.data);
    }
  }, [posts, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
    console.log("indexs", indexOfFirstPage, indexOfLastPost, current_page);
    setLastIndex(indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };

  useEffect(() => {
    if (location?.state?.post) {
      let post = posts?.data?.filter(
        (p) => p?.id === location?.state?.post?.id
      )[0];
      history.push({
        pathname: "/detail-discussion-forum",
        state: {
          post: post,
        },
      });
      // console.log(post, "post");
    }
  }, [location]);


  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onDelete = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_post"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Post.onDeletePost(item?.id, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchPostes());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const onPublish = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: !item?.publiee ? t("message_publish") : t("message_unpublish"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Post.onEditPost(item?.id, { publiee: !item?.publiee }, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchPostes());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Forum</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjouterPostModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="#003DA5" />
                    </span>
                    <span className="adding-item-label">{t("add_post")}</span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="py-5">
                    {posts?.isLoading && <ForumSkeleton />}
                    {!posts?.isLoading &&
                      (currentList?.length ? (
                        currentList.map((post, index) => (
                          <div
                            className="row mb-3 shadow-category align-items-center"
                            key={post?.id}
                          >
                            <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                              <div className="">
                                <div className="bloc-category-inline">
                                  <div className=" bloc-liste">
                                    <NavLink
                                      to={{
                                        pathname: "detail-discussion-forum",
                                        state: { post: post },
                                      }}
                                      className="no-link"
                                    >
                                      <span className="titre-category-prestation">
                                        {post?.titre}
                                      </span>
                                    </NavLink>
                                    <div className="date-faq mt-2">
                                      {t("le")}{" "}
                                      {moment(post?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 pb-3">
                              <div className="bloc-information-forum">
                                <div>
                                  <span className="consultation-number">
                                    {post?.comments?.length}
                                  </span>
                                  <div>
                                    <span className="consultation-name">
                                      Messages
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span className="consultation-number">
                                    {post?.likes?.length}
                                  </span>
                                  <div>
                                    <span className="consultation-name">
                                      {t("likes")}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span className="consultation-number">
                                    {post?.dislikes?.length}
                                  </span>
                                  <div>
                                    <span className="consultation-name">
                                      {t("dislikes")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 pb-3 d-flex align-items-center justify-content-end content-img-auteur-post-forum">
                              <img
                                src={
                                  post?.created_by?.avatar &&
                                  !post?.created_by?.avatar?.includes(
                                    "default.png"
                                  )
                                    ? baseUrl + post?.created_by?.avatar
                                    : ImgProfilForum
                                }
                                alt="Profile Forum"
                                className="img-profil"
                              />
                              <div>
                                <span className="consultation-name pl-3">
                                  {t("par")}
                                </span>
                                <span className="name-auteur-forum pl-3">
                                  {post?.created_by?.prenom +
                                    " " +
                                    post?.created_by?.nom}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 pb-3 d-flex justify-content-end">
                              <div>
                                <span
                                  className="bloc-icon m-2"
                                  title={t("edit")}
                                  onClick={() => setSelectIndex(index)}
                                >
                                  <FeatherIcon
                                    className="linkable"
                                    icon="edit"
                                    data-toggle="modal"
                                    data-target={`#ModifierPostModal${post?.id}`}
                                  />
                                </span>
                              </div>
                              <div>
                                {!post?.publiee ? (
                                  <span
                                    className="bloc-icon m-2"
                                    title={t("publish")}
                                  >
                                    <FeatherIcon
                                      className="linkable"
                                      icon="check-square"
                                      onClick={() => onPublish(post)}
                                    />
                                  </span>
                                ) : (
                                  <span
                                    className="bloc-icon m-2"
                                    title={t("unpublish")}
                                  >
                                    <FeatherIcon
                                      className="linkable"
                                      icon="x-square"
                                      onClick={() => onPublish(post)}
                                    />
                                  </span>
                                )}
                              </div>
                              <div>
                                <span
                                  className="bloc-icon m-2"
                                  title={t("delete")}
                                >
                                  <FeatherIcon
                                    className="linkable"
                                    icon="trash-2"
                                    onClick={() => onDelete(post)}
                                  />
                                </span>
                              </div>
                            </div>
                            <div
                              className="modal fade"
                              id={`ModifierPostModal${post?.id}`}
                              tabIndex="-1"
                              data-backdrop="static"
                              aria-labelledby="ModifierPostModalLabel"
                              aria-hidden="true"
                            >
                              <ModifierPostModal
                                selectIndex={selectIndex}
                                post={post}
                                index={index}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {currentList?.length ? (
                  <PaginationComponent
                    per_page={per_page}
                    current_page={current_page}
                    total_data={total_data}
                    handleClick={handleClick}
                    lastIndex={lastIndex}
                  />
                ) : null}

                <div
                  className="modal fade"
                  id="AjouterPostModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterPostModalLabel"
                  aria-hidden="true"
                >
                  <AjouterPostModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forum;

const ForumSkeleton = () => {
  return (
    <section>
      {Array(4)
        .fill()
        .map((item, index) => (
          <div
            className="row mb-3 shadow-category align-items-center"
            key={index}
          >
            <div className=" col-xl-4 col-lg-4 col-md-4">
              <div className="bloc-category-inline">
                <div className=" bloc-liste">
                  <div className="no-link">
                    <span className="titre-category-prestation">
                      <Skeleton height={36} width={250} />
                    </span>
                  </div>
                  <div className="date-faq mt-2">
                    <Skeleton width={`80%`} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 d-flex">
              <div className="bloc-information-forum">
                <div>
                  <span className="mr-1 consultation-number">
                    <Skeleton width={100} />
                  </span>
                  <div>
                    <span className="mr-1 consultation-name">
                      <Skeleton width={100} />
                    </span>
                  </div>
                </div>
                <div>
                  <span className="mr-1 consultation-number">
                    <Skeleton width={100} />
                  </span>
                  <div>
                    <span className="mr-1 consultation-name">
                      <Skeleton width={100} />
                    </span>
                  </div>
                </div>
                <div>
                  <span className="mr-1 consultation-number">
                    <Skeleton width={100} />
                  </span>
                  <div>
                    <span className="mr-1 consultation-name">
                      <Skeleton width={100} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 d-flex align-items-center justify-content-end">
              <Skeleton circle={true} height={50} width={50} />
              &nbsp;&nbsp;
              <p className="text-forum-card fs-12 fw-300">
                <Skeleton width={`90`} />
              </p>
              &nbsp;&nbsp;
              <strong>
                <Skeleton duration={1} height={30} width={90} />
              </strong>
            </div>
          </div>
        ))}
    </section>
  );
};
