import React, { useMemo } from "react";
import * as IconlyPack from "react-iconly";
import Partenaire from "../../../images/NoImage.png";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import Allemagne from "../../../images/allemagne.png";
import InstitutionsEtPartenairesModal from "../../Modals/Frontend/InstitutionsEtPartenairesModal";
import PublicIcon from "@mui/icons-material/Public";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

var InstitutionEtPartenaire = ({ item }) => {
  const { t } = useTranslation();
  const country = useMemo(() => countryList().getData(), []);
  return (
    <div>
      <div className="details-annuaire-item">
        <div className="bloc-card pt-2">
          <div className="card">
            <div className="card-body p-0">
              <div className="px-4 pt-4">
                <div className="d-flex align-items-center">
                  <div className="pb-3">
                    <img
                      // src={Partenaire}
                      src={
                        item?.logo && !item?.logo?.includes("default.png")
                          ? baseUrl + item?.logo
                          : Partenaire
                      }
                      alt="AnnuairePhoto Profil"
                      className="annuaire-pp"
                    />
                  </div>
                  <div className="pl-4">
                    <p className="name-annuaire">{item?.nom}</p>
                    <p className="fonction">
                      {/* {item?.statut?.charAt(0).toUpperCase() +
                        item?.statut?.slice(1)} */}
                      {t("partner")}
                    </p>
                    <div className="d-flex align-items-center">
                      {item?.pays ? (
                        <img
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                            country?.filter(
                              (val) => val?.label === item?.pays
                            )[0]?.value
                          }.svg`}
                          alt=""
                          className="img-pays-fulbrighter"
                        />
                      ) : null}
                      <p className="pays-origine pl-2">{item?.pays}</p>
                    </div>
                  </div>
                </div>
                <div className="border-top border-bottom py-2">
                  <div className="row  pt-2">
                    <div
                      className="col-sm-12 d-flex align-items-center pb-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <IconlyPack.Message set="bold" primaryColor="#B6B6B6" />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.email}
                      </p>
                    </div>
                    <div
                      className="col-sm-12 d-flex align-items-center pb-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <PublicIcon
                        style={{ color: "#B6B6B6", width: 24, height: 24 }}
                      />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.site_web}
                      </p>
                    </div>
                    <div
                      className="col-sm-12 d-flex align-items-center pb-2"
                      style={{ maxWidth: "100%" }}
                    >
                      <IconlyPack.Call set="bold" primaryColor="#B6B6B6" />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.contact}
                      </p>
                    </div>
                  </div>
                  {/* <div className="bloc-annuaire-fulbrighter-item pt-2">
                    <div className="d-flex align-items-center">
                      <IconlyPack.Call set="bold" primaryColor="#B6B6B6" />
                      <p className="text-fulbrighter-annuaire pl-2">
                        {item?.contact}
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-voir-catalogue"
                    data-toggle="modal"
                    data-target={`#InstitutionsEtPartenairesModal${item?.id}`}
                  >
                    <IconlyPack.Show set="light" primaryColor="#B6B6B6" />
                    <span className="pl-2">{t("view")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`InstitutionsEtPartenairesModal${item?.id}`}
        tabIndex="-1"
        aria-labelledby="InstitutionsEtPartenairesModalLabel"
        aria-hidden="true"
      >
        <InstitutionsEtPartenairesModal item={item} />
      </div>
    </div>
  );
};

export default InstitutionEtPartenaire;
