import React, { useState, useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import * as IconlyPack from "react-iconly";
import AvatarCoordonnateur from "../../../../assets/images/Avatars.png";
import { NavLink, useLocation, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import api from "../../../../Http/global-vars";
import { fetchPostes } from "../../../../redux/Posts/PostAction";
import moment from "moment";
import baseUrl from "../../../../Http/backend-base-url";
import ModifierFulbriterModal from "../../../Modals/Coordonnateur/ModifierFulbriterModal";
import Header from "../../../Navs/Header";
import { useTranslation } from "react-i18next";

var DetailsFulbriters = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const posts = useSelector((state) => state.posts);
  const dispatch = useDispatch();

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const [userPosts, setUserPosts] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPostes());
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.userItem) {
      setUser(location?.state?.userItem);
    }
  }, [location]);

  useEffect(() => {
    if (params?.id) {
      getDetails(params?.id);
      if (!posts?.isLoading) {
        setUserPosts(
          posts?.data?.filter(
            (post) => post?.created_by?.id === parseInt(params?.id)
          )
        );
        // console.log(
        //   "user posts",
        //   posts?.data?.filter(
        //     (post) => post?.created_by?.id === parseInt(params?.id)
        //   )
        // );
      }
    }
  }, [params, posts]);

  const getDetails = (id) => {
    axios
      .get(`${api}user/${id}/`, config)
      .then((response) => {
        // console.log("details", response?.data);
        setUser(response?.data);
      })
      .catch((error) => {
        console.log("error", error?.response);
        if (error?.response?.status === 404) {
          history.goBack();
        }
      });
  };

  // const IsJsonString = (str) => {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // };
  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="position-relative">
                <div className="d-flex justify-content-center">
                  <div className="text-center">
                    <img
                      // src={AvatarCoordonnateur}
                      src={
                        user?.avatar && !user?.avatar?.includes("default.png")
                          ? baseUrl + user?.avatar
                          : AvatarCoordonnateur
                      }
                      alt="Avatar"
                      className="Avatar-profil"
                    />
                    <h1 className="page-title">
                      {user?.prenom + " " + user?.nom}
                    </h1>
                  </div>
                </div>
                <div className="d-flex justify-content-end btn-send-message-position">
                  <NavLink
                    to={{
                      pathname: "/messages-coordonnateur",
                      state: { userItem: user },
                    }}
                    className="btn btn-send-message"
                  >
                    <span className="icon-container">
                      <IconlyPack.Chat set="light" primaryColor="#003DA5" />
                    </span>
                    <span className="adding-item-label pl-2">
                      {t("send_message")}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <ul
                    className="nav nav-tabs navtabs-fulbrighter"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active navlink-fulbrighter"
                        id="information-personnel-tab"
                        data-toggle="tab"
                        href="#information-personnel"
                        role="tab"
                        aria-controls="information-personnel"
                        aria-selected="true"
                      >
                        {t("infos_perso")}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link navlink-fulbrighter"
                        id="activite-tab"
                        data-toggle="tab"
                        href="#activite"
                        role="tab"
                        aria-controls="activite"
                        aria-selected="false"
                      >
                        {t("activity")}{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-5" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="information-personnel"
                      role="tabpanel"
                      aria-labelledby="information-personnel-tab"
                    >
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item border-top">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle"> {t("firstName")}</p>
                            </div>
                            <div>
                              <p className="info-value">{user?.prenom}</p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("lastName")}</p>
                            </div>
                            <div>
                              <p className="info-value">{user?.nom}</p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("email")}</p>
                            </div>
                            <div>
                              <p className="info-value">{user?.email}</p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("address")} </p>
                            </div>
                            <div>
                              <p className="info-value">
                                {user?.adresse || t("not_specified")}
                              </p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("country")} </p>
                            </div>
                            <div>
                              <p className="info-value">
                                {user?.pays || t("not_specified")}
                              </p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>

                        <li className="list-group-item">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">
                                {t("cohort_year")}{" "}
                              </p>
                            </div>
                            <div>
                              <p className="info-value">
                                {user?.annee_cohorte || t("not_specified")}
                              </p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("phone")}</p>
                            </div>
                            <div>
                              <p className="info-value">
                                {user?.phone || t("not_specified")}
                              </p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item border-bottom">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("status")}</p>
                            </div>
                            <div>
                              <p className="info-value">
                                {user?.statut === "etudiant"
                                  ? t("student")
                                  : user?.statut === "faculty"
                                  ? t("faculty")
                                  : user?.statut === "chercheur"
                                  ? t("searcher")
                                  : user?.statut === "staff"
                                  ? t("staff")
                                  : user?.statut === "ata"
                                  ? t("ata")
                                  : null}
                              </p>
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                        {/* <li className="list-group-item border-bottom">
                          <div className="container-list">
                            <div>
                              <p className="info-libelle">{t("skills")}</p>
                            </div>
                            <div>
                              {user?.id &&
                              IsJsonString(user?.domaine_expertise) ? (
                                JSON.parse(user?.domaine_expertise)?.length &&
                                JSON.parse(user?.domaine_expertise).map(
                                  (tag, index) => (
                                    <Tag className="mb-4 tag" key={index}>
                                      {tag}
                                    </Tag>
                                  )
                                )
                              ) : (
                                <p className="info-value">
                                  {t("not_specified")}
                                </p>
                              )}
                            </div>
                            <div>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li> */}
                        <li className="list-group-item border-bottom">
                          <div className="container-list">
                            <div className="w-20 mr-3">
                              <p className="info-libelle">{t("bio")}</p>
                            </div>
                            <div>
                              <p
                                className="info-value"
                                dangerouslySetInnerHTML={{
                                  __html: user?.biographie,
                                }}
                              />
                            </div>
                            <div className="w-20" style={{ textAlign: "end" }}>
                              <button
                                className="btn-info-edit btn"
                                data-toggle="modal"
                                data-target={`#ModifierFulbriterModal${user?.id}`}
                              >
                                {t("edit")}
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="activite"
                      role="tabpanel"
                      aria-labelledby="activite-tab"
                    >
                      <ul className="list-group">
                        {userPosts?.length ? (
                          userPosts.map((post) => (
                            <li className="list-group-item" key={post?.id}>
                              <div>
                                <div>
                                  <p className="date-post-fulbrighter">
                                    {/* Tue, 21 oct */}
                                    {moment(post?.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <p className="author-post-fulbrighter py-2">
                                    {post?.created_by?.prenom +
                                      " " +
                                      post?.created_by?.nom}
                                    &nbsp;
                                    {t("added_post")}
                                  </p>
                                </div>
                                <div>
                                  <NavLink
                                    to={{
                                      pathname: "/detail-discussion-forum",
                                      state: { post: post },
                                    }}
                                    className="title-post-fulbrighter"
                                  >
                                    {post?.titre}
                                  </NavLink>
                                </div>
                              </div>
                            </li>
                          ))
                        ) : (
                          <div className="gfa-rowy mt-5">
                            <div className="alert alert-warning gfa-warning">
                              {t("noData")}
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id={`ModifierFulbriterModal${user?.id}`}
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ModifierFulbriterModalLabel"
            aria-hidden="true"
          >
            <ModifierFulbriterModal fulbrighter={user} from="details" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsFulbriters;

const Tag = (props) => <span className="tag" {...props}></span>;
