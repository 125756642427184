import React, { useEffect, useState, useMemo } from "react";
import { useStep } from "react-hooks-helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import countryList from "react-select-country-list";
import DetailsUniversiteEdit from "./stepsCatalogue/DetailsUniversiteEdit";
import ContentDetailsEdit from "./stepsCatalogue/ContentDetailsEdit";
import { useTranslation } from "react-i18next";

const steps = [
  { id: "principal-information", Component: DetailsUniversiteEdit },
  { id: "other-information", Component: ContentDetailsEdit },
];

function ModifierCatalogue({ university }) {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);
  const [dataForm, setDataForm] = useState({});
  // const ref = useRef(null);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { navigation, university };
  const [fileName, setFileName] = useState(null);
  const [val, setVal] = useState("");
  const [phone, setPhone] = React.useState("");
  const [gouvernance, setGouvernance] = React.useState("");
  const [direction, setDirection] = React.useState("");
  const [note, setNote] = React.useState("");
  const [institut, setInstitut] = React.useState("");

  const Schema = yup.object().shape({
    nom: yup.string().required(t("required")),
    pays: yup.string().required(t("required")),
    nb_facultes: yup.string().nullable(),
    nb_students: yup.string().nullable(),
    head_text: yup.string().nullable(),
    adresse: yup.string().nullable(),
    email: yup.string().email(t("invalid_mail")).nullable(),
    phone: yup.string().nullable(),
    site_web: yup.string().required(t("required")),
    logo: yup.mixed(),
  });

  const { formState, register, handleSubmit, setValue, clearErrors, reset } =
    useForm({
      resolver: yupResolver(Schema),
    });

  useEffect(() => {
    if (university?.id) {
      setValue("nom", university?.nom);
      setValue("nb_facultes", university?.nb_facultes);
      setValue("nb_students", university?.nb_students);
      setValue("email", university?.email);
      setValue("phone", university?.phone);
      setValue("site_web", university?.site_web);
      setValue("pays", university?.pays);
      setValue("head_text", university?.head_text);
      setValue("adresse", university?.adresse);
      setValue("gouvernance", university?.gouvernance);
      setValue("note", university?.note);
      setValue("direction", university?.direction);
      setValue("institut", university?.institut);
      setValue("id", university?.id);
      if (university?.phone) {
        setPhone(university?.phone);
      }

      if (
        university?.pays &&
        options.filter((option) => option.label === university?.pays)?.length
      ) {
        setVal(
          options.filter((option) => option.label === university?.pays)[0]
            ?.value
        );
      }
      if (university?.institut && university?.institut !== undefined) {
        setInstitut(university?.institut);
      }
      if (university?.note && university?.note !== undefined) {
        setNote(university?.note);
      }
      if (university?.gouvernance && university?.gouvernance !== undefined) {
        setGouvernance(university?.gouvernance);
      }
      if (university?.direction && university?.direction !== undefined) {
        setDirection(university?.direction);
      }
    }
  }, [options, setValue, university]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  const handleReset = () => {
    navigation.go("principal-information");
    // setPhone("+221");
    // setVal("");
    // setDataForm({});
    // setFileName("");
    // setInstitut("");
    // setNote("");
    // setGouvernance("");
    // setDirection("");
    // reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("edit_catalogue")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div>
                <Component
                  {...props}
                  setDataForm={setDataForm}
                  dataForm={dataForm}
                  reset={reset}
                  formState={formState}
                  register={register}
                  handleSubmit={handleSubmit}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setVal={setVal}
                  val={val}
                  phone={phone}
                  setPhone={setPhone}
                  fileName={fileName}
                  setFileName={setFileName}
                  gouvernance={gouvernance}
                  direction={direction}
                  institut={institut}
                  note={note}
                  setGouvernance={setGouvernance}
                  setDirection={setDirection}
                  setInstitut={setInstitut}
                  setNote={setNote}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierCatalogue;
