import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./Error404.css";

function Error404() {
  const { t } = useTranslation();
  let token = window.sessionStorage.getItem("userToken");
  let userType = window.sessionStorage.getItem("userType");
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1 className="">Oops!</h1>
          <h2>404 - {t("not_found")}</h2>
        </div>
        <NavLink
          className="btn-return-accueil"
          to={
            !token
              ? "/"
              : userType === "coordo"
              ? "/dashboard-coordonnateur"
              : userType === "admin"
              ? "/dashboard-admin"
              : "/app/home"
          }
        >
          {t("go_home")}
        </NavLink>
      </div>
      {/* <FrontFooter /> */}
    </div>
  );
}

export default Error404;
