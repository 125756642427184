import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import DocumentsAdminTableSkeleton from "./DocumentsAdminTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import moment from "moment";
import DocumentsAdminTableData from "./DocumentsAdminTableData";
import ImgDocument from "../../../../assets/images/pdf.png";
import ImgDoc from "../../../../assets/images/icons/word-icon.png";
import { useDispatch, useSelector } from "react-redux";
import DetailsDocument from "../../../Modals/Coordonnateur/DetailsDocument";
import Swal from "sweetalert2";
import DocumentFile from "../../../Modals/Coordonnateur/DocumentFile";
import { deleteData } from "../../../utils/fetchData";
import { fetchDocuments } from "../../../../redux/documents/DocumentAction";
import { useTranslation } from "react-i18next";

var DocumentsAdminTable = () => {
  const { t } = useTranslation();
  const documents = useSelector((state) => state.documents);
  const dispatch = useDispatch();
  // const [documents, setDocuments] = React.useState(
  //   DocumentsAdminTableData
  // );
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, [documents]);

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const photoFormatter = (cell, row) => {
    return (
      <div>
        {get_url_extension(row?.fichier) === "pdf" ? (
          <img
            style={{ width: "50px", height: "50px" }}
            src={ImgDocument}
            alt="Image"
          />
        ) : (
          <img
            style={{ width: "50px", height: "50px" }}
            src={ImgDoc}
            alt="Image"
          />
        )}

        <span className="pl-3"> {cell}</span>
      </div>
    );
  };

  const onDelete = (id) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: t("warning_message_doc"),
      icon: "warning",
      //   customClass: {
      //     confirmButton: "btn-theme-plus",
      //     closeButton: "btn btn-danger",
      //   },
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`document/${id}/`, token)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchDocuments());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const OpenModal = (id) => {
    window.$(`#DetailsDocumentModal${id}`).modal("show");
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            onClick={() => OpenModal(row?.id)}
            // data-toggle="modal"
            // data-target={`#DetailsDocumentModal${row?.id}`}
          />
        </button>
        <button className="action-btn edit-btn" title={t("download")}>
          <IconlyPack.Download
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#FileDocumentModal${row?.id}`}
          />
        </button>
        <button className="action-btn delete-btn" title={t("delete")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
        <div
          className="modal fade"
          id={`DetailsDocumentModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="DetailsDocumentModalLabel"
          aria-hidden="true"
        >
          <DetailsDocument document={row} />
        </div>

        <div
          className="modal fade"
          id={`FileDocumentModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="FileDocumentModalLabel"
          aria-hidden="true"
        >
          <DocumentFile document={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {documents?.isLoading && <DocumentsAdminTableSkeleton />}
            {!documents?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={documents?.data}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="50"
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    dataField="title"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    {t("title")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataFormat={(cell, row) =>
                      moment(cell).format("DD MMMM YYYY")
                    }
                    width="150"
                    dataField="created_at"
                    // dataAlign="center"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    {t("added_date")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsAdminTable;
