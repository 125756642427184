import React from "react";
import { NavLink } from "react-router-dom";
import Docs from "../../../assets/images/icons/word-icon.png";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import ImgPdf from "../../../assets/images/pdf.png";
import baseUrl from "../../../Http/backend-base-url";

var DocumentItem = ({ doc, devise, annual }) => {
  const get_url_extension = (uri) => {
    return uri.split(/[#?]/)[0].split(".").pop().trim();
  };

  return (
    <div>
      <div className="details-dossier-item">
        <div className="bloc-card pt-1">
          <NavLink
            to={{
              pathname: annual
                ? `/app/annual-reports/${doc?.id}`
                : `/app/documents/${doc?.id}`,
              state: { document: doc },
            }}
            className="no-link"
          >
            <div className="card">
              <div className="card-body">
                <div
                  className="py-0"
                  style={{
                    minHeight: devise ? "20vw" : "14vw",
                    height: devise ? "" : "14vw",
                    overflow: "hidden",
                  }}
                >
                  
                  {get_url_extension(doc?.fichier) === "pdf" ? (
                    <iframe
                      src={baseUrl + doc?.fichier}
                      frameBorder="0"
                      width={`100%`}
                      height={`100%`}
                      allowFullScreen
                      seamless="seamless"
                      title="doc"
                    ></iframe>
                  ) : (
                    <iframe
                      src={
                        "https://view.officeapps.live.com/op/embed.aspx?src=" +
                        baseUrl +
                        doc?.fichier
                      }
                      frameBorder="0"
                      width={`100%`}
                      height={`100%`}
                      title="doc"
                    ></iframe>
                  )}
                </div>
                <div className="row mt-1 p-1 d-flex align-items-center border-detail-dossier">
                  <div className="dossier-infos-img-container">
                    <div>
                      {get_url_extension(doc?.fichier) !== "pdf" ? (
                        <img
                          className=""
                          src={Docs}
                          alt="liste user"
                          style={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={ImgPdf}
                          alt="Imaged"
                        />
                      )}
                    </div>
                  </div>
                  <div className="dossier-infos-label-container">
                    <div className="">
                      <p className="title-liste-dossier-item">{doc?.title}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default DocumentItem;
