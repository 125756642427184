import React, { Component } from "react";
import "./navbarContent.css";
import NavBarCoordonnateur from "./NavBarCoordonnateur";
import NavBarAdmin from "./NavBarAdmin";
export default class navbarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: window.sessionStorage.getItem("userType"),
    };
  }

  render() {
    const { userType } = this.state;
    return (
      <div className="hoverable" id="nav-menu-id">
        <ul className="nav nav-items-custom flex-column">
          {userType === "coordo" && <NavBarCoordonnateur />}

          {userType === "admin" && <NavBarAdmin />}
        </ul>
      </div>
    );
  }
}
