import { FETCH_PLAYLIST } from "../types";

export const playlistReducer = (
  state = { nextPageToken: null, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_PLAYLIST:
      return action.payload;
    default:
      return state;
  }
};
