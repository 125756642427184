import axios from "axios";
import { live } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddlive(data, config) {
    const response = await axios.post(live.onPostlive(), data, config);
    return response.data;
  },
  async onEditlive(id, data, config) {
    const response = await axios.put(live.onEditlive(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeletelive(id, config) {
    const response = await axios.delete(live.onDeletelive(id), config);
    // console.log(response)
    return response.data;
  },
};
