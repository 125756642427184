/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import "./Documents.css";
import { PlaylistVideo } from "./PlaylistItem";

var ReadMedia = (props) => {
  const { playlist, item, videos } = useLocation().state;
  const history = useHistory();
  const { t } = useTranslation();
  const devise = useSelector((state) => state.devise);

  useEffect(() => {
    if (!item) {
      history.replace("/app/media");
    }
  }, []);
  if (!item) return null;
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="container-warc py-5 mt-5">
              <div className="row mb-5">
                <div className="col-lg-9 col-md-12">
                  <div className="content-title-media-player mb-4">
                    <h1 className="titre-banner">{item.snippet.title}</h1>
                  </div>
                  <div className="content-media-read">
                    <div>
                      <iframe
                        title={item.snippet.title}
                        width="100%"
                        height="491"
                        src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}?autoplay=1`}
                        frameborder="0"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="content-text-media-read">
                      <p className="title-description-media-read">
                        {item.snippet.title}
                      </p>
                      <div className="pt-3">
                        <p className="text-description-media-read">
                          {item.snippet.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6"></div>
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  const index = videos.findIndex((i) => i.id === item.id);
  const filtred = videos.slice(index + 1).slice(0, 5);
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="container-warc py-5 mt-5">
          <div className="content-title-media-player mb-4">
            <h1 className="titre-banner">{item.snippet.title}</h1>
          </div>
          <div className="row mb-5">
            <div className="col-lg-9 col-md-12">
              <div className="content-media-read mt-4">
                <div>
                  <iframe
                    title={item.snippet.title}
                    width="100%"
                    height="380"
                    src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}?autoplay=1`}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="content-text-media-read">
                  <p className="title-description-media-read">
                    {item.snippet.title}
                  </p>
                  <div className="pt-3 pb-5">
                    <p className="text-description-media-read">
                      {item.snippet.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {filtred.length > 0 && (
              <div className="col-lg-3 col-md-6">
                <div className="content-others-videos">
                  <p className="title-others-videos-media pb-2">Other videos</p>
                </div>
                <div>
                  {filtred.map((i) => {
                    return (
                      <div className="mb-3" key={i?.id}>
                        <PlaylistVideo
                          videos={videos}
                          playlist={item}
                          item={i}
                          devise={devise}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ReadMedia;
