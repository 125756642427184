import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import User from "../../../../../services/User";
import { fetchFulbrighters } from "../../../../../redux/fulbrighters/FulbrighterAction";
import { useTranslation } from "react-i18next";

function UseAddFulbrighters() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t("invalid_mail")).required(t("required")),
        // password: yup.string().required(),
        // passwordConfirmation: yup
        //   .string()
        //   .oneOf([yup.ref("password"), null], "Passwords must match"),
        prenom: yup.string().required(t("required")),
        nom: yup.string().required(t("required")),
        num_etudiant: yup.string(),
        statut: yup.string().required(t("required")).nullable(),
        phone: yup.string(),
        numero_urgence: yup.string(),
        biographie: yup.string().nullable(),
        pays: yup.string().required(t("required")),
        adresse: yup.string().nullable(),
        domaine_expertise: yup.string().nullable(),
        annee_cohorte: yup.string().required(t("required")),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    // formValues["password"] = "passerty";
    var request = User.onAddUser(formValues, config);

    await request
      .then(async (resp) => {
        await dispatch(fetchFulbrighters());
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$("#AjouterFulbriterModal").modal("hide");
        await setIsDone(true);
        await e.target.reset();
        await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: t("email_used"),
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default UseAddFulbrighters;
