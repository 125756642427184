import { DASHBOARD } from "../types";

export const dashboardReducer = (
  state = { isLoading: true, data: {} },
  action
) => {
  switch (action.type) {
    case DASHBOARD:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
