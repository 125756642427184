/* eslint-disable react-hooks/exhaustive-deps */
import Pusher from "pusher-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserMessages } from "../redux/messages/MessagesAction";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

const pusher = new Pusher("ab08acbedd7ea9b6b1f6", {
  cluster: "eu",
});

export const useChatListener = () => {
  const user = useSelector((s) => s.user);
  const dispatch = useDispatch();
  usePusher(
    "chat",
    () => {
      if (user?.data?.id) {
        dispatch(fetchUserMessages(user?.data?.id));
      }
    },
    [user?.data?.id]
  );
};

const usePusher = (eventName, cb, deps) => {
  React.useEffect(() => {
    const channel = pusher.subscribe("my-channel");
    const subscription = channel.bind(eventName, (data) => {
      console.log(eventName, data);
      cb();
    });
    return () => {
      try {
        if (subscription.subscribed) {
          subscription.unsubscribe();
        } else if (subscription.subscriptionPending) {
          subscription.cancelSubscription();
        }
      } catch (ex) {}
    };
  }, deps);
};
