import { CHEARCH_USERS, FETCH_FULBRIGHTERS } from "../types";

export const fulbrighterReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_FULBRIGHTERS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    case CHEARCH_USERS:
      // console.log("action", action.payload)
      return {
        ...state,
        filteredData: action.payload.items,
      };
    default:
      return state;
  }
};
