/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import baseUrl from "../../../Http/backend-base-url";
import Img from "../../../images/NoImage.png";

function VoirCatalogueModal({ item }) {
  return (
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-header py-3">
          {/* <h5 className="modal-title text-titre" id="VoirCatalogueModal"></h5> */}
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body px-4">
            <div className="pb-5 pt-3 text-center">
              <h3 className="title-catalogue-modal">{item?.pays}</h3>
            </div>
            <div className="pb-4">
              {/* <p className="sub-title-catalogue-modal pb-3">
                {item?.pays} has :{" "}
              </p>
              <div>
                <ul>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - 7 public universities;{" "}
                  </li>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - 4 University Polytechnic Centers.{" "}
                  </li>
                </ul>
              </div> */}
              <div className="bloc-div-border">
                <div className="p-3">
                  <img
                    // src={Img}
                    src={
                      item?.logo && !item?.logo?.includes("default.png")
                        ? baseUrl + item?.logo
                        : Img
                    }
                    style={{ width: "100px", height: "100px" }}
                    alt="ImageUniversite"
                    className="image-catalogue-modal"
                  />
                </div>
                <div className="w-full border-left-bloc-catalogue">
                  <h2 className="sous-title-catalogue-modal p-4">
                    {item?.nom}
                  </h2>
                  <p className="sub-title-catalogue-modal py-4 text-center">
                    {item?.adresse}, {item?.pays}
                  </p>
                  <div className="bloc-annuaire-fulbrighter-item bloc-text-cataloguemodal">
                    <div className="p-4 bloc-titre-content-modal">
                      <p className="sub-title-catalogue-modal pb-3">
                        Total number of Faculty:
                      </p>
                      <p className="sub-title-catalogue-modal pb-3">
                        {item?.nb_facultes}
                      </p>
                    </div>
                    <div className="border-bloc"></div>
                    <div className="p-4 bloc-titre-content-modal">
                      <p className="sub-title-catalogue-modal pb-3">
                        Total number of Students:{" "}
                      </p>
                      <p className="sub-title-catalogue-modal pb-3">
                        {item?.nb_students}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className="body-content-catalogue-modal pb-4">
                GOVERNING BODY
              </h2>
              <div
                className="sub-title-catalogue-modal list-type-li pb-3"
                dangerouslySetInnerHTML={{
                  __html:
                    item?.gouvernance !== "undefined"
                      ? item?.gouvernance
                      : "<p></p>",
                }}
              >
                {/* <ul>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - President
                  </li>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - Vice-President in charge of teaching and pedagogical
                    innovations{" "}
                  </li>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - The Vice-President in charge of Research and International
                    Cooperation
                  </li>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - The Vice-President in charge of professionalization and
                    university-business relations.{" "}
                  </li>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - General Secretary
                  </li>
                  <li className="sub-title-catalogue-modal list-type-li pb-3">
                    - Central Services
                  </li>
                </ul> */}
              </div>
            </div>
            <div>
              <h2 className="body-content-catalogue-modal pb-4">DIRECTORY</h2>
              <div
                className="sub-title-catalogue-modal pb-4"
                dangerouslySetInnerHTML={{
                  __html:
                    item?.direction !== "undefined"
                      ? item?.direction
                      : "<p></p>",
                }}
              />
              {/* <p className="body-content-catalogue-modal pb-4">
                7 training and research units (UFR):
              </p>

              <div>
                <ul>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR Languages, Arts and Communication (UFR / LAC), 7
                    departments: Modern literature; linguistics; English
                    studies; Germanic studies; communication and journalism;
                    translation-interpreting and arts degree, culture management
                    and administration.
                  </li>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR of Human Sciences (UFR / SH) 4 departments : Geography;
                    History and Archaeology; Philosophy-Psychology; Sociology.
                    The UFR also host the Demography Teaching and Research Unit
                    (UERD) and the Adult Development and Management Project
                    (DEDA).
                  </li>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR Law and Political Science (UFR / SJP)
                  </li>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR Economic and Management Sciences (UFR / SEG)
                  </li>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR Health Sciences (UFR / SDS), 3 sections: the Medicine
                    section (with 5 departments); the Pharmacy section (with 3
                    departments); the Higher Health Technicians section (TSS)
                    with the option "Biomedical Analysis"
                  </li>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR Life and Earth Sciences (SVT) 4 departments : Animal
                    Biology and Physiology; Plant Biology and Physiology;
                    Department of Biochemistry and Microbiology; Department of
                    Geology.
                  </li>
                  <li className="sub-title-catalogue-modal pb-3">
                    UFR Exact and Applied Sciences (UFR-SEA), 3 departments:
                    Mathematics and Computer Science; Physics; Chemistry.
                  </li>
                </ul>
              </div> */}
              <div>
                <p className="body-content-catalogue-modal pb-4">
                  Institutes :{" "}
                </p>

                <div
                  className="sub-title-catalogue-modal pb-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      item?.institut !== "undefined"
                        ? item?.institut
                        : "<p></p>",
                  }}
                />

                {/* <div>
                  <ul>
                    <li className="sub-title-catalogue-modal pb-3">
                      The Burkinabe Institute of Arts and Crafts (IBAM)
                    </li>
                    <li className="sub-title-catalogue-modal pb-3">
                      Higher Institute of Population Sciences (ISSP)
                    </li>
                    <li className="sub-title-catalogue-modal pb-3">
                      The Pan-African Institute for Media, Information and
                      Communication Studies and Research (IPERMIC)
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="pb-4">
                <p className="body-content-catalogue-modal pb-4">
                  Current Head of the Research, Cooperation & Partnerships
                </p>
                <div>
                  <ul>
                    <li className="sub-title-catalogue-modal list-type-li pb-3">
                      - Name: {item?.head_text}{" "}
                    </li>
                    <li className="sub-title-catalogue-modal list-type-li pb-3">
                      - E-mail:{" "}
                      <a className="link-contact-catalogue">{item?.email}</a>
                    </li>
                    <li className="sub-title-catalogue-modal list-type-li pb-3">
                      - Telephone (University): {item?.phone}
                    </li>
                    <li className="sub-title-catalogue-modal list-type-li pb-3">
                      - Website:{" "}
                      <a className="link-contact-catalogue">{item?.site_web}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="body-content-catalogue-modal pb-4">
                  <span style={{ textDecoration: "underline" }}>Note:</span>{" "}
                  {/* <span className="sub-title-catalogue-modal pl-2">
                    the first contacts for Fulbright teachers and researchers
                    are:
                  </span> */}
                </p>
                <div>
                  <div
                    className="sub-title-catalogue-modal pb-4"
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.note !== "undefined" ? item?.note : "<p></p>",
                    }}
                  />
                  {/* <ul>
                    <li className="sub-title-catalogue-modal list-type-li pb-3">
                      - The Directorate of Research, Cooperation and
                      Partnerships
                    </li>
                    <li className="sub-title-catalogue-modal list-type-li pb-3">
                      -The Chair of the Department to which they are affiliated
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoirCatalogueModal;
