import React, { useEffect } from "react";
import useCreateGuide from "../../Dashboard/Coordonnateur/GuidesCoordonnateur/requestGuide/useCreateGuide";
import { CKEditor } from "ckeditor4-react";
import { useTranslation } from "react-i18next";

function AjouterQuestionGuideModal() {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
    setValue,
  } = useCreateGuide();

  useEffect(() => {
    if (formState.errors) {
      // console.log(formState.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("add_question")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-10 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="date">
                        {t("title")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("title")}
                        name="titre"
                        {...register("titre")}
                        className="form-control  input-add-document"
                        id="Titre"
                      />
                      {formState.errors && formState.errors.titre && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.titre?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="Ordre">
                        {t("order")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder={t("order")}
                        name="position"
                        {...register("position")}
                        className="form-control  input-add-document"
                        id="Ordre"
                      />
                      {formState.errors && formState.errors.position && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.position?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="text">
                        {t("text")} <span className="required-label">*</span>
                      </label>
                      {/* <textarea
                        className="form-control input-add-document"
                        id="text"
                        rows="3"
                        name="contenu"
                        {...register("contenu")}
                        placeholder={t("text")}
                      ></textarea> */}
                      <CKEditor
                        className="form-control  input-add-document"
                        initData={""}
                        onChange={(evt) =>
                          setValue("contenu", evt.editor.getData())
                        }
                      />
                      {formState.errors && formState.errors.contenu && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.contenu?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("add")}
                    </button>
                  )}
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterQuestionGuideModal;
