import { DEVISE } from "../types";

export const fetchDevise = (devise) => {
  return async (dispatch) => {
    dispatch({
      type: DEVISE,
      payload: devise,
    });
  };
};
