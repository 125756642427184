import React, { Component } from "react";
import "./navbarVerticalV2.css";
import NavbarContent from "../navbarContent/navbarContent";

export default class navbarVerticalV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="navbar-vertical-customisation-v2 no-view-mobile">
        <NavbarContent />
      </div>
    );
  }
}
