import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import ImgPP from "../../../images/others/default-profile-avatar.png";

function VoirFulbrighter({ item }) {
  const { t } = useTranslation();
  // const IsJsonString = (str) => {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // };
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header py-3">
          {/* <h5 className="modal-title text-titre" id="VoirFulbrighter"></h5> */}
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <div className="d-flex div-content-modal">
              <div>
                <img
                  src={
                    item?.avatar && !item?.avatar?.includes("default.png")
                      ? baseUrl + item?.avatar
                      : ImgPP
                  }
                  alt="ImageFulbrighter"
                  className="PpFulbrighter-modal"
                />
              </div>
              <div className="pl-4 bloc-titre-content-modal">
                <h1 className="titre-fulbrighter-modal">
                  {item?.prenom + " " + item?.nom}
                </h1>
                <p className="sous-titre-fulbrighter-modal">
                  {item?.statut === "etudiant"
                    ? t("student")
                    : item?.statut === "faculty"
                    ? t("faculty")
                    : item?.statut === "chercheur"
                    ? t("searcher")
                    : item?.statut === "staff"
                    ? t("staff")
                    : item?.statut === "ata"
                    ? t("ata")
                    : null}
                  -{item?.annee_cohorte}
                </p>
                <p
                  className="text-fulbrighter-modal"
                  dangerouslySetInnerHTML={{
                    __html: item?.biographie,
                  }}
                />
                <div className="bloc-annuaire-skills-item pt-2">
                  {/* <div className="contain-item">
                    <p className="text-fulbrighter-annuaire">Lorem</p>
                  </div>
                  <div className="contain-item">
                    <p className="text-fulbrighter-annuaire">Lorem</p>
                  </div>
                  <div className="contain-item">
                    <p className="text-fulbrighter-annuaire">Lorem</p>
                  </div> */}
                  {/* {item?.id && IsJsonString(item?.domaine_expertise)
                    ? JSON.parse(item?.domaine_expertise)?.length &&
                      JSON.parse(item?.domaine_expertise).map((tag, index) => (
                        // <Tag className="mb-4 tag" key={index}>
                        //   {tag}
                        // </Tag>
                        <div className="annuaire-tag contain-item" key={index}>
                          <p className="text-fulbrighter-annuaire">{tag}</p>
                        </div>
                      ))
                    : null} */}
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="border-top border-bottom bloc-annuaire-fulbrighter-item py-2">
                <div>
                  <p className="text-fulbrighter-modal">{t("email")}</p>
                  <p className="sous-titre-fulbrighter-modal">{item?.email}</p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("phone")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {item?.phone || t("not_specified")}
                  </p>
                </div>
                <div>
                  <p className="text-fulbrighter-modal">{t("country")}</p>
                  <p className="sous-titre-fulbrighter-modal">
                    {item?.pays || t("not_specified")}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <NavLink
                to={{
                  pathname: "/app/messagerie",
                  state: { userItem: item },
                }}
                onClick={() =>
                  window.$(`#VoirFulbrighter${item?.id}`).modal("hide")
                }
                className="btn btn-message-fulbrighter trans-0-2"
              >
                Message
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoirFulbrighter;
