/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import { getVideoId } from "../../../utils";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import "./Documents.css";

var ReadVideo = (props) => {
  const { item } = useLocation().state;
  const history = useHistory();

  const devise = useSelector((state) => state.devise);
  const [videoId, setVideoId] = React.useState("");
  const [url, setUrl] = React.useState("");
  React.useEffect(() => {
    if (item?.lien) {
      console.log("lien", getVideoId(item?.lien));
      if (getVideoId !== false) {
        setVideoId(getVideoId(item?.lien));
      }
    }
  }, [item]);

  React.useEffect(() => {
    if (item?.lien) {
      if (videoId) {
        setUrl(`https://www.youtube.com/embed/${videoId}`);
      } else {
        setUrl(item?.lien);
      }
    } else {
      setUrl(baseUrl + item?.video);
    }
  }, [item, videoId]);
  useEffect(() => {
    if (!item) {
      history.replace("/app/media");
    }
  }, []);
  if (!item) return null;
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="container-warc py-5 mt-5">
              <div className="row mb-5">
                <div className="col-lg-9 col-md-12">
                  <div className="content-title-media-player mb-4">
                    <h1 className="titre-banner">{item.title}</h1>
                  </div>
                  <div className="content-media-read">
                    <div>
                      {/* <video controls width={"100%"} height="auto">
                        <source src={baseUrl + item.video} type="video/mp4" />
                        Votre navigateur ne prend pas en charge la lecture de
                        vidéos HTML5.
                      </video> */}
                      {!!url && (
                        <iframe
                          src={url}
                          title={item?.title + "video"}
                          width={"100%"}
                          height={500}
                          controls
                          allowFullScreen
                        ></iframe>
                      )}
                    </div>
                    <div className="content-text-media-read">
                      <p className="title-description-media-read">
                        {item.title}
                      </p>
                      <div className="pt-3">
                        <p className="text-description-media-read">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6"></div>
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="container-warc py-5 mt-5">
          <div className="content-title-media-player mb-4">
            <h1 className="titre-banner">{item.title}</h1>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="content-media-read mt-4">
                <div>
                  {/* <video controls width={"100%"} height="auto">
                    <source src={baseUrl + item.video} type="video/mp4" />
                    Votre navigateur ne prend pas en charge la lecture de vidéos
                    HTML5.
                  </video> */}
                  {!!url && (
                    <iframe
                      src={url}
                      title={item?.title + "video"}
                      width={"100%"}
                      height={500}
                      controls
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
                <div className="content-text-media-read">
                  <p className="title-description-media-read">{item.title}</p>
                  <div className="pt-3 pb-5">
                    <p className="text-description-media-read">
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ReadVideo;
