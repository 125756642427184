/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton, { Card } from "@nejcm/react-skeleton";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../Http/global-vars";
import {
  fetchDocuments,
  searchDocument,
} from "../../../redux/documents/DocumentAction";
import { fetchExperts } from "../../../redux/experts/ExpertAction";
import { fetchPartner } from "../../../redux/partners/partnerAction";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import DocumentItem from "./DocumentItem";
import "./Documents.css";

var Documents = (props) => {
  const { t } = useTranslation();
  const devise = useSelector((state) => state.devise);
  const documents = useSelector((state) => state.documents);
  const [itemToShow, setItemToShow] = useState(9);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchExperts());
    dispatch(fetchPartner());
    dispatch(fetchDocuments());
  }, []);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const handleClick = async (item) => {
    console.log("click", item);
    let request = axios.put(
      `${api}document/${item?.id}/`,
      { nb_view: item?.nb_view + 1 },
      config
    );
    await request
      .then((response) => {
        console.log("response", response);
        dispatch(fetchDocuments());
      })
      .catch((error) => {
        console.error("error", error?.response);
      });
  };

  useEffect(() => {
    if (itemToShow >= documents?.filteredData?.length) {
      // setItemToShow(3);
      setExpand(true);
    } else {
      // setItemToShow(itemToShow + 5);
      setExpand(false);
    }
  }, [itemToShow]);
  const showMore = () => {
    if (itemToShow >= documents?.filteredData?.length) {
      setItemToShow(9);
      setExpand(true);
      window.scroll(0, 0);
    } else {
      setItemToShow(itemToShow + 6);
      setExpand(false);
    }
  };

  const handleChange = (e) => {
    // console.log("search item", e.target.value.length);
    dispatch(searchDocument(documents?.data, e.target.value.toLowerCase()));
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div className="banner">
              <div className="py-5 text-center">
                <h1 className="titre-banner">{t("find_doc")}</h1>
                <div className="col-xl-6 col-lg-6 col-md-6 offset-md-3 mt-4">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control padding-input"
                      placeholder={t("search_doc")}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={handleChange}
                    />
                    <span
                      className="input-group-text"
                      id="basic-addon-annuaire"
                    >
                      <IconlyPack.Search set="light" primaryColor="#000" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-warc py-5 mt-5">
              <div className="row mb-5">
                {documents?.isLoading && <CardSkeleton />}
                {!documents?.isLoading &&
                  (documents?.filteredData?.length ? (
                    documents?.filteredData?.slice(0, itemToShow).map((doc) => (
                      <div
                        className="col-xl-4 col-lg-4 col-md-4 pb-4"
                        key={doc?.id}
                        onClick={() => handleClick(doc)}
                      >
                        <DocumentItem doc={doc} devise={devise} />
                      </div>
                    ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
                {documents?.filteredData?.length > 9 ? (
                  <div className="col-12 text-center pb-4">
                    <a
                      className="btn btn-participate-event trans-0-2 ml-auto"
                      onClick={showMore}
                      href="#more"
                    >
                      {expand ? (
                        <span>{t("see_less")}</span>
                      ) : (
                        <span>{t("see_more")}</span>
                      )}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="banner">
          <div className="py-5 text-center">
            <h1 className="titre-banner mb-5">{t("find_doc")}</h1>
            <div className="col-xl-6 col-lg-6 col-md-6 offset-md-3 mt-4">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control padding-input"
                  placeholder={t("search_doc")}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={handleChange}
                />
                <button
                  className="input-group-text btn btn-search-doc trans-0-2"
                  id="basic-addon2"
                >
                  {t("search")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-warc py-5 mt-5">
          <div className="row mb-5">
            {documents?.isLoading && <CardSkeleton />}
            {!documents?.isLoading &&
              (documents?.filteredData?.length ? (
                documents?.filteredData?.slice(0, itemToShow).map((doc) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-4 pb-4"
                    key={doc?.id}
                    onClick={() => handleClick(doc)}
                  >
                    <DocumentItem doc={doc} devise={devise} />
                  </div>
                ))
              ) : (
                <div className="gfa-rowy mt-5">
                  <div className="alert alert-warning gfa-warning">
                    {t("noData")}
                  </div>
                </div>
              ))}
            {documents?.filteredData?.length > 9 ? (
              <div className="text-center pb-4 col-12">
                <a
                  className="btn btn-participate-event trans-0-2 ml-auto"
                  onClick={showMore}
                  href="#more"
                >
                  {expand ? (
                    <span>{t("see_less")}</span>
                  ) : (
                    <span>{t("see_more")}</span>
                  )}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Documents;

export const CardSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="col-xl-4 col-lg-4 col-md-4 pb-4">
        <Card image width={`100%`} header />
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
