import { CHEARCH_PARTNERS, FETCH_PARTNERS } from "../types";

export const partnersReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_PARTNERS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    case CHEARCH_PARTNERS:
      // console.log("action", action.payload)
      return {
        ...state,
        filteredData: action.payload.items,
      };
    default:
      return state;
  }
};
