import api from "../../Http/global-vars";
import { CHEARCH_USERS, FETCH_FULBRIGHTERS } from "../types";

export const fetchFulbrighters = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "fulbrighter/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data);
        dispatch({
          type: FETCH_FULBRIGHTERS,
          payload: data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const searchFulbrighter = (users, searchItem, pays, year) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line
  if (searchItem && pays && year) {
    data = users?.filter(
      (user) =>
        (user?.prenom?.toLowerCase()?.includes(searchItem) ||
          user?.nom?.toLowerCase()?.includes(searchItem)) &&
        user?.pays === pays &&
        parseInt(user?.annee_cohorte) === parseInt(year)
    );
  } else if (!searchItem && pays && year) {
    data = users?.filter(
      (user) =>
        user?.pays === pays && parseInt(user?.annee_cohorte) === parseInt(year)
    );
  } else if (!searchItem && pays && !year) {
    data = users?.filter((user) => user?.pays === pays);
  } else if (searchItem && pays && !year) {
    data = users?.filter(
      (user) =>
        (user?.prenom?.toLowerCase()?.includes(searchItem) ||
          user?.nom?.toLowerCase()?.includes(searchItem)) &&
        user?.pays === pays
    );
  } else if (searchItem && !pays && !year) {
    data = users?.filter(
      (user) =>
        user?.prenom?.toLowerCase()?.includes(searchItem) ||
        user?.nom?.toLowerCase()?.includes(searchItem)
    );
  } else if (searchItem && !pays && year) {
    data = users?.filter(
      (user) =>
        (user?.prenom?.toLowerCase()?.includes(searchItem) ||
          user?.nom?.toLowerCase()?.includes(searchItem)) &&
        parseInt(user?.annee_cohorte) === parseInt(year)
    );
  } else if (!searchItem && !pays && year) {
    data = users?.filter(
      (user) => parseInt(user?.annee_cohorte) === parseInt(year)
    );
  }

  // console.log("voir", searchItem, pays, year);
  // return "";
  return (dispatch) => {
    dispatch({
      type: CHEARCH_USERS,
      payload: {
        items:
          !searchItem?.length && !pays && !year
            ? users?.sort((a, b) => b?.id - a?.id)
            : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
