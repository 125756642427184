import React, { useEffect, useMemo, useState } from "react";
import * as IconlyPack from "react-iconly";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import api from "../../../Http/global-vars";
import Swal from "sweetalert2";
import { fetchMe } from "../../../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

var EditAvatar = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        avatar: yup.mixed().required(t("required")),
      }),
    [t]
  );

  const {
    handleSubmit,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleChange = (e) => {
    setValue("avatar", e.target.files[0]);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmit = (data, e) => {
    console.log("avatar", data);
    const fd = new FormData();
    fd.append("avatar", data?.avatar);
    // setAvatar(file);
    setIsLoad(true);
    var request = axios.put(`${api}user/${user?.data?.id}/`, fd, config);

    request
      .then(async (resp) => {
        console.log("res", resp);
        await dispatch(fetchMe());
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
        await window.$("#editAvatar").modal("hide");
        await e.target.reset();
        await reset();
        await setIsLoad(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oupss! Une erreur est survenue lors de la modification.",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsLoad(false);
      });
  };

  const handleReset = () => {
    reset();
  };
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="editAvatar">
            Image
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label className="label-form-profil" htmlFor="avatar">
                    {t("upload_image")}
                    <span className="required-label">*</span>
                  </label>
                  <input
                    type="file"
                    name="avatar"
                    // {...register("avatar")}
                    onChange={handleChange}
                    className="form-control form-input-profil"
                    id="avatar"
                    aria-describedby="emailHelp"
                  />
                  <span className="icon-upload-form">
                    <IconlyPack.Upload set="light" primaryColor="#000" />
                  </span>
                </div>
                {formState.errors && formState.errors.avatar && (
                  <div className="alert alert-danger gfa-alert-danger">
                    {formState.errors.avatar?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel-form"
                data-dismiss="modal"
                onClick={() => handleReset()}
              >
                {t("cancel")}
              </button>
              {isLoad ? (
                <button type="submit" disabled className="btn btn-theme-modal">
                  {t("en_cours")}&nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              ) : (
                <button type="submit" className="btn btn-theme-modal">
                  {t("edit")}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAvatar;
