import api from "../../Http/global-vars";
import { FETCH_SLIDES } from "../types";

export const fetchSlides = () => {
  return async (dispatch) => {
    const url = api + "slides/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_SLIDES,
          payload: data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
