import React, { useEffect, useState } from "react";
import {
  BootstrapTable,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchReportUser } from "../../../../redux/report_user/reports_userAction";
import { deleteData } from "../../../utils/fetchData";
import { ShareWithTableSkelaton } from "./SlideTableSkelaton";

var ShareWithTable = () => {
  const { t } = useTranslation();
  const documents = useSelector((state) => state.reports_user);
  const dispatch = useDispatch();

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, [documents]);

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const onDelete = (id) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: "Are you sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`report_user/${id}/`, token)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchReportUser());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn delete-btn" title={t("delete")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ShareWithTableSkelaton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={documents}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    dataField="email"
                  >
                    {t("email")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataFormat={(cel, row) => <ShowCode code={row.code} />}
                    dataField="code"
                  >
                    Code
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareWithTable;

export function ShowCode({ code }) {
  const [show, setShow] = useState(false);
  return (
    <div>
      <span>{!show ? "******" : code}</span>
      <span
        style={{
          cursor: "pointer",
          display: "inline-block",
          marginLeft: 5,
          marginBottom: 3,
        }}
        onClick={() => setShow(!show)}
      >
        {show ? (
          <i className="fas fa-eye-slash" id="hide_eye"></i>
        ) : (
          <i className="fas fa-eye" id="show_eye"></i>
        )}
      </span>
    </div>
  );
}
