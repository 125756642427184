import React from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";

function DetailsIncident({ incident }) {
  const { t } = useTranslation();
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5 className="modal-title text-titre">Details</h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body py-4">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group my-4">
                  <label className="label-form">
                    {t("fullname")}:
                    {/* <span className="required-label">*</span> */}
                  </label>
                  <span className="sous-titre-fulbrighter-modal">
                    {" "}
                    {incident?.fullname}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group my-4">
                  <label className="label-form">
                    {t("email")}:
                    {/* <span className="required-label">*</span> */}
                  </label>
                  <span className="sous-titre-fulbrighter-modal">
                    {incident?.email}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group my-4">
                  <label className="label-form">
                    Message:
                    {/* <span className="required-label">*</span> */}
                  </label>
                  <span className="sous-titre-fulbrighter-modal">
                    {incident?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsIncident;
