import axios from "axios";
import { categorie } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddCategorie(data, config) {
    const response = await axios.post(
      categorie.onPostCategorie(),
      data,
      config
    );
    return response.data;
  },
  async onEditCategorie(id, data, config) {
    const response = await axios.put(
      categorie.onEditCategorie(id),
      data,
      config
    );
    // console.log(response)
    return response.data;
  },

  async onDeleteCategorie(id, config) {
    const response = await axios.delete(
      categorie.onDeleteCategorie(id),
      config
    );
    // console.log(response)
    return response.data;
  },
};
