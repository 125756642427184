import { CHEARCH_USER, FETCH_USERS } from "../types";

export const UsersReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    case CHEARCH_USER:
      // console.log("action", action.payload)
      return {
        ...state,
        filteredData: action.payload.items,
      };
    default:
      return state;
  }
};
