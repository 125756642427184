/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, Fragment } from 'react'
import './frontHome.css'
import FrontHeader from '../frontHeader/index'
import FrontFooter from '../frontFooter/index'
import Skeleton, { Paragraph } from '@nejcm/react-skeleton'
import FeatherIcon from 'feather-icons-react'
import ImgPP from '../../../images/others/default-profile-avatar.png'
import * as IconlyPack from 'react-iconly'
import AddCommentModal from './AddCommentModal'
import ImgPpPost from '../../../images/others/default-profile-avatar.png'
import ImgPost from '../../../images/NoImage.png'
import ImgEllipse from '../../../images/ellipse.png'
import Live from '../../../images/zoom.png'
import ImgComment1 from '../../../images/others/default-profile-avatar.png'
import NavMenuMobile from './NavMenuMobile'
import { useDispatch, useSelector } from 'react-redux'
import baseUrl from '../../../Http/backend-base-url'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import Post from '../../../services/Post'
import { fetchPostes, filterByCategory } from '../../../redux/Posts/PostAction'
import Swal from 'sweetalert2'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ConfirmParticipateEvent from '../../Modals/Frontend/ConfirmParticipateEvent'
import Events from '../../../services/Events'
import { fetchUserParticipations } from '../../../redux/participation/participationAction'
import VoirLive from '../../Modals/Frontend/VoirLive'
import 'moment/locale/fr'
import 'moment/locale/en-ca'
import { fetchEvents } from '../../../redux/events/eventsAction'
import { fetchLives } from '../../../redux/live/liveAction'
import ReportModal from './ReportModal'
import { createMarkup } from '../../../utils'

// moment.updateLocale("fr", {
//   relativeTime: {
//     future: "in %s",
//     past: "%s ans",
//     s: "1 secondes",
//     ss: "%d secondes",
//     m: "1 minute",
//     mm: "%d minutes",
//     h: "1 heure",
//     hh: "%d heures",
//     d: "1 jour",
//     dd: "%d jours",
//     w: "1 semaine",
//     ww: "%d semaines",
//     M: "1 mois",
//     MM: "%d mois",
//     y: "1 an",
//     yy: "%d ans",
//   },
// });
var FrontHome = (props) => {
  const { t } = useTranslation()
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('userToken')}`,
    },
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required('Text is required.'),
        post: yup.string().required('Post is required.'),
        user: yup.string(),
      }),
    [],
  )
  let lng = window.localStorage.getItem('i18nextLng')
  const [length, setLength] = useState(200)
  const [checked, setChecked] = useState([])
  const [showLess, setShowLess] = React.useState(true)
  const [hasMore, setHasMore] = React.useState(true)
  const [postIndex, setPostIndex] = React.useState(null)
  const { register, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const devise = useSelector((state) => state.devise)
  const posts = useSelector((state) => state.posts)
  const user = useSelector((state) => state.user)
  const categories = useSelector((state) => state.categories)
  const events = useSelector((state) => state.events)
  const userParticipations = useSelector((state) => state.userParticipations)
  const lives = useSelector((state) => state.lives)
  const [itemToShow, setItemToShow] = useState(6)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchPostes())
    dispatch(fetchEvents())
    dispatch(fetchLives())
    window.scrollTo(0, 0)
  }, [dispatch])

  useEffect(() => {
    if (user?.data?.id) {
      // let lng = window.localStorage.getItem("i18nextLng");
      // console.log("langue", lng);
      dispatch(fetchUserParticipations(user?.data?.id))
    }
  }, [user])

  // console.log("events", events);

  const onLike = async (item) => {
    let isLiked = false
    let isDisLiked = false
    if (item?.dislikes?.length) {
      isDisLiked = (await item?.dislikes?.filter(
        (like) => like?.user?.id === user?.data?.id,
      )?.length)
        ? true
        : false
    }
    if (isDisLiked) {
      onDisLike(item)
    }
    if (item?.likes?.length) {
      isLiked = (await item?.likes?.filter(
        (like) => like?.user?.id === user?.data?.id,
      )?.length)
        ? true
        : false
    }
    await console.log('is liked', isLiked)
    if (!isLiked) {
      let data = {
        post: item?.id,
        user: user?.data?.id,
      }
      let request = Post.onLike(data, config)

      await request
        .then(async (response) => {
          console.log('response', response)
          await dispatch(fetchPostes())
        })
        .catch((error) => {
          Swal.showValidationMessage(`Request failed: ${error}`)
        })
    } else {
      let like = await item?.likes?.filter(
        (like) => like?.user?.id === user?.data?.id,
      )[0]
      console.log('like', like)
      let request = Post.onDeleteLike(like?.id, config)

      await request
        .then(async (response) => {
          console.log('response', response)
          await dispatch(fetchPostes())
        })
        .catch((error) => {
          Swal.showValidationMessage(`Request failed: ${error}`)
        })
    }
  }

  const onDisLike = async (item) => {
    let isLiked = false
    if (item?.likes?.length) {
      isLiked = (await item?.likes?.filter(
        (like) => like?.user?.id === user?.data?.id,
      )?.length)
        ? true
        : false
    }
    if (isLiked) {
      onLike(item)
    }
    let isDisLiked = false
    if (item?.dislikes?.length) {
      isDisLiked = (await item?.dislikes?.filter(
        (like) => like?.user?.id === user?.data?.id,
      )?.length)
        ? true
        : false
    }
    // await console.log("is liked", isDisLiked);
    if (!isDisLiked) {
      let data = {
        post: item?.id,
        user: user?.data?.id,
      }
      let request = Post.onDisLike(data, config)

      await request
        .then(async (response) => {
          console.log('response', response)
          await dispatch(fetchPostes())
        })
        .catch((error) => {
          Swal.showValidationMessage(`Request failed: ${error}`)
        })
    } else {
      let dislike = await item?.dislikes?.filter(
        (like) => like?.user?.id === user?.data?.id,
      )[0]
      console.log('dislike', dislike)
      let request = Post.onDeleteDisLike(dislike?.id, config)

      await request
        .then(async (response) => {
          console.log('response', response)
          await dispatch(fetchPostes())
        })
        .catch((error) => {
          Swal.showValidationMessage(`Request failed: ${error}`)
        })
    }
  }

  const onSubmit = async (data, e) => {
    console.log('data', data)
    data['user'] = user?.data?.id

    let request = Post.onComment(data, config)

    await request
      .then(async (response) => {
        // console.log("response comment", response);
        await dispatch(fetchPostes())
        await e.target.reset()
        await reset()
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`)
      })
  }

  const onShowMore = (index) => {
    setPostIndex(index)
    setShowLess(false)
  }

  const handleChangeFilter = (e) => {
    let checkedNew = [...checked, parseInt(e.target.value)]

    // checked = [parseInt(e.target.value)]
    if (checked.includes(parseInt(e.target.value))) {
      checkedNew = checkedNew.filter((tes) => tes !== parseInt(e.target.value))
    }

    let x = (checkedNew) =>
      checkedNew.filter((v, i) => checkedNew.indexOf(v) === i)
    x(checkedNew) // 'John', 'Paul', 'George', 'Ringo'

    dispatch(filterByCategory(posts?.data, x(checkedNew)))
    setChecked(checkedNew)
  }

  const onParticipEvent = async (id) => {
    console.log('id', id)
    let data = {}
    data['user'] = user?.data?.id
    data['event'] = id

    let request = Events.onUserParticipate(data, config)

    await request
      .then(async (response) => {
        console.log('response participation', response)
        await dispatch(fetchUserParticipations(user?.data?.id))
        await Swal.fire({
          icon: 'success',
          title:
            "Votre Participation a été bien prise en compte. Un mail vous sera envoyé 24h avant l'évènement.",
          showConfirmButton: false,
          timer: 5000,
        })
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title:
            "Votre Participation n'est pas prise en compte, veuillez réessayer plut tard.",
          showConfirmButton: false,
          timer: 1500,
        })
        // Swal.showValidationMessage(`Request failed: ${error}`);
      })
  }

  const onDeleteParticipate = (id) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: `Êtes vous sûr de vouloir annuler votre participation à cet évènement ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Events.onDeleteParticipate(id, config)
          .then(async (response) => {
            console.log('res', response)
            await dispatch(fetchUserParticipations(user?.data?.id))
            return response
          })
          .catch((error) => {
            console.log(error)
            Swal.showValidationMessage(`Request failed: ${error}`)
          })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //  console.log("res", result);
        Swal.fire({
          icon: 'success',
          title: `Votre participation a été annulée avec succés.`,
          showConfirmButton: false,
          timer: 2000,
        })
      }
    })
  }

  const fetchMoreData = () => {
    if (itemToShow >= posts?.filteredData?.length) {
      // setItemToShow(6);
      setHasMore(false)
      return
    } else {
      // setExpand(false);
      console.log('more')
      setTimeout(() => {
        setItemToShow(itemToShow + 6)
      }, 500)
    }
    // if (this.state.items.length >= 500) {
    //   this.setState({ hasMore: false });
    //   return;
    // }

    // setTimeout(() => {
    //   this.setState({
    //     items: this.state.items.concat(Array.from({ length: 20 })),
    //   });
    // }, 500);
  }

  const onPublish = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t('message_unpublish'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Post.onEditPost(item?.id, { publiee: false }, config)
          .then(async (response) => {
            console.log('res', response)
            await dispatch(fetchPostes())
            return response
          })
          .catch((error) => {
            console.log(error)
            Swal.showValidationMessage(`Request failed: ${error}`)
          })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('res', result)
        Swal.fire({
          icon: 'success',
          title: t('success'),
          showConfirmButton: false,
          timer: 2000,
        })
      }
    })
  }

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile py-4">
            <section className="container-warc pb-5">
              <div className="pb-5">
                <div className="">
                  <div className="bg-accueil mb-2">
                    <div className="d-flex align-items-center pb-4">
                      <img
                        src={
                          !user?.isLoading
                            ? user?.data?.avatar &&
                              !user?.data?.avatar?.includes("default.png")
                              ? baseUrl + user?.data?.avatar
                              : ImgPP
                            : ImgPP
                        }
                        alt="Photoprofil"
                        className="photo-pp"
                      />
                      <p className="nom-pp pl-2">
                        {!user?.isLoading
                          ? user?.data?.prenom + " " + user?.data?.nom
                          : "..."}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-modal-add-question"
                        data-toggle="modal"
                        data-target="#addCommentModal"
                      >
                        <IconlyPack.Plus set="light" />
                        <span className="pl-2">{t("add_post_home")}</span>
                      </button>
                    </div>
                  </div>
                  {!posts?.isLoading &&
                    (posts?.filteredData?.length ? (
                      <InfiniteScroll
                        className="mb-5"
                        dataLength={itemToShow}
                        next={() => fetchMoreData()}
                        hasMore={hasMore}
                        loader={<h4>{t("en_cours")}...</h4>}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>{t("see_all")}</b>
                          </p>
                        }
                      >
                        {posts?.filteredData
                          ?.slice(0, itemToShow)
                          .map((post, index) => (
                            <div className="bg-accueil mb-4" key={post?.id}>
                              <div className="d-flex align-items-center pb-4">
                                <img
                                  src={
                                    post?.created_by?.avatar &&
                                    !post?.created_by?.avatar?.includes(
                                      "default.png"
                                    )
                                      ? baseUrl + post?.created_by?.avatar
                                      : ImgPP
                                  }
                                  alt="Photoprofil"
                                  className="photo-pp"
                                />
                                <div className="pl-2">
                                  <p className="nom-pp">
                                    {post?.created_by?.prenom +
                                      " " +
                                      post?.created_by?.nom}
                                  </p>
                                  <p className="heure-post">
                                    {lng === "en"
                                      ? moment(post?.created_at)
                                          .locale("en-ca")
                                          .fromNow(true)
                                      : moment(post?.created_at)
                                          .locale("fr")
                                          .fromNow(true)}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p className="text-post-bold">{post?.titre}</p>
                                <div className="show-hide-text wrapper">
                                  {index !== postIndex ? (
                                    <a
                                      id="show-less"
                                      className="show-more"
                                      onClick={() => onShowMore(index)}
                                      href="#"
                                    >
                                      {t("see_more")}
                                    </a>
                                  ) : null}

                                  {/* <p className="text-post-normal">
                                    
                                    {index !== postIndex
                                      ? `${post?.text.slice(0, length)}...`
                                      : post?.text}
                                  </p> */}
                                  <p
                                    className="text-post-normal"
                                    dangerouslySetInnerHTML={createMarkup(
                                      post?.text,
                                      index !== postIndex,
                                      length
                                    )}
                                  />
                                </div>
                              </div>
                              {post?.photo &&
                              !post?.photo?.includes("default.png") ? (
                                <div>
                                  <img
                                    src={
                                      post?.photo &&
                                      !post?.photo?.includes("default.png")
                                        ? baseUrl + post?.photo
                                        : ImgPost
                                    }
                                    alt="Imagepost"
                                    className="img-fluid w-full"
                                  />
                                </div>
                              ) : null}

                              <div className="icon-post-container pt-3">
                                <div className="d-flex align-items-center ml-1">
                                  <button
                                    className="btn btn-like"
                                    onClick={() => onLike(post)}
                                    title={t("like")}
                                  >
                                    <FeatherIcon
                                      icon="thumbs-up"
                                      // className="icon-comment-post"
                                      className={
                                        post?.likes?.length &&
                                        post?.likes?.filter(
                                          (like) =>
                                            like?.user?.id === user?.data?.id
                                        )?.length
                                          ? "icon-comment-post-like"
                                          : "icon-comment-post"
                                      }
                                    />
                                  </button>
                                  <span className="text-comment-post pl-2">
                                    {post?.likes?.length}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center ml-3">
                                  <button
                                    className="btn btn-dislike"
                                    onClick={() => onDisLike(post)}
                                    title={t("dislike")}
                                  >
                                    <FeatherIcon
                                      icon="thumbs-down"
                                      // className="icon-comment-post"
                                      className={
                                        post?.dislikes?.length &&
                                        post?.dislikes?.filter(
                                          (like) =>
                                            like?.user?.id === user?.data?.id
                                        )?.length
                                          ? "icon-comment-post-dislike"
                                          : "icon-comment-post"
                                      }
                                    />
                                  </button>
                                  <span className="text-comment-post pl-2">
                                    {post?.dislikes?.length}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center ml-3">
                                  <button
                                    className="btn"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseExample${post?.id}`}
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    title={t("comment")}
                                  >
                                    <FeatherIcon
                                      icon="message-square"
                                      className="icon-comment-post"
                                    />
                                  </button>
                                  <span className="text-comment-post pl-2">
                                    {post?.comments?.length}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center ml-3">
                                  {user?.data?.user_type === "coordo" ? (
                                    <button
                                      className="btn"
                                      type="button"
                                      onClick={() => onPublish(post)}
                                      title={t("unpublish")}
                                    >
                                      <FeatherIcon
                                        icon="x-square"
                                        className="icon-comment-post"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn"
                                      type="button"
                                      data-toggle="modal"
                                      data-target={`#modalReport${post?.id}`}
                                      title={t("report")}
                                    >
                                      <FeatherIcon
                                        icon="flag"
                                        className="icon-comment-post"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div
                                className="modal fade"
                                id={`modalReport${post?.id}`}
                                tabIndex="-1"
                                data-backdrop="static"
                                aria-labelledby="ReportModalLabel"
                                aria-hidden="true"
                              >
                                <ReportModal post={post} />
                              </div>
                              <div
                                className="collapse mt-4 border-top pt-4"
                                id={`collapseExample${post?.id}`}
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      !user?.isLoading
                                        ? user?.data?.avatar &&
                                          !user?.data?.avatar?.includes(
                                            "default.png"
                                          )
                                          ? baseUrl + user?.data?.avatar
                                          : ImgPpPost
                                        : ImgPpPost
                                    }
                                    alt="Photoprofil"
                                    className="photo-pp-post"
                                  />
                                  <form
                                    className="ml-3 input-file-modal p-3 position-relative d-flex w-full"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div>
                                      {/* <input
                                    name="post"
                                    {...register("post")}
                                    value={post?.id}
                                    hidden
                                  /> */}
                                      <input
                                        type="text"
                                        className="form-control input-add-comment pl-5"
                                        name="text"
                                        onChange={(e) => {
                                          setValue("post", post?.id);
                                          setValue("text", e.target.value);
                                        }}
                                        // {...register("text", {
                                        //   required: "text required"
                                        // })}
                                        placeholder={t("add_comment")}
                                      />
                                      <span className="span-icon-chat">
                                        <IconlyPack.Chat
                                          set="bold"
                                          primaryColor="#C4C4C4"
                                        />
                                      </span>
                                    </div>
                                    <button
                                      className="btn ml-auto"
                                      type="submit"
                                    >
                                      <IconlyPack.Send
                                        set="bold"
                                        primaryColor="#C4C4C4"
                                      />
                                    </button>
                                  </form>
                                </div>
                                {post?.comments?.length
                                  ? post?.comments
                                      .sort((a, b) => b?.id - a?.id)
                                      .map((comment) => (
                                        <div
                                          className="d-flex my-4"
                                          key={comment?.id}
                                        >
                                          <img
                                            // src={ImgComment1}
                                            src={
                                              comment?.user?.avatar &&
                                              !comment?.user?.avatar?.includes(
                                                "default.png"
                                              )
                                                ? baseUrl +
                                                  comment?.user?.avatar
                                                : ImgComment1
                                            }
                                            alt="Photoprofil"
                                            className="photo-pp-post"
                                          />
                                          <div className="container-comment-from ml-3 p-3">
                                            <p className="comment-from-name">
                                              {comment?.user?.prenom +
                                                " " +
                                                comment?.user?.nom}{" "}
                                              <span className="comment-content">
                                                -{" "}
                                                {/* {moment(comment?.created_at)
                                              .locale("fr")
                                              .fromNow(true)} */}
                                                {lng === "en"
                                                  ? moment(comment?.created_at)
                                                      .locale("en-ca")
                                                      .fromNow(true)
                                                  : moment(comment?.created_at)
                                                      .locale("fr")
                                                      .fromNow(true)}
                                              </span>
                                            </p>
                                            <p className="comment-content">
                                              {comment?.text}
                                            </p>
                                          </div>
                                        </div>
                                      ))
                                  : null}
                              </div>
                            </div>
                          ))}
                      </InfiniteScroll>
                    ) : (
                      <div className="gfa-rowy mt-5">
                        <div className="alert alert-warning gfa-warning">
                          {t("noData")}
                        </div>
                      </div>
                    ))}
                  {posts?.isLoading && <CardSkeleton />}
                </div>
              </div>
            </section>
            <div
              className="modal fade"
              id="addCommentModal"
              tabIndex="-1"
              aria-labelledby="addCommentModalLabel"
              aria-hidden="true"
            >
              <AddCommentModal />
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }

  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <section className="container-warc py-5">
          <div className="row mb-4">
            <div className="col-xl-3 col-lg-3 col-md-3">
              <div className="content-type-filter">
                <p className="titre-filter-type pl-3">{t("categories")}</p>
                <div className="container-checkbox-input mt-2">
                  <div className="form-checkbox-input checkbox-input">
                    {categories?.isLoading && <CategorySkeleton />}
                    {!categories?.isLoading &&
                      (categories?.data?.length ? (
                        categories?.data.map((category) => (
                          <label className="ml-0" key={category?.id}>
                            <input
                              type="checkbox"
                              onChange={handleChangeFilter}
                              name="checkbox"
                              value={category?.id}
                            />
                            <span>{category?.name}</span>
                          </label>
                        ))
                      ) : (
                        <div className="gfa-rowy">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="bg-accueil mb-4">
                <div className="d-flex align-items-center pb-4">
                  <img
                    src={
                      !user?.isLoading
                        ? user?.data?.avatar &&
                          !user?.data?.avatar?.includes("default.png")
                          ? baseUrl + user?.data?.avatar
                          : ImgPP
                        : ImgPP
                    }
                    alt="Photoprofil"
                    className="photo-pp"
                  />
                  <p className="nom-pp pl-2">
                    {!user?.isLoading
                      ? user?.data?.prenom + " " + user?.data?.nom
                      : "..."}
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-modal-add-question"
                    data-toggle="modal"
                    data-target="#addCommentModal"
                  >
                    <IconlyPack.Plus set="light" />
                    <span className="pl-2">{t("add_post_home")}</span>
                  </button>
                </div>
              </div>
              {!posts?.isLoading &&
                (posts?.filteredData?.length ? (
                  <InfiniteScroll
                    className="mb-5"
                    dataLength={itemToShow}
                    next={() => fetchMoreData()}
                    hasMore={hasMore}
                    loader={<h4>{t("en_cours")}...</h4>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>{t("see_all")}</b>
                      </p>
                    }
                  >
                    {posts?.filteredData
                      ?.slice(0, itemToShow)
                      .map((post, index) => (
                        <div className="bg-accueil mb-4" key={post?.id}>
                          <div className="d-flex align-items-center pb-4">
                            <img
                              src={
                                post?.created_by?.avatar &&
                                !post?.created_by?.avatar?.includes(
                                  "default.png"
                                )
                                  ? baseUrl + post?.created_by?.avatar
                                  : ImgPP
                              }
                              alt="Photoprofil"
                              className="photo-pp"
                            />
                            <div className="pl-2">
                              <p className="nom-pp">
                                {post?.created_by?.prenom +
                                  " " +
                                  post?.created_by?.nom}
                              </p>
                              <span className="heure-post">
                                {lng === "en"
                                  ? moment(post?.created_at)
                                      .locale("en-ca")
                                      .fromNow(true)
                                  : moment(post?.created_at)
                                      .locale("fr")
                                      .fromNow(true)}
                              </span>
                            </div>
                          </div>
                          <div>
                            <p className="text-post-bold">{post?.titre}</p>
                            <div className="show-hide-text wrapper">
                              {index !== postIndex ? (
                                <a
                                  id="show-less"
                                  className="show-more"
                                  onClick={() => onShowMore(index)}
                                >
                                  {t("see_more")}
                                </a>
                              ) : null}

                              <p
                                className="text-post-normal"
                                dangerouslySetInnerHTML={createMarkup(
                                  post?.text,
                                  index !== postIndex,
                                  length
                                )}
                              />
                               
                                {/* {index !== postIndex
                                  ? `${post?.text.slice(0, length)}...`
                                  : post?.text}
                              </p> */}
                            </div>
                          </div>
                          {post?.photo &&
                          !post?.photo?.includes("default.png") ? (
                            <div>
                              <img
                                src={
                                  post?.photo &&
                                  !post?.photo?.includes("default.png")
                                    ? baseUrl + post?.photo
                                    : ImgPost
                                }
                                alt="Imagepost"
                                className="img-fluid w-full"
                              />
                            </div>
                          ) : null}
                          {/* <div>
                            <img
                              src={
                                post?.photo &&
                                !post?.photo?.includes("default.png")
                                  ? baseUrl + post?.photo
                                  : ImgPost
                              }
                              alt="Image post"
                              className="img-fluid w-full"
                            />
                          </div> */}
                          <div className="icon-post-container pt-3">
                            <div className="d-flex align-items-center ml-3">
                              <button
                                className="btn btn-like"
                                onClick={() => onLike(post)}
                                title={t("like")}
                              >
                                <FeatherIcon
                                  icon="thumbs-up"
                                  // className="icon-comment-post"
                                  className={
                                    post?.likes?.length &&
                                    post?.likes?.filter(
                                      (like) =>
                                        like?.user?.id === user?.data?.id
                                    )?.length
                                      ? "icon-comment-post-like"
                                      : "icon-comment-post"
                                  }
                                />
                              </button>
                              <span className="text-comment-post pl-2">
                                {post?.likes?.length}
                              </span>
                            </div>
                            <div className="d-flex align-items-center ml-4">
                              <button
                                className="btn btn-dislike"
                                onClick={() => onDisLike(post)}
                                title={t("dislike")}
                              >
                                <FeatherIcon
                                  icon="thumbs-down"
                                  // className="icon-comment-post"
                                  className={
                                    post?.dislikes?.length &&
                                    post?.dislikes?.filter(
                                      (like) =>
                                        like?.user?.id === user?.data?.id
                                    )?.length
                                      ? "icon-comment-post-dislike"
                                      : "icon-comment-post"
                                  }
                                />
                              </button>
                              <span className="text-comment-post pl-2">
                                {post?.dislikes?.length}
                              </span>
                            </div>
                            <div className="d-flex align-items-center ml-4">
                              <button
                                className="btn"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapseExample${post?.id}`}
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                title={t("comment")}
                              >
                                <FeatherIcon
                                  icon="message-square"
                                  className="icon-comment-post"
                                />
                              </button>
                              <span className="text-comment-post pl-2">
                                {post?.comments?.length}
                              </span>
                            </div>
                            <div className="d-flex align-items-center ml-4">
                              {user?.data?.user_type === "coordo" ? (
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => onPublish(post)}
                                  title={t("unpublish")}
                                >
                                  <FeatherIcon
                                    icon="x-square"
                                    className="icon-comment-post"
                                  />
                                </button>
                              ) : (
                                <button
                                  className="btn"
                                  type="button"
                                  data-toggle="modal"
                                  data-target={`#modalReport${post?.id}`}
                                  title={t("report")}
                                >
                                  <FeatherIcon
                                    icon="flag"
                                    className="icon-comment-post"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="collapse mt-4 border-top pt-4"
                            id={`collapseExample${post?.id}`}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  !user?.isLoading
                                    ? user?.data?.avatar &&
                                      !user?.data?.avatar?.includes(
                                        "default.png"
                                      )
                                      ? baseUrl + user?.data?.avatar
                                      : ImgPpPost
                                    : ImgPpPost
                                }
                                alt="Photoprofil"
                                className="photo-pp-post"
                              />
                              <form
                                className="ml-3 input-file-modal p-3 position-relative d-flex w-full"
                                onSubmit={handleSubmit(onSubmit)}
                              >
                                <div>
                                  {/* <input
                                name="post"
                                {...register("post")}
                                value={post?.id}
                                id={index}
                                hidden
                              /> */}
                                  <input
                                    type="text"
                                    className="form-control input-add-comment pl-5"
                                    name="text"
                                    onChange={(e) => {
                                      console.log("comment", post?.id, index);
                                      setValue("post", post?.id);
                                      setValue("text", e.target.value);
                                    }}
                                    // {...register("text", {
                                    //   required: "text required"
                                    // })}
                                    placeholder={t("add_comment")}
                                  />
                                  <span className="span-icon-chat">
                                    <IconlyPack.Chat
                                      set="bold"
                                      primaryColor="#C4C4C4"
                                    />
                                  </span>
                                </div>
                                <button className="btn ml-auto" type="submit">
                                  <IconlyPack.Send
                                    set="bold"
                                    primaryColor="#C4C4C4"
                                  />
                                </button>
                              </form>
                            </div>
                            {post?.comments?.length
                              ? post?.comments
                                  .sort((a, b) => b?.id - a?.id)
                                  .map((comment) => (
                                    <div
                                      className="d-flex my-4"
                                      key={comment?.id}
                                    >
                                      <img
                                        // src={ImgComment1}
                                        src={
                                          comment?.user?.avatar &&
                                          !comment?.user?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl + comment?.user?.avatar
                                            : ImgComment1
                                        }
                                        alt="Photoprofil"
                                        className="photo-pp-post"
                                      />
                                      <div className="container-comment-from ml-3 p-3">
                                        <p className="comment-from-name">
                                          {comment?.user?.prenom +
                                            " " +
                                            comment?.user?.nom}{" "}
                                          <span className="comment-content">
                                            -{" "}
                                            {/* {moment(comment?.created_at)
                                          .locale("fr")
                                          .fromNow(true)} */}
                                            {lng === "en"
                                              ? moment(comment?.created_at)
                                                  .locale("en-ca")
                                                  .fromNow(true)
                                              : moment(comment?.created_at)
                                                  .locale("fr")
                                                  .fromNow(true)}
                                          </span>
                                        </p>
                                        <p className="comment-content">
                                          {comment?.text}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                              : null}
                          </div>
                          <div
                            className="modal fade"
                            id={`modalReport${post?.id}`}
                            tabIndex="-1"
                            data-backdrop="static"
                            aria-labelledby="ReportModalLabel"
                            aria-hidden="true"
                          >
                            <ReportModal post={post} />
                          </div>
                        </div>
                      ))}{" "}
                  </InfiniteScroll>
                ) : (
                  <div className="gfa-rowy mt-5">
                    <div className="alert alert-warning gfa-warning">
                      {t("noData")}
                    </div>
                  </div>
                ))}
              {posts?.isLoading && <CardSkeleton />}
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3">
              <div className="bg-accueil mb-4">
                <div className="border-bottom">
                  <p className="text-agenda pb-2">LIVE</p>
                </div>
                {events?.isLoading && userParticipations?.isLoading && (
                  <ProgramSkeleton />
                )}
                {!lives?.isLoading &&
                  !userParticipations?.isLoading &&
                  (lives?.filteredData?.length ? (
                    lives?.filteredData
                      ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
                      ?.map((live) => (
                        <Fragment key={live?.id}>
                          <div
                            className="d-flex mt-3 linkable"
                            data-toggle="modal"
                            data-target={`#VoirLive${live?.id}`}
                          >
                            <img src={Live} alt="Image" />
                            <p className="text-consultation-agenda pl-3">
                              {live?.libelle}
                            </p>
                          </div>
                          <div className="pl-5">
                            <p className="text-agenda-calendar pl-3">
                              <IconlyPack.Calendar set="bold" />
                              <span className="pl-2">
                                {moment(live?.date).format("DD/MM/YYYY")} -{" "}
                                {moment(live?.start_time, "HH:mm:ss").format(
                                  "HH:mm"
                                )}
                              </span>
                            </p>
                          </div>
                          <div
                            className="modal fade"
                            id={`VoirLive${live?.id}`}
                            tabIndex="-1"
                            // data-backdrop="static"
                            aria-labelledby="VoirLiveLabel"
                            aria-hidden="true"
                          >
                            <VoirLive live={live} />
                          </div>
                        </Fragment>
                      ))
                  ) : (
                    <div className="gfa-rowy mt-5 m-0">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
                {/* <div className="d-flex mt-3">
                  <img src={Live} alt="Image" />
                  <p className="text-consultation-agenda pl-3">Lorem ipsum</p>
                </div>
                <div className="pl-5">
                  <p className="text-agenda-calendar pl-3">
                    <IconlyPack.Calendar set="bold" />
                    <span className="pl-2">15/07/2021 à 19:00</span>
                  </p>
                </div> */}
              </div>
              <div className="bg-accueil mb-4">
                <div className="border-bottom">
                  <p className="text-agenda pb-2">{t("agenda_label")}</p>
                </div>
                {events?.isLoading && userParticipations?.isLoading && (
                  <ProgramSkeleton />
                )}
                {!events?.isLoading &&
                  !userParticipations?.isLoading &&
                  (events?.filteredData?.length ? (
                    events?.filteredData
                      ?.sort(
                        (a, b) =>
                          new Date(a?.startDate) - new Date(b?.startDate)
                      )
                      ?.map((event) => (
                        <Fragment key={event?.id}>
                          <div className="d-flex my-3">
                            <img src={ImgEllipse} alt="Image" />
                            <p className="text-consultation-agenda pl-3">
                              {event?.title}
                            </p>
                          </div>
                          <div>
                            <p className="text-agenda-calendar">
                              <IconlyPack.Calendar set="bold" />
                              <span className="pl-2">
                                {moment(event?.startDate).format(
                                  "DD/MM/YYYY - HH:MM"
                                )}
                              </span>
                            </p>
                            <div className="text-right">
                              {userParticipations?.data?.filter(
                                (item) => item?.event?.id === event?.id
                              )?.length ? (
                                <button
                                  className="btn btn-participate-event active"
                                  type="button"
                                  onClick={() =>
                                    onDeleteParticipate(
                                      userParticipations?.data?.filter(
                                        (item) => item?.event?.id === event?.id
                                      )[0]?.id
                                    )
                                  }
                                >
                                  {t("is_participated")}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-participate-event"
                                  type="button"
                                  onClick={() => onParticipEvent(event?.id)}
                                >
                                  {t("participate")}
                                </button>
                              )}
                            </div>
                          </div>
                        </Fragment>
                      ))
                  ) : (
                    <div className="gfa-rowy mt-5 m-0">
                      <div className="alert alert-warning gfa-warning mr-5">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="addCommentModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="addCommentModalLabel"
          aria-hidden="true"
        >
          <AddCommentModal />
        </div>
        <div
          className="modal fade"
          id="ConfirmParticipateEvent"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="ConfirmParticipateEventLabel"
          aria-hidden="true"
        >
          <ConfirmParticipateEvent />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default FrontHome

const CardSkeleton = () => {
  const renderModules = Array(3)
    .fill()
    .map((item, index) => (
      <Skeleton key={index}>
        <Skeleton.Circle width={40} height={40} className="mb-3" />
        <Skeleton.Rectangle height={20} width={`50%`} className="mb-3" />
        <Paragraph className="mb-3" />
        <Skeleton.Rectangle height={300} width={`100%`} className="mb-3" />
      </Skeleton>
    ))
  return <React.Fragment>{renderModules}</React.Fragment>
}

const CategorySkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="d-flex">
        {/* <Skeleton.Circle width={40} height={40} className="mb-3" /> */}
        <Skeleton.Rectangle height={24} width={24} className="mr-1" />
        {/* <Paragraph className="mb-3" /> */}
        <Skeleton.Rectangle height={24} width={`100%`} className="mb-3" />
      </Skeleton>
    ))
  return <React.Fragment>{renderModules}</React.Fragment>
}

const ProgramSkeleton = () => {
  const renderModules = Array(9)
    .fill()
    .map((item, index) => (
      <Skeleton key={index}>
        <Skeleton className="d-flex my-3">
          <Skeleton.Circle width={20} height={20} />
          <Skeleton.Rectangle height={20} width={`100%`} className="mr-1" />
        </Skeleton>
        <Skeleton className="d-flex">
          <Skeleton.Rectangle width={20} height={20} />
          <Skeleton.Rectangle height={20} width={`100%`} className="mr-1" />
        </Skeleton>
      </Skeleton>
    ))
  return <React.Fragment>{renderModules}</React.Fragment>
}
