import React from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import BlogAdminTableSkeleton from "./BlogAdminTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import ImgBlog from "../../../../images/NoImage.png";
import Swal from "sweetalert2";
import Article from "../../../../services/Article";
import { fetchArticles } from "../../../../redux/articles/ArticlesAction";
import baseUrl from "../../../../Http/backend-base-url";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

var BlogAdminTable = () => {
  const { t } = useTranslation();
  const articles = useSelector((state) => state.articles);
  const dispatch = useDispatch();

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onDelete = (item) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_article"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Article.onDeleteArticle(item?.id, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchArticles());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const photoFormatter = (cell, row) => {
    return (
      <div>
        <NavLink
          to={{
            pathname: `/detail-blog-admin/${row?.id}`,
            state: { article: row },
          }}
          className="no-link d-flex"
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
            verticalAlign: "middle",
          }}
        >
          <img
            style={{ width: "70px", height: "62px", borderRadius: "5px" }}
            // src={ImgBlog}
            src={
              row?.image && !row?.image?.includes("default.png")
                ? baseUrl + row?.image
                : ImgBlog
            }
            alt="Images"
          />
          <p
            className="pl-3 d-flex align-items-center"
            style={{
              fontWeight: 500,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            {" "}
            {cell}
          </p>
        </NavLink>
      </div>
    );
  };

  const filterTitle = (cell, row) => {
    return cell;
  };

  const categoryFormatter = (cell, row) => {
    return (
      <div
        style={{
          padding: ".5rem",
          background: "#E2E2E2",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
            verticalAlign: "middle",
          }}
        >
          {" "}
          {cell?.map(
            (item, index) =>
              item?.name + `${index < cell?.length - 1 ? " - " : " "}`
          )}
        </p>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <NavLink
          to={{
            pathname: `/detail-blog-admin/${row?.id}`,
            state: { article: row },
          }}
          className="action-btn show-btn"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </NavLink>
        <NavLink
          to={{
            pathname: `/modifier-article-admin`,
            state: { article: row },
          }}
          className="action-btn edit-btn"
          title={t("edit")}
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </NavLink>
        <button className="action-btn delete-btn" title={t("delete")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row)}
          />
        </button>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {articles?.isLoading && <BlogAdminTableSkeleton />}
            {!articles?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={articles?.data}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="50"
                    dataField="id"
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    filterValue={filterTitle}
                    dataField="titre"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    {t("title")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="date_publication"
                    dataFormat={(cell, row) =>
                      moment(cell).format("DD MMMM YYYY")
                    }
                  >
                    {t("post_date")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="150"
                    dataField="category"
                    dataFormat={(cell, row) => categoryFormatter(cell, row)}
                  >
                    {t("category")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="nb_view"
                    dataAlign="center"
                  >
                    {t("vue")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      verticalAlign: "middle",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogAdminTable;
