import React, { useState, useMemo, useEffect } from "react";
import countryList from "react-select-country-list";
import { CKEditor } from "ckeditor4-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as IconlyPack from "react-iconly";
import ReactFlagsSelect from "react-flags-select";
import useEditExpert from "../../Dashboard/Coordonnateur/GestionExpertsPersonnesRessourcesCoordonnateur/requestExpert/useEditExpert";
import baseUrl from "../../../Http/backend-base-url";
import { useTranslation } from "react-i18next";

function ModifierExpert({ expert }) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
    isDone,
  } = useEditExpert();
  const [avatar, setAvatar] = React.useState(null);
  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const [phone, setPhone] = React.useState("");
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyRelease, setIsKeyReleased] = useState(false);

  useEffect(() => {
    if (tags?.length) {
      register("domaine_expertise");
      setValue("domaine_expertise", JSON.stringify(tags));
      console.log(JSON.stringify(tags));
    } else {
      register("domaine_expertise");
      setValue("domaine_expertise", "");
    }
  }, [tags, input, register, setValue]);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("telephone");
      setValue("telephone", "+" + val);
    } else {
      setValue("telephone", "");
    }
    // console.log("val", val);
  };

  useEffect(() => {
    if (expert?.id) {
      setValue("id", expert?.id);
      setValue("domaine_expertise", expert?.domaine_expertise);
      setValue("prenom", expert?.prenom);
      setValue("nom", expert?.nom);
      setValue("pays", expert?.pays);
      setValue("email", expert?.email);
      setValue("telephone", expert?.telephone);
      if (expert?.telephone) {
        setPhone(expert?.telephone);
      }
      if (
        expert?.pays &&
        options.filter((option) => option.label === expert?.pays)?.length
      ) {
        setVal(
          options.filter((option) => option.label === expert?.pays)[0]?.value
        );
      }
      if (expert?.domaine_expertise) {
        if (IsJsonString(expert?.domaine_expertise)) {
          setTags(JSON.parse(expert?.domaine_expertise));
        }
      }
      setValue("bio", expert?.bio);
    }
  }, [expert, options, setValue]);

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    setAvatar(file);
    setValue("photo", file);
  };

  const changeHandler = (value) => {
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyRelease) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (e, index) => {
    e.preventDefault();
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (isDone) {
      //   setPhone("+221");
      //   setVal("");
      //   setInput("");
      //   setTags([]);
      //   setAvatar(null);
    }
  }, [isDone]);

  const handleReset = () => {
    // reset();
    // setPhone("+221");
    // setVal("");
    // setInput("");
    // setTags([]);
    // setAvatar(null);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">{t("edit_expert")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="prenom">
                        {t("firstName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("firstName")}
                        name="prenom"
                        {...register("prenom")}
                        className="form-control  input-add-document"
                        id="prenom"
                      />
                      {formState.errors && formState.errors.prenom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.prenom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">
                        {t("lastName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("lastName")}
                        name="nom"
                        {...register("nom")}
                        className="form-control  input-add-document"
                        id="nom"
                      />
                      {formState.errors && formState.errors.nom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">{t("skills")}</label>
                      <div className="tags-input">
                        <input
                          type="text"
                          className="form-control  input-add-document"
                          value={input}
                          onKeyDown={onKeyDown}
                          onChange={onChange}
                          onKeyUp={onKeyUp}
                        />
                        {tags.map((tag, index) => (
                          <Tag className="mb-4 tag" key={index}>
                            {tag}
                            <Delete onClick={(e) => deleteTag(e, index)} />
                          </Tag>
                        ))}
                      </div>
                      <Help>{t("skills_guide")}</Help>
                      {formState.errors &&
                        formState.errors.domaine_expertise && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.domaine_expertise?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="country">
                        {t("country")}
                        <span className="required-label">*</span>
                      </label>

                      <ReactFlagsSelect
                        className="form-control input-add-document"
                        selected={val}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                        placeholder={t("select_country")}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">
                        {t("email")}
                        {/* <span className="required-label">*</span> */}
                      </label>
                      <input
                        type="email"
                        placeholder={t("email")}
                        name="email"
                        {...register("email")}
                        className="form-control  input-add-document"
                        id="email"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="phone">{t("phone")}</label>
                      <PhoneInput
                        masks={{ sn: ".. ... .. .." }}
                        inputClass="form-control input-add-document phone-edit"
                        country={"sn"}
                        // onlyCountries={['sn']}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                          border: "none",
                          height: "49px",
                          backgroundColor: "#F2F3F3",
                        }}
                      />

                      {/* <input
                        type="tel"
                        placeholder="Tel"
                        name="phone"
                        {...register("phone")}
                        className="form-control  input-add-document"
                        id="phone"
                      /> */}
                      {formState.errors && formState.errors.phone && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.phone?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="bio">{t("bio")}</label>
                      {/* <textarea
                        className="form-control  input-add-document"
                        id="bio"
                        rows="10"
                        name="bio"
                        {...register("bio")}
                        placeholder={t("bio_guide")}
                      ></textarea> */}
                      <CKEditor
                        className="form-control  input-add-document"
                        initData={
                          expert?.bio && expert?.bio !== undefined
                            ? expert?.bio
                            : ""
                        }
                        onChange={(evt) => {
                          if (evt.editor.getData() !== undefined) {
                            setValue("bio", evt.editor.getData());
                          }
                        }}
                      />
                      {formState.errors && formState.errors.bio && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.bio?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="file">{t("profil_picture")}</label>
                      <div className="inputfile-box">
                        <input
                          type="file"
                          id="file"
                          name="photo"
                          accept="image/*"
                          className="inputfile input-add-document"
                          onChange={changeAvatar}
                        />
                        <label htmlFor="file">
                          <span
                            id="file-name"
                            className="file-box input-add-document"
                          >
                            <span className="text-input-file-costum">
                              {t("upload_image")}
                            </span>
                          </span>
                          <span className="file-button">
                            <IconlyPack.Upload
                              set="light"
                              primaryColor="#000"
                            />
                          </span>
                        </label>
                      </div>
                      <div>
                        {avatar ||
                        (expert?.photo &&
                          !expert?.photo?.includes("default.png")) ? (
                          <img
                            src={
                              avatar
                                ? URL.createObjectURL(avatar)
                                : baseUrl + expert?.photo
                            }
                            className="avatar-profil"
                            alt="Profile"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("edit")}
                    </button>
                  )}
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierExpert;

const Tag = (props) => <span className="tag" {...props}></span>;
const Delete = (props) => (
  <button className="delete btn mr-2" {...props}>
    <IconlyPack.CloseSquare set="light" primaryColor="#000" />
  </button>
);
const Help = (props) => <span className="help" {...props} />;
