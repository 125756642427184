import { GRAPH } from "../types";

export const DataChartReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case GRAPH:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};
