import baseUrl from "./Http/backend-base-url";
import Avatar from "./assets/images/Avatars.png";

export const formatDate = (date) => {
    var a = new Date(date);
    var months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date_r = a.getDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var format = date_r + ' ' + month + ' ' + year;
    return format;
}

export const formatDateHistoriq = (date) => {
    var a = new Date(date);
    var months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date_r = a.getDate();
    var hour = a.getHours();
    var min = (a.getMinutes()<10?'0':'') + a.getMinutes()  
    var format = date_r + '/' + month + '/' + year + ' ' + hour + ':' + min;
    return format;
}

export const calcTime = (time) => {

    if (time >= 0) {
        const hours = Math.floor(time / 60);
        const mins = time % 60;
        return `${hours}h ${mins}m`;
    } else {
        const hours = Math.floor(-time / 60);
        const mins = -time % 60;
        return `${hours}h ${mins}m`;
    }


};

export const getAvatar = (avatar) => {
    // console.log("avatr", avatar);
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;

    return baseUrl + avatar;
    // return Avatar;
  }
  return Avatar;
};

export function createMarkup(
  text,
  showDots,
  numberSlice = 200
) {
  return {
    __html: `${showDots ? text?.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}

export const getVideoId = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url?.match(regExp);
    return (match&&match[7].length === 11)? match[7] : false;
}