import axios from "axios";
import { event } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddEvent(data, config) {
    const response = await axios.post(event.onPostEvent(), data, config);
    return response.data;
  },
  async onEditEvent(id, data, config) {
    const response = await axios.put(event.onEditEvent(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteEvent(id, config) {
    const response = await axios.delete(event.onDeleteEvent(id), config);
    // console.log(response)
    return response.data;
  },
  async onUserParticipate(data, config) {
    const response = await axios.post(event.onParticipe(), data, config);
    return response.data;
  },
  async onDeleteParticipate(id, config) {
    const response = await axios.delete(event.onDeleteParticipate(id), config);
    // console.log(response)
    return response.data;
  },
};
