import axios from "axios";
import { catalogue } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddUniversity(data, config) {
    const response = await axios.post(
      catalogue.onPostUniversity(),
      data,
      config
    );
    return response.data;
  },
  async onEditUniversity(id, data, config) {
    const response = await axios.put(
      catalogue.onEditUniversity(id),
      data,
      config
    );
    // console.log(response)
    return response.data;
  },

  async onDeleteUniversity(id, config) {
    const response = await axios.delete(
      catalogue.onDeleteUniversity(id),
      config
    );
    // console.log(response)
    return response.data;
  },
};
