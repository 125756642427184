/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import Skeleton from "@nejcm/react-skeleton";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import NavBarVerticalV2 from "../../../Components/Dashboard/navbarVerticalV2/navbarVerticalV2";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import PhotoProfil from "../../../images/others/default-profile-avatar.png";
import { fetchUserMessages } from "../../../redux/messages/MessagesAction";
import { fetchUsers } from "../../../redux/Users/UsersAction";
import Header from "../../Navs/Header";
import { ActivitiesSkeleton, MessageSkeleton } from "./Messagerie";
import "./Messagerie.css";
var MessagerieAdmin = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [chatData, setChatData] = useState([]);
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        message: yup.string().required("Message is required."),
      }),
    []
  );
  const { register, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  console.log(formState.errors);
  const devise = useSelector((state) => state.devise);
  const userMessages = useSelector((state) => state.userMessages);
  const user = useSelector((state) => state.user);
  const [isLoading, users] = useSelector((state) => [
    state.users.isLoading,
    state.users.data?.filter((u) => u.id !== user?.data?.id) ?? [],
  ]);
  const dispatch = useDispatch();
  const scrollToEnd = () => {
    if (document.querySelector(`#lastMessage`)) {
      document.querySelector(`#lastMessage`).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  const [userSelected, setUserSelected] = useState({});
  useEffect(() => {
    setChatData(transformChatToChatData(userMessages?.data ?? [], users ?? []));
  }, [user.data.id, userMessages.data?.length, users?.length]);
  useEffect(() => {
    if (user?.data?.id) {
      dispatch(fetchUserMessages(user?.data?.id));
    }
  }, [user?.data?.id]);
  useEffect(() => {
    const uItem = location?.state?.userItem;
    if (!uItem && chatData.length > 0) {
      setUserSelected(chatData[0]);
    }
    if (uItem) {
      const chat = chatData.find((i) => i.user.id === uItem.id);
      setUserSelected(chat ?? chatData[0] ?? {});
      if (document.getElementById(`${chat?.user?.id}`)) {
        document.getElementById(`${chat?.user?.id}`).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [chatData, location]);

  useEffect(() => {
    if (userSelected?.user?.id) {
      scrollToEnd();
      setValue("receiver", userSelected?.user?.id);
      const promises = [];
      for (let m of userSelected.messages) {
        if (m.sender.id === userSelected?.user?.id && !m.read) {
          let request = axios.put(
            `${api}message/${m.id}/`,
            { read: true },
            config
          );
          promises.push(request);
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          dispatch(fetchUserMessages(user?.data?.id));
        });
      }
    }
  }, [userSelected]);

  const handleSelect = (item) => {
    setUserSelected(item);
    history.push({
      pathname: "/messages-admin",
      state: { userItem: item },
    });
  };

  const onSubmit = async (data, e) => {
    data["sender"] = user?.data?.id;
    let request = axios.post(`${api}message/`, data, config);

    await request
      .then(async (response) => {
        console.log("response message", response);
        await dispatch(fetchUserMessages(user?.data?.id));
        setValue("message", "");
        scrollToEnd();
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUsers());
    $(document).ready(function () {
      $("#action_menu_btn").click(function () {
        $(".action_menu").toggle();
      });
    });
  }, []);

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header-message"
        >
          <div className="page-body-container py-4">
            <div className="page-content-container">
              <div className="row justify-content-center h-100 bg-white">
                <div className="col-xl-3 col-lg-3 col-md-4 chat mt-0">
                  <div className="card mb-sm-3 mb-md-0 contacts_card w-full">
                    <div className="card-body contacts_body">
                      <ui className="contacts">
                        {isLoading && <ActivitiesSkeleton />}
                        {!isLoading &&
                          (chatData.length > 0 ? (
                            chatData.map((item) => (
                              <li
                                className={
                                  userSelected?.user?.id === item?.user?.id
                                    ? "user_item active"
                                    : "user_item"
                                }
                                key={item?.user?.id}
                                id={item?.user?.id}
                                onClick={() => handleSelect(item?.user)}
                              >
                                <div className="d-flex bd-highlight">
                                  <div className="img_cont">
                                    <img
                                      // src={PhotoProfil}
                                      alt=""
                                      src={
                                        item?.user?.avatar &&
                                        !item?.user?.avatar?.includes(
                                          "default.png"
                                        )
                                          ? baseUrl + item?.user?.avatar
                                          : PhotoProfil
                                      }
                                      className="rounded-circle user_img"
                                    />
                                  </div>
                                  <div className="user_info">
                                    <span>
                                      {item?.user?.prenom +
                                        " " +
                                        item?.user?.nom}
                                    </span>
                                    <p>{item?.user?.email}</p>
                                    {item.count > 0 && (
                                      <div
                                        style={{
                                          width: 20,
                                          height: 20,
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          backgroundColor: "#F00",
                                          borderRadius: "50%",
                                          color: "#FFF",
                                        }}
                                      >
                                        {item.count}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <div className="gfa-rowy mt-5">
                              <div className="alert alert-warning gfa-warning">
                                {t("noData")}
                              </div>
                            </div>
                          ))}
                      </ui>
                    </div>
                    <div className="card-footer"></div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-8 chat">
                  <div className="card w-full">
                    <div className="card-header msg_head">
                      {userSelected?.user?.id ? (
                        <div className="d-flex bd-highlight">
                          <div className="img_cont">
                            <img
                              alt=""
                              //   src={PhotoProfil}
                              src={
                                userSelected?.user?.avatar &&
                                !userSelected?.user?.avatar?.includes(
                                  "default.png"
                                )
                                  ? baseUrl + userSelected?.user?.avatar
                                  : PhotoProfil
                              }
                              className="rounded-circle user_img"
                            />
                          </div>
                          <div className="user_info">
                            <span>
                              {t("chat_with")}{" "}
                              {userSelected?.user?.prenom +
                                " " +
                                userSelected?.user?.nom}
                            </span>
                            <p>{userSelected?.messages?.length} Messages</p>
                          </div>
                        </div>
                      ) : (
                        <Skeleton className="d-flex bd-highlight">
                          <Skeleton className="img_cont mr-1">
                            <Skeleton.Circle
                              width={70}
                              height={70}
                              className="rounded-circle user_img"
                            />
                          </Skeleton>

                          <Skeleton.Rectangle width={`70%`} height={70} />
                        </Skeleton>
                      )}

                      <span id="action_menu_btn">
                        <i className="fas fa-ellipsis-v"></i>
                      </span>
                      <div className="action_menu">
                        <ul>
                          <li>
                            <i className="fas fa-user-circle"></i> View profile
                          </li>
                          <li>
                            <i className="fas fa-users"></i> Add to close
                            friends
                          </li>
                          <li>
                            <i className="fas fa-plus"></i> Add to group
                          </li>
                          <li>
                            <i className="fas fa-ban"></i> Block
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body msg_card_body">
                      {!userSelected?.user?.id && <MessageSkeleton />}
                      {userSelected?.user?.id &&
                        (userSelected?.messages?.length
                          ? userSelected.messages.map((msg) => (
                              <Fragment key={msg?.id}>
                                {msg?.sender.user_type !== "admin" ? (
                                  <div className="d-flex justify-content-start align-items-center mb-4">
                                    <div className="img_cont_msg">
                                      <img
                                        alt=""
                                        // src={PhotoProfil}
                                        src={
                                          userSelected?.user?.avatar &&
                                          !userSelected?.user?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl +
                                              userSelected?.user?.avatar
                                            : PhotoProfil
                                        }
                                        className="rounded-circle user_img_msg"
                                      />
                                    </div>
                                    <div className="msg_cotainer">
                                      <p className="text-message-item">
                                        {msg?.message}
                                      </p>
                                      <div className="content-msg-time">
                                        <span className="msg_time">
                                          {moment(msg?.created_at).calendar()}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-end align-items-center mb-4">
                                    <div className="msg_cotainer_send">
                                      <span className="name-chat-send-admin">
                                        {msg.sender.prenom} {msg.sender.nom}
                                      </span>
                                      <p className="message-item-send">
                                        {msg?.message}
                                      </p>
                                      <div className="content-time-msg-send">
                                        <span className="msg_time_send">
                                          {moment(msg?.created_at).calendar()}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="img_cont_msg">
                                      <img
                                        alt=""
                                        src={
                                          msg.sender.avatar &&
                                          !msg.sender.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl + msg.sender.avatar
                                            : PhotoProfil
                                        }
                                        className="rounded-circle user_img_msg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                            ))
                          : null)}
                      <div id="lastMessage" />
                    </div>
                    <div className="card-footer">
                      <form
                        className="input-group"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="input-group-append">
                          <span className="input-group-text attach_btn">
                            <i className="fas fa-paperclip"></i>
                          </span>
                        </div>
                        <textarea
                          name="message"
                          {...register("message")}
                          className="form-control type_msg"
                          placeholder={t("type_message")}
                        ></textarea>
                        <div ty className="input-group-append">
                          <button
                            role="button"
                            type="submit"
                            className="input-group-text send_btn"
                          >
                            <i className="fas fa-location-arrow"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagerieAdmin;

export function transformChatToChatData(data, users) {
  const t = (chat) => moment(chat.created_at).toDate().getTime();

  const finalData = [];
  const finalData1 = [];
  const sorted = [...data].sort((a, b) => t(a) - t(b));
  const usersId = Array.from(
    new Set(
      sorted.map((item) =>
        // @ts-ignore
        item.sender.user_type === "admin" ? item.receiver.id : item.sender.id
      )
    )
  );

  for (let id of usersId) {
    const messages = sorted.filter(
      (s) => s.sender.id === id || s.receiver.id === id
    );
    const count = messages.filter((f) => f.sender.id === id && !f.read).length;
    const user =
      messages[0].sender.id === id ? messages[0].sender : messages[0].receiver;
    finalData.push({
      lastMessage: messages[messages.length - 1],
      messages,
      user,
      count,
    });
  }
  const filtred = users.filter(
    (u) => finalData.findIndex((f) => f.user.id === u.id) === -1
  );
  for (let u of filtred) {
    finalData1.push({
      lastMessage: null,
      messages: [],
      user: u,
      count: 0,
    });
  }

  return [...finalData.reverse(), ...finalData1];
}
