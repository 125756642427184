/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./GuidesCoordonnateur.css";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import AjouterQuestionGuideModal from "../../../Modals/Coordonnateur/AjouterQuestionGuideModal";
import Header from "../../../Navs/Header";
import ModifierQuestionGuideModal from "../../../Modals/Coordonnateur/ModifierQuestionGuideModal";
import Guide from "../../../../services/Guide";
import { fetchFaqs } from "../../../../redux/faqs/faqAction";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../../../Modules/Pagination/Pagination";

var GuidesCoordonnateur = () => {
  const { t } = useTranslation();
  const faqs = useSelector((state) => state.faqs);
  const dispatch = useDispatch();
  const [per_page] = useState(6);

  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchFaqs());
  }, [dispatch]);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (faqs?.data?.length) {
      formatData(faqs?.data);
    }
  }, [faqs, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);

    setLastIndex(indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };
  const onDelete = (id) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_faq"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Guide.onDeleteFaq(id, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchFaqs());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
    <div className="component-dashboard-coordonnateur">
      <div className="no-view-mobile">
        <FrontHeader />
      </div>
      <div className="no-view-desktop">
        <Header />
      </div>
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">{t("guide")}</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjouterQuestionGuideModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="#003DA5" />
                    </span>

                    <span className="adding-item-label">
                      {t("add_question")}
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="py-5" id="accordion">
                    {faqs?.isLoading && <ForumSkeleton />}
                    {!faqs?.isLoading &&
                      (currentList?.length ? (
                        currentList.map((faq) => (
                          <div
                            className="row mb-3 shadow-category align-items-center"
                            key={faq?.id}
                            id={`headingOne${faq?.id}`}
                          >
                            <div
                              className="col-xl-7 col-lg-7 col-md-4 pb-3"
                              data-toggle="collapse"
                              data-target={`#collapseOne${faq?.id}`}
                              aria-expanded="false"
                              aria-controls={`#collapseOne${faq?.id}`}
                            >
                              <div className="">
                                <div className="bloc-category-inline">
                                  <div className=" bloc-liste">
                                    <NavLink to="#" className="no-link">
                                      <span className="titre-category-prestation">
                                        {faq?.titre}
                                      </span>
                                    </NavLink>
                                    <div className="date-faq mt-2">
                                      {t("le")}{" "}
                                      {moment(faq?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 pb-3">
                              <div className="bloc-information-forum">
                                <div className="text-center">
                                  <span className="consultation-number">
                                    {faq?.nb_view}
                                  </span>
                                  <div>
                                    <span className="consultation-name">
                                      Consultations
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 pb-3 d-flex justify-content-end">
                              <div>
                                <span
                                  className="bloc-icon m-2"
                                  title={t("edit")}
                                >
                                  <IconlyPack.Edit
                                    set="light"
                                    className="linkable"
                                    data-toggle="modal"
                                    data-target={`#ModifierQuestionGuideModal${faq?.id}`}
                                  />
                                </span>
                              </div>
                              <div>
                                <span
                                  className="bloc-icon m-2"
                                  title={t("delete")}
                                >
                                  <FeatherIcon
                                    className="linkable"
                                    icon="trash-2"
                                    onClick={() => onDelete(faq?.id)}
                                  />
                                </span>
                              </div>
                            </div>
                            <div
                              id={`collapseOne${faq?.id}`}
                              className="collapse"
                              aria-labelledby={`headingOne${faq?.id}`}
                              data-parent="#accordion"
                            >
                              <div className="card-body">
                                <div
                                  className="text-blog-detail"
                                  dangerouslySetInnerHTML={{
                                    __html: faq?.contenu,
                                  }}
                                ></div>
                                {/* {faq?.contenu} */}
                              </div>
                            </div>
                            <div
                              className="modal fade"
                              id={`ModifierQuestionGuideModal${faq?.id}`}
                              tabIndex="-1"
                              data-backdrop="static"
                              aria-labelledby="ModifierQuestionGuideModalLabel"
                              aria-hidden="true"
                            >
                              <ModifierQuestionGuideModal faq={faq} />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {currentList?.length ? (
                  <PaginationComponent
                    per_page={per_page}
                    current_page={current_page}
                    total_data={total_data}
                    handleClick={handleClick}
                    lastIndex={lastIndex}
                  />
                ) : null}
                <div
                  className="modal fade"
                  id="AjouterQuestionGuideModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterQuestionGuideModalLabel"
                  aria-hidden="true"
                >
                  <AjouterQuestionGuideModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidesCoordonnateur;

const ForumSkeleton = () => {
  return (
    <section>
      {Array(4)
        .fill()
        .map((item, index) => (
          <div
            className="row mb-3 shadow-category align-items-center"
            key={index}
          >
            <div className=" col-xl-7 col-lg-7 col-md-7">
              <div className="bloc-category-inline">
                <div className=" bloc-liste">
                  <div className="no-link">
                    <span className="titre-category-prestation">
                      <Skeleton height={36} width={250} />
                    </span>
                  </div>
                  <div className="date-faq mt-2">
                    <Skeleton width={`80%`} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-2 d-flex">
              <div className="bloc-information-forum">
                <div>
                  <span className="mr-1 consultation-number">
                    <Skeleton width={100} />
                  </span>
                  <div>
                    <span className="mr-1 consultation-name">
                      <Skeleton width={100} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 d-flex align-items-center justify-content-end">
              <Skeleton circle={true} height={50} width={50} />
              &nbsp;&nbsp;
              <Skeleton circle={true} height={50} width={50} />
            </div>
          </div>
        ))}
    </section>
  );
};
