import { FETCH_PLAYLIST } from "../types";

export const fetchPlaylist = (data, nextPageToken) => {
  return async (dispatch) => {
    return dispatch({
      type: FETCH_PLAYLIST,
      payload: {
        data,
        nextPageToken,
      },
    });
  };
};
