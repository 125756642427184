import React, { Component } from "react";
import { Link, NavLink, use } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Logo from "../../../images/logo.png";
import * as IconlyPack from "react-iconly";
import EmptyHeader from "../../Auth/EmptyHeader";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default class DeviceDetect extends Component {
  render() {
    return (
      <>
        <BrowserView>
          <h1>This is rendered only in browser</h1>
        </BrowserView>
        <MobileView>
          <div className="connexion-page">
            <EmptyHeader />
            <div className="login-page-container">
              <section className="auth-page-content">
                <form>
                  <div className="row auth-page-row">
                    <div className="col-md-4 offset-md-4 auth-page-col">
                      <div className="row auth-form-row">
                        <div className="logo-ecompta mb-4">
                          <img src={Logo} alt="Logo E-compte" />
                        </div>
                        <h1 className="auth-form-title m-b-90">
                          Bienvenue à WARC
                        </h1>
                        <div className="col-md-12 d-flex justify-content-center mb-4">
                          <p className="text-comment">
                            Vous êtes nouveau ?{" "}
                            <NavLink
                              className="color-green no-link"
                              to="/app/inscription"
                            >
                              Créer un compte
                            </NavLink>
                          </p>
                        </div>
                        <div className="col-md-12 auth-input-col m-b-20">
                          <div className="form-group auth-form-group position-relative">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="label-ecompta"
                            >
                              email
                            </label>
                            <input
                              name="email"
                              type="email"
                              className="form-control auth-form-control pl-3"
                              id="identifiant"
                              placeholder="Adresse email"
                            />
                          </div>
                        </div>
                        <div className="col-md-12 auth-input-col m-b-5">
                          <div className="form-group auth-form-group position-relative">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="label-ecompta"
                            >
                              Mot de passe
                            </label>
                            <input
                              name="password"
                              type="password"
                              className="form-control auth-form-control pl-3"
                              id="password"
                              placeholder="Mot de passe"
                            />
                            {/* <span className="icon-password"><IconlyPack.Hide set="light" primaryColor="#9FA2B4"/></span> */}
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end w-full">
                          <NavLink
                            to="/register"
                            className="pas-de-compte py-4"
                          >
                            Mot de passe oublié ?
                          </NavLink>
                        </div>
                        <div className="col-md-12 auth-input-col py-4">
                          <NavLink
                            to="/dashboard"
                            className="btn auth-submit-btn"
                          >
                            Connexion
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row mx-0 pt-5 mt-5 py-md-4 py-3">
                  <div className="col-md-4 offset-md-5 mt-lg-0 mt-5 px-0 d-lg-flex">
                    <p className="copyright-connexion">
                      ©2021 All Rights Reserved. WARC.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </MobileView>
      </>
    );
  }
}
