// import api from "../../http/global-vars";
import api from "../../Http/global-vars";
import { ME } from "../types";

export const fetchMe = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    let token = window.sessionStorage.getItem("userToken");
    const url = api + "me/";
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data categories", data)
          window.sessionStorage.setItem("userID", data?.id);
          dispatch({
            type: ME,
            payload: data,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
