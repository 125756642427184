import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import Expert from "../../../../../services/Expert";
import { fetchExperts } from "../../../../../redux/experts/ExpertAction";
import { useTranslation } from "react-i18next";

function useEditExpert() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required(t("required")).nullable(),
        nom: yup.string().required(t("required")).nullable(),

        pays: yup.string().required(t("required")).nullable(),
        domaine_expertise: yup.string().nullable(),
        bio: yup.string().nullable(),
        photo: yup.mixed().nullable(),
        email: yup.string().email(t("invalid_mail")).nullable(),
        telephone: yup.string().nullable(),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    let fd = new FormData();
    fd.append("prenom", formValues?.prenom);
    fd.append("nom", formValues?.nom);
    fd.append("pays", formValues?.pays);
    fd.append("domaine_expertise", formValues?.domaine_expertise);
    fd.append("bio", formValues?.bio);
    fd.append("telephone", formValues?.telephone);
    if (formValues?.email && formValues?.email !== undefined) {
      fd.append("email", formValues?.email);
    }

    if (formValues?.photo) {
      fd.append("photo", formValues?.photo);
    }

    // formValues["password"] = "passerty";
    var request = Expert.onEditExpert(formValues?.id, fd, config);

    await request
      .then(async (resp) => {
        await dispatch(fetchExperts());
        await Swal.fire({
          icon: "success",
          title: "Success!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$("#ModifierExpertModal" + formValues?.id).modal("hide");
        await setIsDone(true);
        // await e.target.reset();
        // await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: "Cet email est déja relié à un compte.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default useEditExpert;
