/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import Skeleton from "@nejcm/react-skeleton";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React, { Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import PhotoProfil from "../../../images/others/default-profile-avatar.png";
import { fetchUserMessages } from "../../../redux/messages/MessagesAction";
import { getAvatar } from "../../../utils";
import FrontFooter from "../frontFooter/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import { useChatData } from "./Messagerie";

var DetailsMessage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, userSelected } = useChatData();
  const scrollToEnd = () => {
    if (document.querySelector(`#lastMessage`)) {
      document.querySelector(`#lastMessage`).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const dispatch = useDispatch();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        message: yup.string().required("Message is required."),
      }),
    []
  );
  const { register, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!userSelected) {
      history.replace("/app/messagerie");
    }
  }, []);

  const onSubmit = async (data, e) => {
    data["sender"] = user?.data?.id;

    let request = axios.post(`${api}message/`, data, config);

    await request
      .then(async (response) => {
        console.log("response message", response);
        dispatch(fetchUserMessages(user?.data?.id));
        setValue("message", "");
        scrollToEnd();
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };
  useEffect(() => {
    if (userSelected?.user?.id) {
      setValue("receiver", userSelected?.user?.id);
      scrollToEnd();
    }
  }, [userSelected]);

  useEffect(() => {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $("#action_menu_btn").click(function () {
        $(".action_menu").toggle();
      });
    });
  }, []);
  if (!userSelected) return null;

  return (
    <div>
      <div className="component-front-home">
        <NavMenuMobile />
        <div className="content-view-start view-start-mobile">
          <div className="messagerie">
            <div className="row justify-content-center h-100 mt-5">
              <div className=" chat">
                <div className="card">
                  <div className="card-header msg_head">
                    <div className="d-flex bd-highlight align-items-center">
                      <div>
                        <NavLink to="/app/messagerie" className="no-link">
                          <IconlyPack.CaretLeft
                            set="bold"
                            primaryColor="#000"
                          />
                        </NavLink>
                      </div>
                      {userSelected?.user?.id ? (
                        <div className="d-flex pl-2">
                          <div className="img_cont">
                            <img
                              alt=""
                              src={getAvatar(userSelected?.user?.avatar)}
                              className="rounded-circle user_img"
                            />
                          </div>
                          <div className="user_info">
                            <span>
                              {t("chat_with")}{" "}
                              {userSelected?.user?.prenom +
                                " " +
                                userSelected?.user?.nom}
                            </span>
                            <p>{userSelected?.messages?.length} Messages</p>
                          </div>
                        </div>
                      ) : (
                        <Skeleton className="d-flex pl-2">
                          {/* <Skeleton className="img_cont mr-1"> */}
                          <Skeleton.Circle
                            width={70}
                            height={70}
                            className="rounded-circle user_img"
                          />
                          <Skeleton>
                            <Skeleton.Rectangle
                              className="user_info mb-1"
                              width={150}
                              height={30}
                            />
                            <Skeleton.Rectangle
                              className="user_info"
                              width={150}
                              height={30}
                            />
                          </Skeleton>
                        </Skeleton>
                      )}
                    </div>
                    <span id="action_menu_btn">
                      <i className="fas fa-ellipsis-v"></i>
                    </span>
                    <div className="action_menu">
                      <ul>
                        <li>
                          <i className="fas fa-user-circle"></i> View profile
                        </li>
                        <li>
                          <i className="fas fa-users"></i> Add to close friends
                        </li>
                        <li>
                          <i className="fas fa-plus"></i> Add to group
                        </li>
                        <li>
                          <i className="fas fa-ban"></i> Block
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body msg_card_body">
                    {!userSelected?.user?.id && <MessageSkeleton />}

                    {userSelected?.user?.id &&
                      (userSelected?.messages?.length
                        ? userSelected.messages.map((msg) => (
                            <Fragment key={msg?.id}>
                              {msg?.sender?.id === userSelected?.user?.id ||
                              msg?.sender?.user_type === "admin" ? (
                                <div className="d-flex justify-content-start mb-4">
                                  <div className="img_cont_msg">
                                    <img
                                      alt=""
                                      src={getAvatar(msg.sender?.avatar)}
                                      className="rounded-circle user_img_msg"
                                    />
                                  </div>
                                  <div className="msg_cotainer">
                                    {msg?.message}
                                    <span className="msg_time">
                                      {/* 8:40 AM, Today */}
                                      {moment(msg?.created_at).calendar()}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-end mb-4">
                                  <div className="msg_cotainer_send">
                                    {msg?.message}
                                    <span className="msg_time_send">
                                      {moment(msg?.created_at).calendar()}
                                    </span>
                                  </div>
                                  <div className="img_cont_msg">
                                    <img
                                      alt=""
                                      src={getAvatar(user?.data?.avatar)}
                                      className="rounded-circle user_img_msg"
                                    />
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          ))
                        : null)}
                    <div id="lastMessage" />
                  </div>
                  <div className="card-footer">
                    <form
                      className="input-group"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="input-group-append">
                        <span className="input-group-text attach_btn">
                          <i className="fas fa-paperclip"></i>
                        </span>
                      </div>
                      <textarea
                        name="message"
                        {...register("message")}
                        className="form-control type_msg"
                        placeholder={t("type_message")}
                      ></textarea>
                      <div className="input-group-append">
                        <button
                          type="submit"
                          className="input-group-text send_btn"
                        >
                          <i className="fas fa-location-arrow"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FrontFooter />
      </div>
    </div>
  );
};

export default DetailsMessage;

const MessageSkeleton = () => {
  return (
    <Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
      <Skeleton className="d-flex justify-content-start mb-4">
        <Skeleton className="img_cont ">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>

        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
      </Skeleton>
      <Skeleton className="d-flex justify-content-end mb-4">
        <Skeleton.Rectangle
          width={`70%`}
          height={40}
          style={{ borderRadius: "25px" }}
        />
        <Skeleton className="img_cont">
          <Skeleton.Circle width={40} height={40} className="img_cont_msg" />
        </Skeleton>
      </Skeleton>
    </Skeleton>
  );
};
