import React from "react";

function ConfirmParticipateEvent({ document }) {
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header py-3">
          <h5 className="modal-title text-titre" id="ConfirmParticipateEvent">
            Message de confirmation
          </h5>
          <button
            type="button"
            className="close btn-close-modal-comment"
            data-dismiss="modal"
            aria-label="Close"
            //   onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <div className="modal-body">
            <div className="pb-5 pt-3 text-center">
            </div>
            <div className="pb-5 mb-5">
              <p className="text-paragraph" style={{ color: "#000000" }}>
                Votre Participation a été bien prise en compte. Un mail vous sera envoyé
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmParticipateEvent;
