import React from "react";
import * as IconlyPack from "react-iconly";
import "./DocumentsAdmin.css";

import { useTranslation } from "react-i18next";
import AddSlideModal from "../../../Modals/Coordonnateur/AddSlideModal";
import SlideTable from "../../../Tables/Admin/DocumentsAdminTable/SlideTable";

var Slides = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <div
          className="adding-item-container linkable"
          data-toggle="modal"
          data-target="#AjouterSlideModal"
        >
          <div>
            <span className="icon-container">
              <IconlyPack.Plus set="light" primaryColor="#003DA5" />
            </span>
            <span className="adding-item-label">{t("add_slide")}</span>
          </div>
        </div>
      </div>
      <SlideTable />
      <div
        className="modal fade"
        id="AjouterSlideModal"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="AjouterDocumentModalLabel"
        aria-hidden="true"
      >
        <AddSlideModal />
      </div>
    </>
  );
};

export default Slides;
