import React from "react";
import { NavLink } from "react-router-dom";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import * as IconlyPack from "react-iconly";
import baseUrl from "../../../Http/backend-base-url";
import { getVideoId } from "../../../utils";
var PlaylistItem = ({ item, devise }) => {
  const { snippet } = item;
  const { title, thumbnails } = snippet;
  const { medium } = thumbnails;
  return (
    <div>
      <div className="details-dossier-item media">
        <div className="bloc-card">
          <NavLink
            to={{
              pathname: `/app/media/${item.id}`,
              state: item,
            }}
            className="no-link"
          >
            <div className="card">
              <div className="card-body" style={{ padding: "0px !important;" }}>
                <div
                  className="py-0"
                  style={{
                    minHeight: devise ? "20vw" : "14vw",
                    height: devise ? "" : "14vw",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={medium.url}
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                </div>
                <div className="dossier-infos-label-container mt-2">
                  <div className="m-2">
                    <p className="title-liste-dossier-item">{title}</p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PlaylistItem;

export const PlaylistVideo = ({ item, playlist, devise, videos }) => {
  const { snippet } = item;
  const { title, thumbnails } = snippet;
  const { medium } = thumbnails;
  return (
    <div>
      <div className="details-dossier-item media">
        <div className="bloc-card">
          <NavLink
            to={{
              pathname: `/app/media/read/${item.id}`,
              state: { item, playlist, videos },
            }}
            className="no-link position-relative"
          >
            <div className="card">
              <div className="card-body" style={{ padding: "0px !important;" }}>
                <div
                  className="py-0"
                  style={{
                    minHeight: devise ? "20vw" : "14vw",
                    height: devise ? "" : "14vw",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={medium?.url}
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                  <div
                    className="play-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0,0,0,.7)",
                      display: "none",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconlyPack.Play set="bold" primaryColor="#FFF" />
                  </div>
                </div>
                <div className="dossier-infos-label-container mt-2">
                  <div className="m-2">
                    <p className="title-liste-dossier-item">{title}</p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export const VideoItem = ({ item, devise }) => {
  const [videoId, setVideoId] = React.useState("");
  const [url, setUrl] = React.useState("");
  React.useEffect(() => {
    if (item?.lien) {
      console.log("lien", getVideoId(item?.lien));
      if (getVideoId !== false) {
        setVideoId(getVideoId(item?.lien));
      }
    }
  }, [item]);

  React.useEffect(() => {
    if (item?.lien) {
      if (videoId) {
        setUrl(`https://www.youtube.com/embed/${videoId}`);
      } else {
        setUrl(item?.lien);
      }
    } else {
      setUrl(baseUrl + item?.video);
    }
  }, [item, videoId]);
  return (
    <div>
      <div className="details-dossier-item media">
        <div className="bloc-card">
          <NavLink
            to={{
              pathname: `/app/media/read-video/${item.id}`,
              state: { item },
            }}
            className="no-link position-relative"
          >
            <div className="card">
              <div className="card-body" style={{ padding: "0px !important;" }}>
                <div
                  className="py-0"
                  style={{
                    minHeight: devise ? "20vw" : "14vw",
                    height: devise ? "" : "14vw",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {!!url && (
                    <iframe
                      src={url}
                      title={item?.title + "video"}
                      width={"100%"}
                      height={"100%"}
                      controls
                      allowFullScreen
                    ></iframe>
                  )}
                  {/* <video controls={false} width={"100%"} height="100%">
                    <source src={baseUrl + item.video} type="video/mp4" />
                  </video> */}
                  <div
                    className="play-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0,0,0,.7)",
                      display: "none",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconlyPack.Play set="bold" primaryColor="#FFF" />
                  </div>
                </div>
                <div className="dossier-infos-label-container mt-2">
                  <div className="m-2">
                    <p className="title-liste-dossier-item">{item.title}</p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
