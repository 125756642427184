import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import AuthService from "../../services/AuthServices";
import { useDispatch } from "react-redux";
import { fetchMe } from "../../redux/user/userAction";
import { useTranslation } from "react-i18next";

function UseLoginForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t("invalid_mail")).required(t("required")),
        password: yup.string().required(t("required")),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var request = AuthService.onLogin(formValues);

    await request
      .then(async (resp) => {
        const config = {
          headers: {
            Authorization: `Bearer ${resp?.token}`,
          },
        };
        // console.log("resp", resp);
        let requestMe = AuthService.onMe(config);
        await requestMe
          .then(async (res) => {
            // console.log("me", res?.user_type);
            if (res?.id && res?.user_type === "fulbrighter") {
              window.sessionStorage.setItem("userID", res?.id);
              window.sessionStorage.setItem("userType", res?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: t("success"),
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location = "/app/home";
              });
            } else if (res?.id && res?.user_type === "coordo") {
              window.sessionStorage.setItem("userID", res?.id);
              window.sessionStorage.setItem("userType", res?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: t("success"),
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location = "/dashboard-coordonnateur";
              });
            } else if (res?.id && res?.user_type === "admin") {
              window.sessionStorage.setItem("userID", res?.id);
              window.sessionStorage.setItem("userType", res?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: t("success"),
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location = "/dashboard-admin";
              });
            } else {
              //   toast.error("Connexion non authorisée!");
              Swal.fire({
                icon: "error",
                title: t("unauthorized"),
                showConfirmButton: false,
                timer: 3000,
              });
            }
            setIsSuccessfullySubmitted(false);
          })
          .catch((err) => {
            setIsSuccessfullySubmitted(false);
            console.log("err", err, err?.response);
            //toast.error("This didn't work.")
            if (err?.response === undefined) {
              Swal.fire({
                icon: "error",
                title: t("unauthorized"),
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });

        // getData("me", resp?.token)
        //   .then(async (res) => {
        //     console.log("res", res?.id);

        //     if (res?.id && res?.user_type === "fulbrighter") {
        //       window.sessionStorage.setItem("userType", res?.user_type);
        //       window.sessionStorage.setItem("userToken", resp?.token);
        //       await dispatch(fetchMe());
        //       await Swal.fire({
        //         icon: "success",
        //         title: "Connexion réussie!",
        //         showConfirmButton: false,
        //         timer: 1500,
        //       }).then(() => {
        //         // window.location = "/app/home";
        //       });
        //     } else if (res?.id && res?.user_type === "coordo") {
        //       window.sessionStorage.setItem("userType", res?.user_type);
        //       window.sessionStorage.setItem("userToken", resp?.token);
        //       await dispatch(fetchMe());
        //       await Swal.fire({
        //         icon: "success",
        //         title: "Connexion réussie!",
        //         showConfirmButton: false,
        //         timer: 1500,
        //       }).then(() => {
        //         window.location = "/dashboard-coordonnateur";
        //       });
        //     } else {
        //       //   toast.error("Connexion non authorisée!");
        //       Swal.fire({
        //         icon: "error",
        //         title: "Connexion non authorisée!",
        //         showConfirmButton: false,
        //         timer: 3000,
        //       });
        //     }
        //     setIsSuccessfullySubmitted(false);
        //   })
        //   .catch((err) => {
        //     setIsSuccessfullySubmitted(false);
        //     console.log("err", err, err?.response);
        //     //toast.error("This didn't work.")
        //     if (err?.response === undefined) {
        //       Swal.fire({
        //         icon: "error",
        //         title: "Connexion non authorisée!",
        //         showConfirmButton: false,
        //         timer: 3000,
        //       });
        //     }
        //   });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.non_field_errors) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: t("non_field_errors"),
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.message) {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          //   toast.error("Veuillez vérifier votre connexion internet.");
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseLoginForm;
