import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { DataContext } from "../store/GlobalState";
// import { getData } from "../utils/fetchData";
// import { DataContext } from "../../redux/GlobalState";
// import { getData } from "../../utils/fetchData";
// import { useHistory } from "react-router";
// import Swal from "sweetalert2";
// import AuthService from "../../services/AuthServices";
import { useTranslation } from "react-i18next";

function UseRegisterForm() {
  const { t } = useTranslation();
  // const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
        passwordConfirmation: yup
          .string()
          .oneOf([yup.ref("password"), null], t("match_password")),
        prenom: yup.string().required(t("required")),
        nom: yup.string().required(t("required")),
        num_etudiant: yup.string(),
        statut: yup.string().required(t("required")).nullable(),
        phone: yup.string(),
        numero_urgence: yup.string(),
        adresse: yup.string(),
        pays: yup.string(),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    // setIsSuccessfullySubmitted(true);
    formValues["user_type"] = "fulbrighter";
    console.log("data", formValues);
    // var request = AuthService.onRegister(formValues);

    // await request
    //   .then(async (resp) => {
    //     // console.log('resp', resp)
    //     // window.sessionStorage.setItem("userToken", resp?.token);
    //     await Swal.fire({
    //       icon: "success",
    //       title: t("success"),
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //     // await dispatch(fetchMe());
    //     setTimeout(() => {
    //       window.location = "/app/connexion";
    //     }, 1700);
    //   })
    //   .catch((error) => {
    //     console.error("error", error, error?.response);
    //     if (
    //       error?.response?.data?.email?.length &&
    //       error?.response?.data?.email[0] ===
    //         "user with this email already exists."
    //     ) {
    //       //   toast.error("Email ou mot de passe incorrect.");
    //       Swal.fire({
    //         icon: "error",
    //         title: t("email_used"),
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //     } else if (error?.response?.data?.non_field_errors) {
    //       //   toast.error("Email ou mot de passe incorrect.");
    //       Swal.fire({
    //         icon: "error",
    //         title: t("non_field_errors"),
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //     } else if (error?.response?.data?.status === "failure") {
    //       //   toast.error(error?.response?.data);
    //       Swal.fire({
    //         icon: "error",
    //         title: error?.response?.data?.message,
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //     } else {
    //       //   toast.error("Veuillez vérifier votre connexion internet.");
    //       Swal.fire({
    //         icon: "error",
    //         title: "Veuillez vérifier votre connexion internet.",
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //     }
    //     setIsSuccessfullySubmitted(false);
    //   });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseRegisterForm;
