/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CaretLeft, Download } from "react-iconly";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import { useReportAuthentification } from "../Documents.js/AnnualReportsFront";
import FrontFooter from "../frontFooter/frontFooter";
import FrontHeader from "../frontHeader/frontHeader";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import "./DetailsDocument.css";

var DetailsDocument = ({ annual }) => {
  const history = useHistory();
  const abble = useReportAuthentification(annual);
  const location = useLocation();
  const [document, setDocument] = useState({});
  const devise = useSelector((state) => state.devise);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location?.state?.document) {
      setDocument(location?.state?.document);
    } else {
      history.replace("/app/annual-reports");
    }
  }, []);
  if (!abble && annual) return null;
  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile">
            <div
              className="container-warc mt-5 py-5"
              style={{ minHeight: "500px" }}
            >
              <div className="bloc-entete-document py-4">
                <div>
                  <NavLink
                    to={annual ? "/app/annual-reports" : "/app/documents"}
                    className="no-link"
                  >
                    <CaretLeft set="bold" primaryColor="#000" />
                  </NavLink>
                </div>
                <div>
                  <a
                    className="btn btn-download-document"
                    href={baseUrl + document?.fichier}
                    download
                    target="_blank"
                  >
                    <Download set="bold" primaryColor="#003DA5" />
                    <span className="pl-2">Télécharger</span>
                  </a>
                </div>
              </div>
              <div className="pb-5 pt-3 text-center">
                <h1 className="h3-theme">{document?.title} </h1>
              </div>
              <div className="pb-5 mb-5">
                <p className="text-paragraph" style={{ color: "#000000" }}>
                  {document?.description}
                </p>
              </div>
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-liste-projet bg-white">
      <FrontHeader />
      <div className="content-view-start">
        <div
          className="container-warc mt-5 py-5"
          style={{ minHeight: "700px" }}
        >
          <div className="bloc-entete-document py-4">
            <div>
              <NavLink
                to={annual ? "/app/annual-reports" : "/app/documents"}
                className="no-link"
              >
                <CaretLeft set="bold" primaryColor="#000" />
              </NavLink>
            </div>
            <div>
              <a
                className="btn btn-download-document"
                // onClick={() =>
                //   download(baseUrl + document?.fichier, document?.title)
                // }
                href={baseUrl + document?.fichier}
                download
                target="_blank"
              >
                <Download set="bold" primaryColor="#003DA5" />
                <span className="pl-2">Télécharger</span>
              </a>
            </div>
          </div>
          <div className="pb-5 pt-3 text-center">
            <h1 className="h3-theme">{document?.title} </h1>
          </div>
          <div className="pb-5 mb-5">
            <p className="text-paragraph" style={{ color: "#000000" }}>
              {document?.description}
            </p>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default DetailsDocument;
