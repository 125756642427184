import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import api from "../../../Http/global-vars";

var NousContacter = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);
  const Schema = yup.object().shape({
    message: yup.string().required(t("required")),
    email: yup.string().email(t("invalid_mail")).required(t("required")),
    user: yup.string().nullable(),
  });

  const { reset, formState, register, handleSubmit, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(Schema),
    });
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
  //   },
  // };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  useEffect(() => {
    if (user?.data?.id) {
      setValue("email", user.data.email);
      setValue("user", user.data.id);
    }
  }, [setValue, user]);

  useEffect(() => {
    if (isDone) {
      setValue("message", "");
    }
  }, [isDone, setValue]);
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (user?.data?.id) {
      formValues["user"] = user?.data?.id;
    }
    console.log("data", formValues);

    var request = axios.post(`${api}contactbyvisitor/`, formValues);

    await request
      .then(async (resp) => {
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$("#NousContacter").modal("hide");

        await setIsDone(true);
        // await e.target.reset();
        // await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">{t("contact_us")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => reset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmitForm)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <input
                        type="email"
                        placeholder={t("email") + "*"}
                        name="email"
                        {...register("email")}
                        className="form-control input-add-document"
                        id="email"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <textarea
                        className="form-control  input-add-document"
                        id="bio"
                        rows="5"
                        name="message"
                        {...register("message")}
                        placeholder={t("type_message") + "*"}
                      ></textarea>
                      {formState.errors && formState.errors.message && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.message?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  {/* <button type="submit" className="btn crud-submit-btn">
                    ADD
                  </button> */}
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("send")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NousContacter;
