import axios from "axios";
import api from "../../Http/global-vars";
import { CONFIG } from "../types";

export const fetchConfig = () => {
  return async (dispatch) => {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    //   },
    // };
    const url = api + "getconfig/";
    await axios
      .get(url)
      .then(async (res) => {
        // const data = await res.json();
        console.log("config", res?.data);
        dispatch({
          type: CONFIG,
          payload: res?.data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
