import axios from "axios";
import api from "../../Http/global-vars";
import { GRAPH } from "../types";
import moment from "moment";

export const fetchDataChart = (date) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "connectionbydate/" + date + "/";
    await axios
      .get(url, config)
      .then(async (res) => {
        let data = [];
        // console.log("data chart", res.data?.data);
        for (let index = 0; index < res.data?.data?.stat.length; index++) {
          const element = res.data?.data?.stat[index];
          data[index] = element?.userConnection;
          //   console.log(moment(element.jour).day() - 1);
        }
        // console.log("data chart", data);

        dispatch({
          type: GRAPH,
          payload: data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
