import { FETCH_VIDEOS } from "../types";

export const videoReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_VIDEOS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};
