import React, { useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./GestionExpertsPersonnesRessourcesAdmin.css";
import FrontHeader from "../../../FrontEnd/frontHeader/frontHeader";
import GestionFulbrighterTable from "../../../Tables/Coordonnateur/GestionFulbrighterTable/GestionFulbrighterTable";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import AjouterExpertAdmin from "../../../Modals/Admin/AjouterExpertAdmin";
import GestionExpertsPersonnesRessourcesAdminTable from "../../../Tables/Admin/GestionExpertsPersonnesRessourcesAdminTable/GestionExpertsPersonnesRessourcesAdminTable";
import GestionExpertsPersonnesRessourcesTable from "../../../Tables/Coordonnateur/GestionExpertsPersonnesRessourcesTable/GestionExpertsPersonnesRessourcesTable";
import AjouterExpert from "../../../Modals/Coordonnateur/AjouterExpert";
import { useTranslation } from "react-i18next";

var GestionExpertsPersonnesRessourcesAdmin = () => {
  let { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0">
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("experts_resource")}</h1>
                </div>
                <div
                  className="adding-item-container linkable"
                  data-toggle="modal"
                  data-target="#AjouterExpert"
                >
                  <span className="icon-container">
                    <IconlyPack.Plus set="light" primaryColor="#1A5C8C" />
                  </span>
                  <span className="adding-item-label">{t("add_expert")}</span>
                </div>
              </div>
              <div className="parent-table-container">
                <div className="py-5">
                  <GestionExpertsPersonnesRessourcesTable />
                </div>
              </div>
              <div
                className="modal fade"
                id="AjouterExpert"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AjouterExpertLabel"
                aria-hidden="true"
              >
                <AjouterExpert />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionExpertsPersonnesRessourcesAdmin;
