import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdContacts } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../Http/backend-base-url";
import api from "../../Http/global-vars";
import PhotoProfil from "../../images/others/default-profile-avatar.png";
import { fetchUserMessages } from "../../redux/messages/MessagesAction";
import { useChatData } from "../FrontEnd/Messagerie/Messagerie";
import ChatBoxModal from "../FrontEnd/RemonterIncidents/ChatBoxModal";
import RemonterIncidents from "../FrontEnd/RemonterIncidents/RemonterIncidents";
import NousContacter from "../Modals/Frontend/NousContacter";
import "./Floatbutton.css";

var FloatButton = () => {
  const { t } = useTranslation();
  const user_type = useSelector((state) => state?.user?.data?.user_type);

  return (
    <div>
      <div className="main">
        <div className="top">
          <button
            data-toggle="modal"
            data-target="#SignalerIncidents"
            title={t("report_problem")}
            style={{ background: "transparent" }}
          >
            <div className="square one"></div>
          </button>
          <button
            data-toggle="modal"
            data-target="#NousContacter"
            title={t("contact_us")}
            style={{ background: "transparent" }}
          >
            <div className="square two"></div>
          </button>
        </div>
        <div className="sign">
          <MdContacts style={{ color: "#fff", fontSize: 20 }} />
        </div>
        {user_type && user_type !== "admin" && <ChatWithAdminsModal />}
      </div>
      <div
        className="modal fade"
        id="SignalerIncidents"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="SignalerIncidentsLabel"
        aria-hidden="true"
      >
        <RemonterIncidents />
      </div>
      <div
        className="modal fade"
        id="NousContacter"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="NousContacterLabel"
        aria-hidden="true"
      >
        <NousContacter />
      </div>
      <div
        className="modal fade"
        id="ChatBoxModal"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="ChatBoxModalLabel"
        aria-hidden="true"
      >
        <ChatBoxModal />
      </div>
    </div>
  );
};

export default FloatButton;

function AdminMessage({ msg }) {
  return (
    <div className="d-flex justify-content-start mb-4 align-items-center">
      <div className="img_cont_msg">
        <img
          alt=""
          src={
            msg?.sender?.avatar && !msg?.sender?.avatar?.includes("default.png")
              ? baseUrl + msg?.sender?.avatar
              : PhotoProfil
          }
          className="rounded-circle user_img_msg"
        />
      </div>
      <div>
        <div className="msg_cotainer" style={{ padding: "10px 35px 5px 15px" }}>
          <div className="name-admin-chat-send">
            {`${msg?.sender?.prenom} ${msg?.sender?.nom}`}
          </div>
          <p className="text-message-item" style={{ fontSize: 12 }}>
            {msg.message}
          </p>
          <div className="content-msg-time">
            <span className="msg_time" style={{ fontSize: 6 }}>
              {moment(msg.created_at).calendar()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function UserMessage({ msg }) {
  const user = useSelector((s) => s.user);
  return (
    <div className="d-flex justify-content-end align-items-center mb-4">
      <div
        className="msg_cotainer_send"
        style={{ padding: "10px 10px 5px 35px" }}
      >
        <p className="message-item-send" style={{ fontSize: 12 }}>
          {msg.message}
        </p>
        <div className="content-time-msg-send">
          <span className="msg_time_send" style={{ fontSize: 6 }}>
            {moment(msg.created_at).calendar()}
          </span>
        </div>
      </div>
      <div className="img_cont_msg">
        <img
          alt=""
          src={
            user?.data?.avatar && !user?.data?.avatar?.includes("default.png")
              ? baseUrl + user?.data?.avatar
              : PhotoProfil
          }
          className="rounded-circle user_img_msg"
        />
      </div>
    </div>
  );
}

function ChatWithAdminsModal() {
  const { t } = useTranslation();
  const { chatData, user } = useChatData();
  const [chat, setChat] = useState(null);
  useEffect(() => {
    setChat(chatData.find((c) => c.user.user_type === "admin"));
  }, [chatData]);
  const dispatch = useDispatch();
  const Schema = yup.object().shape({
    message: yup.string().required(t("required")),
  });
  const { formState, register, handleSubmit, setValue, clearErrors } = useForm({
    resolver: yupResolver(Schema),
  });
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const scrollToEnd = () => {
    if (document.querySelector(`#lastMessage1`)) {
      document.querySelector(`#lastMessage1`).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  useEffect(() => {
    if (isDone) {
      setValue("message", "");
    }
  }, [isDone, setValue]);
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (user?.data?.id) {
      formValues["sender"] = user?.data?.id;
    }
    var request = axios.post(`${api}messageadmin/`, formValues, config);
    await request
      .then(async (resp) => {
        await Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(fetchUserMessages(user?.data?.id));
        await setIsSuccessfullySubmitted(false);
        await window.$("#ChatBoxModal").modal("hide");
        await setIsDone(true);

        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  useEffect(() => {
    scrollToEnd();
  }, [chat]);
  return (
    <div className="bottom">
      <Popup
        trigger={
          <button type="button" style={{ background: "transparent" }}>
            <div className="square three"></div>
          </button>
        }
        position={"top right"}
      >
        <div className="container-popup-chat-box">
          <div className="content-header-popup-title">
            <p className="title-popup-chat-box">
              Discuter avec les administrateurs
            </p>
          </div>
          <div className="content-popup-chat-bloc">
            <div className="container-discussion-bloc">
              {chat &&
                chat.messages.map((msg) => {
                  if (msg.sender?.id === user.data?.id) {
                    return <UserMessage key={msg.id} msg={msg} />;
                  }
                  return <AdminMessage key={msg.id} msg={msg} />;
                })}
              <div id="lastMessage1" />
            </div>
            <div className="container-form-chat-box">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mt-4">
                      <textarea
                        class="form-control input-form-incident"
                        id="exampleFormControlTextarea1"
                        rows={2}
                        placeholder="Message"
                        {...register("message")}
                      ></textarea>
                      {formState.errors && formState.errors.message && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.message?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end">
                    {isSuccessfullySubmitted && (
                      <button disabled className="btn btn-submit-chat">
                        {t("en_cours")} &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!isSuccessfullySubmitted && (
                      <button type="submit" className="btn btn-submit-chat">
                        <RiSendPlaneFill />
                        <span className="pl-2">{t("send")}</span>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <button style={{ background: "transparent" }}>
        <div className="square four"></div>
      </button>
    </div>
  );
}
