import React, { useState, useMemo, useEffect } from "react";
import Select, { components } from "react-select";
import countryList from "react-select-country-list";
import * as IconlyPack from "react-iconly";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import useEditPartner from "../../Dashboard/Coordonnateur/InstitutionsEtPartenairesCoordonnateur/requestPartner/useEditPartner";
import baseUrl from "../../../Http/backend-base-url";
import { useTranslation } from "react-i18next";

function ModifierPartner({ partner }) {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
    isDone,
  } = useEditPartner();
  const { t } = useTranslation();
  const [avatar, setAvatar] = React.useState(null);
  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const [phone, setPhone] = React.useState("");
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyRelease, setIsKeyReleased] = useState(false);

  useEffect(() => {
    if (partner?.id) {
      register("id");
      setValue("id", partner?.id);
      setValue("description", partner?.description);
      setValue("email", partner?.email);
      setValue("nom", partner?.nom);
      setValue("pays", partner?.pays);
      setValue("contact", partner?.contact);
      setValue("site_web", partner?.site_web);
      if (
        partner?.pays &&
        options.filter((option) => option.label === partner?.pays)?.length
      ) {
        setVal(
          options.filter((option) => option.label === partner?.pays)[0]?.value
        );
      }

      if (partner?.contact) {
        setPhone(partner?.contact);
      }
    }
  }, [partner]);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("contact");
      setValue("contact", "+" + val);
    } else {
      setValue("contact", "");
    }
    // console.log("val", val);
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    setAvatar(file);
    setValue("logo", file);
  };

  const changeHandler = (value) => {
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (isDone) {
      //   setPhone("+221");
      //   setVal("");
      //   setInput("");
      //   setTags([]);
      //   setAvatar(null);
    }
  }, [isDone]);

  const handleReset = () => {
    // reset();
    // setPhone("+221");
    // setVal("");
    // setInput("");
    // setTags([]);
    // setAvatar(null);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("edit_partner")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="nom">
                        {t("name")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("name_partner")}
                        name="nom"
                        {...register("nom")}
                        className="form-control  input-add-document"
                        id="nom"
                      />
                      {formState.errors && formState.errors.nom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="country">
                        {t("country")}
                        <span className="required-label">*</span>
                      </label>

                      <ReactFlagsSelect
                        className="form-control input-add-document"
                        selected={val}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                        placeholder={t("select_country")}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">
                        {t("email")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder={t("email")}
                        name="email"
                        {...register("email")}
                        className="form-control  input-add-document"
                        id="email"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="phone">
                        {t("phone")} <span className="required-label">*</span>
                      </label>
                      <PhoneInput
                        masks={{ sn: ".. ... .. .." }}
                        inputClass="form-control input-add-document phone-edit"
                        country={"sn"}
                        // onlyCountries={['sn']}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "5px",
                          background: "#F2F3F3",
                          height: "49px",
                          border: "none",
                        }}
                      />
                      {formState.errors && formState.errors.contact && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.contact?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="site_web">{t("website")}</label>
                      <input
                        type="url"
                        placeholder={t("website")}
                        name="site_web"
                        {...register("site_web")}
                        className="form-control  input-add-document"
                        id="site_web"
                      />
                      {formState.errors && formState.errors.site_web && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.site_web?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="file">Logo</label>
                      <div className="inputfile-box">
                        <input
                          type="file"
                          id="file"
                          name="photo"
                          accept="image/*"
                          className="inputfile input-add-document"
                          onChange={changeAvatar}
                        />
                        <label htmlFor="file">
                          <span
                            id="file-name"
                            className="file-box input-add-document"
                          >
                            <span className="text-input-file-costum">
                              {t("upload_image")}
                            </span>
                          </span>
                          <span className="file-button">
                            <IconlyPack.Upload
                              set="light"
                              primaryColor="#000"
                            />
                          </span>
                        </label>
                      </div>

                      <div>
                        {avatar ||
                        (partner?.logo &&
                          !partner?.logo?.includes("default.png")) ? (
                          <img
                            src={
                              avatar
                                ? URL.createObjectURL(avatar)
                                : baseUrl + partner?.logo
                            }
                            className="avatar-profil"
                            alt="Profile"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control input-add-document"
                        id="description"
                        rows="10"
                        {...register("description")}
                        name="description"
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("edit")}
                    </button>
                  )}
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierPartner;
