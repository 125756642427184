import api from "../../Http/global-vars";
import { FETCH_DOCUMENTS, CHEARCH_DOCUMENTS } from "../types";

export const fetchDocuments = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "document/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data);
        dispatch({
          type: FETCH_DOCUMENTS,
          payload: data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const searchDocument = (documents, searchItem) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line
  data = documents?.filter((doc) =>
    doc?.title?.toLowerCase()?.includes(searchItem)
  );
  console.log("voir", searchItem, data);
  // return "";
  return (dispatch) => {
    dispatch({
      type: CHEARCH_DOCUMENTS,
      payload: {
        items: !searchItem?.length
          ? documents?.sort((a, b) => b?.id - a?.id)
          : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
