import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import Logo from "../../../images/logo.png";
import * as IconlyPack from "react-iconly";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import { useSelector } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
// import TagsInput from "./TagsInput";
import moment from "moment";

var PersonalDetails = (props) => {
  const { t } = useTranslation();
  const { onSubmit, isSuccessfullySubmitted } = props;
  const [startDate, setStartDate] = useState(null);
  const Schema = yup.object().shape({
    email: yup.string().email(t("invalid_mail")).required(t("required")),
    password: yup.string().required(t("required")),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t("match_password")),
    biographie: yup.string(),
    pays: yup.string().required(t("required")),
    adresse: yup.string(),
    domaine_expertise: yup.string(),
    annee_cohorte: yup.string().required(t("required")),
  });
  const {
    register,
    formState,
    // isSuccessfullySubmitted,
    clearErrors,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(Schema),
  });
  // const devise = useSelector((state) => state.devise);
  const history = useHistory();
  let token = window.sessionStorage.getItem("userToken");
  const [val, setVal] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  // const [tags, setTags] = useState([]);

  useEffect(() => {
    // console.log("ddd", props)
    window.scrollTo(0, 0);
  }, []);

  // const handleTagsChange = (tags) => {
  //   setTags(tags);
  // };

  // useEffect(() => {
  //   console.log("tags", tags);
  //   if (tags?.length) {
  //     register("domaine_expertise");
  //     setValue("domaine_expertise", JSON.stringify(tags));
  //   } else {
  //     register("domaine_expertise");
  //     setValue("domaine_expertise", "");
  //   }
  // }, [register, setValue, tags]);

  const changeHandler = (value) => {
    // console.log(
    //   "val",
    //   value,
    //   "options",
    //   options.filter((option) => option.value === value)[0]
    // );
    setValue(
      "pays",
      options.filter((option) => option.value === value)[0]?.label
    );
    setVal(value);
  };

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        history.push("/app/home");
      }, 1000);
    }
  }, [history, token]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  return (
    <div>
      <div className="login-page-container">
        <section className="auth-page-content">
          <form className="row auth-page-row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-4 offset-md-4 auth-page-col">
              <div className="row auth-form-row">
                {/* <div className="logo-ecompta mb-4">
                  <img src={Logo} alt="Logo E-compte" />
                </div> */}
                <h1 className="auth-form-title m-b-90">{t("welcome")}</h1>
                <div className="col-sm-12 d-flex justify-content-center mb-4">
                  <p className="text-comment">
                    {t("have_account")}&nbsp;
                    <NavLink
                      className="color-green no-link"
                      to="/app/connexion"
                    >
                      {t("login")}
                    </NavLink>
                  </p>
                </div>

                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="nom" className="label-ecompta">
                      {t("email")}
                      <span className="required-label">*</span>
                    </label>
                    <input
                      name="email"
                      type="email"
                      {...register("email")}
                      className="form-control auth-form-control pl-3"
                      id="email"
                      placeholder={t("email")}
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="password" className="label-ecompta">
                      {t("password")}
                      <span className="required-label">*</span>{" "}
                    </label>
                    <input
                      name="password"
                      type="password"
                      {...register("password")}
                      className="form-control auth-form-control pl-3"
                      id="password"
                      placeholder={t("password")}
                    />
                    {formState.errors && formState.errors.password && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.password?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="form-group auth-form-group position-relative">
                    <label
                      htmlFor="passwordConfirmation"
                      className="label-ecompta"
                    >
                      {t("confirm_password")}
                      <span className="required-label">*</span>{" "}
                    </label>
                    <input
                      name="passwordConfirmation"
                      type="password"
                      {...register("passwordConfirmation")}
                      className="form-control auth-form-control pl-3"
                      id="passwordConfirmation"
                      placeholder={t("confirm_password")}
                    />
                    {formState.errors &&
                      formState.errors.passwordConfirmation && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.passwordConfirmation?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="">
                    <div className="form-group auth-form-group position-relative">
                      <label className="label-ecompta" htmlFor="pays">
                        {/* Pays */}
                        {t("country")}
                        <span className="required-label">*</span>
                      </label>

                      <ReactFlagsSelect
                        className="register-pays"
                        selected={val}
                        style={{
                          width: "100%",
                          padding: "30px 10px 30px 60px",
                          borderRadius: "10px",
                        }}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                        placeholder={t("country")}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col m-b-5">
                  <div className="">
                    <div className="form-group auth-form-group position-relative">
                      <label className="label-ecompta" htmlFor="cohort_year">
                        Cohort year
                        <span className="required-label">*</span>
                      </label>

                      {/* <input
                        name="annee_cohorte"
                        {...register("annee_cohorte")}
                        type="date"
                        className="form-control auth-form-control pl-3"
                        id="cohort_year"
                        placeholder={t("cohort_year")}
                      /> */}
                      <DatePicker
                        dateFormat="yyyy"
                        showYearPicker
                        placeholderText={t("select_year")}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          console.log(date);
                          setValue(
                            "annee_cohorte",
                            moment(date).format("YYYY")
                          );
                        }}
                        className="form-control auth-form-control pl-3"
                      />
                      {formState.errors && formState.errors.annee_cohorte && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.annee_cohorte?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 auth-input-col m-b-20">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="adresse" className="label-ecompta">
                      {/* Adresse */}
                      {t("address")}
                    </label>
                    <input
                      name="adresse"
                      type="adresse"
                      {...register("adresse")}
                      className="form-control auth-form-control pl-3"
                      id="adresse"
                      placeholder={t("address")}
                    />
                  </div>
                </div>
                {/* <div className="col-sm-12 auth-input-col m-b-20">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="adresse" className="label-ecompta">
                      {t("skills")}
                    </label>
                    <TagsInput
                      value={tags}
                      setTags={setTags}
                      onChange={handleTagsChange}
                    />
                  </div>
                </div> */}
                <div className="col-sm-12 auth-input-col m-b-20">
                  <div className="form-group auth-form-group position-relative">
                    <label htmlFor="adresse" className="label-ecompta">
                      {t("bio")}
                    </label>
                    <textarea
                      className="form-control form-control auth-form-control pl-3"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="biographie"
                      {...register("biographie")}
                      placeholder={t("bio_guide")}
                    ></textarea>
                  </div>
                </div>
                <div className="col-sm-12 auth-input-col py-4">
                  {!isSuccessfullySubmitted ? (
                    <button
                      className="btn auth-submit-btn"
                      // onClick={navigation.next}
                      type="submit"
                    >
                      {/* Inscription */}
                      {t("register")}
                      <span>
                        <IconlyPack.ArrowRight set="light" />
                      </span>
                    </button>
                  ) : (
                    <button className="btn auth-submit-btn" disabled>
                      {t("en_cours")}
                      <span>
                        <i className="fas fa-spin fa-spinner"></i>
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className="row mx-0 pt-5 mt-5 py-md-4 py-3 justify-content-center">
            <p className="copyright-connexion">
              ©2021 All Rights Reserved. WARC.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
export default PersonalDetails;
