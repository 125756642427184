import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
// import { toast, ToastContainer } from 'react-toastify';
import EmptyHeader from "./EmptyHeader";
import axios from "axios";
import api from "./../../Http/global-vars";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

class ModifierPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      inProgress: false,
      isSuccess: false,
      isError: false,
      is_empty_email: false,
      is_invalid_email: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {}

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value.toLowerCase();

    this.setState({
      fields: fields,
    });
    console.log(fields);
  }

  onValidateLoginForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_email: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        });
      }, 5000);

      errors["email"] = this.props.t("required");
    }

    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        isValidForm = false;
        this.setState({
          is_invalid_email: true,
        });
        setTimeout(() => {
          this.setState({
            is_invalid_email: false,
          });
        }, 5000);

        errors["invalid_email"] = this.props.t("invalid_mail");
      }
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.onValidateLoginForm()) {
      this.setState({
        inProgress: true,
      });
      var data = this.state.fields;
      axios
        .post(api + "request-password-reset/", data)
        .then((response) => {
          console.log(response);
          window.localStorage.setItem("email", data?.email);
          this.setState({
            inProgress: false,
          });
          if (
            response.data.status === 200 ||
            response.data.status === "success"
          ) {
            this.setState({
              isSuccess: true,
            });
            Swal.fire({
              icon: "success",
              title: this.props.t("send_code_success"),
              showConfirmButton: false,
              timer: 5000,
            }).then(() => {
              window.location = "/reset-password";
            });
            // toast.success('')
          }
        })
        .catch((error) => {
          console.log(error?.response);
          // toast.error("")
          if (error?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            //   toast.error("Veuillez vérifier votre connexion internet.");
            Swal.fire({
              icon: "error",
              title:
                "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          this.setState({
            isError: true,
            inProgress: false,
          });

          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <EmptyHeader />
        <div className="">
          <div className="row login-page-row">
            <div className="col-md-6 offset-md-3 right-side-col">
              <div className="reset-form-container">
                <div className="login-form-introduction d-flex justify-content-center">
                  <h1 className="login-title-label">{t("reset_password")} </h1>
                </div>
                <form id="login-form-reset" className="reset-request-form">
                  <div className=" my-4">
                    <p className="text-comment pb-3">
                      {t("enter_mail")} <br />
                      {t("send_code")}
                    </p>
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="mail" />
                      </span>
                    </div>
                    <input
                      name="email"
                      id="email"
                      onChange={(e) => this.handleChange(e)}
                      type="email"
                      className="form-control input-with-icon"
                      placeholder={t("email")}
                    />
                    {this.state.is_empty_email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {this.state.errors.email}{" "}
                      </div>
                    )}
                    {this.state.is_invalid_email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {this.state.errors.invalid_email}{" "}
                      </div>
                    )}
                  </div>

                  <div className="row submit-btn-row py-5 my-5">
                    <div className="col-md-8 offset-md-2 submit-btn-col">
                      {this.state.inProgress === false ? (
                        <button
                          type="submit"
                          onClick={(e) => this.onSubmit(e)}
                          className="btn-connexion"
                        >
                          {t("submit")}
                        </button>
                      ) : (
                        <button className="btn-connexion in-progress-btn">
                          {t("en_cours")} &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* {this.state.isSuccess || this.state.isError ?
                    <ToastContainer /> : ''
                } */}
      </>
    );
  }
}

export default withTranslation()(ModifierPassword);