import api from "../../Http/global-vars";
import { CHEARCH_EXPERTS, FETCH_EXPERTS } from "../types";

export const fetchExperts = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "expert/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data);
        dispatch({
          type: FETCH_EXPERTS,
          payload: data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const searchExpert = (experts, searchItem, pays) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line
  if (searchItem && pays) {
    data = experts?.filter(
      (user) =>
        (user?.prenom?.toLowerCase()?.includes(searchItem) ||
          user?.nom?.toLowerCase()?.includes(searchItem)) &&
        user?.pays === pays
    );
  } else if (!searchItem && pays) {
    data = experts?.filter((user) => user?.pays === pays);
  } else if (searchItem && !pays) {
    data = experts?.filter(
      (user) =>
        user?.prenom?.toLowerCase()?.includes(searchItem) ||
        user?.nom?.toLowerCase()?.includes(searchItem)
    );
  }

  console.log("voir", searchItem, data);
  // return "";
  return (dispatch) => {
    dispatch({
      type: CHEARCH_EXPERTS,
      payload: {
        items:
          !searchItem?.length && !pays
            ? experts?.sort((a, b) => b?.id - a?.id)
            : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
