import React, { useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import UseAddCOndition from "../../Dashboard/Admin/ParametresAdmin/requestAdmin/UseAddCOndition";
import { useTranslation } from "react-i18next";

function AddContentPageModal({ type }) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
    isDone,
  } = UseAddCOndition();

  useEffect(() => {
    if (type) {
      setValue("type", type);
    }
  }, [type]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("add_content")}{" "}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="text">{t("contenu")}</label>

                      <CKEditor
                        initData=""
                        onChange={(evt) =>
                          setValue("text", evt.editor.getData())
                        }
                      />
                      {formState.errors && formState.errors.text && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.text?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("add")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddContentPageModal;
