import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { fetchVideos } from "../../../../../redux/video/VideoAction";
import VideoApi from "../../../../../services/Video";

const fileExtensions = [
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/x-ms-wmv",
  "video/x-flv",
  "video/x-matroska",
  "video/mp4",
  "video/3gpp",
  "video/mpeg",
  "video/mpeg",
  "video/webm",
];

function UseCreateVideo() {
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("Title is required."),
        description: yup.string().required("Description is required."),
        lien: yup.string().when("video", {
          is: (val) => !val,
          then: yup.string().url().required("Link is required").nullable(),
        }),
        video: yup
          .mixed()
          .when("lien", {
            is: (val) => !val,
            then: yup
              .mixed()
              .required("File is required.")
              .test("fileType", "Unsupported File Format", (value) => {
                return value && fileExtensions.includes(value.type);
              })
              .nullable(),
          })
          .nullable(),
      },[["video","lien"]]),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    let fd = new FormData();
    fd.append("title", formValues?.title);
    fd.append("description", formValues?.description);
    if (formValues?.lien) {
      fd.append("lien", formValues?.lien);
    }
    if (formValues?.video) {
      fd.append("video", formValues?.video);
    }
    console.log("data", formValues);
    var request = VideoApi.onAddVideo(fd, config);

    await request
      .then(async () => {
        // console.log('resp', resp)
        // window.sessionStorage.setItem("userToken", resp?.token);
        await dispatch(fetchVideos());
        await Swal.fire({
          icon: "success",
          title: "Success!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$("#AjouterVideoModal").modal("hide");
        await setIsDone(true);
        await e.target.reset();
        await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `Oups! une erreur de statut ${error?.response?.status} est survenue, veuillez réessayer plus tard.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default UseCreateVideo;

export function UseUpdateVideo(item) {
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("Title is required."),
        description: yup.string().required("Description is required."),
        lien: yup.string().url().nullable().label("Link"),
        video: yup
          .mixed()
          .notRequired()
          .test("fileType", "Unsupported File Format", (value) => {
            if (!value) return true;
            return value && fileExtensions.includes(value.type);
          }),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: item?.title,
      description: item?.description,
      lien: item?.lien
    },
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    let fd = new FormData();
    fd.append("title", formValues?.title);
    fd.append("description", formValues?.description);
    if (formValues?.lien) {
      fd.append("lien", formValues?.lien);
    }
    if (formValues?.video) {
      fd.append("video", formValues?.video);
    }
    console.log("data", formValues);
    var request = VideoApi.onEditVideo(item.id, fd, config);
    await request
      .then(async () => {
        // console.log('resp', resp)
        // window.sessionStorage.setItem("userToken", resp?.token);
        await dispatch(fetchVideos());
        await Swal.fire({
          icon: "success",
          title: "Success!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await window.$(`#UpdateVideoModal${item.id}`).modal("hide");
        await setIsDone(true);
        // await e.target.reset();
        // await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `Oups! une erreur de statut ${error?.response?.status} est survenue, veuillez réessayer plus tard.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}
