/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile,
// } from "react-device-detect";
import { createStore, StateMachineProvider } from "little-state-machine";
import moment from "moment";
import { useDispatch } from "react-redux";
import { BrowserRouter, Redirect } from "react-router-dom";
import ModifierPassword from "./Components/Auth/ModifierPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import AgendaAdmin from "./Components/Dashboard/Admin/AgendaAdmin/AgendaAdmin";
import BlogAdmin from "./Components/Dashboard/Admin/BlogAdmin/BlogAdmin";
import DetailsBlogAdmin from "./Components/Dashboard/Admin/BlogAdmin/DetailsBlogAdmin";
import CatalogueAdmin from "./Components/Dashboard/Admin/CatalogueAdmin/CatalogueAdmin";
import CategorieAdmin from "./Components/Dashboard/Admin/CategorieAdmin/CategorieAdmin";
import DashboardAdmin from "./Components/Dashboard/Admin/DashboardAdmin/DashboardAdmin";
import AnnualReports from "./Components/Dashboard/Admin/DocumentsAdmin/AnnualReports";
import DocumentsAdmin from "./Components/Dashboard/Admin/DocumentsAdmin/DocumentsAdmin";
import DetailsDiscussionForumAdmin from "./Components/Dashboard/Admin/ForumAdmin/DetailsDuscussionForumAdmin";
import ForumAdmin from "./Components/Dashboard/Admin/ForumAdmin/ForumAdmin";
import DetailsFulbritersAdmin from "./Components/Dashboard/Admin/GestionDesFulbrightersAdmin/DetailsFulbritersAdmin";
import GestionDesFulbrightersAdmin from "./Components/Dashboard/Admin/GestionDesFulbrightersAdmin/GestionDesFulbrightersAdmin";
import GestionExpertsPersonnesRessourcesAdmin from "./Components/Dashboard/Admin/GestionExpertsPersonnesRessourcesAdmin/GestionExpertsPersonnesRessourcesAdmin";
import GestionIncidentAdmin from "./Components/Dashboard/Admin/GestionIncidentAdmin/GestionIncidentAdmin";
import GestionReportAdmin from "./Components/Dashboard/Admin/GestionReportAdmin/GestionReportAdmin";
import GuidesAdmin from "./Components/Dashboard/Admin/GuidesAdmin/GuidesAdmin";
import InstitutionsEtPartenairesAdmin from "./Components/Dashboard/Admin/InstitutionsEtPartenairesAdmin/InstitutionsEtPartenairesAdmin";
import ChatBoxAdmin from "./Components/Dashboard/Admin/MessagesAdmin/ChatBoxAdmin";
import MessagesAdmin from "./Components/Dashboard/Admin/MessagesAdmin/MessagesAdmin";
import ParametresAdmin from "./Components/Dashboard/Admin/ParametresAdmin/ParametresAdmin";
import ProfilAdmin from "./Components/Dashboard/Admin/ProfilAdmin/ProfilAdmin";
import VideosAdmin from "./Components/Dashboard/Admin/Videos/VideosAdmin";
import AgendaCoordonnateur from "./Components/Dashboard/Coordonnateur/AgendaCoordonnateur/AgendaCoordonnateur";
import AjouterArticleBlog from "./Components/Dashboard/Coordonnateur/BlogCoordonnateur/AjouterArticleBlog";
import BlogCoordonnateur from "./Components/Dashboard/Coordonnateur/BlogCoordonnateur/BlogCoordonnateur";
import DetailsBlogCoordonnateur from "./Components/Dashboard/Coordonnateur/BlogCoordonnateur/DetailsBlogCoordonnateur";
import ModifierArticleBlog from "./Components/Dashboard/Coordonnateur/BlogCoordonnateur/ModifierArticleBlog";
import CatalogueCoordonnateur from "./Components/Dashboard/Coordonnateur/CatalogueCoordonnateur/CatalogueCoordonnateur";
import CategoriesCoordonateur from "./Components/Dashboard/Coordonnateur/CategoriesCoordonateur/CategoriesCoordonateur";
import Dashboard from "./Components/Dashboard/Coordonnateur/Dashboard/Dashboard";
import DocumentsCoordonnateur from "./Components/Dashboard/Coordonnateur/DocumentsCoordonnateur/DocumentsCoordonnateur";
import DetailsDiscussionForum from "./Components/Dashboard/Coordonnateur/Forum/DetailsDuscussionForum";
import Forum from "./Components/Dashboard/Coordonnateur/Forum/Forum";
import DetailsFulbriters from "./Components/Dashboard/Coordonnateur/GestionDesFulbrighters/DetailsFulbriters";
import GestionDesFulbrighters from "./Components/Dashboard/Coordonnateur/GestionDesFulbrighters/GestionDesFulbrighters";
import GestionExpertsPersonnesRessourcesCoordonnateur from "./Components/Dashboard/Coordonnateur/GestionExpertsPersonnesRessourcesCoordonnateur/GestionExpertsPersonnesRessourcesCoordonnateur";
import GestionIncidents from "./Components/Dashboard/Coordonnateur/GestionIncident/GestionIncidents";
import GestionReport from "./Components/Dashboard/Coordonnateur/GestionReport/GestionReport";
import GuidesCoordonnateur from "./Components/Dashboard/Coordonnateur/GuidesCoordonnateur/GuidesCoordonnateur";
import InstitutionsEtPartenairesCoordonnateur from "./Components/Dashboard/Coordonnateur/InstitutionsEtPartenairesCoordonnateur/InstitutionsEtPartenairesCoordonnateur";
import DetailsMessageCoordonnateur from "./Components/Dashboard/Coordonnateur/Messages/DetailsMessage";
import Messages from "./Components/Dashboard/Coordonnateur/Messages/Messages";
import ProfilCoordonnateur from "./Components/Dashboard/Coordonnateur/ProfilCoordonnateur/ProfilCoordonnateur";
import Error404 from "./Components/Error404/Error404";
import Annuaire from "./Components/FrontEnd/Annuaire/Annuaire";
import Blog from "./Components/FrontEnd/Blog/Blog";
import DetailsBlog from "./Components/FrontEnd/Blog/DetailsBlog";
import DetailsDocument from "./Components/FrontEnd/DetailsDocument/DetailsDocument";
import AnnualReportsFront from "./Components/FrontEnd/Documents.js/AnnualReportsFront";
import CatalogueDesUniversites from "./Components/FrontEnd/Documents.js/CatalogueDesUniversites";
import Documents from "./Components/FrontEnd/Documents.js/Documents";
import Guides from "./Components/FrontEnd/Documents.js/Guides";
import Media from "./Components/FrontEnd/Documents.js/Media";
import MediaDetail from "./Components/FrontEnd/Documents.js/MediaDetail";
import ReadMedia from "./Components/FrontEnd/Documents.js/ReadMedia";
import ReadVideo from "./Components/FrontEnd/Documents.js/ReadVideo";
import MentionLegaleCgu from "./Components/FrontEnd/frontFooter/MentionLegaleCgu";
import DetailsEventMobile from "./Components/FrontEnd/frontHome/DetailsEventMobile";
import DetailsLiveMobile from "./Components/FrontEnd/frontHome/DetailsLiveMobile";
import FrontHome from "./Components/FrontEnd/frontHome/frontHome";
import FrontLogin from "./Components/FrontEnd/frontLogin/frontLogin";
import FrontRegister from "./Components/FrontEnd/frontRegister/FrontRegister";
import DetailsMessage from "./Components/FrontEnd/Messagerie/DetailsMessage";
import Messagerie from "./Components/FrontEnd/Messagerie/Messagerie";
import MessagerieAdmin from "./Components/FrontEnd/Messagerie/MessagerieAdmin";
import MonProfil from "./Components/FrontEnd/MonProfil/MonProfil";
import FloatButton from "./Components/LandingPage/FloatButton";
import LandingPage from "./Components/LandingPage/LandingPage";
import isAuthenticate from "./modules/secure/secure";
import { fetchAdmins } from "./redux/admins/adminsAction";
import { fetchAnnualReports } from "./redux/annual_reports/annual_reportsAction";
import { fetchArticles } from "./redux/articles/ArticlesAction";
import { fetchCatalogue } from "./redux/catalogues/cataloguesAction";
import { fetchCategories } from "./redux/categories/CategoiesAction";
import { fetchCgu } from "./redux/cgu/cguAction";
import { fetchDataChart } from "./redux/chart/chartAction";
import { fetchConditions } from "./redux/conditions/conditionAction";
import { fetchConfig } from "./redux/config/configAction";
import { fetchContacts } from "./redux/contact/contactAction";
import { fetchDashboard } from "./redux/dashboard/dashboardAction";
import { fetchDevise } from "./redux/devise/DeviseAction";
import { fetchDocuments } from "./redux/documents/DocumentAction";
import { fetchEvents } from "./redux/events/eventsAction";
import { fetchExperts } from "./redux/experts/ExpertAction";
import { fetchFaqs } from "./redux/faqs/faqAction";
import { fetchFulbrighters } from "./redux/fulbrighters/FulbrighterAction";
import { fetchMl } from "./redux/legalNotice/LegalNoticeAction";
import { fetchLives } from "./redux/live/liveAction";
import { fetchUserMessages } from "./redux/messages/MessagesAction";
import { fetchNotifUser } from "./redux/notifications/notificationAction";
import { fetchUserParticipations } from "./redux/participation/participationAction";
import { fetchPartner } from "./redux/partners/partnerAction";
import { fetchPostes } from "./redux/Posts/PostAction";
import register from "./redux/register";
import { fetchReports } from "./redux/report/reportAction";
import { fetchReportUser } from "./redux/report_user/reports_userAction";
import { fetchSlides } from "./redux/slides/SlidesAction";
import { fetchMe } from "./redux/user/userAction";
import { fetchUsers } from "./redux/Users/UsersAction";
import { fetchVideos } from "./redux/video/VideoAction";
import UseDeviceDetect from "./utils/DetectSize";
import { useChatListener } from "./utils/pusher";
createStore(register);
// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

function App() {
  const dispatch = useDispatch();
  useChatListener();
  let token = window.sessionStorage.getItem("userToken");
  var isLogInExpired = isAuthenticate();
  var userType = window.sessionStorage.getItem("userType");
  let userID = window.sessionStorage.getItem("userID");

  useEffect(() => {
    dispatch(fetchCgu());
    dispatch(fetchMl());
    dispatch(fetchConfig());
    dispatch(fetchSlides());
  }, [dispatch]);

  useEffect(() => {
    if (token && userType === "admin") {
      dispatch(fetchAdmins());
    }

    if (token && (userType === "admin" || userType === "coordo")) {
      dispatch(fetchDataChart(moment(new Date()).format("DD-MM-YYYY")));
      dispatch(fetchReports());
      dispatch(fetchAnnualReports());
      dispatch(fetchReportUser());
    }
  }, [token, userType, dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMe());
      dispatch(fetchDashboard());
      dispatch(fetchPostes());
      dispatch(fetchCategories());
      dispatch(fetchArticles());
      dispatch(fetchUsers());
      dispatch(fetchFulbrighters());
      dispatch(fetchDocuments());
      dispatch(fetchEvents());
      dispatch(fetchLives());
      dispatch(fetchConditions());
      dispatch(fetchExperts());
      dispatch(fetchPartner());
      dispatch(fetchFaqs());
      dispatch(fetchCatalogue());
      dispatch(fetchContacts());
      dispatch(fetchVideos());
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (token && userID) {
      dispatch(fetchUserMessages(userID));
      dispatch(fetchNotifUser(userID));
      dispatch(fetchUserParticipations(userID));
    }
  }, [userID, token, dispatch]);
  let isMobile = UseDeviceDetect();
  useEffect(() => {
    // console.log("isMobile", isMobile);
    dispatch(fetchDevise(isMobile));
  }, [isMobile, dispatch]);

  return (
    <StateMachineProvider>
      <BrowserRouter>
        <div className="router-container">
          {userType !== "admin" && userType !== "coordo" && <FloatButton />}

          {/* {console.log("test", UseDeviceDetect())} */}
          <Switch>
            <Route path="/app/home">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <FrontHome />
              )}
            </Route>
            <Route path="/request-password">
              <ModifierPassword />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/app/documents/:id">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsDocument />
              )}
            </Route>
            <Route path="/app/annual-reports/:id">
              <DetailsDocument annual />
            </Route>
            <Route exact path="/" component={LandingPage} />
            <Route path="/app/connexion" component={FrontLogin} />
            <Route path="/app/inscription" component={FrontRegister} />

            <Route path="/app/blog">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Blog />
              )}
            </Route>
            <Route path="/app/detail-blog/:id">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsBlog />
              )}
            </Route>
            <Route path="/app/liste-des-evenements">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsEventMobile />
              )}
            </Route>
            <Route path="/app/liste-des-live">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsLiveMobile />
              )}
            </Route>
            <Route exact path="/app/messagerie">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Messagerie />
              )}
            </Route>
            <Route path="/app/messagerie/:id">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsMessage />
              )}
            </Route>
            <Route path="/app/documents">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Documents />
              )}
            </Route>
            <Route path="/app/annual-reports">
              <AnnualReportsFront />
            </Route>
            <Route exact path="/app/media">
              <Media />
            </Route>
            <Route exact path="/app/media/read-video/:id">
              <ReadVideo />
            </Route>
            <Route exact path="/app/media/read/:id">
              <ReadMedia />
            </Route>
            
            <Route path="/app/media/:id">
              <MediaDetail />
            </Route>
            <Route path="/app/guides">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Guides />
              )}
            </Route>
            <Route path="/app/catalogue-des-universites">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <CatalogueDesUniversites />
              )}
            </Route>
            <Route path="/app/annuaire/:idTabs">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Annuaire />
              )}
            </Route>
            <Route path="/app/page-content/:idTabs">
              <MentionLegaleCgu />
            </Route>
            <Route path="/app/mon-profil">
              {isLogInExpired || !token || userType === "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <MonProfil />
              )}
            </Route>

            {/* Routes coordonnateur */}
            <Route path="/dashboard-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Dashboard />
              )}
            </Route>
            <Route path="/documents-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DocumentsCoordonnateur />
              )}
            </Route>
            <Route path="/guides-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GuidesCoordonnateur />
              )}
            </Route>
            <Route path="/catalogue-des-universites-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <CatalogueCoordonnateur />
              )}
            </Route>
            <Route path="/gestion-des-fulbrighters-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionDesFulbrighters />
              )}
            </Route>
            <Route path="/experts-et-personnes-ressources-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionExpertsPersonnesRessourcesCoordonnateur />
              )}
            </Route>
            <Route path="/institutions-et-partenaires-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <InstitutionsEtPartenairesCoordonnateur />
              )}
            </Route>

            <Route path="/detail-fulbriters-coordonnateur/:id">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsFulbriters />
              )}
            </Route>

            <Route path="/blog-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <BlogCoordonnateur />
              )}
            </Route>
            <Route path="/categories-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <CategoriesCoordonateur />
              )}
            </Route>
            <Route path="/forum-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Forum />
              )}
            </Route>
            <Route exact path="/messages-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <Messages />
              )}
            </Route>
            <Route path="/details-messages-coordonnateur/:id">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsMessageCoordonnateur />
              )}
            </Route>
            <Route path="/agenda-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <AgendaCoordonnateur />
              )}
            </Route>
            <Route path="/profil-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ProfilCoordonnateur />
              )}
            </Route>
            <Route path="/detail-discussion-forum">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsDiscussionForum />
              )}
            </Route>

            <Route path="/detail-blog-coordonnateur/:id">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsBlogCoordonnateur />
              )}
            </Route>
            <Route path="/ajouter-article-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <AjouterArticleBlog />
              )}
            </Route>
            <Route path="/modifier-article-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ModifierArticleBlog />
              )}
            </Route>

            <Route path="/reports-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionReport />
              )}
            </Route>

            <Route path="/incidents&feedbacks-coordonnateur">
              {isLogInExpired || !token || userType !== "coordo" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionIncidents />
              )}
            </Route>

            <Route path="/dashboard-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DashboardAdmin />
              )}
            </Route>
            <Route path="/documents-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DocumentsAdmin />
              )}
            </Route>

            <Route path="/videos-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <VideosAdmin />
              )}
            </Route>

            <Route path="/catalogue-des-universites-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <CatalogueAdmin />
              )}
            </Route>
            <Route path="/annual-reports-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <AnnualReports />
              )}
            </Route>
            <Route path="/institutions-et-partenaires-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <InstitutionsEtPartenairesAdmin />
              )}
            </Route>
            <Route path="/guides-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GuidesAdmin />
              )}
            </Route>
            <Route path="/experts-et-personnes-ressources-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionExpertsPersonnesRessourcesAdmin />
              )}
            </Route>
            <Route path="/gestion-des-fulbrighters-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionDesFulbrightersAdmin />
              )}
            </Route>
            <Route path="/blog-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <BlogAdmin />
              )}
            </Route>
            <Route path="/categories-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <CategorieAdmin />
              )}
            </Route>
            <Route path="/forum-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ForumAdmin />
              )}
            </Route>
            <Route path="/contacts-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <MessagesAdmin />
              )}
            </Route>
            <Route path="/messages-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <MessagerieAdmin />
              )}
            </Route>
            <Route path="/messagerie-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ChatBoxAdmin />
              )}
            </Route>
            <Route path="/agenda-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <AgendaAdmin />
              )}
            </Route>
            <Route path="/profil-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ProfilAdmin />
              )}
            </Route>
            <Route path="/admin-detail-discussion-forum">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsDiscussionForumAdmin />
              )}
            </Route>

            <Route path="/detail-blog-admin/:id">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsBlogAdmin />
              )}
            </Route>
            <Route path="/ajouter-article-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <AjouterArticleBlog />
              )}
            </Route>
            <Route path="/modifier-article-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ModifierArticleBlog />
              )}
            </Route>
            <Route path="/detail-fulbriters-admin/:id">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <DetailsFulbritersAdmin />
              )}
            </Route>
            <Route path="/parametres-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <ParametresAdmin />
              )}
            </Route>
            <Route path="/reports-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionReportAdmin />
              )}
            </Route>
            <Route path="/incidents&feedbacks-admin">
              {isLogInExpired || !token || userType !== "admin" ? (
                <Redirect to="/app/connexion" />
              ) : (
                <GestionIncidentAdmin />
              )}
            </Route>
            <Route component={Error404} />
          </Switch>
        </div>
      </BrowserRouter>
    </StateMachineProvider>
  );
}

export default App;
