import React, { useEffect } from "react";
import UseEditCategories from "../../Dashboard/Coordonnateur/CategoriesCoordonateur/requestCategories/UseEditCategories";
import { useTranslation } from "react-i18next";

function ModifierCategorieModal({ category }) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseEditCategories();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (category?.id) {
      register("id");
      setValue("id", category?.id);
      setValue("name", category?.name);
    }
  }, [category]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-dialog-centered modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            {t("edit_category")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="name">
                        {t("name_category")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("name_category")}
                        name="name"
                        {...register("name")}
                        className="form-control input-add-document"
                        id="name"
                      />
                      {formState.errors && formState.errors.name && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.name?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("edit")}
                    </button>
                  )}
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierCategorieModal;
