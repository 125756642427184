import axios from "axios";
import { partner } from "./Endpoints";
// import axios from "axios";

export default {
  async onAddPartner(data, config) {
    const response = await axios.post(partner.onPostPartner(), data, config);
    return response.data;
  },
  async onEditPartner(id, data, config) {
    const response = await axios.put(partner.onEditPartner(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeletePartner(id, config) {
    const response = await axios.delete(partner.onDeletePartner(id), config);
    // console.log(response)
    return response.data;
  },
};
