import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useTranslation } from "react-i18next";
import UseEditSubAdminForm from "../../Dashboard/Admin/ParametresAdmin/requestAdmin/UseEditSubAdminForm";

function EditSubAdminModal({ admin }) {
  const { t } = useTranslation();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseEditSubAdminForm();

  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
  };



  useEffect(() => {
    if (admin?.id) {
      setValue("id", admin?.id);
      setValue("prenom", admin?.prenom);
      setValue("nom", admin?.nom);
      setValue("email", admin?.email);
      setValue("phone", admin?.phone);
      if (admin?.phone) {
        setPhone(admin?.phone);
      }
      //   setValue("pays", admin?.pays);
      setValue("statut", admin?.statut);
      setValue("numero_etudiant", admin?.numero_etudiant);
      setValue("adresse", admin?.adresse);
      //   if (
      //     admin?.pays &&
      //     options.filter((option) => option.label === admin?.pays)?.length
      //   ) {
      //     setVal(
      //       options.filter((option) => option.label === admin?.pays)[0]?.value
      //     );
      //   }
    }
  }, [admin]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    // reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title"> {t("edit_admin")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="prenom">
                        {t("firstName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("firstName")}
                        {...register("prenom")}
                        name="prenom"
                        className="form-control  input-add-document"
                        id="prenom"
                      />
                      {formState.errors && formState.errors.prenom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.prenom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">
                        {t("lastName")}{" "}
                        <span className="required-label">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("lastName")}
                        name="nom"
                        {...register("nom")}
                        className="form-control  input-add-document"
                        id="nom"
                      />
                      {formState.errors && formState.errors.nom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">
                        {t("email")} <span className="required-label">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder={t("email")}
                        name="email"
                        {...register("email")}
                        className="form-control  input-add-document"
                        id="email"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="phone">{t("phone")}</label>
                      <PhoneInput
                        masks={{ sn: ".. ... .. .." }}
                        inputClass="form-control input-add-document phone-edit"
                        country={"sn"}
                        // onlyCountries={['sn']}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "5px",
                          background: "#F2F3F3",
                          height: "49px",
                          border: "none",
                        }}
                      />
                      {formState.errors && formState.errors.phone && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.phone?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">{t("address")}</label>
                      <input
                        type="text"
                        placeholder={t("address")}
                        name="adresse"
                        {...register("adresse")}
                        className="form-control  input-add-document"
                        id="adresse"
                      />
                      {formState.errors && formState.errors.adresse && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.adresse?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 crud-col-item">
                  <div className="">
                    <div className="form-group crud-form-group">
                      <label htmlFor="exampleFormControlSelect1">
                        {t("country")}
                      </label>

                      <ReactFlagsSelect
                        className="form-control input-add-document"
                        selected={val}
                        searchable
                        showOptionLabel
                        showSecondaryOptionLabel
                        onSelect={changeHandler}
                        placeholder={t("select_country")}
                      />
                      {formState.errors && formState.errors.pays && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.pays?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="col-md-12 mt-4">
                  {isSuccessfullySubmitted && (
                    <button disabled className="btn crud-submit-btn">
                      {t("en_cours")} &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button type="submit" className="btn crud-submit-btn">
                      {t("edit")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSubAdminModal;
