import React, { useState, useEffect } from "react";
import "./chartApplication.css";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { fr, enUS } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataChart } from "../../../../../redux/chart/chartAction";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
let lng = window.localStorage.getItem("i18nextLng");

registerLocale(lng === "en" ? "en" : "fr", lng === "en" ? enUS : fr);

var ChartApplication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let data = useSelector((state) => state?.dataChart);
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    dispatch(fetchDataChart(moment(startDate).format("DD-MM-YYYY")));
  }, [dispatch, startDate]);
  const series = [
    {
      name: t("nbr_connexion"),
      data: data?.isLoading ? [0, 0, 0, 0, 0, 0, 0] : data?.data,
    },
  ];
  const options = {
    chart: {
      height: 350,
      background: "#FFFFFF",
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      z: {
        formatter: undefined,
        title: "Size: 20px",
      },
      marker: {
        show: true,
      },
    },
    colors: ["#003DA5"],

    // fill: {
    //   colors: ["#01223a09 96.74%)"],
    // },
    fill: {
      colors: ["rgb(0,61,165, 0) 96.74%"],
    },

    grid: {
      show: true,

      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],
    },

    yaxis: {
      show: true,
    },

    legend: {
      show: false,
    },
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <div className="component-chart-application">
      <div className="d-sm-flex align-items-center mb-5">
        <h4 className="title mb-sm-0 mb-3">{t("nbr_connexion")}</h4>
        <div className="ml-auto">
          {/* <select className="form-control">
            <option>Semaine du 30/11/2020</option>
            <option>Semaine du 1/12/2020</option>
          </select> */}

          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            className="form-control chart-date"
            selected={startDate}
            onChange={handleDateChange}
            locale={lng === "en" ? "en" : "fr"}
            showWeekNumbers
          />
        </div>
      </div>
      {data?.isLoading ? (
        <Skeleton height={400} />
      ) : (
        <Chart
          className="chart-dashboard-marketplace"
          options={options}
          series={series}
          data="Candidature"
          type="area"
          width="100%"
          height="400"
        />
      )}
    </div>
  );
};

export default ChartApplication;
