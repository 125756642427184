import api from "../../Http/global-vars";
import { FETCH_FAQS, CHEARCH_FAQS } from "../types";

export const fetchFaqs = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "faq/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data);
        dispatch({
          type: FETCH_FAQS,
          payload: data.results.sort((a, b) => a?.position - b?.position),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const searchFaq = (faqs, searchItem) => {
  // posts.filter((x) => console.log("object", x.category.indexOf(2)));

  let data = [];
  // eslint-disable-next-line
  data = faqs?.filter((doc) => doc?.titre?.toLowerCase()?.includes(searchItem));
  //   console.log("voir", searchItem, data);
  // return "";
  return (dispatch) => {
    dispatch({
      type: CHEARCH_FAQS,
      payload: {
        items: !searchItem?.length
          ? faqs?.sort((a, b) => b?.id - a?.id)
          : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
