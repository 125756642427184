import { FETCH_CGU } from "../types";

export const cguReducer = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
    case FETCH_CGU:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};
