import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { fetchAnnualReports } from "../../../../../redux/annual_reports/annual_reportsAction";
import { postData, putData } from "../../../../utils/fetchData";

function useCreateAnnualReport(item) {
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("Title is required."),
        description: yup.string().required("Description is required."),
        fichier: item
          ? yup.mixed().test("fileType", "Unsupported File Format", (value) => {
              // console.log(value);
              if (value) {
                return (
                  value &&
                  [
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ].includes(value.type)
                );
              }
              return true;
            })
          : yup
              .mixed()
              .required("File is required.")
              .test("fileType", "Unsupported File Format", (value) => {
                return (
                  value &&
                  [
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ].includes(value.type)
                );
              }),
      }),
    [item]
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: item?.title || "",
      description: item?.description?.toString() || "",
    },
  });

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    let fd = new FormData();
    fd.append("title", formValues?.title);
    fd.append("description", formValues?.description);
    if (formValues?.fichier) {
      fd.append("fichier", formValues?.fichier);
    }
    let token = window.sessionStorage.getItem("userToken");
    var request;
    if (item) {
      request = putData(`annual_report/${item.id}/`, fd, token);
    } else {
      request = postData("annual_report/", fd, token);
    }
    await request
      .then(async () => {
        // console.log('resp', resp)
        // window.sessionStorage.setItem("userToken", resp?.token);
        await dispatch(fetchAnnualReports());
        await Swal.fire({
          icon: "success",
          title: "Success!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        if (item) {
          await window.$(`#AddAnnualReport${item.id}`).modal("hide");
        } else {
          await window.$("#AddAnnualReport").modal("hide");
          await e.target.reset();
        }

        await setIsDone(true);

        await reset();
        setTimeout(() => {
          setIsDone(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `Oups! une erreur de statut ${error?.response?.status} est survenue, veuillez réessayer plus tard.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default useCreateAnnualReport;
