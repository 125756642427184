import api from "../Http/global-vars";

export const authenticate = {
  onLogin() {
    return `${api}auth/login/`;
  },
  onRegesister() {
    return `${api}auth/register/`;
  },
  onMe() {
    return `${api}me/`;
  },
};

export const post = {
  onComment() {
    return `${api}comment/`;
  },
  onDeleteComment($id) {
    return `${api}comment/${$id}/`;
  },
  onEditComment($id) {
    return `${api}comment/${$id}/`;
  },
  onLike() {
    return `${api}like/`;
  },
  onDisLike() {
    return `${api}dislike/`;
  },
  onDeleteLike($id) {
    return `${api}like/${$id}/`;
  },
  onDeleteDisLike($id) {
    return `${api}dislike/${$id}/`;
  },
  onAddPost() {
    return `${api}post/`;
  },
  onEditPost($id) {
    return `${api}post/${$id}/`;
  },
  onDeletePost($id) {
    return `${api}post/${$id}/`;
  },
};

export const document = {
  onPostDocument() {
    return `${api}document/`;
  },
  onEditDocument($id) {
    return `${api}document/${$id}/`;
  },
  onDeleteDocument($id) {
    return `${api}document/${$id}/`;
  },
};

export const video = {
  onPostVideo() {
    return `${api}video/`;
  },
  onEditVideo($id) {
    return `${api}video/${$id}/`;
  },
  onDeleteVideo($id) {
    return `${api}video/${$id}/`;
  },
};


export const user = {
  onPostUser() {
    return `${api}fulbrighter/`;
  },
  onEditUser($id) {
    return `${api}user/${$id}/`;
  },
  onDeleteUser($id) {
    return `${api}user/${$id}/`;
  },
  onPostCoordo() {
    return `${api}user/`;
  },
  onEditAdmin($id) {
    return `${api}adminuser/${$id}/`;
  },
  onDeleteAdmin($id) {
    return `${api}adminuser/${$id}/`;
  },
  onPostAdmin() {
    return `${api}adminuser/`;
  },
};

export const article = {
  onPostArticle() {
    return `${api}article/`;
  },
  onEditArticle($id) {
    return `${api}article/${$id}/`;
  },
  onDeleteArticle($id) {
    return `${api}article/${$id}/`;
  },
  onCommentArticle() {
    return `${api}comment_article/`;
  },
};

export const event = {
  onPostEvent() {
    return `${api}event/`;
  },
  onEditEvent($id) {
    return `${api}event/${$id}/`;
  },
  onDeleteEvent($id) {
    return `${api}event/${$id}/`;
  },
  onParticipe() {
    return `${api}participation/`;
  },
  onDeleteParticipate($id) {
    return `${api}participation/${$id}/`;
  },
};

export const faq = {
  onPostFaq() {
    return `${api}faq/`;
  },
  onEditFaq($id) {
    return `${api}faq/${$id}/`;
  },
  onDeleteFaq($id) {
    return `${api}faq/${$id}/`;
  },
};

export const expert = {
  onPostExpert() {
    return `${api}expert/`;
  },
  onEditExpert($id) {
    return `${api}expert/${$id}/`;
  },
  onDeleteExpert($id) {
    return `${api}expert/${$id}/`;
  },
};

export const partner = {
  onPostPartner() {
    return `${api}partenaire/`;
  },
  onEditPartner($id) {
    return `${api}partenaire/${$id}/`;
  },
  onDeletePartner($id) {
    return `${api}partenaire/${$id}/`;
  },
};

export const catalogue = {
  onPostUniversity() {
    return `${api}university/`;
  },
  onEditUniversity($id) {
    return `${api}university/${$id}/`;
  },
  onDeleteUniversity($id) {
    return `${api}university/${$id}/`;
  },
};

export const live = {
  onPostlive() {
    return `${api}live/`;
  },
  onEditlive($id) {
    return `${api}live/${$id}/`;
  },
  onDeletelive($id) {
    return `${api}live/${$id}/`;
  },
};

export const categorie = {
  onPostCategorie() {
    return `${api}category/`;
  },
  onEditCategorie($id) {
    return `${api}category/${$id}/`;
  },
  onDeleteCategorie($id) {
    return `${api}category/${$id}/`;
  },
};
