/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import FrontHeader from "../frontHeader/index";
import FrontFooter from "../frontFooter/index";
import NavMenuMobile from "../frontHome/NavMenuMobile";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@nejcm/react-skeleton";
import "react-light-accordion/demo/css/index.css";
import CatalogueItem from "./CatalogueItem";
import {
  fetchCatalogue,
  searchUniversity,
} from "../../../redux/catalogues/cataloguesAction";
import CountrySelect from "react-bootstrap-country-select";
import { useTranslation } from "react-i18next";

var CatalogueDesUniversites = () => {
  const { t } = useTranslation();
  const [val, setVal] = useState("");
  const [pays, setPays] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const catalogues = useSelector((state) => state.catalogues);
  const devise = useSelector((state) => state.devise);
  const [itemToShow, setItemToShow] = useState(9);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCatalogue());
  }, [dispatch]);

  const handleChange = (e) => {
    // console.log("search item", e.target.value.length);
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (catalogues?.data?.length) {
      dispatch(
        searchUniversity(catalogues?.data, searchTerm?.toLowerCase(), pays)
      );
    }
      
  }, [catalogues.data, dispatch, pays, searchTerm]);

  const changeHandler = (value) => {
    // console.log("options", value);
    setPays(value?.name);
    setVal(value);
  };

  useEffect(() => {
    if (itemToShow >= catalogues?.filteredData?.length) {
      // setItemToShow(3);
      setExpand(true);
    } else {
      // setItemToShow(itemToShow + 5);
      setExpand(false);
    }
  }, [catalogues, itemToShow]);
  const showMore = () => {
    if (itemToShow >= catalogues?.filteredData?.length) {
      setItemToShow(9);
      setExpand(true);
      window.scroll(0, 0);
    } else {
      setItemToShow(itemToShow + 6);
      setExpand(false);
    }
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile pb-5 mb-5">
            <div className="bloc-banner2">
              <div className="container-warc">
                <div className="py-5 text-center">
                  <h1
                    className="titre-banner-annuaire"
                    style={{ color: "#000" }}
                  >
                    Catalogue des universités
                  </h1>
                </div>
              </div>
            </div>
            <div className="container-warc my-5">
              <div className="row align-items-center">
                <div className="col-md-12 pb-3">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control input-annuaire pl-3"
                      placeholder="Chercher une université"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon-annuaire"
                      onChange={handleChange}
                    />
                    <span
                      className="input-group-text"
                      id="basic-addon-annuaire"
                    >
                      <IconlyPack.Search set="light" primaryColor="#000" />
                    </span>
                  </div>
                </div>
                <div className="col-md-12 pb-3">
                  <div>
                    <div>
                      <label className="pl-2">Filtrer</label>
                    </div>
                    <div className="w-full">
                      <CountrySelect
                        value={val}
                        onChange={changeHandler}
                        searchPartner
                        placeholder="Select Country"
                        size="sm"
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-4">
                <div className="row">
                  {catalogues?.isLoading && <CardSkeleton />}
                  {!catalogues?.isLoading &&
                    (catalogues?.filteredData?.length ? (
                      catalogues?.filteredData
                        ?.slice(0, itemToShow)
                        .map((item) => (
                          <div className="col-md-4" key={item?.id}>
                            <CatalogueItem item={item} />
                          </div>
                        ))
                    ) : (
                      <div className="gfa-rowy mt-5">
                        <div className="alert alert-warning gfa-warning">
                          Aucun catalogue disponible pour le moment
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {catalogues?.filteredData?.length > 9 ? (
                <div className="text-center my-5">
                  <a
                    className="btn btn-participate-event trans-0-2 ml-auto"
                    onClick={showMore}
                  >
                    {expand ? <span>Voir Moins</span> : <span>Voir Plus</span>}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start">
        <div className="bloc-banner2">
          <div className="container-warc">
            <div className="py-5 text-center">
              <h1 className="titre-banner-annuaire" style={{ color: "#000" }}>
                {t("catalogue")}
              </h1>
            </div>
          </div>
        </div>
        <div className="container-warc pb-5 pt-3">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control input-annuaire pl-3"
                  placeholder={t("search_catalogue")}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon-annuaire"
                  onChange={handleChange}
                />
                <span className="input-group-text" id="basic-addon-annuaire">
                  <IconlyPack.Search set="light" primaryColor="#000" />
                </span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex-justify-content-end">
              <div className="bloc-filter-right">
                <div className="w-full">
                  <label className="justify-content-end pr-2">
                    {t("filter")}
                  </label>
                </div>
                <div className="w-full mx-2">
                  {/* <select
                    className="form-control select-annuaire input-annuaire"
                    id="exampleFormControlSelect1"
                  >
                    <option>Pays</option>
                  </select> */}
                  <CountrySelect
                    value={val}
                    onChange={changeHandler}
                    searchPartner
                    placeholder={t("select_country")}
                    size="sm"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="my-4">
            <div className="row mb-5">
              {catalogues?.isLoading && <CardSkeleton />}
              {!catalogues?.isLoading &&
                (catalogues?.filteredData?.length ? (
                  catalogues?.filteredData?.slice(0, itemToShow).map((item) => (
                    <div className="col-md-4" key={item?.id}>
                      <CatalogueItem item={item} />
                    </div>
                  ))
                ) : (
                  <div className="gfa-rowy mt-5">
                    <div className="alert alert-warning gfa-warning">
                      {t("noData")}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {catalogues?.filteredData?.length > 9 ? (
            <div className="text-center my-5">
              <a
                className="btn btn-participate-event trans-0-2 ml-auto"
                onClick={showMore}
              >
                {expand ? (
                  <span>{t("see_less")}</span>
                ) : (
                  <span>{t("see_more")}</span>
                )}
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default CatalogueDesUniversites;

const CardSkeleton = () => {
  const renderModules = Array(12)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="col-xl-4 col-lg-4 col-md-4 pb-4">
        <Skeleton className="details-annuaire-item">
          <Skeleton className="bloc-card pt-2">
            <Skeleton className="d-flex justify-content-start">
              <Skeleton.Circle height={64} width={64} className="mb-3" />
              <Skeleton className="mb-3 w-100 m-2" width={`100%`}>
                <Skeleton.Rectangle
                  className="mb-1"
                  height={20}
                  width={`75%`}
                />
                <Skeleton.Rectangle
                  className="mb-1"
                  height={15}
                  width={`75%`}
                />
                <Skeleton.Rectangle
                  className="mb-1"
                  height={10}
                  width={`75%`}
                />
              </Skeleton>
            </Skeleton>
            <Skeleton className="d-flex justify-content-center mb-3 border-top border-bottom">
              <Skeleton.Rectangle height={50} width={`50%`} className="m-2" />
              <Skeleton.Rectangle height={50} width={`50%`} className="m-2" />
            </Skeleton>

            <Skeleton className="d-flex justify-content-center">
              <Skeleton.Rectangle
                width={`30%`}
                height={20}
                className="mb-3 mr-1 ml-1"
              />
            </Skeleton>
          </Skeleton>
        </Skeleton>
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
