import React from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { putData } from "../../../utils/fetchData";
import { useTranslation } from "react-i18next";
import SubAdminsSkeleton from "./SubAdminsSkeleton";
import { fetchAdmins } from "../../../../redux/admins/adminsAction";
import EditSubAdminModal from "../../../Modals/Admin/EditSubAdminModal";

function ListSubAdminsTable() {
  const { t } = useTranslation();
  const admins = useSelector((state) => state.admins);
  const dispatch = useDispatch();

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      // title: "Êtes vous sûr de vouloir archiver cet utilisateur?",
      title: t("warning_message_admin"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return putData(
          `adminuser/${item?.id}/`,
          { is_archived: !item?.is_archived, is_active: !item?.is_active },
          token
        )
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchAdmins());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  //   const OpenModal = (id) => {
  //     window.$(`#DetailsDocumentModal${id}`).modal("show");
  //   };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            // onClick={() => OpenModal(row?.id)}
            // data-toggle="modal"
            // data-target={`#DetailsDocumentModal${row?.id}`}
          />
        </button> */}
        <button className="action-btn edit-btn" title={t("edit")}>
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#ModifierSubAdminModal${row?.id}`}
          />
        </button>
        <button className="action-btn delete-btn" title={t("archive")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row)}
          />
        </button>
        <div
          className="modal fade"
          id={`ModifierSubAdminModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="ModifierSubAdminModalLabel"
          aria-hidden="true"
        >
          <EditSubAdminModal admin={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {admins?.isLoading && <SubAdminsSkeleton />}
            {!admins?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={admins?.data}
                  striped={true}
                  tableContainerClass="table-responsive"
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    filter={{ type: "TextFilter" }}
                    filterValue={(cell, row) => cell + " " + row?.nom}
                    dataField="prenom"
                    dataFormat={(cell, row) => cell + " " + row?.nom}
                  >
                    {t("firstName")} & {t("lastName")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="email"
                    // dataAlign="center"
                    filter={{
                      type: "TextFilter",
                    }}
                  >
                    {t("email")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="130"
                    filter={{ type: "TextFilter" }}
                    dataField="phone"
                  >
                    {t("phone")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="adresse"
                    filter={{
                      type: "TextFilter",
                    }}
                  >
                    {t("adresse")}
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="pays"
                    filter={{
                      type: "TextFilter",
                    }}
                  >
                    {t("country")}
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="80"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListSubAdminsTable;
